import {Injectable} from '@angular/core';
import {GrpcDataService} from './login/grpc-data.service';
import {KeyStoreServiceClient, SessionStoreServiceClient} from './api/security_pb_service';
import {PbChatSessions, PbKey, PbKeyList, PbRsaEncryptedForDevices, PbSession, PbSessionKey, PbSessionKeys} from './api/security_pb';
import {PbApproveDevice, PbChatMessage, PbGroupChatFind} from './api/groupchats_pb';
import {CypherService} from './cypher.service';
import {Empty, PbRegister, PbRsaEncrypted, PbUser} from './api/groups_pb';
import {TeamyAccount} from './login/teamy-account';
import * as jspb from 'google-protobuf';
import {CommonUtils} from './util/common.utils';

class ChatCrypto {
  currentSessionId?: string;
  sessionKeysMap: Map<string, CryptoKey> = new Map();
  missingSessions: Set<string> = new Set();
  undecryptedMessages: Map<string, PbChatMessage[]> = new Map();
  lastPeersRequest?: Date;

  addCurrentSessionKey(key: CryptoKey) {
    if (this.currentSessionId) {
      this.addSessionKey(this.currentSessionId, key);
    }
  }

  addSessionKey(sessionId: string, key: CryptoKey) {
    this.sessionKeysMap.set(sessionId, key);
    this.missingSessions.delete(sessionId);
  }

  addMissingSession(sessionId: string) {
    this.missingSessions.add(sessionId);
    this.lastPeersRequest = undefined; // clear lock
  }

  addUndecryptedMessage(msg: PbChatMessage) {
    const sessionid = msg.getSessionid();
    const undecrypted = this.undecryptedMessages.get(sessionid);
    if (undecrypted) {
      undecrypted.push(msg);
    } else {
      this.undecryptedMessages.set(sessionid, [msg]);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  keyStoreService = new KeyStoreServiceClient(this.grpcDataService.grpcURL, undefined);
  sessionStoreService = new SessionStoreServiceClient(this.grpcDataService.grpcURL, undefined);

  userId?: string;
  deviceId?: string;
  devicePrivateKey?: CryptoKey;
  devicePublicKey?: CryptoKey;
  generatedAccountPrivateKey?: CryptoKey; // temporarily stored till registration is complete
  accountPrivateKeys = new Map<number, CryptoKey>(); // version -> key

  chats: Map<string, ChatCrypto> = new Map(); // map by chatId
  cacheSessions?: Cache; // store inside browser

  constructor(private grpcDataService: GrpcDataService,
              private cypherService: CypherService) {
  }

  async sessionKeysUpdated(chatId: string, sessions: PbKeyList[]) {
    if (!this.chats.has(chatId)) {
      return;
    }
    const chatCrypto = this.chats.get(chatId);
    for (const keyList of sessions) {
      const sessionid = keyList.getSessionid();
      if (chatCrypto?.missingSessions.has(sessionid)) {
        console.log(`sessionKeysUpdated chatId=${chatId} sess=${sessionid}`);
        const key = await this.decryptSessionKey(keyList);
        if (key) {
          const messages = chatCrypto.undecryptedMessages.get(sessionid);
          chatCrypto.addSessionKey(sessionid, key);
          if (messages) {
            console.log(`sessionKeysUpdated chatId=${chatId} sess=${sessionid} decrypting ${messages.length} postponed messages`);
            for (const message of messages) {
              await this.decryptMessageBody(key, message);
            }
          }
          chatCrypto.undecryptedMessages.delete(sessionid);
        }
      }
    }
  }

  async sendEncryptedAccountPrivateKeys(code: string, foreignDevicePublicKey: Uint8Array) {
    const privKeys: Map<number, ArrayBuffer> = await this.exportAccountPrivateKeys();
    const pbKey: PbKey = new PbKey();
    pbKey.setKey(foreignDevicePublicKey);
    const response: PbRsaEncrypted = await this.cypherService.encryptRsa(pbKey, privKeys, this.maxAccountKeyVersion());
    response.setDataid(code);
    response.setVersion(this.maxAccountKeyVersion());
    await this.grpcDataService.call(this.keyStoreService, this.keyStoreService.shareAccountPrivateKey, response);
    console.log('shareAccountPrivateKey sent', response.getDataid());
  }

  private async exportAccountPrivateKeys() {
    const privKeys = new Map<number, ArrayBuffer>();
    for (const [version, key] of this.accountPrivateKeys) {
      const accountPrivateKeyBuffer: ArrayBuffer = await this.cypherService.exportPrivateKey(key);
      privKeys.set(version, accountPrivateKeyBuffer);
    }
    return privKeys;
  }

  async generateKeysAtRegistration(reg: PbRegister) {
    const timeStart: number = new Date().getTime();
    this.deviceId = 'Web/' + new Date().getTime() % 1000000; // @todo use UUID? // allow repeated logins from web by making deviceId always the same?
    const deviceKeyPair = await this.cypherService.generateRsaKeyPair();
    this.devicePublicKey = deviceKeyPair.publicKey;
    this.devicePrivateKey = deviceKeyPair.privateKey;
    const accountKeyPair = await this.cypherService.generateRsaKeyPair();
    const timeEnd: number = new Date().getTime();
    console.log(`Time to generate 2 RSA key pairs: ${timeEnd - timeStart} ms`);
    this.generatedAccountPrivateKey = accountKeyPair.privateKey;
    const accountPublicKey: CryptoKey = accountKeyPair.publicKey;

    const exportedAccountPublicKey: ArrayBuffer = await this.cypherService.exportPublicKey(accountPublicKey);
    reg.setAccountpublickey(new Uint8Array(exportedAccountPublicKey));
    await this.setDevicePublicKey(reg);
  }

  async setDevicePublicKey(reg: PbRegister) {
    if (this.devicePublicKey && this.deviceId) {
      const exportedDevicePublicKey: ArrayBuffer = await this.cypherService.exportPublicKey(this.devicePublicKey);
      reg.setDevicepublickey(new Uint8Array(exportedDevicePublicKey));
      reg.setDeviceid(this.deviceId);
      CommonUtils.setDeviceName(reg);
    }
  }

  async isMyDevicePublicKey(approveDevice: PbApproveDevice) {
    if (!this.devicePublicKey) {
      return false; // we don't store our public key in LocalStorage, it's lost after refresh
    }
    const reg = new PbRegister();
    await this.setDevicePublicKey(reg);
    return reg.getDevicepublickey_asB64() === approveDevice.getPublickey_asB64();
  }

  getChat(chatId: string): ChatCrypto {
    let res = this.chats.get(chatId);
    if (!res) {
      res = new ChatCrypto();
      this.chats.set(chatId, res);
    }
    return res;
  }

  // async logAccountKey() {
  //   let keyBytes: ArrayBuffer = await this.cypherService.exportPrivateKey(this.accountPrivateKey);
  //   console.log("Account private key:", new Uint8Array(keyBytes));
  // }

  async setAccount(account: TeamyAccount) {
    this.cacheSessions = await caches.open('sessions');
    this.userId = account.user.getId();
    if (account.accountPrivateKey) {
      await this.importAccountPrivateKeys(account.accountPrivateKey);
    } else {
      if (this.generatedAccountPrivateKey) {
        this.accountPrivateKeys.set(account.accountKeyVersion, this.generatedAccountPrivateKey); // my generated key accepted by server
      }
      this.generatedAccountPrivateKey = undefined;
    }
    this.saveAccountToLocalStorage(account);
    if (account.devicePublicKeys && account.devicePublicKeys.getLength() > 0) {
      this.multicastAccountPrivateKeys(account.devicePublicKeys);
    }
  }

  async multicastAccountPrivateKeys(devicePublicKeys: jspb.Map<string, Uint8Array | string>) {
    const privKeys: Map<number, ArrayBuffer> = await this.exportAccountPrivateKeys();

    const msg = new PbRsaEncryptedForDevices();
    for (const [devId, devKey] of devicePublicKeys.toArray()) {
      const pbKey: PbKey = new PbKey();
      pbKey.setKey(devKey);
      const response: PbRsaEncrypted = await this.cypherService.encryptRsa(pbKey, privKeys, this.maxAccountKeyVersion());
      msg.getDevicesMap().set(devId, response);
    }
    await this.grpcDataService.call(this.keyStoreService, this.keyStoreService.multicastAccountPrivateKeys, msg);
  }

  private async importAccountPrivateKeys(rsaEncrypted: PbRsaEncrypted) {
// some other device already logged in to my account has sent me all its account private keys
    if (!this.devicePrivateKey) {
      return;
    }
    const accountPrivateKeysBytes: Map<number, ArrayBuffer> = await this.cypherService.decryptRsaMap(this.devicePrivateKey, rsaEncrypted);
    for (const [version, bytes] of accountPrivateKeysBytes) {
      console.log('Importing account private key version: ' + version);
      const key = await this.cypherService.importPrivateKey(new Uint8Array(bytes));
      this.accountPrivateKeys.set(version, key);
    }
  }

  base64toUInt8Array(base64: string): Uint8Array {
    return new Uint8Array(atob(base64).split('').map(c => c.charCodeAt(0)));
  }

  uint8ArrayToBase64(array: Uint8Array): string {
    return btoa(String.fromCharCode(...array));
  }

  async loadAccountFromLocalStorage() {
    const stored = localStorage.getItem('acc');
    if (!stored) {
      return null;
    }
    const obj = JSON.parse(stored);
    const user: PbUser = PbUser.deserializeBinary(this.base64toUInt8Array(obj.userBin));
    this.cacheSessions = await caches.open('sessions');
    this.userId = user.getId();
    this.deviceId = obj.deviceId;
    this.devicePrivateKey = await this.cypherService.importPrivateKey(this.base64toUInt8Array(obj.devicePrKey));

    const storedKeys = localStorage.getItem('accKeys');
    if (storedKeys) {
      this.accountPrivateKeys.clear();
      for (const [version, keyStr] of Object.entries(JSON.parse(storedKeys))) {
        console.log('Importing private account key from local storage: version=' + version);
        const key: CryptoKey = await this.cypherService.importPrivateKey(this.base64toUInt8Array(keyStr as string));
        this.accountPrivateKeys.set(parseInt(version, 10), key);
      }
    }
    return new TeamyAccount(user, undefined, this.maxAccountKeyVersion(), obj.tokenTeamy, undefined);
  }

  maxAccountKeyVersion() {
    return Math.max.apply(Math, [...this.accountPrivateKeys.keys()]);
  }

  async saveAccountToLocalStorage(account: TeamyAccount) {
    if (!this.devicePrivateKey) {
      return;
    }
    const devicePrivateKeyBuffer = await this.cypherService.exportPrivateKey(this.devicePrivateKey);

    const data = JSON.stringify({
      user: account.user.toObject(),
      userBin: this.uint8ArrayToBase64(account.user.serializeBinary()),
      tokenTeamy: account.tokenTeamy,
      deviceId: this.deviceId,
      devicePrKey: this.uint8ArrayToBase64(new Uint8Array(devicePrivateKeyBuffer)),
    });
    localStorage.setItem('acc', data);
    await this.saveAccountKeysToLocalStorage();
  }

  private async saveAccountKeysToLocalStorage() {
    const exportedPrivKeyVersions: any = {};
    for (const [version, key] of this.accountPrivateKeys) {
      const arrayBuffer = await this.cypherService.exportPrivateKey(key);
      exportedPrivKeyVersions[version] = this.uint8ArrayToBase64(new Uint8Array(arrayBuffer));
    }
    localStorage.setItem('accKeys', JSON.stringify(exportedPrivKeyVersions));
  }

  signOut() {
    // localStorage.removeItem('accKeys');
    // localStorage.removeItem('acc');
    localStorage.clear();
    this.accountPrivateKeys.clear();
    console.log('encryption service signOut() done');
  }

  async decryptMessage(message?: PbChatMessage) {
    if (!message) {
      return;
    }
    const sessionid = message.getSessionid();
    if (!sessionid || !message.getEncrypted_asU8().length) {
      return;
    }
    const chatCrypto = this.getChat(message.getChatid());
    if (chatCrypto.missingSessions.has(sessionid)) {
      message.setBody('');
      chatCrypto.addUndecryptedMessage(message);
      return; // can't help this - just don't have the key to descrypt
    }
    let key = chatCrypto.sessionKeysMap.get(sessionid);
    if (!key) {
      console.log(`requesting session key from server: chatId=${message.getChatid()}, sessId=${sessionid}`);
      const cacheKey = `/?chatId=${message.getChatid()}&sessionId=${sessionid}`;
      const fromCache = await this.cacheSessions?.match(cacheKey);
      let keyList: PbKeyList;
      if (fromCache) {
        const bin = await fromCache.arrayBuffer();
        keyList = PbKeyList.deserializeBinary(new Uint8Array(bin)); // network call avoided
        console.log('decryptMessage from cache', cacheKey, keyList.toObject());
      } else {
        keyList = await this.getSessionIdKey(message.getChatid(), sessionid);
        await this.cacheSessions?.put(cacheKey, new Response(keyList.serializeBinary()));
      }
      key = await this.decryptSessionKey(keyList);
      if (!key) {
        console.log(`could not determine session key for sessionId=${sessionid}, userId=${this.userId}, msgId=${message.getId()} `, message.toObject());
        message.setBody('');
        chatCrypto.addMissingSession(sessionid);
        chatCrypto.addUndecryptedMessage(message);
        setTimeout(() => this.autoRequestSessionsKeysFromPeerUsers(), 1000);
        return;
      } else {
        chatCrypto.addSessionKey(sessionid, key);
      }
    }
    await this.decryptMessageBody(key, message);
  }

  private async decryptMessageBody(key: CryptoKey, message: PbChatMessage) {
    // console.log('decryptMessageBody');
    let descryptedStr = '???';
    try {
      const decrypted: ArrayBuffer = await this.cypherService.decryptSymmetric(key, message.getEncrypted_asU8());
      descryptedStr = new TextDecoder().decode(decrypted);
    } catch (e) {
      console.error('decryptMessageBody error ' + message.getChatid());
    }
    // console.log('decrypted message: ', descryptedStr, message.getEncrypted_asU8());
    message.setBody(descryptedStr);
  }

  async encryptMessage(msg: PbChatMessage, body: string) {
    const chatId: string = msg.getChatid();
    console.log('encryptMessage() called, userId=' + this.userId);
    let keyList = await this.getSessionKey(chatId);
    const {key: encryptedForMe} = this.findMyEncryptedKey(keyList);
    console.log('keyList, userId=' + this.userId, keyList.toObject(), encryptedForMe == null ? '-' : encryptedForMe.byteLength);
    if (!keyList.getSessionid() || !encryptedForMe) {
      keyList = await this.createAndSaveSession(chatId);
    }
    this.getChat(chatId).currentSessionId = keyList.getSessionid();
    let aesKey = await this.decryptSessionKey(keyList);
    if (!aesKey) {
      console.log('cannot decryptSessionKey creating new session', keyList.toObject());
      keyList = await this.createAndSaveSession(chatId);
      this.getChat(chatId).currentSessionId = keyList.getSessionid();
      aesKey = await this.decryptSessionKey(keyList);
      console.log('cannot decryptSessionKey creating new session result', aesKey, keyList.toObject());
    }
    if (!aesKey) {
      throw Error('encryptMessage: can neither get nor create session');
    }
    this.getChat(chatId).addCurrentSessionKey(aesKey);
    let encryptedMessage = new ArrayBuffer(0);
    if (body !== '') {
      encryptedMessage = await this.cypherService.encryptSymmetric(aesKey, new TextEncoder().encode(body));
    }
    msg.setSessionid(this.getChat(chatId).currentSessionId ?? '');
    const uint8Array = new Uint8Array(encryptedMessage);
    msg.setEncrypted(uint8Array);
    console.log(`encrypted message '${body}' to`, uint8Array);
  }

  findMyEncryptedKey(keyList: PbKeyList): { version: number | undefined; key: Uint8Array | undefined } {
    for (const pbKey of keyList.getKeylistList()) {
      if (pbKey.getId() === this.userId && this.accountPrivateKeys.has(pbKey.getVersion())) {
        return {version: pbKey.getVersion(), key: pbKey.getKey_asU8()};
      }
    }
    const keyListDescr = keyList.getKeylistList().map(pbKey => pbKey.getId() + '/' + pbKey.getVersion());
    console.log(`userId=${this.userId} vers ${[...this.accountPrivateKeys.keys()]} not found among ${keyList.getKeylistList().length} keys: ${keyListDescr}`);
    return {version: undefined, key: undefined};

    // const filtered = keyList.getKeylistList().filter(key => key.getId() === this.userId && key.getVersion() === this.accountKeyVersion);
    // if (filtered.length != 1) {
    //   console.info(`userId=${this.userId} vers ${this.accountKeyVersion} not found among ${keyList.getKeylistList().length} keys: ${keyList.getKeylistList().map(pbKey => pbKey.getId())}`);
    //   return null;
    // }
    // //console.log('filtered encrypted session keys:', this.userId, filtered);
    // const encryptedForMe: PbKey = filtered[0];
    // return encryptedForMe.getKey_asU8();
  }

  async decryptSessionKey(keyList: PbKeyList): Promise<CryptoKey | undefined> {
    const {version, key: encryptedForMe} = this.findMyEncryptedKey(keyList);
    // console.log('decryptSessionKey encryptedForMe=', encryptedForMe);
    if (!encryptedForMe || !version) {
      return Promise.resolve(undefined);
    }

    const privateKey = this.accountPrivateKeys.get(version);
    if (!privateKey) {
      return Promise.resolve(undefined);
    }
    // console.log('decryptSessionKey decrypting', privateKey);
    // crypto.subtle.decrypt(
    //   {name: 'RSA-OAEP',},
    //   privateKey,
    //   encryptedForMe)
    //   .then((value: ArrayBuffer) => {/*console.log('ok decryppt')*/
    //   }, err => console.error('decryptSessionKey error', err));

    let decryptedKey: ArrayBuffer;
    try {
      decryptedKey = await crypto.subtle.decrypt({name: 'RSA-OAEP'}, privateKey, encryptedForMe);
    } catch (e) {
      console.error('decryptSessionKey error', e);
      return Promise.resolve(undefined);
    }
    // console.log('decryptSessionKey decryptedKey=', decryptedKey);

    return this.cypherService.importAesKey(decryptedKey);
  }

  async createAndSaveSession(chatId: string): Promise<PbKeyList> {
    const newSessId: string = '' + new Date().getTime() % 1000000; // @todo use a longer value
    console.log('creating session with newSessId=' + newSessId);
    const cryptoKey = await this.cypherService.generateAesKey();
    const sessionKey = await this.cypherService.exportAesKey(cryptoKey);
    const publicKeys = await this.loadPublicKeys(chatId);
    const encryptedKeys = await this.encryptSessionKey(publicKeys.getKeylistList(), sessionKey);
    const result = await this.setSessionKey(chatId, newSessId, encryptedKeys);
    return result;
  }

  encryptSessionKey(pubKeys: PbKey[], sessionKey: ArrayBuffer): Promise<PbKey[]> {
    console.log('encrypting session key for pubKeys', pubKeys);
    const results = pubKeys.map(pubKey => this.cypherService.encryptAsymmetric(pubKey, sessionKey)
      .catch(error => {
        console.error('Error encrypting session key ' + pubKey.getId(), error);
        return undefined;
      }));
    return Promise.all(results)
      .then((buffers: Array<ArrayBuffer | undefined>) =>
        buffers.map((encryptedKey: ArrayBuffer | undefined, index) => {
          const k = new PbKey();
          const pbKeyChatMember = pubKeys[index];
          k.setId(pbKeyChatMember.getId());
          k.setVersion(pbKeyChatMember.getVersion());
          if (encryptedKey) {
            const uint8Array = new Uint8Array(encryptedKey);
            k.setKey(uint8Array);
          }
          return k;
        })
      );
  }

  getSessionIdKey(chatId: string, sessionId: string): Promise<PbKeyList> {
    const parm = new PbSession();
    parm.setChatid(chatId);
    parm.setSessionid(sessionId);
    return this.grpcDataService.call<PbSession, PbKeyList>(this.sessionStoreService, this.sessionStoreService.getSessionKey, parm);
  }

  async getSessionKey(chatId: string) {
    const parm = new PbSession();
    parm.setChatid(chatId);
    // parm.setUserid(userId);
    const res: PbKeyList = await this.grpcDataService.call<PbSession, PbKeyList>(this.sessionStoreService, this.sessionStoreService.getSessionKey, parm);
    console.log('getSessionKey', chatId, res.getSessionid(), res.getKeylistList());
    return res;
  }

  async setSessionKey(chatId: string, sessionId: string, keys: PbKey[]) {
    const sess = new PbSessionKey();
    sess.setChatid(chatId);
    const keyList = new PbKeyList();
    sess.setKeylist(keyList);
    keyList.setSessionid(sessionId);
    keyList.setKeylistList(keys);
    await this.grpcDataService.call<PbSessionKey, Empty>(this.sessionStoreService, this.sessionStoreService.setSessionKey, sess);
    return keyList;
  }

  async loadPublicKeys(chatId: string) {
    const find = new PbGroupChatFind();
    find.setChatid(chatId);
    return await this.grpcDataService.call<PbGroupChatFind, PbKeyList>(this.keyStoreService, this.keyStoreService.loadPublicKeys, find);
  }

  loadPublicKeysPromise(chatId: string): Promise<PbKeyList> {
    const find = new PbGroupChatFind();
    find.setChatid(chatId);
    return this.grpcDataService.call(this.keyStoreService, this.keyStoreService.loadPublicKeys, find);
  }

  // async accountPrivateKeyRequestEvent(accountprivatekeyrequest: PbKey) {
  //   try {
  //     console.log('accountPrivateKeyRequestEvent, local accountPrivateKey=', this.accountPrivateKey, accountprivatekeyrequest.getId());
  //     if (this.accountPrivateKey) {
  //       //@todo SECURITY RISK !!! need to alert the user and ask him permission describing the remote device somehow or displaying the remote public key
  //       if (confirm('SECURITY RISK !!! Do you want to encrypt and send account private key to another device? (...need some way to trust that device...)')) {
  //         const accountPrivateKeyBuffer: ArrayBuffer = await crypto.subtle.exportKey(
  //           'pkcs8', //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
  //           this.accountPrivateKey //can be a publicKey or privateKey, as long as extractable was true
  //         );
  //         console.log('export private key ok', accountPrivateKeyBuffer);
  //         const response: PbRsaEncrypted = await this.cypherService.encryptRsa(accountprivatekeyrequest, accountPrivateKeyBuffer, this.accountKeyVersion);
  //         response.setDataid(accountprivatekeyrequest.getId());
  //         await this.grpcDataService.call(this.keyStoreService, this.keyStoreService.shareAccountPrivateKey, response);
  //         console.log('shareAccountPrivateKey sent', response.getDataid());
  //       }
  //     }
  //   } catch (e) {
  //     console.error('accountPrivateKeyRequestEvent', e);
  //   }
  // }

  // async accountPrivateKeyResponseEvent(accountprivatekeyresponse: PbRsaEncrypted) {
  //   console.log("accountPrivateKeyResponseEvent, local accountPrivateKey=", this.accountPrivateKey, accountprivatekeyresponse.getDataid());
  //   if (this.deviceId === accountprivatekeyresponse.getDataid()) {
  //     let decryptedAccountPrivateKey: ArrayBuffer = await this.cypherService.decryptRsa(this.deviceKeyPair.privateKey, accountprivatekeyresponse);
  //     console.log("decryptedAccountPrivateKey", decryptedAccountPrivateKey);
  //     this.accountPrivateKey = await this.cypherService.importPrivateKey(new Uint8Array(decryptedAccountPrivateKey));
  //   } else {
  //     console.log("accountPrivateKeyResponseEvent ignored, response not for this device", accountprivatekeyresponse.getDataid());
  //   }
  // }

  autoRequestSessionsKeysFromPeerUsers() {
    console.log('autoRequestSessionsKeysFromPeerUsers');
    for (const entry of this.chats.entries()) {
      const [chatId, chatCrypto]: [string, ChatCrypto] = entry;
      if (chatCrypto.undecryptedMessages.size > 0 && chatCrypto.missingSessions.size > 0 && !chatCrypto.lastPeersRequest) {
        chatCrypto.lastPeersRequest = new Date(); // only ever ask once
        console.log(`autoRequestSessionsKeysFromPeerUsers chat=${chatId} missingSessions=${chatCrypto.missingSessions.size} undecryptedMessages=${chatCrypto.undecryptedMessages.size}`);
        this.requestSessionsKeysFromPeerUsers(chatId);
      }
    }
  }

  requestSessionsKeysFromPeerUsers(chatId: string) {
    const sessionIds = this.getChat(chatId).missingSessions;
    console.log('requestSessionsKeysFromPeerUsers', chatId, sessionIds);
    const sessions = new PbChatSessions();
    sessions.setChatid(chatId);
    sessions.setSessionidList([...sessionIds]);
    this.grpcDataService.call(this.sessionStoreService, this.sessionStoreService.requestPeerUsers, sessions);
  }

  async servePeerSessionsKeyRequest(from: string, sessions: PbChatSessions) {
    try {
      const keyList = await this.loadPublicKeys(sessions.getChatid());
      const keyListDescr = keyList.getKeylistList().map(pbKey => pbKey.getId() + '/' + pbKey.getVersion());
      console.log(`servePeerSessionsKeyRequest chatId=${sessions.getChatid()} current participants=${keyListDescr}`);
      const filtered = keyList.getKeylistList().filter(pbKey => pbKey.getId() === from);
      if (filtered.length !== 1) {
        console.error('servePeerSessionsKeyRequest: no public key for ' + from);
        return false;
      }

      const keys = new PbSessionKeys();
      keys.setChatid(sessions.getChatid());
      const sessionKeysMap = this.getChat(sessions.getChatid()).sessionKeysMap;
      console.log(`Peer needs sessions=${sessions.getSessionidList()} my=${[...sessionKeysMap.keys()]}`);
      for (const [sessionId, key] of sessionKeysMap.entries()) {
        if (sessions.getSessionidList().includes(sessionId)) {
          console.log('can provide session key', sessions.getChatid(), sessionId, key);
          const exportedSessionKey: ArrayBuffer = await this.cypherService.exportAesKey(key);
          console.log('exported session key', sessions.getChatid(), sessionId);
          const encrypted: PbKey[] = await this.encryptSessionKey(filtered, exportedSessionKey);
          console.log('encrypted session key', sessions.getChatid(), sessionId);
          const keyListSess = new PbKeyList();
          keyListSess.setSessionid(sessionId);
          keyListSess.setKeylistList(encrypted);
          keys.addKeylist(keyListSess);
        }
      }
      await this.grpcDataService.call(this.sessionStoreService, this.sessionStoreService.updateSessionKeys, keys);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async accountKeyUpdate(newKey: PbRsaEncryptedForDevices) {
    if (!this.deviceId) {
      return;
    }
    const keyForMe = newKey.getDevicesMap().get(this.deviceId);
    console.log('accountKeyUpdate keyForMe', keyForMe ? keyForMe.toObject() : keyForMe);
    if (keyForMe) {
      await this.importAccountPrivateKeys(keyForMe);
      await this.saveAccountKeysToLocalStorage();
    }
  }
}
