import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {concat, interval} from 'rxjs';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService {

  constructor(appRef: ApplicationRef, updates: SwUpdate) {
    console.log('CheckForUpdateService constructor');
    if (!updates.isEnabled) {
      console.log('CheckForUpdateService disabled');
      return;
    }
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    // const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const every30min$ = interval(30 * 60 * 1000);
    // const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
    const everyIntervalOnceAppIsStable$ = concat(appIsStable$, every30min$);

    everyIntervalOnceAppIsStable$.subscribe(() => {
      console.log(new Date() + ' SwUpdate checkForUpdate()');
      updates.checkForUpdate();
    });

    updates.available.subscribe(event => {
      console.log(new Date() + ' SwUpdate current version is', event.current);
      console.log(new Date() + ' SwUpdate available version is', event.available);
      if (window.confirm('There is a new version of Teamy available. Would you like to update?')) {
        updates.activateUpdate().then(() => document.location.reload());
      }
    });
    updates.activated.subscribe(event => {
      console.log(new Date() + ' SwUpdate old version was', event.previous);
      console.log(new Date() + ' SwUpdate new version is', event.current);
    });

    // setInterval(() => {
    //     console.log(new Date() + ' SwUpdate checkForUpdate()');
    //     this.updates.checkForUpdate();
    //   },
    //   5 * 60_000
    // ); // re-check every 5 minutes

  }
}
