import {Injectable} from '@angular/core';
import {interval, of} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {delayWhen, map, tap} from 'rxjs/operators';

import * as OAuth from 'oauth-1.0a';

import {PbCloudStorageToken, PbUser} from './api/groups_pb';
import {GrpcDataService} from './login/grpc-data.service';
import {UserServiceClient} from './api/groups_pb_service';
import {TimeService} from './time.service';

const consumer = {key: '2e9936de46635e78ad129d7adcff351e', secret: 'd184e61ad7774d0f61a9963f1dd0ae1f076bb5b5'};

interface FileResponse {
  id: string | undefined; // null only when upload cancelled by user, 4sync server cannot return undefined
  name: string | undefined; // null only when upload cancelled by user, 4sync server cannot return undefined
  code?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CloudStorageService {

  readonly MB = 1024 * 1024;
  // readonly MAX_UPLOAD_MB = 125; // TEAMYWEB-339: за 5-6 минут успешно залил 132912697 байт (=126.8 MByte) viber.rpm, а уже 150 мегабайт не проходит,
  readonly MAX_UPLOAD_MB = 1000; // new upload method, limit increased: TEAMYWEB-366 Upload: use chunks

  userService: UserServiceClient;
  oAuth: OAuth = new OAuth({
    consumer,
    // signature_method: 'HMAC-SHA1' - no need for this, unexpectedly PLAINTEXT also works
  });
  token?: OAuth.Token = undefined;
  userData?: { rootFolderId: string };

  urlsMap: Map<string, string> = new Map(); // avatars
  // getImageOrFileFinalUrlCache: Map<string, string> = new Map<string, string>(); // @todo do we need to invalidate the cache with timeout because URLs are ephemeral?
  cacheAvatars?: Cache;
  cachePreviewSizeL?: Cache; // 1080p
  cachePreviewSizeS?: Cache; // 240p

  private previewsSizeL: Map<string, string> = new Map(); // 1080p
  private previewsSizeS: Map<string, string> = new Map(); // 240p

  uploadProgress: Map<string, number> = new Map();
  uploadRequests: Map<string, XMLHttpRequest> = new Map();

  delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  constructor(private grpcDataService: GrpcDataService, private timeService: TimeService) {
    this.userService = new UserServiceClient(this.grpcDataService.grpcURL, undefined);

    // class OAuthSrvTime extends OAuth {
    //   getTimeStamp(): number {
    //     console.log('oAuth.getTimeStamp');
    //     return parseInt((timeService.getCorrectedTime() / 1000).toString(), 10);
    //   }
    // }
    // this.oAuth = new OAuthSrvTime({
    //   consumer,
    //   // signature_method: 'HMAC-SHA1' - no need for this, unexpectedly PLAINTEXT also works
    // });


    // this.oAuth.getTimeStamp = () => parseInt(new Date().getTime()/1000, 10);
    this.oAuth.getTimeStamp = () => {
      const correctedTime = timeService.getCorrectedTime();
      console.log(`oAuth.getTimeStamp local-to-server time delta: ${correctedTime - Date.now()}ms`);
      return parseInt((correctedTime / 1000).toString(), 10);
    };

    // ORIGINAL IMPLEMENTATION:

    // /**
    //  * Get Current Unix TimeStamp
    //  * @return {Int} current unix timestamp
    //  */
    // OAuth.prototype.getTimeStamp = function() {
    //   return parseInt(new Date().getTime()/1000, 10);
    // };

  }

  executeGet(resource: string) {
    const url = 'https://api.4sync.com/v1_2' + resource;
    // const url = 'https://api.4sync.com/web/rest/v1_2' + resource;
    // const url = 'https://api.dev4shared.com/v1_2' + resource; ///files/p4UFfZwXce/thumbnails/s
    return fetch(/*'https://cors-escape.herokuapp.com/' +*/ url, {
      headers: {
        ...this.oAuth.toHeader(this.oAuth.authorize({url, method: 'GET'}, this.token)),
        skipRedirect: 'true'
      },
      // redirect: 'error'
    });
  }

  // executeGetXMLHttpRequest(resource: string) {
  //   const url = 'https://api.4sync.com/v1_2' + resource;
  //   var xhr = new XMLHttpRequest();
  //   xhr.open('get', url);
  //   let aheader = this.oAuth.toHeader(this.oAuth.authorize({url: url, method: 'GET'}, this.token)).Authorization;
  //   xhr.setRequestHeader('Authorization', aheader);
  //   xhr.send();
  //   xhr.onreadystatechange = () => {
  //     console.log(resource + ' ready state ' + xhr.readyState);
  //     if (xhr.readyState == XMLHttpRequest.HEADERS_RECEIVED || xhr.readyState == XMLHttpRequest.DONE) {
  //       console.log(resource + ' location ' + xhr.getResponseHeader('Location'));
  //       debugger;
  //     }
  //   };
  // }

  /* no used because somehow dead-loop appeared when using AsyncPipe with this
  async getAvatarFinalUrl(avatarId: string) {
    console.log(`getAvatarFinalUrl '${avatarId}' `);
    if (!avatarId) return "";
    let prefix = "sourceId:";
    if (avatarId.startsWith(prefix)) avatarId = avatarId.substr(prefix.length);
    let response = await this.executeGet(`/files/${avatarId}/download`);
    let fileFinalUrl = response.headers.get("X-Final-Url");
    console.log(avatarId + " fileFinalUrl=", fileFinalUrl);
    return fileFinalUrl;
  }
  */

  getPreviewSize(fileId: string, size: string) {
    const prefix = 'sourceId:';
    if (fileId.startsWith(prefix)) {
      fileId = fileId.substr(prefix.length);
    }

    if (size === 'l') {
      if (this.previewsSizeL.has(fileId)) {
        return this.previewsSizeL.get(fileId);
      }
      this.previewsSizeL.set(fileId, ''); // marker, file is being processed
    } else {
      if (this.previewsSizeS.has(fileId)) {
        return this.previewsSizeS.get(fileId);
      }
      this.previewsSizeS.set(fileId, ''); // marker, file is being processed
    }

    this.retrievePreviewSize(fileId, size); // start async retrieve
    return '';
  }

  private async downloadAndCache(cache: Cache | undefined, fileId: string, url: string) {
    const responseBlob = await fetch(url);
    // cache.add(new Request(fileFinalUrl));
    cache?.put(fileId, responseBlob);
  }

  private async retrievePreviewSize(fileId: string, size = 'l') {
    // L: height-1080, width-1920
    // S: height-240, width-320
    const cache = size === 'l' ? this.cachePreviewSizeL : this.cachePreviewSizeS;
    const cacheResp = await cache?.match(fileId);
    if (cacheResp) {
      const blob = await cacheResp.blob();
      this.setPreviewToMap(fileId, URL.createObjectURL(blob), size);
      return;
    }

    const resource = `/files/${fileId}/thumbnails/` + size;
    const apiResp = await this.executeGet(resource);
    const location = apiResp.headers.get('Location');
    if (!location) {
      return;
    }
    console.log('retrievePreviewSize: ' + size + ' - ' + fileId, location);

    // try to get thumbnail, retry 3 times
    let dcResp = await fetch(location, {method: 'HEAD'});
    if (dcResp.ok) {
      this.setPreviewToMap(fileId, location, size);
      this.downloadAndCache(cache, fileId, location);
      return;
    }
    await this.delay(2000);
    console.log('retrievePreviewSizeL ' + fileId + ' retry 1', location);

    dcResp = await fetch(location, {method: 'HEAD'});
    if (dcResp.ok) {
      this.setPreviewToMap(fileId, location, size);
      this.downloadAndCache(cache, fileId, location);
      return;
    }
    await this.delay(5000);
    console.log('retrievePreviewSizeL ' + fileId + ' retry 2', location);

    dcResp = await fetch(location, {method: 'HEAD'});
    if (dcResp.ok) {
      this.setPreviewToMap(fileId, location, size);
      this.downloadAndCache(cache, fileId, location);
      return;
    }
    await this.delay(10_000);
    console.log('retrievePreviewSizeL ' + fileId + ' retry 3', location);

    dcResp = await fetch(location, {method: 'HEAD'});
    if (dcResp.ok) {
      this.setPreviewToMap(fileId, location, size);
      this.downloadAndCache(cache, fileId, location);
      return;
    }

    console.log('retrievePreviewSizeL ' + fileId + ' retry failed, using original file');
    const originalFile = await this.getImageOrFileFinalUrl(fileId);
    this.previewsSizeL.set(fileId, originalFile);
  }

  setPreviewToMap(fileId: string, location: string, size: string) {
    if (size === 'l') {
      this.previewsSizeL.set(fileId, location);
      return;
    }
    this.previewsSizeS.set(fileId, location);
    return;
  }

  async bgLoadAvatar(avatarId: string) {
    const responseBlob = await this.cacheAvatars?.match(avatarId);
    console.log('bgLoadAvatar', avatarId, responseBlob);
    if (responseBlob) {
      const blob = await responseBlob.blob();
      // console.log(`blob size for fileId=${fileId} is ${blob.size}`);
      this.urlsMap.set(avatarId, URL.createObjectURL(blob));
      return;
    }

    // console.log('getAvatarFinalUrlObservable not in cache', avatarId, this.urlsMap);
    // let resource = `/files/${avatarId}/download`;
    // const resource = `/files/${avatarId}/thumbnails/s`;

    // S: height-240, width-320
    const resource = `/files/${avatarId}/thumbnails/s`;

    // this.executeGetXMLHttpRequest(resource);

    const ready: { ok: boolean } = {ok: false};
    fromPromise(this.executeGet(resource)).pipe(
      // map(response => response.headers.get('X-Final-Url')),
      // map(response => this.getFileFinalUrl(response)),
      // switchMap(response => fromPromise(response.blob()) /*this.getFileFinalUrl(response)*/),
      // map(blob => URL.createObjectURL(blob)),
      // tap(response => {
      //   debugger;
      // }),
      // switchMap(response => fromPromise(response.text())),
      map(response => response.headers.get('Location') ?? ''),
      tap(url => console.log(`avatarId=${avatarId} finalUrl=${url}`)),
      this.delayTillPreviewReady(ready, avatarId, 2000),
      this.delayTillPreviewReady(ready, avatarId, 5000),
      this.delayTillPreviewReady(ready, avatarId, 10000),
      tap(url => {
        this.urlsMap.set(avatarId, url);
        this.saveAvatarToCache(avatarId, url);
      })
    ).subscribe(); // force execution
  }

  async saveAvatarToCache(avatarId: string, url: string) {
    const responseBlob = await fetch(url);
    // cache.add(new Request(fileFinalUrl));
    this.cacheAvatars?.put(avatarId, responseBlob);
  }

  getAvatarFinalUrl(avatarId: string): string {
    if (!avatarId) {
      return '';
    }
    if (avatarId.startsWith('https://')) {
      // e.g. https://lh5.googleusercontent.com/-zJY6arGBdIw/AAAAAAAAAAI/AAAAAAAAAZg/80I610mO0Z8/s96-c/photo.jpg
      return avatarId;
    }
    const prefix = 'sourceId:';
    if (avatarId.startsWith(prefix)) {
      avatarId = avatarId.substr(prefix.length);
    }
    // avatarId = 'p4UFfZwXce';

    if (this.urlsMap.has(avatarId)) {
      return this.urlsMap.get(avatarId) ?? '';
    }
    this.urlsMap.set(avatarId, '');
    this.bgLoadAvatar(avatarId);
    return this.urlsMap.get(avatarId) ?? '';
  }

  delayTillPreviewReady(ready: { ok: boolean }, avatarId: string, delay: number) {
    return delayWhen((url: string) => {
      if (ready.ok) {
        return of(undefined);
      }
      // check for resized preview readiness
      return fromPromise(fetch(url, {method: 'HEAD'})).pipe(
        tap(response => console.log('avatar ready? response=' + response.status + ` avatarId=${avatarId} finalUrl=${url}`)),
        tap(response => {
          if (response.ok) {
            ready.ok = true; // skip future retries
          }
        }),
        delayWhen(response => response.ok ? of(undefined) : interval(5000))
      );
    });
  }

  async getImageOrFileFinalUrlForDownload(fileId: string) {
    // no content blob caching here: 1) file can be large 2) content attachment true file name header must not be lost 3) download progress should be seen
    console.log(`getImageOrFileFinalUrlForDownload '${fileId}' `);
    if (!fileId) {
      return '';
    }
    const prefix = 'sourceId:';
    if (fileId.startsWith(prefix)) {
      fileId = fileId.substr(prefix.length);
    }

    const response: Response = await this.executeGet(`/files/${fileId}/download`);
    const fileFinalUrl = this.getFileFinalUrl(response);
    console.log(fileId + ' fileFinalUrl=', fileFinalUrl);
    return fileFinalUrl;
  }

  async getImageOrFileFinalUrl(fileId: string) {
    console.log(`getImageOrFileFinalUrl '${fileId}' `);
    if (!fileId) {
      return '';
    }
    const prefix = 'sourceId:';
    if (fileId.startsWith(prefix)) {
      fileId = fileId.substr(prefix.length);
    }

    const cache = await caches.open('cloud-media-cache');
    let responseBlob = await cache.match(fileId);

    // if (this.getImageOrFileFinalUrlCache.has(fileId)) {
    //   return this.getImageOrFileFinalUrlCache.get(fileId);
    // }
    if (!responseBlob) {
      const response: Response = await this.executeGet(`/files/${fileId}/download`);
      const fileFinalUrl = this.getFileFinalUrl(response);
      console.log(fileId + ' fileFinalUrl=', fileFinalUrl);
      // this.getImageOrFileFinalUrlCache.set(fileId, fileFinalUrl);
      if (!fileFinalUrl) {
        return '';
      }
      responseBlob = await fetch(fileFinalUrl);
      // cache.add(new Request(fileFinalUrl));
      cache.put(fileId, responseBlob.clone()); // without clone() we have: TypeError: Failed to execute 'blob' on 'Response': body stream is locked
    }
    const blob = await responseBlob.blob();
    console.log(`blob size for fileId=${fileId} is ${blob.size}`);
    return URL.createObjectURL(blob);

    // return fileFinalUrl;
  }

  private getFileFinalUrl(response: Response) {
    // const fileFinalUrl = response.headers.get('X-Final-Url'); - for /*'https://cors-escape.herokuapp.com/' +*/
    let fileFinalUrl = response.headers.get('Location');
    const alternativeUrl = response.headers.get('X-Location');
    if (alternativeUrl && Math.random() < 0.5) {
      // Location: https://dc414.4sync.com/web/rest/download/endpoint/JpDKvvaz/DOWNLOAD/00pMA9AhNcCiDvPZkBHYI8mdn69ysgBpiG6DSl3zIzprDFhLrXyc-M2XM2MPwpfRDmMid6Zu_x3o1LvqrLN2N0cnu6kAfhPh7pmpKGVge31idAQp_9A5wcOAPXto7VZyH2ZduSbaXMdCsJsVpn3wdLl2d9H_5hgrTvBdkp2cahy37536yHMxaWCY4yGuNIW5bqzH0WcMTTZVU6qoKsrqrs9f7AuIfSAfRVqKo_2Wgu7OH4gJW0baQm9fyls6geFdC9
      // X-Location: ...

      fileFinalUrl = alternativeUrl; // @todo maybe need to sent HEAD request to make sure file exists before choosing the server?
    }
    return fileFinalUrl;
  }

  // @todo fetch-based version doesn't support upload progress measured in bytes yet, maybe splitting a file into pieces would be a solution
  // async uploadFileFetch(file: File) {
  //   const url = 'https://upload.4sync.com/v1_2' + '/files?folderId=' + this.userData?.rootFolderId + '&fileName=' + encodeURIComponent(file.name);
  //   const response = await fetch(url, {
  //     method: 'POST',
  //     body: file,
  //     headers: {
  //       ...this.oAuth.toHeader(this.oAuth.authorize({url, method: 'POST'}, this.token)),
  //       'Content-Type': 'application/octet-stream'
  //     }
  //   });
  //   const json: { id: string; name: string } = await response.json();
  //   console.log('upload result', json);
  //   return json;
  // }

  async createFolder(parentId: string, name: string) {
    const url = 'https://api.4sync.com/v1_2/folders';
    const bodyData = 'parentId=' + parentId + '&name=' + encodeURIComponent(name);
    const response = await fetch(url, {
      method: 'POST',
      body: bodyData,
      headers: {
        ...this.oAuth.toHeader(this.oAuth.authorize({url, method: 'POST', data: bodyData, includeBodyHash: false}, this.token)),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    // {"id":"SIRenXbh","name":"+380672222000","description":"","parentId":"tNZTuE1y","path":"/My 4Sync/+380672222000","modified":"2020-05-27T13:25:49.184Z","access":"private",
    // "numChildren":0,"numFiles":0,"ownerId":"FJyfEpOY","permissions":"read","passwordProtected":false,"folderLink":"https://www.4sync.com/s/dSIRenXbh",
    // "userPermissions":"owner","status":"normal","hasMembers":false}
    const json: { id: string; name: string; parentId: string } = await response.json();
    console.log('createFolder result', json);
    return json.id;
  }

  async getFolder(name: string) {
    const response = await this.executeGet(`/folders/${this.userData?.rootFolderId}/children`);
    const folderChildren: { folders: Array<{ id: string; name: string }> } = await response.json();
    console.log('folders under root:', folderChildren);
    let folderId = folderChildren.folders.filter(f => f.name === name).map(f => f.id)[0];
    if (!folderId && this.userData) {
      folderId = await this.createFolder(this.userData.rootFolderId, name);
    }
    return folderId;
  }

  fileAddNumber(name: string, num: number) {
    const posLastDot = name.lastIndexOf('.');
    if (posLastDot < 0) {
      return `${name} (${num})`;
    }
    return name.substr(0, posLastDot) + ` (${num})` + name.substr(posLastDot);
  }

  async uploadFileOver150M(uploadId: string, folderId: string, file: File): Promise<FileResponse> {
    // https://www.4sync.com/developer/docs/
    if (!folderId && this.userData) {
      folderId = this.userData.rootFolderId;
    }

    let jsonStart: FileResponse | undefined;
    for (let i = 0; i <= 200; i++) {
      const urlStart = 'https://upload.4sync.com/v1_2' + '/upload';
      const bodyData = 'folderId=' + folderId + '&size=' + file.size + '&name=' + encodeURIComponent(i === 0 ? file.name : this.fileAddNumber(file.name, i));
      const responseStart = await fetch(urlStart, {
        method: 'POST',
        body: bodyData,
        headers: {
          ...this.oAuth.toHeader(this.oAuth.authorize({url: urlStart, method: 'POST', data: bodyData, includeBodyHash: false}, this.token)),
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });
      console.log('responseStart', responseStart.status);
      jsonStart = await responseStart.json();
      console.log('jsonStart', jsonStart);
      if (jsonStart?.code === '403.0201') {
        continue;
      }
      if (jsonStart?.id) {
        break;
      }
      throw jsonStart;
    }
    if (!jsonStart?.id) {
      throw jsonStart;
    }

    // jsonStart
    // {message: "The file or folder with name 44.txt already exists.", code: "403.0201", cause: null}

    // const urlStatus1 = 'https://upload.4sync.com/v1_2' + '/upload/' + jsonStart.id + '/status';
    // const responseStatus1 = await fetch(urlStatus1, {
    //   method: 'GET',
    //   headers: {
    //     ...this.oAuth.toHeader(this.oAuth.authorize({url: urlStatus1, method: 'GET'}, this.token)),
    //   }
    // });
    // console.log('responseStatus1', responseStatus1.status, responseStatus1.headers.get('Range'));

    const urlBody = 'https://upload.4sync.com/v1_2' + '/upload/' + jsonStart.id;
    // bytes {firstByteInclusive}-{lastByteInclusive}/{wholeFileSize}
    const respBody = await this.uploadFileBody(urlBody, uploadId, file, 201, `bytes 0-${file.size - 1}/${file.size}`, '' + file.size);
    console.log('respBody', respBody);
    if (respBody.abort) {
      return {id: undefined, name: undefined};
    }

    // const responseStatus2 = await fetch(urlStatus1, {
    //   method: 'GET',
    //   headers: {
    //     ...this.oAuth.toHeader(this.oAuth.authorize({url: urlStatus1, method: 'GET'}, this.token)),
    //   }
    // });
    // console.log('responseStatus2', responseStatus2.status, responseStatus2.headers.get('Range'));

    return jsonStart;
  }

  async uploadFile(uploadId: string, folderId: string, file: File): Promise<FileResponse> {
    if (!folderId && this.userData) {
      folderId = this.userData.rootFolderId;
    }
    const url = 'https://upload.4sync.com/v1_2' + '/files?folderId=' + folderId + '&fileName=' + encodeURIComponent(file.name);
    const upl = await this.uploadFileBody(url, uploadId, file, 200);
    if (upl.abort || !upl.txt) {
      return {id: undefined, name: undefined};
    }
    const json: FileResponse = JSON.parse(upl.txt);
    console.log('upload result', json);
    return json;
  }

  private uploadFileBody(url: string, uploadId: string, file: File, expectStatus: number, contentRange?: string, contentLength?: string) {
    const oauthHeader = this.oAuth.toHeader(this.oAuth.authorize({url, method: 'POST'}, this.token));

    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = (e: ProgressEvent) => {
      const percentComplete = (e.loaded / e.total) * 100;
      console.log('uploadFile %=' + percentComplete);
      this.uploadProgress.set(uploadId, percentComplete);
    };

    this.uploadProgress.set(uploadId, 0);
    this.uploadRequests.set(uploadId, xhr);
    return new Promise<{ abort: boolean; txt?: string }>((resolve, reject) => {
      xhr.onload = () => {
        this.uploadProgress.delete(uploadId);
        this.uploadRequests.delete(uploadId);
        if (xhr.status === expectStatus) {
          // alert("Sucess! Upload completed");
          resolve({abort: false, txt: xhr.responseText});
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = () => {
        this.uploadProgress.delete(uploadId);
        this.uploadRequests.delete(uploadId);
        reject('Error! Upload failed. Can not connect to server.');
        // alert("Error! Upload failed. Can not connect to server.");
      };
      xhr.onabort = () => {
        this.uploadProgress.delete(uploadId);
        this.uploadRequests.delete(uploadId);
        resolve({abort: true});
      };
      xhr.open('POST', url, true);
      // artem.web
      // фишка в том, что тут нужно лить application/octet-stream, он легче в обработке.
      xhr.setRequestHeader('Content-Type', 'application/octet-stream'/*file.type*/);
      xhr.setRequestHeader('Authorization', oauthHeader.Authorization);
      if (contentRange) {
        xhr.setRequestHeader('Content-Range', contentRange);
      }
      // if (contentLength) { // Refused to set unsafe header "Content-Length"
      //   xhr.setRequestHeader('Content-Length', contentLength);
      // }
      xhr.setRequestHeader('ngsw-bypass', 'true'); // https://angular.io/guide/service-worker-devops - feature that is currently not supported in service workers (e.g. reporting progress on uploaded files)
      xhr.send(file);
    });

    // const response = await fetch(url, {
    //   method: 'POST',
    //   body: file,
    //   headers: {
    //     ... this.oAuth.toHeader(this.oAuth.authorize({url: url, method: 'POST'}, this.token)),
    //     'Content-Type': 'application/octet-stream'
    //   }
    // });
    // const json: { id: string, name: string } = {id: "ID0", name: "NAME0"};
    // console.log('upload result', json);
    // return json;
  }

  hasUploadProgress(uploadId: string) {
    return this.uploadProgress.has(uploadId);
  }

  getUploadProgress(uploadId: string) {
    return Math.round(this.uploadProgress.get(uploadId) ?? 0);
  }

  cancelUpload(uploadId: string) {
    const xmlHttpRequest = this.uploadRequests.get(uploadId);
    if (xmlHttpRequest) {
      xmlHttpRequest.abort();
    }
  }

  parseFormData(data: string) {
    const mapFormData = new Map<string, string>();
    const items = data.split('&');
    for (const item of items) {
      const nameValue = item.split('=');
      mapFormData.set(nameValue[0], decodeURIComponent(nameValue[1]));
    }
    return mapFormData;
  }

  async connectCloudStorage(loginUser: string) {
    // https://www.4sync.com/developer/docs/#nav_method_folders

    console.log('connectCloudStorage start');
    const user = new PbUser();
    user.setId(loginUser);
    const storageToken = await this.grpcDataService.call<PbUser, PbCloudStorageToken>(this.userService, this.userService.getCloudStorageToken, user);
    console.log('Token 4sync: ', storageToken.getToken4syncsauth());

    // this.oAuth = new OAuth({
    //   consumer,
    //   // signature_method: 'HMAC-SHA1' - no need for this, unexpectedly PLAINTEXT also works
    // });

    const response: Response = await this.executeGet('/oauth/token?sauth_service=Teamy&sauth_token=' + storageToken);
    console.log('got response from /oauth/token', response.status, response);

    // const accessTokenFormData = await response.formData(); - not supported in Safari: https://developer.mozilla.org/en-US/docs/Web/API/Body/formData
    // oauth_token=5d5b345dfda33099c4a1aae0028aeca6&oauth_token_secret=fec2eab55ab659467830c5fc8e80f4d2e1e0af48
    const accessTokenData = await response.text();
    const accessTokenFormData = this.parseFormData(accessTokenData);
    console.log('accessTokenFormData', accessTokenFormData);

    console.log('fetch response, accessToken form-data', response.status, accessTokenFormData.get('oauth_token'), accessTokenFormData.get('oauth_token_secret'));
    if (response.status !== 200) {
      return;
      // alert('Cloud storage connection failed!');
    }
    // let accessToken = await response.text();
    // console.log("fetch response, accessToken", response.status, accessToken); // e.g.: oauth_token=a6c6a18b6450b6133bf268a8f67e012f&oauth_token_secret=5af5342aa827ac4a3a36ca915881d7b1505b71e4

    // let re = /^oauth_token=([^&]+)&oauth_token_secret=([^&]+)$/;
    // let found = accessToken.match(re);
    // if (found) {
    //   let [, oauth_token, oauth_token_secret] = found;
    this.token = {
      // key: oauth_token, secret: oauth_token_secret
      key: accessTokenFormData.get('oauth_token') as string,
      secret: accessTokenFormData.get('oauth_token_secret') as string
    };
    const response2 = await this.executeGet('/user');
    this.userData = await response2.json();
    console.log('fetch response2, userData', this.userData);

    this.cacheAvatars = await caches.open('avatar-cache');
    this.cachePreviewSizeL = await caches.open('PreviewSizeL');
    this.cachePreviewSizeS = await caches.open('PreviewSizeS');

    // this.getAvatarFinalUrlObservable("v6sMe2WK").subscribe(url => {
    //   console.log('test avatar: ' + url);
    // });

    // let response3 = await this.executeGet("/files/fKquS9-E/download");
    // let response3 = await this.executeGet("/files/fKquS9-E/preview");


    // const response3 = await this.executeGet('/files/NhOEAfrA/download'); // avatar
    // const fileFinalUrl = response3.headers.get('X-Final-Url');
    // console.log('fileFinalUrl=', fileFinalUrl);


    // let imageArrayBuffer = await response3.arrayBuffer(); //@todo should we use Blob ?
    // console.log("fetch response3, imageArrayBuffer", imageArrayBuffer);
//    }

    /*
    Seems to work with oauth_signature_method=\"PLAINTEXT\"

    [user@localhost Web-Client]$ curl -H "Authorization: OAuth oauth_consumer_key=\"2e9936de46635e78ad129d7adcff351e\", oauth_nonce=\"whEiSVNIhEKXNyxR5r0IwfIJoc3R3EFs\",
    oauth_signature=\"d184e61ad7774d0f61a9963f1dd0ae1f076bb5b5%26\", oauth_signature_method=\"PLAINTEXT\", oauth_timestamp=\"1546597364\", oauth_version=\"1.0\""
    "https://api.4sync.com/v1_2/oauth/token?sauth_service=Teamy&sauth_token=,,W2pUhxywj4HF7HYePkp-KohrvUqFljcBTWNh6E6A4VTKk9N5qokaaTBdFj8VRwgaiG3rhY2uq3R631gbYaHQtM86Cy8bkvyE-uGKqXO0yRY9SC_L4fkB4gPhBz0-gNama5PpfZ0uurPbUStfitljqgoqzfJWQIqNDxPxvJQJtJA"
    oauth_token=33128c9b947f4e6870e762a0772d3140&oauth_token_secret=452cff84fe404ed77ca6e91708d17ec764eef5c2[user@localhost Web-Client]$

     */

    // if (response.status !== 200) {
    // let oauth = new OAuth({
    //   consumer: {public: "2e9936de46635e78ad129d7adcff351e", secret: "d184e61ad7774d0f61a9963f1dd0ae1f076bb5b5"}
    // });
    // let header = oauth.getHeader({
    //   url: url,
    //   method: 'GET'
    // }, {});
    // console.log("Header", header);

//     *     headers: {
//       *         Authorization: oauth.getHeader(request_data, token)
//         *     }
//
//         static DefaultOAuthConsumer consumer = new DefaultOAuthConsumer("2e9936de46635e78ad129d7adcff351e",
//             "d184e61ad7774d0f61a9963f1dd0ae1f076bb5b5");
//
//         public static void main(String[] args) throws Exception {
// // create an HTTP request to a protected resource
//             URL url = new URL("https://api.4sync.com/v1_2/oauth/token?sauth_service=Teamy&sauth_token=iu5xVSNu8DFnj7NVA9ieXohrvUqFljcBTWNh6E6A4VTW_aPS9EoRqe4DGHphpHR7WgZnSlCLhq3JjI8aL7qabSGS_a-Pd7AI7gQbWZ5DdHeumh4wngqmNtj9kkxEEULDfroA3YCyGWC9bVXogkkU_MhGg539ujqxtqloGxxOXXs");
//             HttpURLConnection request = (HttpURLConnection) url.openConnection();
//
//             // sign the request (consumer is a Signpost DefaultOAuthConsumer)
//             consumer.sign(request);
//
//       });

  }

}
