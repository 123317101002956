import {Injectable} from '@angular/core';
import {PbUser} from '../api/groups_pb';

@Injectable({
  providedIn: 'root'
})
export class AvatarDataService {

  AVATARS_COLOR = ['#FF8A65', '#FFB74D', '#FFD54F', /*'#FFF176',*/ '#DCE775', '#AED581', '#81C784', '#4DB6AC', '#4DD0E1',
    '#4FC3F7', '#64B5F6', '#7986CB', '#9575CD', '#BA68C8', '#F06292', '#E57373'];

  BUBBLE_COLOR = ['#FFEEE8', '#FFF4E5', '#FFF9E5', '#FAFCEA', '#F3F9EC', '#ECF7ED', '#E5F4F3', '#E5F8FB', '#E5F6FE',
    '#E8F4FE', '#EBEDF7', '#EFEAF8', '#F5E9F7', '#FDE8EF', '#FBEAEA'];

  // abbreviate full name to a couple of characters
  public getCharacter(fullName: string, textLen: number): string {
    if (fullName && fullName.trim() !== '') {
      try {
        let builder = '';
        if (textLen === 1) {
          builder += String.fromCodePoint(fullName.codePointAt(0) ?? 0);
        } else {
          const words: string[] = fullName.trim().split(' ');
          if (words.length >= 2) {
            builder += String.fromCodePoint(words[0].codePointAt(0) ?? 0);
            builder += String.fromCodePoint(words[1].codePointAt(0) ?? 0);
          } else {
            builder += fullName.substring(0, Math.min(textLen, fullName.length));
          }
        }
        return builder;
      } catch (exception) {
        // ignored
      }
    }
    return '#';
  }

  // tslint:disable:no-bitwise
  hashCode(str: string): number {
    let hash = 0;
    let i;
    let chr;
    if (str.length === 0) {
      return hash;
    }
    /* eslint-disable no-bitwise */
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  userName(user: PbUser) {
    const fullName = user.getFullname();
    if (fullName && fullName.trim() !== '') {
      return fullName;
    }
    return user.getId();
  }

  public getLinearGradient(user: PbUser): string {
    return this.getLinearGradientByName(this.userName(user));
  }

  public getLinearGradientByName(name: string): string {
    const colors = this.getColorPair(name);
    const grad = `linear-gradient(${colors[0]}, ${colors[1]})`;
    return grad;
  }

  public getBubbleColor(fullName: string): string {
    const hash = this.hashCode(fullName);
    const index1 = Math.abs(hash) % this.BUBBLE_COLOR.length;
    return this.BUBBLE_COLOR[index1];
  }

  public getColorPair(fullName?: string): string[] {
    const hash = fullName ? this.hashCode(fullName) : 0;
    const index1 = Math.abs(hash) % this.AVATARS_COLOR.length;
    const index2 = index1 + 1 < this.AVATARS_COLOR.length ? index1 + 1 : 0;
    // console.log("getColorPair " + fullName + " " + index1 + " " + index2 + " " + hash);

    return [this.AVATARS_COLOR[index1], this.AVATARS_COLOR[index2]];
  }
}
