import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';
import {ModalComponent} from './dialog/modal.component';
import {SettingsComponent} from './settings/settings.component';
import {AvatarComponent} from './avatar/avatar.component';
import {FullnameComponent} from './fullname/fullname.component';
import {NewtopicComponent} from './newtopic/newtopic.component';
import {ChooseUsersComponent} from './chooseusers/choose-users.component';

import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeUk from '@angular/common/locales/uk';
import localeUkExtra from '@angular/common/locales/extra/uk';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {GroupavatarComponent} from './groupavatar/groupavatar.component';
import {InfoComponent} from './info/info.component';
import {ChatComponent} from './chat/chat.component';
import {ChatslistComponent} from './chatslist/chatslist.component';
import {ImageviewComponent} from './imageview/imageview.component';
import {MaterialModule} from './material/material.module';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {EmojiModule} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {DigitOnlyDirective} from './digit-only.directive';
import {OneDigitOnlyDirective} from './one-digit-only.directive';
import {AngularPageVisibilityModule} from 'angular-page-visibility';
import {DragDropDirective} from './drag-n-drop-upload.directive';
import {DragScrollModule} from 'cdk-drag-scroll';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import {EmojiToSpanPipe} from './emoji-to-span.pipe';
import {MatDialogModule} from '@angular/material/dialog';
import { TeaminfoComponent } from './teaminfo/teaminfo.component';

const playerFactory = () => player;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ModalComponent,
    SettingsComponent,
    AvatarComponent,
    FullnameComponent,
    NewtopicComponent,
    ChooseUsersComponent,
    GroupavatarComponent,
    InfoComponent,
    ChatComponent,
    ChatslistComponent,
    ImageviewComponent,
    DigitOnlyDirective,
    OneDigitOnlyDirective,
    DragDropDirective,
    EmojiToSpanPipe,
    TeaminfoComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PickerModule,
    EmojiModule,
    LottieModule.forRoot({player: playerFactory}),
    AngularPageVisibilityModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatDialogModule,
    DragScrollModule,
  ],
  providers: [
    Title,
    EmojiToSpanPipe,
  ],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
registerLocaleData(localeUk, 'uk', localeUkExtra);
registerLocaleData(localeRu, 'ru', localeRuExtra);
registerLocaleData(localeDe, 'de', localeDeExtra);
