import {Injectable} from '@angular/core';
import {GroupChatDataService} from './groupchats/groupchat-data.service';
import {ChatDataService} from './chats/chat-data.service';
import {EncryptionService} from './encryption.service';
import {PbChatMessage, PbGroupChat, PbPromotion} from './api/groupchats_pb';
import {LinkParseService} from './login/link-parse.service';
import {NewMessage} from './new-message';
import {IndexedDbService} from './indexed-db.service';
import {GroupUtils} from './util/group.utils';

const LIMIT = 50;

@Injectable({
  providedIn: 'root'
})
export class MessageDbService {

  private db = new Map<string, PbChatMessage[]>(); // key=chatId
  private hasMore = new Set<string>(); // chatIds where we need to load older messages from the server
  private sentUnconfirmed = new Set<string>(); // messageIds being sent
  private sentFromThisDevice = new Set<string>();
  private sendErrors: Map<string, NewMessage> = new Map<string, NewMessage>(); // messageIds with errors
  private sentFileHandles = new Map<string, FileSystemFileHandle>(); // messageId -> file sent

  constructor(private groupChatDataService: GroupChatDataService,
              private chatDataService: ChatDataService,
              private linkParseService: LinkParseService,
              private encryptionService: EncryptionService,
              private indexedDbService: IndexedDbService,
  ) {
  }

  pushNotRegisteredShareMessage(userId: string) {
    if (!this.db.has(userId)) {
      console.log('pushNotRegisteredShareMessage', userId);
      const msg = new PbChatMessage();
      msg.setCreationdate(Date.now());
      msg.setFrom(GroupUtils.TEAMY_BOT);
      const prom = new PbPromotion();
      prom.setHtmltext('I\'m not registered yet. Please share to me Teamy.');
      prom.setLinktext('SHARE');
      prom.setLink('REG');
      msg.setPromotion(prom);
      this.db.set(userId, [msg]);
    }
  }

  getCachedMessages(chat: PbGroupChat) {
    return this.db.get(chat.getId());
  }

  hasCachedMessages(chat?: PbGroupChat) {
    if (!chat) {
      return false;
    }
    const cached = this.db.get(chat.getId());
    return cached && cached.length > 0;
  }

  async onScrolledToTop(chat: PbGroupChat, callback?: () => void) {
    const history = this.db.get(chat.getId());
    if (history && this.hasMore.has(chat.getId())) {
      const first = history[0];
      const historyBlock = await this.loadHistoryBlock(chat, first);
      if (history[0] === first) { // additional check because async can be called twice
        history.unshift(...historyBlock);
      }
      setTimeout(() => callback && callback(), 0);
    } else {
      return false;
    }
    return undefined;
  }

  async getMessages(chat: PbGroupChat) {
    if (!this.db.has(chat.getId())) {
      let history = await this.indexedDbService.loadChatMessages(chat.getId());
      if (history && history.length > 0 && history[history.length - 1].getId() === chat.getLastMessage()?.getId()) {
        // no recent changes, take history from cache
      } else {
        history = await this.loadHistoryBlock(chat);
        if (history.length > 0) {
          this.indexedDbService.saveChatMessages(chat.getId(), history);
        }
      }
      this.db.set(chat.getId(), history);
    }
    return this.db.get(chat.getId());
  }

  hasMoreHistory(chat: PbGroupChat) {
    return this.hasMore.has(chat.getId());
  }

  private async loadHistoryBlock(chat: PbGroupChat, before?: PbChatMessage) {
    let history: PbChatMessage[];
    if (chat.getId().includes('@')) {
      history = await this.groupChatDataService.getHistory(chat, LIMIT, before?.getCreationdate() ?? 0);
    } else {
      history = await this.chatDataService.getHistory(chat, LIMIT, before?.getCreationdate() ?? 0);
    }
    if (history.length === LIMIT) {
      this.hasMore.add(chat.getId());
      console.log(`hasMore messages ${chat.getId()} ${chat.getDescription()}`);
    } else {
      this.hasMore.delete(chat.getId());
      console.log(`no more messages ${chat.getId()} ${chat.getDescription()}`);
    }
    for (const message of history) {
      await this.encryptionService.decryptMessage(message);
      this.linkParseService.processLinks(message);
    }
    history = this.amendMessages(chat, history);
    return history;
  }

  amendMessages(chat: PbGroupChat, array: PbChatMessage[]): PbChatMessage[] { // insert day and "New messages" separators
    // @todo remove this method because logic has been changed to add separators dynamically
    const res: PbChatMessage[] = [];
    const dayStart = 0;
//    let oldMessages = true;
    for (const msg of array) {
      // const msgDayStart = TimeUtils.getDayStart(msg.getCreationdate());
      // if (msgDayStart != dayStart) {
      //   dayStart = msgDayStart;
      //   const dayMsg = new PbChatMessage();
      //   dayMsg.setCreationdate(dayStart);
      //   dayMsg.setBody(TimeUtils.getChatMessageStr(dayStart));
      //   dayMsg.setFrom(''); // generated
      //   res.push(dayMsg);
      // }
      // if (oldMessages && chat.getLastviewed() < msg.getCreationdate()) {
      //   oldMessages = false;
      //   const newMsg = new PbChatMessage();
      //   newMsg.setCreationdate(msg.getCreationdate());
      //   newMsg.setBody('New messages');
      //   newMsg.setFrom(''); // generated
      //   res.push(newMsg);
      // }
      res.push(msg);
    }
    return res;
  }

  messageEventReceived(message: PbChatMessage) {
    console.log('messageEventReceived ' + message.getChatid() + ' ' + message.getId());
    this.sentUnconfirmed.delete(message.getId()); // server for sure already has this message
    const messages = this.db.get(message.getChatid());
    if (messages) {
      const index = messages.findIndex((msg: PbChatMessage) => msg.getId() === message.getId());
      if (index === -1) {
        messages.push(message); // new message, produces live update of user's view of chat history
      } else {
        messages[index] = message; // update or delete
        console.log('message updated: ' + message.getBody());
      }
      this.indexedDbService.saveChatMessages(message.getChatid(), messages);
    }
  }

  globalSearch(search: string): PbChatMessage[] {
    const result = [];
    for (const list of this.db.values()) {
      for (const msg of list) {
        if (msg.getFrom() /*not a synthetic message*/ && msg.getBody().toLowerCase().includes(search.toLowerCase())) {
          result.push(msg);
        }
      }
    }
    return result;
  }

  searchSuggest(search: string): string[] {
    const result = new Set<string>();
    for (const list of this.db.values()) {
      for (const msg of list) {
        if (msg.getFrom() /*not a synthetic message*/ && msg.getBody().toLowerCase().includes(search.toLowerCase())) {
          result.add(msg.getBody());
        }
      }
    }
    return [...result];
  }

  deleteMessage(groupChat: boolean, msg: PbChatMessage): Promise<PbChatMessage> {
    if (groupChat) {
      return this.groupChatDataService.deleteMesage(msg);
    } else {
      return this.chatDataService.deleteMesage(msg);
    }
  }

  async sendMessage(groupChat: boolean, msg: PbChatMessage, update: boolean): Promise<PbChatMessage> {
    console.log('sendMessage update=' + update + ' id=' + msg.getId() + ' groupChat=' + groupChat);
    this.sentUnconfirmed.add(msg.getId());
    this.sentFromThisDevice.add(msg.getId());
    if (groupChat) {
      return update ? await this.groupChatDataService.updateMessage(msg) : await this.groupChatDataService.sendMessage(msg);
      // .pipe(
      //   tap(msg => this.sentUnconfirmed.delete(msg.getId()))
      // );
    } else {
      return update ? await this.chatDataService.updateMessage(msg) : await this.chatDataService.sendMessage(msg);
      // .pipe(
      //   tap(msg => this.sentUnconfirmed.delete(msg.getId()))
      // );
    }
  }

  setSentFileHandle(messageId: string, fileHandle: FileSystemFileHandle) {
    this.sentFileHandles.set(messageId, fileHandle);
  }

  getSentFileHandle(messageId: string): FileSystemFileHandle | undefined {
    return this.sentFileHandles.get(messageId);
  }

  setSendError(newMessage: NewMessage) {
    this.sendErrors.set(newMessage.copyMsg.getId(), newMessage);
  }

  clearSendError(newMessage: NewMessage) {
    this.sendErrors.delete(newMessage.copyMsg.getId());
  }

  isSentOk(message: PbChatMessage) {
    return !this.sentUnconfirmed.has(message.getId());
  }

  isSentFromThisDevice(message: PbChatMessage) {
    return this.sentFromThisDevice.has(message.getId());
  }

  isSentError(message: PbChatMessage) {
    return this.sendErrors.has(message.getId());
  }

  getUnsentMessage(msgId: string) {
    return this.sendErrors.get(msgId);
  }

}
