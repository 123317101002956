import {Injectable} from '@angular/core';
import {
  PbChatActivity,
  PbChatMessage,
  PbChatMessageList,
  PbGroupChat,
  PbGroupChatFind,
  PbGroupChatList,
  PbHistoryFind,
  PbHistoryMessage
} from '../api/groupchats_pb';
import {ServiceError} from '../api/groups_pb_service';
import {ChatServiceClient} from '../api/groupchats_pb_service';
import {GrpcDataService} from '../login/grpc-data.service';
import {Observable, Subscriber} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatDataService {
  chatService = new ChatServiceClient(this.grpcDataService.grpcURL, undefined);

  constructor(private grpcDataService: GrpcDataService) {
  }

  async getHistory(chat: PbGroupChat, limit: number, beforeCreationDate: number) {
    const pbHistoryFind = new PbHistoryFind();
    pbHistoryFind.setChatid(chat.getId());
    pbHistoryFind.setLimit(limit);
    if (beforeCreationDate) {
      pbHistoryFind.setBeforecreationdate(beforeCreationDate);
    }
    const res: PbChatMessageList = await this.grpcDataService.call(this.chatService, this.chatService.getHistory, pbHistoryFind);
    return res.getMessageList();
  }

  async updateChat(newChat: PbGroupChat){
    return await this.grpcDataService.call<PbGroupChat, PbGroupChat>(this.chatService, this.chatService.update, newChat);
  }

  update(chat: PbGroupChat): Observable<PbGroupChat> {
    // console.log(`updating chat ${chat.getId()} ${chat.getDescription()} msg=${chat.getLastmessage()?.getBody()}`);
    return Observable.create((observer: Subscriber<PbGroupChat>) => {
      this.grpcDataService.call<PbGroupChat, PbGroupChat>(this.chatService, this.chatService.update, chat).then(res => observer.next(res));
    });
  }

  chatActivity(a: PbChatActivity) {
    this.grpcDataService.call(this.chatService, this.chatService.chatActivity, a);
  }

  async find(otherUser: string) {
    const query = new PbGroupChatFind();
    query.setChatid(otherUser);
    console.log('finding chat', otherUser);
    const res: PbGroupChatList = await this.grpcDataService.call(this.chatService, this.chatService.find, query);
    return res.getChatList()[0];
  }

  async getAll() {
    console.log('finding all chats');
    const res: PbGroupChatList = await this.grpcDataService.call(this.chatService, this.chatService.find, new PbGroupChatFind());
    return res.getChatList();
  }

  deleteMesage(msg: PbChatMessage) {
    return this.grpcDataService.call<PbChatMessage, PbChatMessage>(this.chatService, this.chatService.deleteMessage, msg);
  }

  async sendMessage(msg: PbChatMessage): Promise<PbChatMessage> {
    const result = await this.grpcDataService.call<PbChatMessage, PbChatMessage>(this.chatService, this.chatService.sendMessage, msg);
    return result;
  }

  async updateMessage(msg: PbChatMessage): Promise<PbChatMessage> {
    const result = await this.grpcDataService.call<PbChatMessage, PbChatMessage>(this.chatService, this.chatService.updateMessage, msg);
    return result;
  }

  async markHistoryAsSeen(chatId: string, messageId: string) {
    const msg = new PbHistoryMessage();
    msg.setChatid(chatId);
    msg.setMessageid(messageId);
    await this.grpcDataService.call(this.chatService, this.chatService.markHistoryAsSeen, msg);
  }

  async markHistoryAsDelivered(chatId: string, messageId: string) {
    const msg = new PbHistoryMessage();
    msg.setChatid(chatId);
    msg.setMessageid(messageId);
    await this.grpcDataService.call(this.chatService, this.chatService.markHistoryAsDelivered, msg);
  }

}
