import {Component, OnInit} from '@angular/core';
import {GroupChatDataService} from '../groupchats/groupchat-data.service';
import {ChatDataService} from '../chats/chat-data.service';
import {CloudStorageService} from '../cloud-storage.service';
import {ChatDbService} from '../chat-db.service';
import {MessageDbService} from '../message-db.service';
import {UserDbService} from '../user-db.service';
import {GrpcDataService} from '../login/grpc-data.service';
import {GroupDbService} from '../group-db.service';
import {GroupDataService} from '../groups/group-data.service';
import {InfoComponent} from '../info/info.component';
import {ModalService} from '../dialog/modal.service';
import {TeamyError} from '../types';

@Component({
  selector: 'app-teaminfo',
  templateUrl: './teaminfo.component.html',
  styleUrls: ['../info/info.component.scss', './teaminfo.component.scss']
})

export class TeaminfoComponent extends InfoComponent implements OnInit {
  teammatesListVisible = false;
  teammatesListNoBack = false;

  // fiveTeammates: PbGroupMember[];

  constructor(protected groupChatDataService: GroupChatDataService,
              protected chatDataService: ChatDataService,
              protected cloudStorageService: CloudStorageService,
              protected chatDbService: ChatDbService,
              protected messageDbService: MessageDbService,
              protected userDbService: UserDbService,
              protected grpcDataService: GrpcDataService,
              protected modalService: ModalService,
              protected groupDbService: GroupDbService,
              protected groupDataService: GroupDataService) {
    super(groupChatDataService, chatDataService, cloudStorageService, chatDbService, messageDbService, userDbService, grpcDataService, groupDbService, groupDataService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   super.ngOnChanges(changes);
  // }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  async saveNewName() {
    if (!this.editedName) {
      return;
    }
    if (this.selectedGroup) {
      const clone = this.selectedGroup.clone();
      clone.setDescription(this.editedName);

      try {
        await this.groupDataService.updateGroup(clone);
        console.log('saveNewName ' + this.infoType);
        this.isEditingName = false;
      } catch (e) {
        console.log('error saveNewName ' + this.infoType, e);
        // const message = `<b>Failed!</b> ` + (e instanceof TeamyError && e.isNameTooLong() ? 'Name too long' : 'Unknown reason');
        const message = `Failed!\n\n ` + (e instanceof TeamyError && e.isNameTooLong() ? 'Name too long' : 'Unknown reason');
        alert(message);

        // @todo seems like we should use showNotifyBlock but it will not be visible under this dialog window
        // @todo what other way of displaying errors we have in the design?

        // this.showNotifyBlock.emit({
        //   type: 'error',
        //   message
        // });
      }
    }
  }

  editNameStart() {
    if (this.selectedGroup) {
      this.editedName = this.selectedGroup.getDescription();
    }
    this.isEditingName = true;
    this.focusInput();
  }

  async setAvatar(files: FileList | null) {
    const file = files?.item(0);
    if (file && this.selectedGroup) {
      if (file.type.startsWith('image/')) {
        const {id: newId, name: newName} = await this.cloudStorageService.uploadFile('avatar', '', file);
        const prefix = 'sourceId:';

        this.selectedGroup.setAvatarUrl(prefix + newId);
        this.groupDataService.update(this.selectedGroup).subscribe(updatedGroup => {
          console.log('saveNewAvatar ' + this.infoType);
        });
      }
    }
  }

  closeAndResetPopup() {
    this.closeModal('teaminfo-modal');
    this.archivedListVisible = false;
    this.teammatesListVisible = false;
    this.teammatesListNoBack = false;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  showLeaveConfirm() {
    this.openModal('confirm-leave');
  }

  leaveTeam(confirm: boolean) {
    this.closeModal('confirm-leave');
    if (!confirm || !this.selectedGroup) {
      return;
    }

    this.groupDataService.leaveTeam(this.selectedGroup.getId());
    setTimeout(() => this.closeAndResetPopup(), 300);
  }

  showTeammatesList(disableBack: boolean) {
    this.resortGroupMembers();
    this.teammatesListVisible = true;
    this.teammatesListNoBack = disableBack;
  }

}
