import {PbGroup} from '../api/groups_pb';

export class GroupUtils {

  static readonly HOME_GROUP_ID = '#HOME';
  static readonly PEOPLE_GROUP_ID = '';
  public static readonly HOME_GROUP = GroupUtils.ephemeralGroup(GroupUtils.HOME_GROUP_ID, 'Home');
  public static readonly PEOPLE_GROUP = GroupUtils.ephemeralGroup(GroupUtils.PEOPLE_GROUP_ID, 'People');

  public static readonly COUNTER_DELAY = 3_000; // delay for automatically resetting unread messages counts
  public static readonly TEAMY_BOT = 'teamy.bot';
  public static readonly TEAMY_BOT_AVATAR_URL = '/assets/img/teamy-avatar.svg';

  static isRealGroup(groupId: string) {
    return groupId !== this.HOME_GROUP_ID && groupId !== this.PEOPLE_GROUP_ID;
  }

  static groupIdFromChatId(chatId: string) {
    const at = chatId.indexOf('@');
    if (at < 1) { throw new Error('not a group chat'); }
    return chatId.substr(at + 1);
  }

  static isGroupChat(chatId: string) {
    const at = chatId.indexOf('@');
    return at > 0;
  }

  private static ephemeralGroup(id: string, description: string): PbGroup {
    const g = new PbGroup();
    g.setId(id);
    g.setDescription(description);
    return g;
  }

  // static incrementUnreadCount(group: PbGroup) {
  //   group.setUnreadmessagescount(group.getUnreadMessagesCount() + 1);
  // }

}
