import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ɵmarkDirty as markDirty
} from '@angular/core';
import {PbContact, PbGroup, PbUser} from '../api/groups_pb';
import {PbChatMessage, PbChatUpdate, PbGroupChat} from '../api/groupchats_pb';
import {ModalService} from '../dialog/modal.service';
import {TimeUtils} from '../util/time.utils';
import {Observable} from 'rxjs';
import {GroupDataService} from '../groups/group-data.service';
import {SearchResults} from '../groups/search-results';
import {EncryptionService} from '../encryption.service';
import {ChatDbService} from '../chat-db.service';
import {CommonUtils} from '../util/common.utils';
import {DomSanitizer} from '@angular/platform-browser';
import {UserDbService} from '../user-db.service';
import {animate, query, stagger, style, transition, trigger} from '@angular/animations';
import {MessageFormatService} from '../message-format.service';
import {debounceTime} from 'rxjs/operators';
import {MatMenuTrigger} from '@angular/material/menu';
import {GroupChatDataService} from '../groupchats/groupchat-data.service';
import {ChatDataService} from '../chats/chat-data.service';
import {GroupDbService} from '../group-db.service';
import {InfoType, NotifyBlock} from '../types';
import {MessageDbService} from '../message-db.service';

@Component({
  selector: 'app-chatslist',
  templateUrl: './chatslist.component.html',
  styleUrls: ['./chatslist.component.scss'],

  animations: [
    trigger('chatsAnimation', [
      transition('* => *', [
        query('div.tm-chat-item-animate', style({transform: 'translateY(100%)'}), {optional: true}),
        query('div.tm-chat-item-animate',
          stagger('100ms', [
            animate('200ms', style({transform: 'translateY(0)'}))
          ]), {optional: true})
      ])
    ])
  ]
})
export class ChatslistComponent implements OnInit, OnChanges {

  constructor(private groupChatDataService: GroupChatDataService,
              private chatDataService: ChatDataService,
              private chatDbService: ChatDbService,
              private userDbService: UserDbService,
              private groupDataService: GroupDataService,
              private groupDbService: GroupDbService,
              private encryptionService: EncryptionService,
              private messageDbService: MessageDbService,
              private messageFormatService: MessageFormatService,
              private sanitizer: DomSanitizer,
              private zone: NgZone,
              private modalService: ModalService) {
  }

  @Input() isHome = false;
  @Input() isPeople = false;
  @Input() showContacts = false;
  @Input() realGroup?: PbGroup;
  @Input() search$?: Observable<string>;
  @Input() allChats: PbGroupChat[] = [];
  @Input() chatsUp: Set<string> = new Set<string>(); // for animation: chats just resorted up in chat navigation
  @Input() selectedChat?: PbGroupChat;
  @Input() hideListZoneLoading = false;

  @Output() infoTypeChanged = new EventEmitter<InfoType>();
  @Output() chatIndexChanged = new EventEmitter<PbGroupChat>();
  @Output() selectedChatChanged = new EventEmitter<PbGroupChat>();
  @Output() scrollToMessageInChat = new EventEmitter<PbChatMessage>();
  @Output() showNotifyBlock = new EventEmitter<NotifyBlock>();

  searchResults?: SearchResults;
  currentSearch = '';
  sectionTitle = '';
  mouseInChat = new Set<string>();

  // context menu
  @ViewChild(MatMenuTrigger, {static: false}) contextMenu?: MatMenuTrigger;
  contextMenuPosition = {x: '0px', y: '0px'};
  menuBackdrop?: HTMLElement;

  ngOnInit() {
    if (this.search$) {
      this.search$
        .pipe(debounceTime(500))
        .subscribe(search => {
          this.zone.run(async () => {
            await this.globalSearch(search);
            markDirty(this);
          });
        });
    }
  }

  chatsAnimationValue() {
    // return this.allChats.map(chat => chat.getId()).join(" ");
    // console.log('chatsAnimationValue', this.chatsUp);
    return [...this.chatsUp].join(' ');
  }

  isChatAnimated(chat: PbGroupChat) {
    return this.chatsUp && this.chatsUp.has(chat.getId());
  }

  async globalSearch(search: string) {
    console.log('chatslist globalSearch()', search);
    if (search.trim() === '') {
      this.searchResults = undefined;
      return;
    }

    this.currentSearch = search;

    // @todo fix search
    // this.decryptLastMessages(results.chats);
    // this.decryptLastMessages(results.groupChats);
    const results = await this.groupDataService.globalSearch(search);
    this.searchResults = results;
    const groupMembers: Map<string, PbUser> = this.groupDbService.searchAllGroupMembers(search);
    const privateChats = this.searchResults.chats;
    for (const contact of this.getContacts() ?? []) {
      if (contact.getFullname().toLowerCase().includes(search.toLowerCase())) {
        if (!privateChats.some(chat => chat.getId() === contact.getId())) {
          const chatOrContact: PbGroupChat = await this.getChatOrContact(contact.getId());
          if (this.searchResults !== results) {
            return; // new search started
          }
          privateChats.push(chatOrContact);
        }
      }
    }
    for (const chat of privateChats) {
      groupMembers.delete(chat.getId());
    }
    for (const userId of groupMembers.keys()) {
      const chatOrContact: PbGroupChat = await this.getChatOrContact(userId);
      if (this.searchResults !== results) {
        return; // new search started
      }
      // this.searchResults.otherTeammates.push(chatOrContact);
      this.searchResults.otherTeammates = [...this.searchResults.otherTeammates, chatOrContact]; // otherwise Angular doesn't see the change

      // or need ngDoCheck() {iterableDiffer...}
      // https://stackoverflow.com/questions/42962394/angular-2-how-to-detect-changes-in-an-array-input-property
    }
    if (results.empty) {
      const chatOrContact: PbGroupChat = await this.getChatOrContact(this.autoAppendGmailOrPhone(search));
      results.nonExistentChats.push(chatOrContact);
    }
    // console.log('searchResults.otherTeammates', this.searchResults.otherTeammates);
    // for (const x of this.searchResults.otherTeammates) {
    //   console.log('searchResults.otherTeammates-x', x);
    // }
  }

  autoAppendGmailOrPhone(search: string) {
    if (search.startsWith('+')) {
      return search; // phone
    }
    if (!isNaN(parseInt(search, 10)) && parseInt(search, 10).toString() === search) {
      return '+' + search; // phone
    }
    if (!search.includes('@')) {
      return search + '@gmail.com';
    }
    if (search.endsWith('@')) {
      return search + 'gmail.com';
    }
    return search;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('chatslist allChats', this.allChats);
    if (this.isHome) {
      this.sectionTitle = 'Recents';
    } else if (this.isPeople) {
      this.sectionTitle = 'People';
    } else {
      this.sectionTitle = this.realGroup?.getDescription() ?? '';
    }
  }

  getContacts() {
    return this.userDbService.getCachedContacts();
  }

  formatDateLastMessage(millis?: number) {
    if (millis === undefined) {
      return '';
    }
    // return new Date(millis).toLocaleString();
    return TimeUtils.getLastMessageStr(millis);
  }

  async onChatSelect(index: number, chat: PbGroupChat) {
    if (this.isHome && index >= 0) {
      this.chatIndexChanged.emit(chat);
    } else {
      this.selectedChatChanged.emit(chat);
    }
  }

  goToMessageFromSearch(chat: PbGroupChat, message: PbChatMessage) {
    this.onChatSelect(-1, chat);
    this.scrollToMessageInChat.emit(message);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  showTeamInfo() {
    // this.infoTypeChanged.emit('TEAM');
    this.openModal('teaminfo-modal');

  }

  // private selectFirstChat() {
  //   if (this.allChats && this.allChats.length) {
  //     this.selectedChat = this.allChats[0];
  //     console.log('chatslist selectFirstChat ', this.selectedChat.getId());
  //     //this.onChatSelect(this.selectedChat);
  //     this.loadMessages(this.selectedChat);
  //   } else {
  //     this.selectedChat = undefined;
  //   }
  // }

  isGroupChatMessage(msg: PbChatMessage): PbGroupChat[] { // convenient to return array to use *ngFor in the template which can define a new variable 'chat'
    return msg.getChatid().includes('@') ? [this.chatDbService.findCachedChat(msg.getChatid()) ?? new PbGroupChat()] : [];
  }

  isPrivateChatMessage(msg: PbChatMessage): PbGroupChat[] { // convenient to return array to use *ngFor in the template which can define a new variable 'chat'
    return !msg.getChatid().includes('@') ? [this.chatDbService.findCachedChat(msg.getChatid()) ?? new PbGroupChat()] : [];
  }

  /**
   * Used for 'Last message' in the list of chats
   */
  getLastMessageBody(msg?: PbChatMessage) {
    return this.messageFormatService.getLastMessageBody(msg);
  }

  getChatLastVisibleMsg(chat: PbGroupChat) {
    return this.messageFormatService.getChatLastVisibleMessage(chat, this.messageDbService.getCachedMessages(chat));
  }

  getBodyHighlighted(msg: PbChatMessage) {
    return CommonUtils.highlightHtml(this.sanitizer, this.getLastMessageBody(msg), this.currentSearch);
  }

  getChatDescriptionHighlighted(groupChat: PbGroupChat) {
    return CommonUtils.highlightHtml(this.sanitizer, groupChat.getDescription(), this.currentSearch);
  }

  isChatSelected(chat: PbGroupChat): boolean {
    if (chat === this.selectedChat) {
      return true;
    }
    if (this.selectedChat) {
      return chat.getId() === this.selectedChat.getId();
    }
    return false;
  }

  isContactSelected(contact: PbContact): boolean {
    if (this.selectedChat) {
      return contact.getId() === this.selectedChat.getId();
    }
    return false;
  }

  async startChatWithContact(contact: PbContact) {
    console.log('startChatWithContact', contact.getId());
    const chat = await this.getChatOrContact(contact.getId());
    this.selectedChatChanged.emit(chat);
  }

  private async getChatOrContact(userId: string) {
    let chat = await this.chatDbService.findChat(false, userId);
    // let chat: PbGroupChat = await this.chatDbService.findOrCreatePrivateChat(contact.getId());
    if (!chat) {
      chat = new PbGroupChat();

      const serverUser = await this.userDbService.findServerUser(userId);
      if (serverUser) {
        console.log('found user by phone', userId, serverUser.getId(), serverUser.getRegistered(), serverUser.toObject());
        userId = serverUser.getId().replace('@', '_');
        chat.setId(userId);
        if (!serverUser.getRegistered()) {
          this.messageDbService.pushNotRegisteredShareMessage(userId);
          this.chatDbService.setNotRegisteredChat(chat.getId());
        }
      } else {
        chat.setId(userId);
      }
    }
    return chat;
  }

  private isNotRegistered() {
    const chat = this.selectedChat;
    if (!chat) {
      return false;
    }
    if (chat.getGroupId()) {
      return false;
    }
    return this.userDbService.isNotRegisteredCached(chat.getId());
  }

  trackContact(index: number, contact: PbContact) {
    return contact ? contact.getId() : undefined;
  }

  onContextMenu(event: MouseEvent, item: PbGroupChat) {
    event.preventDefault();
    const selectedElement = (event.target as HTMLElement).closest('.tm-chat-item');

    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    if (this.contextMenu) {
      this.contextMenu.menuData = {item};
      this.contextMenu.menu.focusFirstItem('mouse');

      this.contextMenu.openMenu();
    }
    selectedElement?.setAttribute('data-active', '');

    this.menuBackdrop = document.querySelectorAll('.cdk-overlay-backdrop')[0] as HTMLElement;

    if (window.innerHeight - event.clientY < 250) {
      this.menuBackdrop.setAttribute('data-show-up', 'true');
    } else {
      this.menuBackdrop.setAttribute('data-show-up', 'false');
    }

    this.menuBackdrop.addEventListener('contextmenu', (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      this.contextMenu?.closeMenu();
      selectedElement?.removeAttribute('data-active');
    });

    this.menuBackdrop.addEventListener('click', (e: Event) => {
      selectedElement?.removeAttribute('data-active');
    });
  }

  @HostListener('contextmenu', ['$event'])
  onContextMenu1(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  archiveTopic(item: PbGroupChat) {
    item.setArchived(true);
    this.updateSelectedChat(item).subscribe(updatedGroup => {
      console.log('archiveTopic updateChat completed');
      this.showNotifyBlock.emit({
        type: 'success',
        message: `<b>Done!</b> You have archived the topic "${item.getDescription()}"`
      });
    });
  }

  togglePin(item: PbGroupChat) {
    item.setPin(!item.getPin());
    this.updateSelectedChat(item).subscribe(updatedGroup => {
      console.log('updated chat or group pin');
    });
  }

  toggleMute(item: PbGroupChat) {
    item.setMute(!item.getMute());
    this.updateSelectedChat(item).subscribe(updatedGroup => {
      console.log('updated chat or group mute');
    });
  }

  private updateSelectedChat(item: PbGroupChat) {
    if (item.getGroupId()) {
      return this.groupChatDataService.update(item);
    } else {
      return this.chatDataService.update(item);
    }
  }

  onMouseEnter(chatId: string) {
    this.mouseInChat.add(chatId);
  }

  onMouseLeave(chatId: string) {
    this.mouseInChat.delete(chatId);
  }
}
