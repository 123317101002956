import {Injectable} from '@angular/core';

import {DBSchema, IDBPDatabase, openDB} from 'idb';
import {PbChatMessage, PbChatMessageList} from './api/groupchats_pb';

interface MyDB extends DBSchema {
  chatHistory: {
    key: string; // chatId
    value: {
      lastMsgId: string;
      // messages: Array<PbChatMessage>;
      len: number;
      msgDbg: Array<{ crea: string; d: boolean }>;
      messages: Uint8Array;
      tStr: string;
    };
  };
  // 'favourite-number': {
  //   key: string;
  //   value: number;
  // };
  // products: {
  //   value: {
  //     name: string;
  //     price: number;
  //     productCode: string;
  //   };
  //   key: string;
  //   indexes: { 'by-price': number };
  // };
}

@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {
  private database?: IDBPDatabase<MyDB>;

  constructor() {
    this.initDb();
  }

  async initDb() {
    this.database = await openDB<MyDB>('my-db3', 1, {
      upgrade: (db) => {
        db.createObjectStore('chatHistory');
        // db.createObjectStore('favourite-number');
        //
        // const productStore = db.createObjectStore('products', {
        //   keyPath: 'productCode',
        // });
        // productStore.createIndex('by-price', 'price');
      },
    });
  }

  async loadChatMessages(chatId: string) {
    if (!this.database) {
      return;
    }
    const res = await this.database.get('chatHistory', chatId);
    if (res?.messages) {
      return PbChatMessageList.deserializeBinary(res.messages).getMessageList();
    }
    return undefined;
  }

  async saveChatMessages(chatId: string, messages: Array<PbChatMessage>) {
    if (!this.database) {
      return;
    }
    if (messages.length < 1) {
      return;
    }
    const transaction = this.database.transaction('chatHistory', 'readwrite');
    const chatHistory = transaction.objectStore('chatHistory');

    const msgList = new PbChatMessageList();
    msgList.setMessageList(messages);
    try {
      await chatHistory.put({
        lastMsgId: messages[messages.length - 1].getId(),
        tStr: new Date().toISOString(),
        len: messages.length,
        msgDbg: messages.map(msg => ({crea: new Date(msg.getCreationdate()).toISOString(), d: msg.getDeleted()})),
        messages: msgList.serializeBinary(),
      }, chatId);

      // await transaction.complete;
      await transaction.done;

      // console.log('jsbook saved');
    } catch (err) {
      console.log('error', err.message);
    }
  }
}
