import {Injectable} from '@angular/core';
import {GroupChatDataService} from './groupchats/groupchat-data.service';
import {ChatDataService} from './chats/chat-data.service';
import {GroupDataService} from './groups/group-data.service';
import {BehaviorSubject} from 'rxjs';
import {PbGroup, PbGroupMember, PbUser} from './api/groups_pb';
import {GroupUtils} from './util/group.utils';
import {PbChatMessage} from './api/groupchats_pb';
import {Title} from '@angular/platform-browser';
import {PbGrpMember} from './groups/pb-grp-member';

@Injectable({
  providedIn: 'root'
})
export class GroupDbService {

  public groups$: BehaviorSubject<PbGroup[]> = new BehaviorSubject([] as Array<PbGroup>);
  private groupsMap = new Map<string, BehaviorSubject<PbGroup | undefined>>();
  private groupMembersMap = new Map<string, PbGrpMember[]>();
  private groupLastMessages = new Map<string, PbChatMessage>();

  private originalTitle: string;
  private readonly originalFavicon: string;
  private readonly newMessageFavicon: string;
  private notificationTitle?: string;
  private titleIntervalHandle?: number;

  private orderGroupIds: string[] = [];

  constructor(private groupChatDataService: GroupChatDataService,
              private chatDataService: ChatDataService,
              private groupDataService: GroupDataService,
              private titleService: Title
  ) {
    this.originalTitle = this.titleService.getTitle();
    this.originalFavicon = document.getElementById('appFavicon')?.getAttribute('href') ?? '';
    this.newMessageFavicon = this.originalFavicon.replace('filled', 'newmessages'); /* favicon-newmessages.png */
  }

  updateGroupLastMessage(groupId: string, messages: PbChatMessage[]) {
    for (const msg of messages) {
      const lastMessages = this.groupLastMessages.get(groupId);
      if (lastMessages) {
        if (msg.getCreationdate() > lastMessages.getCreationdate()) {
          this.groupLastMessages.set(groupId, msg);
        }
      } else {
        this.groupLastMessages.set(groupId, msg);
      }
    }
  }

  cacheOrderGroupIds(groupIds: string[]) {
    this.orderGroupIds = groupIds;
    sessionStorage.setItem('dbg.orderGroupIds', JSON.stringify(groupIds));
  }

  async saveOrder(groups: PbGroup[]) {
    const groupIds = groups.map(group => group.getId());
    this.cacheOrderGroupIds(groupIds);
    await this.groupDataService.saveOrder(groupIds);
  }

  async loadAll() {
    const data = await this.groupDataService.getAll();
    this.cacheOrderGroupIds(data.getOrderedgroupidList());
    this.groupsLoaded(data.getGroupList());
    const users = new Array<PbUser>();
    data.getMembersMap().forEach((val, groupId) => {
      const memList = val.getGroupmemberList().map(m => m as PbGrpMember); // server guarantees getUser() is always defined here
      if (groupId) { // groupId==='' means additional users: myself and the bot
        this.groupMembersMap.set(groupId, memList);
      }
      for (const member of memList) {
        users.push(member.getUser());
      }
    });
    return users;
  }

  async loadGroups() {
    const list: PbGroup[] = await this.groupDataService.getGroups();
    const orderIds: string[] = await this.groupDataService.getOrderGroupIds(); // drag-and-drop order set up by user
    this.cacheOrderGroupIds(orderIds);
    // if (orderIds && orderIds.length) {
    //   list.sort((a, b) => {
    //     let posa = orderIds.indexOf(a.getId());
    //     if (posa === -1) {
    //       posa = orderIds.length;
    //     }
    //     let posb = orderIds.indexOf(b.getId());
    //     if (posb === -1) {
    //       posb = orderIds.length;
    //     }
    //     return posa < posb ? -1 : (posa > posb ? 1 : 0);
    //   });
    // }
    this.groupsLoaded(list);
  }

  private groupsLoaded(list: PbGroup[]) {
    list = this.sort(list);
    this.groups$.next(list);
    this.recomputeHomeUnreadCounts();
    for (const group of list) {
      if (!this.groupsMap.has(group.getId())) {
        this.groupsMap.set(group.getId(), new BehaviorSubject<PbGroup | undefined>(group));
      }
    }
  }

  recomputeHomeUnreadCounts() {
    const groups = this.groups$.getValue();
    if (groups) {

      let sum = groups.map(group => group.getUnreadMessagesCount())
        .reduce((summ, current) => summ + current, 0);
      sum += GroupUtils.PEOPLE_GROUP.getUnreadMessagesCount();
      GroupUtils.HOME_GROUP.setUnreadMessagesCount(sum);

      let sumAlerts = groups.map(group => group.getUnreadAlertMessagesCount())
        .reduce((summ, current) => summ + current, 0);
      sumAlerts += GroupUtils.PEOPLE_GROUP.getUnreadAlertMessagesCount();
      GroupUtils.HOME_GROUP.setUnreadAlertMessagesCount(sumAlerts);

      this.setTrayBadge(sum);
      if (sum > 0) {
        this.notificationTitle = sum + ' notification' + (sum > 1 ? 's' : '');
        this.titleService.setTitle(this.notificationTitle);
        document.getElementById('appFavicon')?.setAttribute('href', this.newMessageFavicon);
        clearInterval(this.titleIntervalHandle);
        this.titleIntervalHandle = setInterval(() => this.timerHandler(), 1000);
      } else {
        this.titleService.setTitle(this.originalTitle);
        document.getElementById('appFavicon')?.setAttribute('href', this.originalFavicon);
        clearInterval(this.titleIntervalHandle);
      }
    }
  }

  private setTrayBadge(sum: number): void {
    // badge api v3 is finally stable
    const nav = navigator as any;
    if (nav.setAppBadge) { // Firefox doesn't seem to support this (Feb 2021)
      if (sum > 0) {
        nav.setAppBadge(sum);
      } else {
        nav.clearAppBadge();
      }
    }

    // if ((window as any).ExperimentalBadge) {
    //   // old v1 Badging API
    //   if (sum > 0) {
    //     (window as any).ExperimentalBadge.set(sum);
    //   } else {
    //     (window as any).ExperimentalBadge.clear();
    //   }
    //   console.log('window.ExperimentalBadge', sum);
    // }
    // if ('setExperimentalAppBadge' in navigator) {
    //   // isSupported('v2')
    //   console.log('setExperimentalAppBadge', sum);
    //   if (sum > 0) {
    //     (navigator as any).setExperimentalAppBadge(sum);
    //   } else {
    //     (navigator as any).clearExperimentalAppBadge();
    //   }
    // }
  }

  timerHandler() {
    if (this.titleService.getTitle() === this.originalTitle) {
      this.titleService.setTitle(this.notificationTitle ?? '');
    } else {
      this.titleService.setTitle(this.originalTitle);
    }
  }

  refreshGroupMembers(groupId: string) {
    if (GroupUtils.isRealGroup(groupId)) {
      this.groupDataService.getMembers(groupId).then(members => {
        // if (!this.groupMembersMap.has(groupId)) {
        //   this.groupMembersMap.set(groupId, []);
        // }
        this.groupMembersMap.set(groupId, members);
      });
    }
  }

  getGroupMembers(groupId: string): PbGrpMember[] {
    if (!this.groupMembersMap.has(groupId)) {
      this.groupMembersMap.set(groupId, []);
      this.refreshGroupMembers(groupId);
    }
    return this.groupMembersMap.get(groupId) ?? [];
  }

  searchAllGroupMembers(search: string): Map<string, PbUser> {
    const users = new Map<string, PbUser>();
    for (const list of this.groupMembersMap.values()) {
      for (const member of list) {
        if (!users.has(member.getUser().getId())) {
          if (member.getUser().getFullname().toLowerCase().includes(search.toLowerCase())) {
            users.set(member.getUser().getId(), member.getUser());
          }
        }
      }
    }
    return users;
  }

  async getOrLoadGroup(groupId: string) {
    if (this.groupsMap.has(groupId)) {
      const subjCache = this.groupsMap.get(groupId);
      if (subjCache?.getValue()) {
        return subjCache.getValue();
      }
    }
    const group = await this.groupDataService.getGroup(groupId);
    const subj = new BehaviorSubject<PbGroup | undefined>(group);
    this.groupsMap.set(groupId, subj);
    return group;
  }

  getGroup$(groupId: string): BehaviorSubject<PbGroup | undefined> {
    console.log('group-db getGroup$ ' + groupId);
    const groupSubj = this.groupsMap.get(groupId);
    if (groupSubj) {
      return groupSubj;
    }
    const subj = new BehaviorSubject<PbGroup | undefined>(undefined);
    this.groupsMap.set(groupId, subj);
    this.groupDataService.getGroup(groupId).then(group => subj.next(group));
    return subj;

    // if (!groupId) {
    //   return of(undefined);
    // }
    // return fromPromise(this.getGroup(groupId));

    // return this.groups$.pipe(
    //   map(list => list.filter(group => group.getId() === groupId)[0]),
    //   switchMap(group => {
    //     if (group) {
    //       return of(group);
    //     } else {
    //       return fromPromise(this.groupDataService.getGroup(groupId)); // when creating a new group, we don't have it in cache yet
    //     }
    //   })
    // );
  }

  hasGroup(groupId: string) {
    return this.groups$.getValue().some(pbGroup => pbGroup.getId() === groupId);
  }

  // async getGroup(groupId: string) {
  //   let group = this.groups$.getValue().find(pbGroup => pbGroup.getId() === groupId);
  //   if (group) return group;
  //   if (this.groupsMap.has(groupId)) return this.groupsMap.get(groupId);
  //   group = await this.groupDataService.getGroup(groupId);
  //   this.groupsMap.set(groupId, group);
  //   return group;
  // }

  async addGroup(groupId: string) {
    const pbGroup = await this.groupDataService.getGroup(groupId);
    if (pbGroup) {
      this.updateGroupsList(pbGroup, false);
    }
  }

  groupLeaveEvent(group: PbGroup) {
    this.updateGroupsList(group, true);
  }

  updateGroupEvent(updGroup: PbGroup) {
    this.updateGroupsList(updGroup, false);
  }

  // incrementGroupUnreadMessages(groupId: string) {
  //   const groupsArray = this.groups$.getValue();
  //   if (groupsArray) {
  //     groupsArray.forEach((group, idx) => {
  //       if (group.getId() === groupId) {
  //         GroupUtils.incrementUnreadCount(group);
  //         this.groups$.next(this.sort(groupsArray));
  //         this.recomputeHomeUnreadCounts();
  //         return;
  //       }
  //     });
  //   }
  // }

  setGroupUnreadMessages(groupId: string, count: number, alertCount: number) {
    const groupsArray = this.groups$.getValue();
    if (groupsArray) {
      groupsArray.forEach((group, idx) => {
        if (group.getId() === groupId) {
          group.setUnreadMessagesCount(count);
          group.setUnreadAlertMessagesCount(alertCount);
          this.groups$.next(this.sort(groupsArray));
          this.recomputeHomeUnreadCounts();
          return;
        }
      });
    }
  }

  private updateGroupsList(updGroup: PbGroup, remove: boolean) {
    const groupSubj = this.groupsMap.get(updGroup.getId());
    if (!groupSubj) {
      this.groupsMap.set(updGroup.getId(), new BehaviorSubject<PbGroup | undefined>(updGroup));
    } else {
      groupSubj.next(updGroup);
    }

    const groupsArray = this.groups$.getValue();
    if (groupsArray) {
      let foundIdx = -1;
      groupsArray.forEach((group, idx) => {
        if (group.getId() === updGroup.getId()) {
          foundIdx = idx;
        }
      });
      if (remove) {
        if (foundIdx >= 0) {
          groupsArray.splice(foundIdx, 1);
        }
      } else {
        if (foundIdx >= 0) {
          groupsArray[foundIdx] = updGroup;
        } else {
          console.log('======== inserting new group ======');
          groupsArray.push(updGroup); // new group
        }
      }
      this.groups$.next(this.sort(groupsArray));
    }
    this.recomputeHomeUnreadCounts();
  }

  private compare(groupA: PbGroup, groupB: PbGroup): number {
    const ord = this.orderGroupIds;
    if (ord?.length) {
      let posa = ord.indexOf(groupA.getId());
      if (posa === -1) {
        // new group at start?
        posa = -1; // ord.length;
      }
      let posb = ord.indexOf(groupB.getId());
      if (posb === -1) {
        // new group at start?
        posb = -1; // ord.length;
      }
      if (posa < posb) {
        return -1;
      }
      if (posa > posb) {
        return 1;
      }
    }

    // use pin and last message time only if both groups are not in the ordered list
    if (groupA.getPin() && !groupB.getPin()) {
      return -1;
    } // put pinned first
    if (!groupA.getPin() && groupB.getPin()) {
      return 1;
    }

    if (this.getLastMessageTime(groupA) > this.getLastMessageTime(groupB)) {
      return -1;
    }
    if (this.getLastMessageTime(groupA) < this.getLastMessageTime(groupB)) {
      return 1;
    }
    return 0;
  }

  private getLastMessageTime(group: PbGroup) {
    if (!this.groupLastMessages.has(group.getId())) {
      return 0;
    }
    return this.groupLastMessages.get(group.getId())?.getCreationdate() ?? 0;
  }

  private sort(groupsArray: PbGroup[]): PbGroup[] {
    return groupsArray.sort((a, b) => this.compare(a, b));
  }

}
