import {Injectable} from '@angular/core';
import {
  PbChatActivity,
  PbChatMessage,
  PbChatMessageList,
  PbGroupChat,
  PbGroupChatFind,
  PbGroupChatList,
  PbGroupChatMembers,
  PbHistoryFind,
  PbHistoryMessage,
  PbSendMessageResult
} from '../api/groupchats_pb';
import {ServiceError} from '../api/groups_pb_service';
import {GroupChatServiceClient} from '../api/groupchats_pb_service';
import {GrpcDataService} from '../login/grpc-data.service';
import {fromPromise} from 'rxjs/internal-compatibility';
import {Observable, Subscriber} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupChatDataService {
  groupChatService = new GroupChatServiceClient(this.grpcDataService.grpcURL, undefined);

  constructor(private grpcDataService: GrpcDataService) {
  }

  async getHistory(chat: PbGroupChat, limit: number, beforeCreationDate: number) {
    const pbHistoryFind = new PbHistoryFind();
    pbHistoryFind.setChatid(chat.getId());
    pbHistoryFind.setLimit(limit);
    if (beforeCreationDate) {
      pbHistoryFind.setBeforecreationdate(beforeCreationDate);
    }
    const res: PbChatMessageList = await this.grpcDataService.call(this.groupChatService, this.groupChatService.getHistory, pbHistoryFind);
    return res.getMessageList();
  }

  async find(chatId: string) {
    const query = new PbGroupChatFind();
    query.setChatid(chatId);
    const res: PbGroupChatList = await this.grpcDataService.call(this.groupChatService, this.groupChatService.find, query);
    return res.getChatList()[0];
  }

  async addMembers(chatId: string, members: string[]) {
    const q = new PbGroupChatMembers();
    q.setChatid(chatId);
    q.setUserList(members);
    const res: PbGroupChat = await this.grpcDataService.call<PbGroupChatMembers, PbGroupChat>(this.groupChatService, this.groupChatService.addMembers, q);
    return res;
  }

  async removeMembers(chatId: string, members: string[]) {
    const q = new PbGroupChatMembers();
    q.setChatid(chatId);
    q.setUserList(members);
    const res: PbGroupChat = await this.grpcDataService.call<PbGroupChatMembers, PbGroupChat>(this.groupChatService, this.groupChatService.removeMembers, q);
    return res;
  }

  async create(newChat: PbGroupChat) {
    console.log('creating chat', newChat.getDescription());
    // return fromPromise(this.grpcDataService.call<PbGroupChat, PbGroupChat>(this.groupChatService, this.groupChatService.create, newChat));
    return await this.grpcDataService.call<PbGroupChat, PbGroupChat>(this.groupChatService, this.groupChatService.create, newChat);
  }

  async updateChat(newChat: PbGroupChat) {
    return await this.grpcDataService.call<PbGroupChat, PbGroupChat>(this.groupChatService, this.groupChatService.update, newChat);
  }

  update(newChat: PbGroupChat): Observable<PbGroupChat> {
    return Observable.create((observer: Subscriber<PbGroupChat>) => {
      this.grpcDataService.call<PbGroupChat, PbGroupChat>(this.groupChatService, this.groupChatService.update, newChat)
        .then(chat => observer.next(chat), (reason: ServiceError) => this.logError('groupChatService.update', reason));
    });
  }

  logError(call: string, reason: ServiceError) {
    if (reason.code === 13) {
      const startStr = 'java.lang.RuntimeException: io.grpc.StatusRuntimeException: UNKNOWN:';
      const start = reason.message.indexOf(startStr);
      if (start > 0) {
        const obj = JSON.parse(reason.message.substr(start + startStr.length));
        console.log('logError ' + call, obj);
        // {type: "NAME_TOO_LONG", description: "name length 104 is over 100"}
        return;
      }
    }
    console.log('logError ' + call, reason.code, reason.message, reason.metadata, /*reason*/);
  }

  chatActivity(a: PbChatActivity) {
    this.grpcDataService.call(this.groupChatService, this.groupChatService.chatActivity, a);
  }

  async getAll(groupId: string) {
    const pbGroupChatFind = new PbGroupChatFind();
    pbGroupChatFind.setGroupid(groupId);
    const res: PbGroupChatList = await this.grpcDataService.call(this.groupChatService, this.groupChatService.find, pbGroupChatFind);
    return res.getChatList();
  }

  async getAllArchived(groupId: string) {
    const pbGroupChatFind = new PbGroupChatFind();
    pbGroupChatFind.setGroupid(groupId);
    pbGroupChatFind.setArchived(true);
    const res: PbGroupChatList = await this.grpcDataService.call(this.groupChatService, this.groupChatService.find, pbGroupChatFind);
    return res.getChatList();
  }

  deleteMesage(msg: PbChatMessage) {
    return this.grpcDataService.call<PbChatMessage, PbChatMessage>(this.groupChatService, this.groupChatService.deleteMessage, msg);
  }

  async sendMessage(msg: PbChatMessage): Promise<PbChatMessage> {
    const result = await this.grpcDataService.call<PbChatMessage, PbSendMessageResult>(this.groupChatService, this.groupChatService.sendMessage, msg);
    return result.getChatmessage() as PbChatMessage;
  }

  async updateMessage(msg: PbChatMessage): Promise<PbChatMessage> {
    const result = await this.grpcDataService.call<PbChatMessage, PbChatMessage>(this.groupChatService, this.groupChatService.updateMessage, msg);
    return result;
  }

  async markHistoryAsSeen(chatId: string, messageId: string) {
    const msg = new PbHistoryMessage();
    msg.setChatid(chatId);
    msg.setMessageid(messageId);
    await this.grpcDataService.call(this.groupChatService, this.groupChatService.markHistoryAsSeen, msg);
  }

  async markHistoryAsDelivered(chatId: string, messageId: string) {
    const msg = new PbHistoryMessage();
    msg.setChatid(chatId);
    msg.setMessageid(messageId);
    await this.grpcDataService.call(this.groupChatService, this.groupChatService.markHistoryAsDelivered, msg);
  }

}
