import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appDragDrop]'
})

export class DragDropDirective {
  @Output() fileDropped = new EventEmitter<any>();

  @HostBinding('attr.data-dnd-state') private dndState?: string;

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    // console.log('onDragOver types', evt.dataTransfer.types);
    if (evt.dataTransfer?.types.includes('Files')) {
      this.dndState = 'dnd-over';
    }
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dndState = 'dnd-leave';
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dndState = 'dnd-drop';

    const files = evt.dataTransfer?.files;
    if (files && files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
