import {EventEmitter, Injectable} from '@angular/core';
import {PbEvent, PbEventHistory, PbEventHistoryFind, PbEventSubscribe} from '../api/events_pb';
import {CommonUtils} from '../util/common.utils';
import {ResponseStream, Status} from '../api/groupchats_pb_service';
import {PbApproveDevice, PbChatMessage, PbChatUpdate, PbGroupChat} from '../api/groupchats_pb';
import {PbContact, PbUser} from '../api/groups_pb';
import {GroupUtils} from '../util/group.utils';
import {MessageUtils} from '../util/message.utils';
import {UserDbService} from '../user-db.service';
import {ChatDbService} from '../chat-db.service';
import {MessageDbService} from '../message-db.service';
import {GroupDbService} from '../group-db.service';
import {DelayQueue} from '../util/delay-queue';
import {NotificationService} from '../notification.service';
import {GrpcDataService} from './grpc-data.service';
import {EncryptionService} from '../encryption.service';
import {EventServiceClient} from '../api/events_pb_service';
// import {grpc} from "grpc-web-client";
import {LinkParseService} from './link-parse.service';
import {CountersService} from './counters.service';
import {grpc} from '@improbable-eng/grpc-web';
import UpdateType = PbChatUpdate.UpdateType;
import {BoolHolder} from './bool-holder';

@Injectable({
  providedIn: 'root'
})
export class ServerEventService {

  startTime = new Date(); // @todo need server time
  loginUser?: string;
  retryTimeoutHandle?: number;
  needsGetHistory?: ResponseStream<PbEvent>;

  eventService: EventServiceClient;

  seenMessageIdWithTime = new Set<string>();
  processedHistoryEvents = new Set<string>();

  servePeerSessionsKeyRequestQueue: DelayQueue = new DelayQueue(0.05, 100);

  eventStream?: ResponseStream<PbEvent>;

  accountVersionFromServer?: number;
  localTimeShift = 0;

  public forcedSignoutEvents = new EventEmitter<void>();
  public linkParseFinished = new EventEmitter<void>();

  delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  constructor(
    private notificationService: NotificationService,
    private grpcDataService: GrpcDataService,
    private encryptionService: EncryptionService,
    private userDbService: UserDbService,
    private chatDbService: ChatDbService,
    private messageDbService: MessageDbService,
    private linkParseService: LinkParseService,
    private groupDbService: GroupDbService,
    private countersService: CountersService,
  ) {
    const grpcURL = this.grpcDataService.grpcURL;
    this.eventService = new EventServiceClient(grpcURL, undefined);
  }

  unsubscribe() {
    clearTimeout(this.retryTimeoutHandle);
    this.loginUser = undefined;
    this.needsGetHistory = undefined;
    if (this.eventStream) {
      this.eventStream.cancel();
    }
  }

  async subscribeListener(grpcMetadata: grpc.Metadata, loginUser: string) {
    this.loginUser = loginUser;

    if (!navigator.onLine) {
      console.log('can\'t listen to events: not online; retrying...');
      this.retryTimeoutHandle = setTimeout(() => this.subscribeListener(grpcMetadata, loginUser), 3000);
      return;
    }

    console.log('receiveEventsStream subscribing', new Date());
    const subscribe = new PbEventSubscribe();
    CommonUtils.setDeviceNameUpdate(subscribe);
    this.eventStream = this.eventService.receiveEventsStream(subscribe, grpcMetadata);
    this.eventStream.on('data', (event: PbEvent) => {
      this.onEvent(event, true, new BoolHolder()); // notification sound every time
    });
    this.eventStream.on('end', () => {
      console.log('receiveEventsStream end, re-subscribing...', new Date());
      this.retryTimeoutHandle = setTimeout(() => this.subscribeListener(grpcMetadata, loginUser), 2000);
    });
    this.eventStream.on('status', (status: Status) => console.log('receiveEventsStream status: ', status));
    const currentStream = this.eventStream;
    this.needsGetHistory = currentStream; // we probably missed some events when resubscribing
    while (this.needsGetHistory === currentStream) {
      try {
        if (navigator.onLine) {
          await this.getEventsHistory();
          break; // break if ok
        }
      } catch (e) {
        console.error('getEventsHistory error; will retry', e);
      }
      await CommonUtils.delay(3000);
    }
  }

  private messageIdAndTime(msg: PbChatMessage) {
    return msg.getId() + ' ' + msg.getUpdatedate();
  }

  private async getEventsHistory() {
    const HISTORY_LIMIT = 2_000;
    const find = new PbEventHistoryFind();
    const stored = localStorage.getItem('lastEvent');
    if (stored) {
      const obj = JSON.parse(stored);
      find.setAftertime(obj.lastEvent - 1);
    } else {
      const MINUTE = 60_000;
      find.setAftertime(new Date().getTime() - 30 * MINUTE); // @todo last online time, not 30 minutes here?
    }
    find.setLimit(HISTORY_LIMIT);
    console.log('checking events history getAftertime=', find.getAftertime(), new Date(find.getAftertime()).toISOString());
    const history: PbEventHistory = await this.grpcDataService.call<PbEventHistoryFind, PbEventHistory>(this.eventService, this.eventService.eventsHistory, find);
    if (history.getNodata()) {
      console.log('events history missing');
    } // might mean we forever lost some events because they have already been purged from server
    console.log('events history size=', history.getEventList().length);
    const notificationSoundPlayed = new BoolHolder(); // one sound per block of received notifications
    let eventCount = 1;
    const eventTotal = history.getEventList().length;
    for (const event of history.getEventList()) {
      console.log(`#${eventCount} of ${eventTotal}: events history contains`, new Date(event.getTimestamp()), CommonUtils.protobufToObj(event));
      eventCount++;
    }
    for (const event of history.getEventList()) {
      // replaying all events
      console.log('events history contains ', new Date(event.getTimestamp()), CommonUtils.protobufToObj(event));
      // if (event.hasChatmessage() || event.hasAccountkeyupdate() || event.hasChatcountersupdate() || event.hasGroupchatcountersupdate() || event.hasPeersessionkeysrequest()) {
      const msg = event.getChatmessage();
      if (msg) {
        if (msg.getCreationdate() < this.startTime.getTime() && msg.getUpdatedate() < this.startTime.getTime()) {
          continue; // ignore old message
        }
        if (this.seenMessageIdWithTime.has(this.messageIdAndTime(msg))) {
          continue; // ignore seen message, message processing is not exactly idempotent now, have to skip messages already seen in online events
        }
        console.log('ChatMessage from EventHistory', msg.toObject());
        // await this.delay(2000);
        // console.log('Waited 2s to avoid too many sounds at once');
      }
      // only for idempotent events - those events which can be processed twice
      const key = event.getEventCase() + ' at ' + event.getTimestamp();
      if (!this.processedHistoryEvents.has(key)) { // avoid reprocessing all the same events after every reconnect
        await this.onEvent(event, false, notificationSoundPlayed);
        this.processedHistoryEvents.add(key);
      }
      // }
    }

    if (history.getEventList().length < HISTORY_LIMIT) {
      // make this check only if we finished processing all the events history,
      // so all existing ACCOUNTKEYUPDATE events are now processed
      this.checkLocalAccountKeysVersion();
    }
  }

  private checkLocalAccountKeysVersion() {
    const versionLocal = this.encryptionService.maxAccountKeyVersion();
    console.log(`checkLocalAccountKeysVersion() srv=${this.accountVersionFromServer} local=${versionLocal}`);
    if (this.accountVersionFromServer && this.accountVersionFromServer > versionLocal) {
      const msg = `Please relogin! Missing recent account key version ${this.accountVersionFromServer}, only have versions up to ${versionLocal}`;
      console.warn(msg);
      alert(msg);
      this.forcedSignoutEvents.emit();
    }
  }

  private async onEvent(event: PbEvent, stream: boolean, notificationSoundPlayed: BoolHolder) {
    if (stream) {
      console.log(new Date() + '  receiveEventsStream data: ', CommonUtils.protobufToObj(event));
    } else {
      console.log(new Date() + '  getEventsHistory data: ', CommonUtils.protobufToObj(event));
    }
    const subscribed = event.getEventsubscribed();
    if (subscribed) {
      this.accountVersionFromServer = subscribed.getAccountkeyversion();
      this.localTimeShift = Date.now() - subscribed.getServertime();
      console.log(`localTimeShift.sec=${this.localTimeShift / 1000}`);
    }
    const message = event.getChatmessage();
    if (message) {
      this.seenMessageIdWithTime.add(this.messageIdAndTime(message));
      await this.onChatMessage(message, notificationSoundPlayed);
    }
    const chatupdate = event.getChatupdate();
    if (chatupdate) {
      if (chatupdate.getLastMessage()) {
        await this.encryptionService.decryptMessage(chatupdate.getLastMessage());
      }
      this.chatDbService.updateChatEvent(chatupdate);
    }
    const updChat = event.getGroupchatupdate();
    if (updChat) {
      await this.onChatUpdate(updChat);
    }
    if (event.hasChatmerged()) {
      console.log('chat merged by phone', event.getChatmerged());
      // @todo change local cache data
    }
    const groupupdate = event.getGroupupdate();
    if (groupupdate) {
      this.groupDbService.updateGroupEvent(groupupdate);
    }
    const groupleave = event.getGroupleave();
    if (groupleave) {
      this.groupDbService.groupLeaveEvent(groupleave);
    }
    const groupinvite = event.getGroupinvite();
    if (groupinvite) {
      // NOTE: this doesn't arrive when user himself creates a group, need to process 'groupchatupdate' instead
      console.log('group invite message', groupinvite.toObject());
      this.groupDbService.updateGroupEvent(groupinvite);
      // const user = await this.userDbService.findUserFresh(event.getFrom());
      // this.notificationService.showGroupInviteNotification(user, event.getGroupinvite(), notificationSoundPlayed);
    }
    const request = event.getPeersessionkeysrequest();
    if (request) {
      // if (confirm('Send decryption keys to ' + event.getFrom() + ' for chat: ' + request.getChatid() + '?')) {
      // console.warn('SECURITY RISK !!! Chat keys sent WITHOUT VERIFYING ACCOUNT PUBLIC KEY to ' + event.getFrom() + ' for chat: ' + request.getChatid());
      this.servePeerSessionsKeyRequestQueue.enqueue(() => this.encryptionService.servePeerSessionsKeyRequest(event.getFrom(), request));
      // }
    }
    const userupdate = event.getUserupdate();
    if (userupdate) {
      this.userDbService.updateUser(userupdate);
    }
    const sessionkeysupdated = event.getSessionkeysupdated();
    if (sessionkeysupdated) {
      console.log(`session keys updated by ${event.getFrom()} for chat`, sessionkeysupdated.getChatid());
      await this.encryptionService.sessionKeysUpdated(sessionkeysupdated.getChatid(), sessionkeysupdated.getKeylistList());
    }
    const accountkeyupdate = event.getAccountkeyupdate();
    if (accountkeyupdate) {
      console.log('Accountkeyupdate', accountkeyupdate.toObject());
      await this.encryptionService.accountKeyUpdate(accountkeyupdate);
    }
    const userStatus = event.getUserstatusupdate();
    if (userStatus) {
      console.log(`event.getUserstatusupdate() ${new Date()} id=${userStatus.getId()} act=${userStatus.getLastactivity()} actTime=${userStatus.getLastactivitytime()} `);
      const user = this.userDbService.findUser(userStatus.getId());
      if (user) {
        user.setLastactivity(userStatus.getLastactivity()); // used as a flag: =0 if online, !=0 otherwise
        user.setLastactivitytime(userStatus.getLastactivitytime());
      }
    }
    const chatcountersupdate = event.getChatcountersupdate();
    if (chatcountersupdate) {
      this.countersService.onChatCountersUpdate(chatcountersupdate, false);
    }
    const groupchatcountersupdate = event.getGroupchatcountersupdate();
    if (groupchatcountersupdate) {
      this.countersService.onChatCountersUpdate(groupchatcountersupdate, true);
    }
    const chatTs = event.getMessageseen();
    if (chatTs) {
      this.chatDbService.updateSeenTs(chatTs.getChatid(), chatTs.getLasttimestamp());
    }
    const messagedelivered = event.getMessagedelivered();
    if (messagedelivered) {
      this.chatDbService.updateDeliveredTs(messagedelivered.getChatid(), messagedelivered.getLasttimestamp());
    }
    const contactsupdated = event.getContactsupdated();
    if (contactsupdated) {
      console.log('Event: Contactsupdated');
      const upd: PbContact[] = contactsupdated.getContactList();
      this.userDbService.updateContacts(upd);
    }
    const contactsdeleted = event.getContactsdeleted();
    if (contactsdeleted) {
      this.userDbService.deleteContacts(contactsdeleted.getContactList());
    }
    const parsedLink = event.getParsedlink();
    if (parsedLink) {
      console.log('parsed link result: ', parsedLink.toObject());
      this.linkParseService.addParsedLink(parsedLink);
      this.linkParseFinished.emit();
    }
    const chatActivities = event.getChatactivities();
    if (chatActivities) {
      console.log('chatActivities', chatActivities.toObject());
      this.chatDbService.updateActivities(chatActivities);
    }

    const eventTime = event.getTimestamp();
    if (eventTime > 0) {
      const data = JSON.stringify({
        lastEvent: eventTime,
        lastEventStr: new Date(eventTime).toISOString(),
      });
      localStorage.setItem('lastEvent', data);
    } else {
      console.log(new Date() + '  event without timestamp, stream=' + stream, CommonUtils.protobufToObj(event));
    }

    const loggedOff = event.getLoggedoff();
    if (loggedOff) {
      console.log('server forces me to log off', loggedOff.toObject());
      this.forcedSignoutEvents.emit();
    }
  }

  async onChatUpdate(updChat: PbGroupChat) {
    const groupid = updChat.getGroupId();
    if (groupid) {
      // group-chat, maybe from a new group
      if (!this.groupDbService.hasGroup(groupid)) {
        await this.groupDbService.addGroup(groupid);
      }
    }
    if (updChat.getLastMessage()) {
      await this.encryptionService.decryptMessage(updChat.getLastMessage());
    }
    this.chatDbService.updateChatEvent(updChat);
    // this.currentDisplayService.updateChatEvent(updChat);
  }

  async onChatMessage(message: PbChatMessage, notificationSoundPlayed: BoolHolder) {
    try {
      try {
        await this.encryptionService.decryptMessage(message);
      } catch (e) {
        console.error('onChatMessage decryptMessage failed ' + message.getChatid() + ' ' + message.getId() + ' ' + new Date(message.getCreationdate()), e);
      }
      console.log(`RECV message = From: ${message.getFrom()} body: ${message.getBody()}`);
      await this.linkParseService.processLinks(message);

      if (message.getChatupdate() && message.getChatupdate()?.getType() === UpdateType.USER_ADD_TEAM) {
        this.groupDbService.refreshGroupMembers(GroupUtils.groupIdFromChatId(message.getChatid()));
      }
      let skipNotification = false;
      const approve = message.getChatupdate()?.getApprovedevice();
      if (message.getChatupdate() && message.getChatupdate()?.getType() === UpdateType.APPROVE_DEVICE && approve) {
        const code = approve.getCode();
        const my = await this.encryptionService.isMyDevicePublicKey(approve);
        console.log(`teamy.bot add-device-code ${code} my=${my}`);
        if (my) {
          skipNotification = true;
        } else {
          // alert('SECURITY ALERT! sending account private key to device: ' + device); //@todo we don't want to bother the user with questions? but then server can mimic any genuine device
          this.encryptionService.sendEncryptedAccountPrivateKeys(code, approve.getPublickey_asU8());
        }
      }
      await this.chatDbService.markHistoryAsDelivered(message.getChatid(), message);

      // await because we need sync processing of messages to have correct counters,
      // another device may have already read the message, we will know it thru an event with new counters:
      await this.updateView(message, notificationSoundPlayed);

      // const sampleCreation = message.getChatupdate()?.getSample();
      const sampleCreationOrNewGrpTopic = message.getChatupdate()?.getType() === UpdateType.CHAT_CREATE && message.getFrom() === GroupUtils.TEAMY_BOT;
      console.log(`preparing notification s=${sampleCreationOrNewGrpTopic} ${message.getFrom()} ${message.getUpdatedate()} ${message.getCreationdate()}`);
      if ((message.getUpdatedate() === 0 /*system message*/ || message.getUpdatedate() === message.getCreationdate()) && message.getFrom() !== this.loginUser && !sampleCreationOrNewGrpTopic) {
        // only if message is new, not an update or delete, and not my own
        if (!skipNotification) {
          const user = await this.userDbService.findOrLoadUser(message.getFrom());
          if (user) {
            this.notificationService.showNotification(user, message, notificationSoundPlayed);
          }
        }
      }

    } catch (e) {
      console.error('onChatMessage ' + message.getChatid() + ' ' + message.getId(), e);
    }
  }

  private playOutgoingAudio() {
    console.log('Playing audio outgoing_message');
    const audio = new Audio();
    audio.src = 'assets/outgoing_message.mp3';
    audio.load();
    audio.play();
  }

  private async updateView(message: PbChatMessage, notificationSoundPlayed: BoolHolder) {
    this.messageDbService.messageEventReceived(message);
    await this.chatDbService.messageEventReceived(message);

    if (message.getFrom() === this.loginUser && this.messageDbService.isSentFromThisDevice(message)) {
      this.playOutgoingAudio();
    }

    const groupId: string = MessageUtils.getGroupId(message);
    this.groupDbService.updateGroupLastMessage(groupId, [message]);
    console.log(`updateView message in group [${groupId}]`);
    this.countersService.recomputeGroupCounts(!!groupId, groupId);
    setTimeout(() => {
      this.countersService.recomputeGroupCounts(!!groupId, groupId);
    }, GroupUtils.COUNTER_DELAY); // in case chat counter is auto-reset in 3 seconds, recompute again

    // if (groupId) {
    //   this.groupDbService.incrementGroupUnreadMessages(groupId);
    // } else if (groupId === '') {
    //   //GroupUtils.incrementUnreadCount(GroupUtils.PEOPLE_GROUP);
    //   this.groupDbService.recomputeHomeUnreadCounts();
    // }
  }

}
