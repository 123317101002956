import {Injectable} from '@angular/core';
import {GrpcDataService} from './login/grpc-data.service';
import {PbChatMessage, PbChatUpdate, PbGroupChat} from './api/groupchats_pb';
import {GroupUtils} from './util/group.utils';
import UpdateType = PbChatUpdate.UpdateType;
import {MessageUtils} from './util/message.utils';

@Injectable({
  providedIn: 'root'
})
export class MessageFormatService {

  private userIdToFullName: Map<string, string> = new Map();
  private userIdToGivenName: Map<string, string> = new Map();

  constructor(private grpcDataService: GrpcDataService
  ) {
  }

  cacheUserFullName(userId: string, fullName: string, givenName: string) {
    this.userIdToFullName.set(userId, fullName);
    this.userIdToGivenName.set(userId, givenName);
  }

  private getFullName(userId: string) {
    const name = this.userIdToFullName.get(userId);
    if (name) {
      return name;
    }
    return userId;
  }

  private getGivenName(userId: string) {
    const name = this.userIdToGivenName.get(userId);
    if (name) {
      return name;
    }
    return userId;
  }

  describeSystemMessage(msg: PbChatMessage): string {
    let actor: string;
    if (msg.getFrom() === this.grpcDataService.userId) {
      actor = 'You ';
    } else {
      if (this.userIdToGivenName.has(msg.getFrom())) {
        actor = this.userIdToGivenName.get(msg.getFrom()) + ' ';
      } else {
        // return '...';
        return ''; // don't make a flash of wrong content, it looks worse
      }
    }

    const update = msg.getChatupdate();
    // for (let userId of update.getUsersList()) {
    //   if (!this.userIdToFullName.has(userId)) return "...."+userId;
    // }
    const usersNamed = update?.getUsersList().map(userId => this.getFullName(userId)).join(', ');
    switch (update?.getType()) {
      case UpdateType.UNDEFINED:
        return 'UNDEFINED';
      case UpdateType.RENAME:
        return actor + 'renamed the topic to \'' + update.getDescription() + '\'';
      case UpdateType.AVATAR:
        return actor + 'updated image of the topic';
      case UpdateType.ARCHIVED:
        return actor + 'archived this topic';
      case UpdateType.UNARCHIVED:
        return actor + 'unarchived this topic';

      case UpdateType.USER_ADD_CHAT:
        return actor + 'added new chat members: ' + usersNamed;
      case UpdateType.USER_ADD_TEAM:
        return usersNamed + ' joined this team';
      case UpdateType.USER_REMOVE_CHAT:
        // return actor + 'removed chat members: ' + usersNamed;
        return usersNamed + ' left this topic';
      case UpdateType.USER_REMOVE_TEAM:
        return usersNamed + ' left this team';

      case UpdateType.APPROVE_DEVICE:
        return msg.getBody();
      case UpdateType.CHAT_CREATE:
        if (msg.getFrom() === GroupUtils.TEAMY_BOT) {
          if (update.getSample()) {
            return 'We created sample topic for your team';
          } else {
            return 'We created general topic for your team';
          }
        }
        return actor + 'created the topic \'' + update.getDescription() + '\' with ' + update.getUsersList().length + ' teammates';
    }
    return actor + '??? (unknown system message) ' + update?.getType(); // @todo can label be automatically found with Typescript Enums?
  }

  getChatLastVisibleMessage(chat: PbGroupChat, history: PbChatMessage[] | undefined): PbChatMessage | undefined {
    if (this.isVisible(chat.getLastMessage())) {
      return chat.getLastMessage();
    }
    if (!history) {
      return undefined;
    }
    for (let i = history.length - 1; i >= 0; i--) {
      if (this.isVisible(history[i])) {
        return history[i];
      }
    }
    // console.log('getChatLastVisibleMessage none ' + chat.getId());
    return undefined;
  }

  isVisible(msg?: PbChatMessage) {
    if (!msg) {
      return false;
    }
    return msg.getBody() || msg.getDeleted() || msg.getFile() || msg.getChatupdate() || msg.hasPromotion();
  }

  /**
   * Used for 'Last message' in the list of chats
   */
  getLastMessageBody(msg?: PbChatMessage) {
    if (!msg) {
      return '';
      // return 'No messages here yet…';
    }
    if (msg.getDeleted()) {
      if (msg.getFrom() === this.grpcDataService.userId) {
        return 'You deleted a message';
      } else {
        return this.getGivenName(msg.getFrom()) + ' deleted a message';
      }
    }
    if (msg.getBody()) {
      return this.parseDecorations(msg);
    }
    const file = msg.getFile();
    if (MessageUtils.isFileVideo(file)) {
      return 'Sent a video';
    }
    if (file && file.getPreview()) {
      return 'Sent a photo';
    }
    if (file) {
      return 'Sent a file';
    }
    if (msg.getEncrypted_asU8().byteLength > 0) {
      return '(encrypted)';
    }
    if (msg.getChatupdate()) {
      if (msg.getChatupdate()?.getType() === UpdateType.CHAT_CREATE && msg.getFrom() !== GroupUtils.TEAMY_BOT) {
        return 'Created new topic';
      }
      // return MessageUtils.describeSystemMessage(msg);
      return this.describeSystemMessage(msg);
    }
    if (msg.hasPromotion()) {
      return 'Welcome to Teamy!';
    }
    console.log('last message body formatting not implemented', msg.toObject());
    return '.....';
    // return MessageUtils.getBody(msg);
  }

  parseDecorations(message: PbChatMessage) {
    let parsedMessage = message.getBody().replace(/@[\w_.+]+\s\(([^)]+)\)/g, '$1'); // mentions
    parsedMessage = parsedMessage.split('[quote]').join('').split('[.quote]').join(''); // quotes
    parsedMessage = parsedMessage.replace(/<span[^>]*>([^<]*)<\/span>/g, '$1'); // emoji

    return parsedMessage;
  }

}
