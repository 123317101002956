import {ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, NgZone, OnInit, ViewChild} from '@angular/core';
import {PbGroup, PbRegister, PbUser} from './api/groups_pb';
import {LoginDataService} from './login/login-data.service';
import {GroupDataService} from './groups/group-data.service';
import {ModalService} from './dialog/modal.service';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {PbChatMessage, PbGroupChat} from './api/groupchats_pb';
import {FormBuilder, FormGroup} from '@angular/forms';
import {GroupDbService} from './group-db.service';
import {EncryptionService} from './encryption.service';
import {GroupUtils} from './util/group.utils';
import {ChatDisplay} from './chat/chat.display';
import {ChatDbService} from './chat-db.service';
import {MessageDbService} from './message-db.service';
import {NotificationService} from './notification.service';
import {ChatDisplayService} from './chats/chat-display.service';
import {CommonUtils} from './util/common.utils';
import {UserDbService} from './user-db.service';
import {OnPageVisible} from 'angular-page-visibility';
import {CheckForUpdateService} from './check-for-update.service';
import {animate, query, stagger, style, transition, trigger} from '@angular/animations';
import {ChatslistComponent} from './chatslist/chatslist.component';
import {CdkDragDrop} from '@angular/cdk/drag-drop/drag-events';
import {InfoType, NotifyBlock} from './types';
import {ɵmarkDirty as markDirty} from '@angular/core';
import {CloudStorageService} from './cloud-storage.service';
import {ChatUtils} from './util/chat.utils';
import {TeaminfoComponent} from './teaminfo/teaminfo.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [LoginDataService, GroupDataService],
  animations: [
    trigger('chatCardAnimation', [
      transition('* => *', [
        query('.tm-chat-card-animate', style({transform: 'translateY(100%)'}), {optional: true}),
        query('.tm-chat-card-animate',
          stagger('100ms', [
            animate('200ms', style({transform: 'translateY(0)'}))
          ]), {optional: true})
      ])
    ])
  ]
})
export class AppComponent implements OnInit {
  themeClassName = 'theme-dark';
  loggedIn = false;
  loggingIn = false;
  infoType: InfoType = '';
  notificationType = '';
  searchField = '';
  notificationText = '';
  // searchSuggestions: string[];
  searchSuggestionsLoading = false;
  searchForm?: FormGroup;
  search$ = new Subject<string>();
  title = 'app';
  leftGroups: PbGroup[] = [];
  showValidationBlock?: string;
  linkEmail?: string; // for retrying failed login from a link
  filters = {
    all: true,
    pinned: true,
    topics: true,
    direct: true,
  };
  showFilters = false;
  isSearchActive = false;
  groupsCached: PbGroup[] = [];
  selectedGroup?: PbGroup;
  chatNavigation: PbGroupChat[] = [];
  chatsUp = new Set<string>(); // for animation: chats just resorted up in chat navigation

  selectedChat?: PbGroupChat;
  chatDisplays: ChatDisplay[] = [];
  showContacts = false;
  selectedChatByGroup: Map<string, PbGroupChat> = new Map<string, PbGroupChat>();

  hideChatZoneLoading = false;
  hideListZoneLoading = false;
  // public lottieConfig: object;
  private anim: any;
  groupsHaveScroll = false;
  messageTarget?: PbChatMessage;
  showTeamsListScrollShadow = false;

  @ViewChild('cardsFilter', {static: false}) cardsFilter?: ElementRef;
  @ViewChild(ChatslistComponent) chatsListCompoment?: ChatslistComponent;
  @ViewChild(TeaminfoComponent) teamInfoCompoment?: TeaminfoComponent;
  @ViewChild('groupsScrollContainer', {static: false}) groupsScrollContainer?: ElementRef;

  constructor(private loginDataService: LoginDataService,
              private groupDataService: GroupDataService,
              private chatDbService: ChatDbService,
              public userDbService: UserDbService,
              private messageDbService: MessageDbService,
              private encryptionService: EncryptionService,
              private modalService: ModalService,
              private groupDbService: GroupDbService,
              private cloudStorageService: CloudStorageService,
              private notificationService: NotificationService,
              private chatDisplayService: ChatDisplayService,
              private checkForUpdateServer: CheckForUpdateService /* force the service to init */,
              private zone: NgZone,
              private cd: ChangeDetectorRef,
              private fb: FormBuilder) {
    (Error as any).stackTraceLimit = 100;
    // Error.stackTraceLimit; - non-standard property?

    this.search$.subscribe((value) => {
      this.isSearchActive = !!value;
    });

    // caches.open('cache1').then(cache => cache.put());
    // caches.match()
  }

  logStateInfo() {
    console.log('chatDisplays', this.chatDisplays);
  }

  async registerByLink(verifyEmail: string, verifyEmailCode: string, magic: boolean) {
    // alert('Verify email: ' + verifyEmail);

    const request = new PbRegister();
    await this.encryptionService.generateKeysAtRegistration(request);
    request.setEmail(verifyEmail);
    request.setVerificationcode(verifyEmailCode);
    console.log('verifyEmail from link', window.location.href, request.getVerificationcode()/*, request.getAccountpublickey()*/);
    const account = await this.loginDataService.verifyEmail(request);
    console.log('verifyEmail from link, account=', account);
    if (account) {
      this.showValidationBlock = 'validation-ok';
      setTimeout(() => {
        this.showValidationBlock = 'hide-signin';
        markDirty(this); // hopefully this ensures repainting
      }, 4000);
      await this.loginDataService.signIn(account);
    } else {
      if (magic) {
        this.showValidationBlock = 'magic-error';
      } else {
        this.showValidationBlock = 'validation-error';
      }
    }

    history.replaceState({}, document.title, new URL(window.location.href).origin); // remove registration parameters from browser address bar
    this.loggingIn = false;
  }

  @OnPageVisible()
  whenPageVisible(): void {
    console.log('OnPageVisible => visible');
    if (this.isHomeSelected()) {
      this.zone.run(() => {
        this.chatDisplays = this.chatDisplays.sort((a, b) => {
          const aidx = this.indexOfChatInNavigation(a.selectedChat);
          const bidx = this.indexOfChatInNavigation(b.selectedChat);
          if (aidx < bidx) {
            return -1;
          }
          if (aidx > bidx) {
            return 1;
          }
          return 0;
        });
      });
      for (const chatDisplay of this.chatDisplays) {
        const chatId = chatDisplay.selectedChat.getId();
        const autoResetNeeded = this.chatDisplayService.isChatCounterAutoResetNeeded(chatId);
        console.log(`whenPageVisible() chatId=${chatId} autoReset=${autoResetNeeded}`);
        if (chatDisplay.messages.length && autoResetNeeded) {
          setTimeout(() => {
            this.chatDbService.scrolledChatEnd(chatDisplay.selectedChat, chatDisplay.messages[chatDisplay.messages.length - 1]);
            markDirty(this); // hopefully this ensures repainting
          }, GroupUtils.COUNTER_DELAY);
        }
      }
    }
  }

  indexOfChatInNavigation(chat: PbGroupChat) {
    return this.chatNavigation.findIndex((c) => c.getId() === chat.getId());
  }

  ngOnInit(): void {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      window.resizeTo(1260, 920);
    }

    // Firebase initial link: https://link.teamy.chat/?link=https://test.teamy.chat?verify_email%3Dandrii.denysenko%25404shared.com%26code%3D082266%26magic=true&apn=com.teamy.dev&ibi=com.teamy.chat&isi=1369219122
    // Firebase redirected link: https://localhost.teamy.chat/?verify_email=andrii.denysenko@4shared.com&code=082268&magic=true
    const link = window.location.href;
    console.log('opened with url=', link);
    const searchParams = new URL(link).searchParams;
    const verifyEmail = searchParams.get('verify_email');
    const verifyEmailCode = searchParams.get('code');
    const magic = 'true' === searchParams.get('magic');
    if (verifyEmail && verifyEmailCode) {
      this.loggingIn = true;
      this.linkEmail = verifyEmail;
      this.registerByLink(verifyEmail, verifyEmailCode, magic);
    }

    this.loginDataService.isUserLoggedIn.subscribe(value => {
      console.log('Login happened ' + value);
      this.loggedIn = value;
      if (value) {
        this.subscribeGroupsLoader();
        this.showValidationBlock = 'hide-signin';
        // this.setSelected(CurrentDisplayService.HOME_GROUP);
        // this.loadChatsAfterLogin();
        this.setSelected(GroupUtils.HOME_GROUP);
        if (!this.cloudStorageService.token) {
          this.showNotifyBlock({type: 'error', message: 'Failed to connect to the cloud storage service'});
        }
      }
    });

    this.chatDbService.chatUpdateEvents.subscribe((updatedChat: PbGroupChat) => {
      if (this.selectedChat && this.selectedChat.getId() === updatedChat.getId()) {
        this.selectedChat = updatedChat;
      }
      if (this.chatDisplays) {
        for (const chatDisplay of this.chatDisplays) {
          if (chatDisplay.selectedChat.getId() === updatedChat.getId()) {
            console.log(`app.component: chat updated id=${updatedChat.getId()} archived=${updatedChat.getArchived()}`);
            if (updatedChat.getArchived()) {
              // if (this.isHomeSelected()) {
              this.infoType = '';
              this.chatDisplays = CommonUtils.arrayRemove(this.chatDisplays, chatDisplay);
              // }
            } else {
              chatDisplay.selectedChat = updatedChat;
            }
          }
        }
      }
      if (this.chatNavigation) {
        for (let i = 0; i < this.chatNavigation.length; i++) {
          if (this.chatNavigation[i].getId() === updatedChat.getId()) {
            if (this.isHomeSelected() && updatedChat.getArchived()) {
              this.chatNavigation.splice(i, 1);
            } else {
              this.chatNavigation[i] = updatedChat;
            }
          }
        }
      }
      this.resortChatNavigation(!this.isHomeSelected());
      if (!this.isHomeSelected() && updatedChat.getArchived() && this.selectedChat?.getId() === updatedChat.getId()) {
        console.log('app.component: selected chat archived');
        if (this.chatNavigation.length > 0) {
          this.selectedChatChanged(this.chatNavigation[0]);
        }
      }
    });

    this.chatDbService.messageReceivedEvents.subscribe((message: PbChatMessage) => {
      if (message?.getFrom() === this.loginDataService.loginUser) {
        return; // ignore my messages: if typing a message or uploading a file, don't resort cards on Home
      }
      if (document.activeElement && document.activeElement.classList.contains('home-message-input-field')) {
        return; // don't update home if user is typing a message
      }
      if (this.isHomeSelected() && this.chatsListCompoment && this.chatsListCompoment.mouseInChat.size > 0) {
        console.log('Home reorder stopped: chats mouse-active', this.chatsListCompoment.mouseInChat);
        return; // don't update home if user mouse is within a chats list
      }

      if (message && this.isHomeSelected() && this.chatDisplays.every(cd => cd.selectedChat.getId() !== message.getChatid())) {
        // need to refresh Home to see the new chat
        this.setOrResetSelected(true, GroupUtils.HOME_GROUP);
        return;
      }

      const oldChatNavigation = [...this.chatNavigation];
      this.resortChatNavigation(!this.isHomeSelected());

      this.chatsUp = new Set<string>();
      for (let i = 0; i < this.chatNavigation.length; i++) {
        const chatId = this.chatNavigation[i].getId();
        const oldPos = oldChatNavigation.findIndex(value => value.getId() === chatId);
        console.log('resortChatNavigation', i, chatId, oldPos);
        if (oldPos !== -1 && oldPos > i) {
          this.chatsUp.add(chatId);
        }
      }
    });

    this.notificationService.navigateGroupAndChat.subscribe((groupAndChat: { group: PbGroup; chat: PbGroupChat }) => {
      this.zone.run(() => { // maybe zone doesn't work here because setSelected() is an async call
        console.log(`notificationService.navigateGroupAndChat received`);
        this.setSelected(groupAndChat.group, groupAndChat.chat).then(() => {
          markDirty(this); // hopefully this ensures repainting
        });
        // this.selectedChatChanged(groupAndChat.chat);
      });
    });

    this.searchForm = this.fb.group({
      searchInput: null
    });
  }

  private subscribeGroupsLoader() {
    this.groupDbService.groups$.pipe(map(grps => {
      console.log('app.component: refreshing groups list');
      this.groupsCached = grps;
      const currentGroupId = this.selectedGroup ? this.selectedGroup.getId() : undefined;
      if (currentGroupId !== GroupUtils.PEOPLE_GROUP_ID && currentGroupId !== GroupUtils.HOME_GROUP_ID) {
        const freshGroup = grps.find(group => group.getId() === currentGroupId);
        if (!freshGroup) {
          this.setSelected(GroupUtils.PEOPLE_GROUP);
          // this.currentDisplayService.setSelected(GroupUtils.HOME_GROUP_ID);
        } else {
          this.selectedGroup = freshGroup;
        }
      }
      return [
        GroupUtils.HOME_GROUP,
        GroupUtils.PEOPLE_GROUP,
        ...grps];
    })).subscribe(value => {
      this.leftGroups = value;
      this.checkIfGroupsHaveScroll();
    });
  }

// loadChatsAfterLogin() {
  //   this.currentDisplayService.getSelectedChat$().subscribe(chat => {
  //     if (!this.chatDisplays) {
  //       this.chatDisplays = [new ChatDisplay(chat, null)];
  //     } else {
  //       this.chatDisplays[0] = this.chatDisplays[0].setChat(chat);
  //     }
  //     //this.selectedChat = chat;
  //
  //     //@todo revert this somehow
  //     // if (chat) {
  //     //   this.notificationService.setCurrentChat(this.selectedChat);
  //     //   //setTimeout(() => this.goChatBottom(), 0);
  //     // }
  //   });
  //   this.currentDisplayService.getSelectedGroupAndChat$().subscribe(({group, chat}) => {
  //     this.groupChanged(group, chat);
  //   });
  // }

  resortChatNavigation(pinnedFirst: boolean) {
    this.chatNavigation = this.chatNavigation.sort((a, b) => ChatUtils.compare(pinnedFirst, a, b));
    if (!this.chatDisplays || this.chatDisplays.length === 1) {
      return;
    }
    this.chatDisplays = this.chatDisplays.sort((a, b) => ChatUtils.compare(pinnedFirst, a.selectedChat, b.selectedChat));
  }

  isFireFox() {
    return navigator.userAgent.includes('Firefox');
  }

  chatScrollIndexChanged(chat: PbGroupChat /*index: number*/) {
    const appChatElement = window.document.getElementById('appChat_' + chat.getId());
    if (appChatElement) {
      window.document.getElementById('chatsWrapper')?.scrollTo({
        top: appChatElement.offsetTop - 10,
        behavior: 'smooth', // isFirefoxBrowser ? 'auto' : 'smooth'
      });
    }
    // window.document.getElementById('appChat' + index).scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  async openPrivateChat(chat: PbGroupChat) {
    await this.setSelected(GroupUtils.PEOPLE_GROUP, chat);
    // this.selectedChatChanged(chat);
  }

  async selectedChatChanged(chat: PbGroupChat) {
    if (this.isHomeSelected()) {
      this.navigateFullView(chat);
    }

    const startTime = new Date().getTime();
    if (this.selectedChat && this.selectedChat.getId() === chat.getId()) {
      if (this.chatDisplays.length === 1 && this.chatDisplays[0].selectedChat.getId() === chat.getId()) { // check needed, when switching from Home to People right chat sometimes is not selected without this
        return;
      }
    }
    this.hideLoading(false, true);

    try {
      console.log('chatslist onChatSelect ' + chat.getId());
      const messages = await this.loadMessages(chat);
      this.chatDisplays = [new ChatDisplay(chat, messages)];
      console.log('chatDisplays', this.chatDisplays);
      this.setVisibleChatsForNotificationsSuppression();

      if (chat.getGroupId() && this.infoType === 'CHAT') {
        this.infoType = 'GROUP_CHAT';
      }
      if (!chat.getGroupId() && this.infoType === 'GROUP_CHAT') {
        this.infoType = 'CHAT';
      }
      this.selectedChat = chat;
      if (this.selectedGroup) {
        this.selectedChatByGroup.set(this.selectedGroup.getId(), chat);
      }

      console.log('app.component: selectedChatChanged, direct: millis=' + (new Date().getTime() - startTime));
      setTimeout(() => console.log('app.component: selectedChatChanged, post: millis=' + (new Date().getTime() - startTime)));
    } catch (e) {
      console.error('selectedChatChanged() error ' + chat.getId(), e);
    }
  }

  private async loadMessages(chat: PbGroupChat) {
    let messages: PbChatMessage[] = [];
    if (!chat.getGroupId()) {
      if (!chat.getCreationDate() && !this.messageDbService.getCachedMessages(chat)) {
        messages = []; // creating new private chat, not on server yet
      } else {
        messages = await this.messageDbService.getMessages(chat) ?? [];
      }
    }

    if (chat.getGroupId()) {
      messages = await this.messageDbService.getMessages(chat) ?? [];
    }
    this.groupDbService.updateGroupLastMessage(chat.getGroupId(), messages);
    return messages;
  }

  async navigateFullView(selectedChat?: PbGroupChat) {
    if (selectedChat?.getGroupId()) {
      const group = await this.groupDbService.getOrLoadGroup(selectedChat?.getGroupId());
      if (group) {
        this.setSelected(group, selectedChat);
      }
      // this.groupDbService.getGroup$(selectedChat.getGroupid())
      //   .pipe(take(1))
      //   .subscribe(group => this.setSelected(group, selectedChat));
    } else {
      this.setSelected(GroupUtils.PEOPLE_GROUP, selectedChat);
    }
  }

  setVisibleChatsForNotificationsSuppression() {
    this.chatDisplayService.setVisibleChats(this.chatDisplays.map(chatDisplay => chatDisplay.selectedChat));
  }

  private loadChats(groupId: string) {
    console.log('app component loadChats', groupId);
    if (groupId === GroupUtils.HOME_GROUP_ID) {
      return this.chatDbService.getAll();
    }
    return this.chatDbService.getChats(groupId);
  }

  async decryptLastMessages(chats: PbGroupChat[]) {
    for (const chat of chats) {
      const lastmessage = chat.getLastMessage();
      if (lastmessage) {
        await this.encryptionService.decryptMessage(lastmessage);
      }
    }
  }

  stringIdentity(str: string) {
    return str;
  }

  // selectedChatHasBeenArchived(group: PbGroup) {
  //   console.log(`app.component selectedChatHasBeenArchived in group=${group?.getDescription()}`);
  // }

  async setSelected(group: PbGroup, chat?: PbGroupChat) {
    await this.setOrResetSelected(false, group, chat);
  }

  async setOrResetSelected(forceRefresh: boolean, group: PbGroup, chat?: PbGroupChat) {
    const startTime = new Date().getTime();
    this.search$.next('');
    if (!forceRefresh && this.selectedGroup && this.selectedGroup.getId() === group.getId()) {
      if (!chat || (this.selectedChat && this.selectedChat.getId() === chat.getId())) {
        return;
      }
    }
    this.hideLoading(false, false);

    console.log('app.component: set selected group or chat', group ? group.getId() : '<no group>');
    // if (this.infoType === 'TEAM' && !GroupUtils.isRealGroup(group.getId())) {
    //   this.infoType = '';
    // }
    // if (group.getId() === GroupUtils.HOME_GROUP_ID) {
    //   this.infoType = '';
    // }
    if (!GroupUtils.isRealGroup(group.getId())) {
      this.infoType = '';
    }
    if (group.getId() !== GroupUtils.PEOPLE_GROUP_ID) {
      this.showContacts = false;
    }
    this.chatDisplayService.setHome(group.getId() === GroupUtils.HOME_GROUP_ID);
    const firstTimeLoad = !this.selectedGroup;
    this.selectedGroup = group;
    const array = await this.loadChats(group.getId()) ?? [];
    this.decryptLastMessages(array); // no need to await decryption, nothing depends on it! load page fast, decrypt later (in Promise)
    if (this.selectedGroup !== group) {
      return;
    } // user has already navigated to another group
    this.chatNavigation = array;
    console.log('chatNavigation loaded ids=', this.chatNavigation.map(achat => achat.getId()));
    this.resortChatNavigation(group.getId() !== GroupUtils.HOME_GROUP_ID);
    console.log('chatNavigation ids=', this.chatNavigation.map(achat => achat.getId()));

    if (firstTimeLoad) {
      // app. is loading first time
      // this.zone.run(() => {
      this.hideListZoneLoading = true;
      // this.cd.detectChanges();
      // });
    }

    if (group.getId() === GroupUtils.HOME_GROUP_ID) {
      this.chatNavigation = this.chatNavigation.filter(achat => !achat.getMute());
      const MAX = 10;
      if (this.chatNavigation.length > MAX) {
        this.chatNavigation = this.chatNavigation.slice(0, MAX);
      }
      console.log('chatNavigation=', this.chatNavigation);
      this.chatDisplays = [];
      const usersInMessages = new Set<string>();
      for (const homeChat of this.chatNavigation) {
        let msgs: Array<PbChatMessage> = [];
        try {
          msgs = await this.loadMessages(homeChat);
        } catch (e) {
          console.error('loadMessages ' + homeChat.getId(), e);
        }
        this.addMessageUsersToSet(usersInMessages, msgs);
        if (this.selectedGroup === group) { // unless user already clicked on another group
          this.chatDisplays.push(new ChatDisplay(homeChat, msgs)); // progressive loading upon first login to the app
          if (firstTimeLoad) {
            this.hideChatZoneLoading = true; // first chat messages are ready, the rest will appear below soon
          }
        }
      }
      this.userDbService.precacheUsers(usersInMessages);
      this.setVisibleChatsForNotificationsSuppression();
    } else {
      if (!chat && this.chatNavigation && this.chatNavigation.length) {
        const oldSelectedChat = this.selectedChatByGroup.get(group.getId());
        if (oldSelectedChat) {
          const oldSelectedChatId = oldSelectedChat.getId(); // preserve chat selected in this group earlier
          chat = this.chatNavigation.find(ch => ch.getId() === oldSelectedChatId);
        }
        if (!chat) {
          chat = this.chatNavigation[0];
        }
      }
    }
    if (this.selectedGroup !== group) {
      return;
    } // user has already navigated to another group
    if (chat) {
      this.chatDisplays = []; // remove old chat(s) from display while the new one is being loaded
      await this.selectedChatChanged(chat);
      this.logStateInfo();
    }
    console.log('app.component: set selected group or chat, millis=' + (new Date().getTime() - startTime), chat);

    if (!firstTimeLoad) {
      this.hideLoading(false, true);
    }

    setTimeout(() => {
      console.log('app.component: set selected group or chat, post: millis=' + (new Date().getTime() - startTime));
      if (this.isPeopleSelected() && !this.showContacts) {
        // open up 'People' tab fast, then later load contacts into DOM which can take time if there are 500+ contacts which actually happens
        setTimeout(() => {
          this.showContacts = true;
          markDirty(this); // hopefully this ensures repainting
          console.log('app.component: set selected group or chat, showContacts: millis=' + (new Date().getTime() - startTime));
        }, 20);
      }
    });

  }

  addMessageUsersToSet(set: Set<string>, msgs: PbChatMessage[]) {
    for (const msg of msgs) {
      set.add(msg.getFrom());
      set.add(msg.getAuthor());
      if (msg.getChatupdate()) {
        msg.getChatupdate()?.getUsersList().forEach(user => set.add(user));
      }
    }
  }

  isHomeSelected() {
    return !!this.selectedGroup && this.selectedGroup.getId() === GroupUtils.HOME_GROUP_ID;
  }

  isPeopleSelected() {
    return !!this.selectedGroup && this.selectedGroup.getId() === GroupUtils.PEOPLE_GROUP_ID;
  }

  isRealGroupSelected() {
    return !!this.selectedGroup && this.isRealGroup(this.selectedGroup);
  }

  isGroupSelected(groupId: string) {
    return !!this.selectedGroup && this.selectedGroup.getId() === groupId;
  }

  isRealGroup(group: PbGroup) {
    return group && group.getId() !== GroupUtils.HOME_GROUP_ID && group.getId() !== GroupUtils.PEOPLE_GROUP_ID;
  }

  get loginUser(): PbUser {
    return this.loginDataService.user;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  @HostBinding('class') get themeClass() {
    return this.themeClassName;
  }

  setInfoType(chat: PbGroupChat | null, type: InfoType) {
    if (chat) {
      this.selectedChat = chat;
    }
    this.infoType = type;
    console.log(type);
  }

  showNotifyBlock(notifyObj: NotifyBlock) {
    this.notificationType = notifyObj.type;
    this.notificationText = notifyObj.message;

    setTimeout(() => {
      this.notificationType = '';
      markDirty(this); // hopefully this ensures repainting
    }, 5000);
  }

  globalSearch() {
    this.search$.next(this.searchField);
  }

  searchOnReturnFocus() {
    if (this.searchField) {
      this.globalSearch();
    }
  }

  clearSearch() {
    this.searchField = '';
    this.globalSearch();
  }

  trackGroup(index: number, group: PbGroup) {
    return group ? group.getId() : undefined;
  }

  switchFilters(filterNumber: number) {
    if (filterNumber === 0) {
      if (this.filters.all) {
        this.filters.pinned = false;
        this.filters.topics = false;
        this.filters.direct = false;
      } else {
        this.filters.pinned = true;
        this.filters.topics = true;
        this.filters.direct = true;
      }
    }

    if (filterNumber === 1) {
      this.filters.pinned = !this.filters.pinned;
    }

    if (filterNumber === 2) {
      this.filters.topics = !this.filters.topics;
    }

    if (filterNumber === 3) {
      this.filters.direct = !this.filters.direct;
    }

    this.filters.all = !!(this.filters.pinned && this.filters.topics && this.filters.direct);
  }

  hideLoading(showChatZone: boolean, showListZone: boolean = true) {
    if (showChatZone) {
      setTimeout(() => {
        this.hideChatZoneLoading = true;
        markDirty(this); // hopefully this ensures repainting
      }, 0);
    } else {
      this.hideChatZoneLoading = false;
    }

    if (showListZone) {
      setTimeout(() => {
        this.hideListZoneLoading = true;
        markDirty(this); // hopefully this ensures repainting
      }, 0);
    } else {
      this.hideListZoneLoading = false;
    }
  }

  getAppInfoGroup() {
    if (this.infoType === 'GROUP_CHAT' && this.selectedChat?.getGroupId() !== this.selectedGroup?.getId()) {
      // group chat info displayed on home, selectedGroup is HOME, not the chat's parent group
      return this.groupsCached.find(grp => grp.getId() === this.selectedChat?.getGroupId());
      // return this.groupDbService.getGroup$(this.selectedChat.getGroupid()).getValue();
    }
    return this.selectedGroup;
  }

  chatCardAnimationValue() {
    return [...this.chatsUp].join(' ');
  }

  isChatCardAnimated(chat: PbGroupChat) {
    if (!this.isHomeSelected()) {
      return undefined;
    }
    return this.chatsUp && this.chatsUp.has(chat.getId());
  }

  dropGroupElement(e: CdkDragDrop<any, any>) {
    if (e.currentIndex < 2) {
      e.currentIndex = 2;
    } // prevent move upper than people and home

    const elem = this.leftGroups[e.previousIndex];
    this.leftGroups.splice(e.previousIndex, 1);
    this.leftGroups.splice(e.currentIndex, 0, elem);
    this.groupDbService.saveOrder(this.leftGroups);
  }

  checkIfGroupsHaveScroll() {
    if (!this.groupsScrollContainer) {
      return;
    }
    setTimeout(() => {
      if (this.groupsScrollContainer) {
        this.groupsHaveScroll = this.groupsScrollContainer.nativeElement.scrollHeight > this.groupsScrollContainer.nativeElement.clientHeight;
      }
      markDirty(this); // hopefully this ensures repainting
    }, 100);
  }

  showShadows(e: Event) {
    if (this.groupsScrollContainer?.nativeElement.scrollTop > 0) {
      this.showTeamsListScrollShadow = true;
    } else {
      this.showTeamsListScrollShadow = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  windowOnResize() {
    this.checkIfGroupsHaveScroll();
  }

  scrollToMessageBridge(message: PbChatMessage) {
    this.messageTarget = message;
  }

  @HostListener('document:scroll', ['$event'])
  horizontalScrollHandler(event: Event) {
    const leftOffset = (event.target as any).scrollingElement.scrollLeft;
    if (leftOffset > 0) {
      (document.getElementsByClassName('tm-go-group')[0] as HTMLElement).style.left = -leftOffset + 1 + 'px';
      (document.getElementsByClassName('tm-go-group')[1] as HTMLElement).style.left = -leftOffset + 1 + 'px';
    } else {
      (document.getElementsByClassName('tm-go-group')[0] as HTMLElement).style.left = 'auto';
      (document.getElementsByClassName('tm-go-group')[1] as HTMLElement).style.left = 'auto';
    }
  }

  showTeamMembers($event: PbGroupChat) {
    this.teamInfoCompoment?.showTeammatesList(true);
    this.openModal('teaminfo-modal');
  }
}
