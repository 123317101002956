import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from '../dialog/modal.service';
import {GroupChatDataService} from '../groupchats/groupchat-data.service';
import {PbGroupChat} from '../api/groupchats_pb';
import {PbGroup} from '../api/groups_pb';
import {CloudStorageService} from '../cloud-storage.service';
import {GroupDataService} from '../groups/group-data.service';
import {NotifyBlock, TeamyError} from '../types';

@Component({
  selector: 'app-newtopic',
  templateUrl: './newtopic.component.html',
  styleUrls: ['../chooseusers/choose-users.component.scss', '../newtopic/newtopic.component.scss']
})
export class NewtopicComponent implements OnInit, AfterViewInit {
  @Input() selectedGroup?: PbGroup;

  @Output() showNotifyBlock = new EventEmitter<NotifyBlock>();
  @Output() notifyShowInviteToChat = new EventEmitter<Set<string>>();
  @Output() notifyNewTopicCreated = new EventEmitter<PbGroupChat>();

  // static DEFAULT_TOPIC_NAME = 'New topic';
  topicName = '';
  onlyInvitedTeammates = false;
  invitedTeammates?: Set<string>;
  avatarIdString?: string;
  avatarLink?: string;
  // NewtopicComponent.DEFAULT_TOPIC_NAME;

  // @ViewChild('topicNameInput') inputTopicName: ElementRef<HTMLInputElement>;

  constructor(private modalService: ModalService,
              private cloudStorageService: CloudStorageService,
              private groupDataService: GroupDataService,
              private groupChatDataService: GroupChatDataService) {
  }

  ngOnInit() {
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  async createTopic() {
    this.topicName = this.topicName.trim();
    if (!this.topicName || !this.selectedGroup) {
      return;
    }
    const chat = new PbGroupChat();
    chat.setGroupId(this.selectedGroup.getId());
    chat.setDescription(this.topicName);
    chat.setMembersOnly(this.onlyInvitedTeammates);
    if (this.invitedTeammates) {
      chat.setUsersList([...this.invitedTeammates]);
    }
    if (this.avatarIdString) {
      chat.setAvatarUrl(this.avatarIdString);
    }

    try {
      const createdChat = await this.groupChatDataService.create(chat);
      this.notifyNewTopicCreated.emit(createdChat);
    } catch (e) {
      console.log('error createTopic', e);
      // const message = `<b>Failed!</b> ` + (e instanceof TeamyError && e.isNameTooLong() ? 'Name too long' : 'Unknown reason');
      const message = `Failed!\n\n ` + (e instanceof TeamyError && e.isNameTooLong() ? 'Name too long' : 'Unknown reason');
      alert(message);
      // @todo seems like we should use showNotifyBlock but it will not be visible under this dialog window before we close it
      // @todo what other way of displaying errors we have in the design?
      return;
    }
    if (this.invitedTeammates) {
      this.showNotifyBlock.emit({
        type: 'success',
        message: '<b>Success!</b> You created a topic for ' + this.invitedTeammates.size + ' teammates.'
      });
    } else {
      this.showNotifyBlock.emit({
        type: 'success',
        message: '<b>Success!</b> You created a new topic'
      });
    }

    this.closeAndClearModal();
  }

  ngAfterViewInit(): void {
    // this.inputTopicName.nativeElement.focus();
  }

  invitePeopleToPrivate(forseShowPopup = false) {
    if ((this.onlyInvitedTeammates && !this.invitedTeammates) || forseShowPopup) {
      this.notifyShowInviteToChat.emit(this.invitedTeammates);
    }
  }

  buildSelectedContactsList(selectedContacts: Set<string>) {
    this.invitedTeammates = selectedContacts;
  }

  get firstSix() {
    return this.invitedTeammates ? [...this.invitedTeammates].slice(0, 6) : [];
  }

  closeAndClearModal() {
    this.topicName = '';
    this.onlyInvitedTeammates = false;
    this.invitedTeammates = undefined;
    this.closeModal('newtopic-modal');
    this.avatarIdString = undefined;
    this.avatarLink = undefined;
  }

  async setAvatar(files: FileList | null) {
    const file = files?.item(0);
    if (file) {
      console.log(`setting avatar file ${file.name} size=${file.size} type=${file.type}`);

      if (file.type.startsWith('image/')) {
        const {id: newId, name: newName} = await this.cloudStorageService.uploadFile('avatar', '', file);
        this.avatarIdString = 'sourceId:' + newId;
        this.avatarLink = (window.URL ? URL : webkitURL).createObjectURL(file);
      }
    }
  }

  isEmptyChatName() {
    return !this.topicName || this.topicName.trim() === '';
  }
}
