<jw-modal id="settings-modal">
  <div class="tm-modal-header modal-with-close">
    <h6>Settings</h6>
    <div class="tm-close-modal" (click)="closeModal('settings-modal');"></div>
  </div>
  <div class="tm-modal-body">
    <div style="width: 766px;">
      <div class="tm-modal-tabs-list">
        <div class="tm-modal-go-tab tab-profile" (click)="selectTab('profile')" [class.selected]="selectedTab==='profile'">Profile</div>
        <!--<div class="tm-modal-go-tab tab-notifications" (click)="selectTab('notifications')" [class.selected]="selectedTab==='notifications'">Notifications and Sounds</div>-->
        <div class="tm-modal-go-tab tab-theme" (click)="selectTab('color')" [class.selected]="selectedTab==='color'">Appearance</div>
        <!--<div class="tm-modal-go-tab tab-storage" (click)="selectTab('data')" [class.selected]="selectedTab==='data'">Data and Storage</div>-->
        <a href="https://teamy.chat/privacy.html" target="_blank" class="tm-modal-go-tab tab-privacy">Privacy Policy</a>
        <!--<div class="tm-modal-go-tab tab-about" (click)="selectTab('about')" [class.selected]="selectedTab==='about'">About Teamy</div>-->
      </div>

      <div [ngSwitch]="selectedTab" class="tm-modal-tab-holder">

        <div *ngSwitchCase="'profile'" class="tm-modal-tab">
          <div class="profile-info-holder">
            <app-avatar [userId]="currentUser().getId()" class="tm-big-ava"></app-avatar>
            <input type="file" style="display: none" accept="image/*" #fileInput (change)="setUserAvatar(fileInput.files)" />

            <div *ngIf="!isEditingName">
              <app-fullname [userId]="currentUser().getId()" *ngIf="!isEditingName"></app-fullname>
              <div class="edit-name" (click)="editNameStart()"></div>
            </div>

            <div class="tm-name-input-holder" *ngIf="isEditingName">
              <div class="tm-upload-ava" (click)="fileInput.click()"></div>
              <div class="tm-input-item">
                <div class="tm-input-ext">
                  <input autocomplete="new-password" spellcheck="false" name="userName" [(ngModel)]="userName" #inputToFocus/>
                  <div class="tm-input-placeholder">Name</div>
                </div>
              </div>

              <div class="clear-fix change-name-buttons-holder">
                <button class="tm-btn float-right" (click)="saveNewUSerName()">Save</button>
                <button class="tm-btn tm-btn-transp float-right" (click)="isEditingName = false">Cancel</button>
              </div>
            </div>
          </div>

          <div class="tm-chat-subtitle">Profile</div>

          <div class="settings-item tm-settings-email">Email <div class="info-right">{{currentUser().getEmail()}}</div></div>
          <div class="settings-item tm-settings-logout" (click)="logout()">Sign Out</div>
        </div>

        <div *ngSwitchCase="'color'" class="tm-modal-tab">
          <div class="tm-chat-subtitle">Chat Appearance</div>
          <div class="settings-item tm-settings-color">
            Rainbow text bubbles <input class="tm-checkbox" name="enableRainbowBubbles" type="checkbox" [(ngModel)]="rainbowChatBubbles" (change)="rainbowChange()" />
            <div class="tm-switcher"></div>
          </div>

          <div class="settings-item tm-settings-card">
            Show chat as card <input class="tm-checkbox" name="enableCardView" type="checkbox" [(ngModel)]="cardView" (change)="cardViewChange()" />
            <div class="tm-switcher"></div>
          </div>

          <!--<div class="tm-chat-subtitle">App Color Theme</div>-->
          <!--<div>-->
            <!--<label>-->
              <!--<span style="display: inline-block; background-color: black; border-radius: 50%; width: 13px; height: 13px;"></span> Dark-->
              <!--<input type="radio" value="theme-dark" name="color-theme" [(ngModel)]="colorThemeName">-->
            <!--</label>-->
          <!--</div>-->
          <!--<div>-->
            <!--<label>-->
              <!--<span style="display: inline-block; background-color: blue; border-radius: 50%; width: 13px; height: 13px;"></span> Blue-->
              <!--<input type="radio" value="theme-blue" name="color-theme" [(ngModel)]="colorThemeName">-->
            <!--</label>-->
          <!--</div>-->
        </div>
      </div>
    </div>
  </div>

</jw-modal>
