import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {ModalService} from '../dialog/modal.service';
import {PbChatMessage} from '../api/groupchats_pb';
import {CloudStorageService} from '../cloud-storage.service';
import {TimeUtils} from '../util/time.utils';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {CommonUtils} from '../util/common.utils';

@Component({
  selector: 'app-imageview',
  templateUrl: './imageview.component.html',
  styleUrls: ['./imageview.component.scss']
})

export class ImageviewComponent implements OnInit {
  imgHasLoaded = true;
  messageAuthor?: string;
  imageName?: string;
  messageTime?: number;
  rotateAngle = 0;
  rotateAngleClass = '';
  showMoreMenu = false;
  isOwnMessage = false;
  initialMediaIndex?: number;
  currentMediaIndex = 0;
  maxMediaIndex?: number;
  messagesWithMedia: Array<PbChatMessage> = [];
  currentImageUrl?: string | SafeUrl;
  currentImageDownloadUrl?: string;
  message: PbChatMessage = new PbChatMessage();
  allChatMessages: Array<PbChatMessage> = [];
  savedURLs = new Map<string, SafeUrl>();

  constructor(private modalService: ModalService,
              private sanitizer: DomSanitizer,
              private cloudStorageService: CloudStorageService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ImageviewComponent>) {
  }

  ngOnInit() {
    this.message = this.data.message;
    this.allChatMessages = this.data.allChatMessages;
    this.init();
  }

  init() {
    this.buildMediaMessagesArr(this.allChatMessages);
    this.currentMediaIndex = this.messagesWithMedia.findIndex((message) => message.getId() === this.message.getId());
    this.initialMediaIndex = this.currentMediaIndex;
    this.buildPreviewInfo(this.message);
  }

  closeModal() {
    this.dialogRef.close();
  }

  buildMediaMessagesArr(allMessages: Array<PbChatMessage>) {
    this.messagesWithMedia = [];

    for (const msg of allMessages) {
      if (msg.getFile()?.getPreview()) {
        this.messagesWithMedia.push(msg);
      }
    }

    this.maxMediaIndex = this.messagesWithMedia.length - 1;
  }

  async getImageUrl(message: PbChatMessage) {
    this.imgHasLoaded = false;
    const savedUrl = this.savedURLs.get(message.getId());
    if (savedUrl) {
      this.currentImageUrl = savedUrl;
      this.imgHasLoaded = true;
      return;
    }

    const fileId = message.getFile()?.getData();
    if (!fileId) {
      return;
    }
    const forSyncPreviewUrl = this.cloudStorageService.getPreviewSize(fileId, 'l');
    if (forSyncPreviewUrl) {
      if (CommonUtils.isUrlFromCache(forSyncPreviewUrl)) {
        this.imgHasLoaded = true;
      }
      console.log('imageview: preview url', fileId, forSyncPreviewUrl);
      this.currentImageUrl = this.sanitizer.bypassSecurityTrustUrl(forSyncPreviewUrl);
      this.currentImageDownloadUrl = await this.cloudStorageService.getImageOrFileFinalUrl(fileId); // moved awaited call after cache checking
    } else {
      // preview not yet ready
      this.currentImageDownloadUrl = await this.cloudStorageService.getImageOrFileFinalUrl(fileId);
      if (CommonUtils.isUrlFromCache(this.currentImageDownloadUrl)) {
        this.imgHasLoaded = true;
      }
      this.currentImageUrl = this.sanitizer.bypassSecurityTrustUrl(this.currentImageDownloadUrl);
      console.log('imageview: forced to use final url', fileId, this.currentImageDownloadUrl);
    }
    this.savedURLs.set(message.getId(), this.currentImageUrl);
  }

  buildPreviewInfo(message: PbChatMessage) {
    this.messageAuthor = message.getFrom();
    this.imageName = message.getFile()?.getName();
    this.messageTime = message.getCreationdate();
    this.isOwnMessage = !message.getIncoming();
    this.getImageUrl(message);
  }

  mediaPreviewError(e: Event) {
    // todo: add visual message about error
  }

  mediaPreviewLoaded(e: Event) {
    this.imgHasLoaded = true;
  }

  formatDateLastMessage(message: PbChatMessage) {
    const millis: number = message.getCreationdate();
    // return new Date(millis).toLocaleString();
    return TimeUtils.sTimeFormat(millis);
  }

  rotateMedia() {
    this.rotateAngle = (this.rotateAngle + 90) % 360;
    this.rotateAngleClass = 'rotate' + this.rotateAngle;
  }

  resetRotateMedia() {
    this.rotateAngle = 0;
    this.rotateAngleClass = 'rotate' + this.rotateAngle;
  }

  downloadMedia() {
    if (!this.currentImageDownloadUrl) {
      return;
    }
    fetch(this.currentImageDownloadUrl)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.setAttribute('href', url);
        a.setAttribute('download', this.imageName ?? '');
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => console.log('oh no! some error occurred during file download'));
  }

  changeMediaInView(goNext: boolean) {
    if ((goNext && (this.currentMediaIndex === this.maxMediaIndex)) || (!goNext && this.currentMediaIndex === 0)) {
      return;
    }
    this.currentMediaIndex += goNext ? 1 : -1;
    this.resetRotateMedia();

    this.message = this.messagesWithMedia[this.currentMediaIndex];
    this.buildPreviewInfo(this.message);
  }

  @HostListener('window:keydown', ['$event'])
  changeMediaViaKeyboard(event: KeyboardEvent) {
    if (!this.messagesWithMedia) {
      return;
    }

    switch (event.key) {
      case 'ArrowLeft':
        this.changeMediaInView(false);
        break;
      case 'ArrowRight':
        this.changeMediaInView(true);
    }
  }

  goToCurrentMediaMessage() {
    this.closeModal();
    const messageIndexInAllMessages = this.allChatMessages.findIndex((message) => message.getId() === this.message.getId());
    this.data.scrollToMediaMessageInChat(messageIndexInAllMessages);
  }
}
