import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
// import {grpc} from 'grpc-web-client';
import {Empty, PbAuthResult, PbLogin, PbPingResult, PbRegister, PbUser} from '../api/groups_pb';
import {UserServiceClient} from '../api/groups_pb_service';

import {GrpcDataService} from './grpc-data.service';
import {EncryptionService} from '../encryption.service';
// import {Authorization} from 'oauth-1.0a';
// import * as OAuth from 'oauth-1.0a';
import {CloudStorageService} from '../cloud-storage.service';
import {TeamyAccount} from './teamy-account';
import {MessageDbService} from '../message-db.service';
import {GroupDbService} from '../group-db.service';
import {ChatDbService} from '../chat-db.service';
import {NotificationService} from '../notification.service';
import {UserDbService} from '../user-db.service';
import {CommonUtils} from '../util/common.utils';
import {ServerEventService} from './server-event.service';
import {grpc} from '@improbable-eng/grpc-web';
import {TimeService} from '../time.service';
import {TeamyError} from '../types';
// import OAuth = require('oauth-1.0a');

// import 'oauth-1.0a';
// const OAuth = require('oauth-1.0a');

@Injectable({
  providedIn: 'root'
})
export class LoginDataService {
  // myId: number = Math.random();

  public grpcURL;
  public grpcMetadata: grpc.Metadata = new grpc.Metadata();

  userService: UserServiceClient;

  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public everLoggedIn = false;
  loggedIn = false;
  loginUser?: string;
  public user: PbUser = new PbUser();

  constructor(private notificationService: NotificationService,
              private grpcDataService: GrpcDataService,
              private encryptionService: EncryptionService,
              private cloudStorageService: CloudStorageService,
              private userDbService: UserDbService,
              private chatDbService: ChatDbService,
              private timeService: TimeService,
              private messageDbService: MessageDbService,
              private groupDbService: GroupDbService,
              private serverEventService: ServerEventService) {
    this.grpcURL = this.grpcDataService.grpcURL;
    this.userService = new UserServiceClient(this.grpcURL, undefined);
    // console.log("LoginDataService created " + this.isUserLoggedIn + " " + this.myId + " " + window.location.host + " " + this.grpcURL);
    this.serverEventService.forcedSignoutEvents.subscribe(() => this.forcedSignout());
  }

  // private static getHost() {
  //   let host: string = window.location.host;
  //   if (host.includes(':')) {
  //     host = host.substring(0, host.indexOf(':'));
  //   }
  //   return host;
  // }

  async localStorageSignIn() {
    const pingResult = await this.grpcDataService.call<Empty, PbPingResult>(this.userService, this.userService.ping, new Empty());
    this.timeService.setServerTime(pingResult.getServertime());
    const account = await this.encryptionService.loadAccountFromLocalStorage();
    if (account) {
      await this.setAccount(account);
      // const freshUser = await this.userDbService.findUserFresh(account.user.getId());
      const freshUser = await this.userDbService.findOrLoadUser(account.user.getId()); // the user has just been loaded to cache as part of findAll()
      if (freshUser && this.user.getId() === freshUser.getId()) { // if no relogin happened in the interim
        this.user = freshUser;
        account.user = freshUser;
        this.encryptionService.saveAccountToLocalStorage(account);
      }
      return true;
    } else {
      return false;
    }
  }

  async signIn(account: TeamyAccount) {
    this.encryptionService.setAccount(account);
    let versions = '';
    if (account.accountPrivateKey) {
      versions = '' + account.accountPrivateKey.getVersioneditemsMap().keys();
    }
    console.log('My login account key version ' + account.accountKeyVersion + ' otherVersions=' + versions);
    await this.setAccount(account);
  }

  private async setAccount(account: TeamyAccount) {
    try {
      this.user = account.user;
      this.loginUser = account.user.getId();
      this.grpcDataService.userId = this.user.getId();
      this.grpcMetadata = new grpc.Metadata();
      this.grpcMetadata.set('authToken', account.tokenTeamy);
      this.grpcDataService.setMetadata(this.grpcMetadata);

      console.log('calling cloudStorageService.connectCloudStorage()');
      await this.cloudStorageService.connectCloudStorage(this.loginUser);

      this.loggedIn = true;
      this.everLoggedIn = true;

      console.log('avatar url = ' + this.user.getAvatarurl());

      console.log('calling serverEventService.subscribeListener()');
      await this.serverEventService.subscribeListener(this.grpcMetadata, this.loginUser);

      console.log('calling groupDbService.loadGroups()');
      // this.groupDbService.loadGroups();
      // this.groupDbService.loadAll();
      await this.initialLoad();
      this.isUserLoggedIn.next(true);

      console.log('calling userDbService.getContacts()');
      this.userDbService.getContacts(); // init cache
      setInterval(() => this.markActive(), 50_000); // once per 50 seconds
    } catch (e) {
      if (e instanceof TeamyError && e.isAuthTokenInvalid()) {
        console.log('setAccount error AUTH_TOKEN_INVALID, doing signOut()');
        // await this.signOut();
        await this.forcedSignout();
      } else {
        console.log('setAccount error ' + e.message, e);
        throw e;
      }
    }
  }

  async initialLoad() {
    const users = await this.groupDbService.loadAll();
    this.userDbService.precacheInitialUsers(users);
  }

  async markActive() {
    if (this.isUserLoggedIn.getValue() && CommonUtils.isActive()) {
      if (navigator.onLine) {
        await this.grpcDataService.call(this.userService, this.userService.markActive, new Empty());
      } else {
        console.log('markActive cancelled: no internet connection');
      }
    }
  }

  async register(idToken: string, verificationCode: string): Promise<TeamyAccount | undefined> { // login/registration with Google
    const reg = new PbRegister();
    reg.setIdtoken(idToken);
    let account: TeamyAccount;
    if (verificationCode) {
      reg.setVerificationcode(verificationCode);
      await this.encryptionService.setDevicePublicKey(reg);
      const res: PbAuthResult = await this.grpcDataService.call<PbRegister, PbAuthResult>(this.userService, this.userService.approveDevice, reg);
      const user = res.getUser();
      const accountprivatekey = res.getAccountprivatekey();
      if (!res.getTokenteamy() || !user || !accountprivatekey) {
        console.log('no tokenTeamy, tried login with Google');
        return undefined;
      }
      account = new TeamyAccount(user, res.getAccountprivatekey(), accountprivatekey.getVersion(), res.getTokenteamy(), res.getDevicepublickeysMap());
    } else {
      await this.encryptionService.generateKeysAtRegistration(reg);
      const res: PbAuthResult = await this.grpcDataService.call<PbRegister, PbAuthResult>(this.userService, this.userService.register, reg);
      const user = res.getUser();
      const accountprivatekey = res.getAccountprivatekey();
      if (!res.getTokenteamy() || !user || !accountprivatekey) {
        return undefined;
      }
      account = new TeamyAccount(user, undefined/*will use my generated key*/, accountprivatekey.getVersion(), res.getTokenteamy(), res.getDevicepublickeysMap());
    }
    return account;
  }

  async login(email: string): Promise<PbAuthResult> {
    const reg = new PbRegister();
    await this.encryptionService.generateKeysAtRegistration(reg);

    const request = new PbLogin();
    request.setEmail(email);
    request.setDeviceid(reg.getDeviceid());
    request.setDevicepublickey(reg.getDevicepublickey());
    request.setDevicename(reg.getDevicename());
    return this.grpcDataService.call<PbLogin, PbAuthResult>(this.userService, this.userService.login, request);
  }

  async verifyEmail(request: PbRegister): Promise<TeamyAccount | undefined> {
    const res: PbAuthResult = await this.grpcDataService.call<PbRegister, PbAuthResult>(this.userService, this.userService.verifyEmail, request);
    const user = res.getUser();
    const accountprivatekey = res.getAccountprivatekey();
    if (!res.getTokenteamy() || !user || !accountprivatekey) {
      return undefined;
    }
    return new TeamyAccount(user, undefined/*will use my generated key*/, accountprivatekey.getVersion(), res.getTokenteamy(), res.getDevicepublickeysMap());
  }

  async approveDevice(email: string, code: string): Promise<TeamyAccount | undefined> {
    const request = new PbRegister();
    request.setEmail(email);
    request.setVerificationcode(code);
    await this.encryptionService.setDevicePublicKey(request);
    const res: PbAuthResult = await this.grpcDataService.call<PbRegister, PbAuthResult>(this.userService, this.userService.approveDevice, request);
    const user = res.getUser();
    const accountprivatekey = res.getAccountprivatekey();
    if (!res.getTokenteamy() || !user || !accountprivatekey) {
      console.log('no tokenTeamy, tried login with email: ' + email);
      return undefined;
    }
    return new TeamyAccount(user, res.getAccountprivatekey(), accountprivatekey.getVersion(), res.getTokenteamy(), res.getDevicepublickeysMap());
  }

  async registerEmail(email: string, name: string): Promise<PbAuthResult> {
    const request = new PbRegister();
    request.setDeviceid(this.encryptionService.deviceId ?? '');
    request.setEmail(email);
    request.setName(name);
    // @todo request.setDevicepublickey()
    return this.grpcDataService.call<PbRegister, PbAuthResult>(this.userService, this.userService.registerEmail, request);
  }

  async forcedSignout() {
    await this.signOut();
    setTimeout(() => {
      console.log('reloading page...');
      location.reload();
    }, 2000);
  }

  async signOut() {
    this.encryptionService.signOut();
    this.serverEventService.unsubscribe();
    this.isUserLoggedIn.next(false);
    try {
      await this.grpcDataService.call(this.userService, this.userService.logout, new Empty());
    } catch (e) {
      if (e instanceof TeamyError && e.isAuthTokenInvalid()) {
        console.log('userService.logout() not called (auth token already invalid, that\'s ok');
      } else {
        throw e;
      }
    }
  }

  updateUser() {
    console.log(`updating user [${this.user.getFullname()}]`);
    this.userService.updateUser(this.user, this.grpcMetadata, (error, updatedUser) => {
      if (updatedUser) {
        this.user = updatedUser;
        console.log(`updated user [${this.user.getFullname()}]`);
      }
    });
  }

  updateUserFullName(fullName: string) {
    const upd = new PbUser();
    upd.setFullname(fullName);
    this.grpcDataService.call(this.userService, this.userService.updateUserFullName, upd);
  }

  async updateUserAvatar(avatarUrl: string) {
    const upd = new PbUser();
    upd.setAvatarurl(avatarUrl);
    const resUser = await this.grpcDataService.call(this.userService, this.userService.updateUserAvatar, upd);
    return resUser;
  }

  async sendMagicLink(email: string) {
    const req = new PbRegister();
    req.setEmail(email);
    const res = await this.grpcDataService.call<PbRegister, PbAuthResult>(this.userService, this.userService.sendMagicLink, req);
    return res;
  }

}
