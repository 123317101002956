import {formatDate} from '@angular/common';
import {PbUser} from '../api/groups_pb';

export class TimeUtils {
  static readonly DAY_MILLIS: number = 24 * 60 * 60 * 1000;

  // private static final SimpleDateFormat sTimeFormat =
  //   new SimpleDateFormat("HH:mm", Locale.US);
  // private static final SimpleDateFormat sDayOfWeekFormat =
  //   new SimpleDateFormat("EEEE", Locale.US);
  // private static final SimpleDateFormat sMonthDateFormat =
  //   new SimpleDateFormat("MMM d", Locale.US);
  // private static final SimpleDateFormat sDateMonthFormat =
  //   new SimpleDateFormat("d MMMM", Locale.US);

  static sTimeFormat(t: number): string {
    return formatDate(t, 'HH:mm', TimeUtils.locale());
  }

  static sDayOfWeekFormat(t: number): string {
    return formatDate(t, 'EEEE', TimeUtils.locale());
  }

  static sMonthDateFormat(t: number): string {
    return formatDate(t, 'MMM d', TimeUtils.locale());
  }

  static sDateMonthFormat(t: number): string {
    if (new Date().getFullYear() !== new Date(t).getFullYear()) {
      return formatDate(t, 'd MMMM yyyy', TimeUtils.locale());
    }
    return formatDate(t, 'd MMMM', TimeUtils.locale());
  }

  public static sHoursMinutesFormat(t: number): string {
    return formatDate(t, 'HH:mm', TimeUtils.locale());
  }

  static locale(): string {
    return 'en-US'; // @todo take locale from app settings in the future. We shall have a limited set of languages in the app, choosable in settings.


    // return "ru-RU";
    /*
    return navigator.languages
      ? navigator.languages[0]
      : (navigator.language || (navigator as any).userLanguage)
      */
  }

  public static getLastMessageStr(messageTime: number): string {
    const days = TimeUtils.daysBetween(messageTime, new Date().getTime());
    switch (days) {
      case 0: // today
        return TimeUtils.sTimeFormat(messageTime);
      case 1: // yesterday
        return 'Yesterday';
      case 2:
      case 3:
      case 4:
      case 5:
      case 6: // this week
        return TimeUtils.sDayOfWeekFormat(messageTime);
      default:
        return TimeUtils.sMonthDateFormat(messageTime);
    }
  }

  public static getChatMessageStr(messageTime: number): string {
    const days = TimeUtils.daysBetween(messageTime, new Date().getTime());
    switch (days) {
      case 0: // today
        return 'Today';
      case 1: // yesterday
        return 'Yesterday';
      default:
        return TimeUtils.sDateMonthFormat(messageTime);
    }
  }

  private static daysBetween(start: number, end: number): number {
    const timeStart = start < end ? start : end;
    const timeEnd = start > end ? start : end;

    const finishOfStartDay = TimeUtils.getDayStart(timeStart) + TimeUtils.DAY_MILLIS - 1;
    let diff = 0;
    if (timeEnd > finishOfStartDay) {
      diff = Math.trunc(((timeEnd - finishOfStartDay) / TimeUtils.DAY_MILLIS)) + 1;
    }

    return diff;
  }

  public static getDayStart(time: number): number {
    const start = new Date(time);
    start.setHours(0, 0, 0, 0);
    return start.getTime();
  }

  private static hoursBetween(time1: number, time2: number) {
    return Math.trunc(Math.abs(time2 - time1) / 3600_000);
    // return (int) TimeUnit.MILLISECONDS.toHours(time1 > time2 ? time1 - time2 : time2 - time1);
  }

  private static minutesBetween(time1: number, time2: number) {
    return Math.trunc(Math.abs(time2 - time1) / 60_000);
    // return (int) TimeUnit.MILLISECONDS.toMinutes(time1 > time2 ? time1 - time2 : time2 - time1);
  }

  public static isOnline(user?: PbUser): boolean {
    if (user?.getLastactivity() === 0) { return true; } // till server tells us this user went online, we keeps its online status
    return false;
  }

  public static getLastSeenStrOrOnline(user: PbUser): string {
    if (user.getLastactivity() === 0) { return ''; } // till server tells us this user went online, we keeps its online status
    return TimeUtils.getLastSeenStr(user.getLastactivitytime());
  }

  private static getLastSeenStr(lastActiveTimeMillis: number): string {
    const agoSeconds = (new Date().getTime() - lastActiveTimeMillis) / 1000;
    if (agoSeconds < 40) { return 'online'; }
    return `last seen ${TimeUtils.getLastActivityStr(lastActiveTimeMillis)}`;
  }

  public static getLastActivityStr(idleTimeMillis: number): string {
    const currentTime = new Date().getTime();
    if (idleTimeMillis > currentTime) { idleTimeMillis = currentTime; }
    const days = TimeUtils.daysBetween(currentTime, idleTimeMillis);
    switch (days) {
      case 0: // this day
        let minutes = TimeUtils.minutesBetween(currentTime, idleTimeMillis);
        const hours = TimeUtils.hoursBetween(currentTime, idleTimeMillis);
        switch (hours) {
          case 0:
            // this hour
            switch (minutes) {
              case 0:
                return 'a moment ago';
              case 1:
                return 'a minute ago';
              default:
                return `${minutes} minutes ago`;
            }
          default:
            // more then 1 hour
            minutes -= 60;
            return minutes < 5
              ? 'an hour ago'
              : `today at ${TimeUtils.sTimeFormat(idleTimeMillis)}`;
        }
      case 1: // yesterday
        return `yesterday at ${TimeUtils.sTimeFormat(idleTimeMillis)}`;
      default: // days ago
        return `on ${TimeUtils.sMonthDateFormat(idleTimeMillis)} at ${TimeUtils.sTimeFormat(idleTimeMillis)}`;
    }
  }


}
