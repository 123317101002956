import {Injectable} from '@angular/core';
import {GrpcDataService} from '../login/grpc-data.service';
import {GroupUtils} from '../util/group.utils';
import {UserDbService} from '../user-db.service';
import {DomSanitizer} from '@angular/platform-browser';
import {CommonUtils} from '../util/common.utils';

@Injectable({
  providedIn: 'root'
})
export class FullnameService {

  constructor(private grpcDataService: GrpcDataService,
              private userDbService: UserDbService,
              private sanitizer: DomSanitizer,
  ) {
  }

  computeLabelForUser(userId: string, options: { plaintext: boolean; withYou: boolean; highlightText?: string; nameOnly: boolean }) {
    if (userId === GroupUtils.TEAMY_BOT) {
      return 'Teamy';
    } else {
      const contact = this.userDbService.findContact(userId);
      if (contact) {
        return this.mapUserName(userId, options.nameOnly ? contact.getGivenname() : contact.getFullname(), options);
      } else {
        const pbUser = this.userDbService.findUser(userId);
        if (!pbUser) {
          return userId;
        } else {
          return this.mapUserName(userId, options.nameOnly ? pbUser.getGivenname() : pbUser.getFullname(), options);
        }
      }
    }
  }

  private mapUserName(userId: string, fullName: string, options: { plaintext: boolean; withYou: boolean; highlightText?: string }): string {
    if (!fullName) {
      return userId.replace('_', '@'); // make email from userId
    }
    if (userId === this.grpcDataService.userId) {
      if (options.plaintext) {
        return 'You';
      } else if (options.withYou) {
        return fullName + ' (you)';
      }
    } else if (options.plaintext) {
      return fullName.split(' ').shift() ?? '';
    }
    if (options.highlightText) {
      try {
        return CommonUtils.highlightHtml(this.sanitizer, fullName, options.highlightText);
      } catch (e) {
        console.log('mapUserName', e);
        return fullName ?? '';
      }
    } else {
      return fullName ?? '';
    }
  }

}
