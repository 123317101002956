// package: 
// file: security.proto

var security_pb = require("./security_pb");
var groupchats_pb = require("./groupchats_pb");
var groups_pb = require("./groups_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var KeyStoreService = (function () {
  function KeyStoreService() {}
  KeyStoreService.serviceName = "KeyStoreService";
  return KeyStoreService;
}());

KeyStoreService.loadPublicKeys = {
  methodName: "loadPublicKeys",
  service: KeyStoreService,
  requestStream: false,
  responseStream: false,
  requestType: groupchats_pb.PbGroupChatFind,
  responseType: security_pb.PbKeyList
};

KeyStoreService.shareAccountPrivateKey = {
  methodName: "shareAccountPrivateKey",
  service: KeyStoreService,
  requestStream: false,
  responseStream: false,
  requestType: groups_pb.PbRsaEncrypted,
  responseType: groups_pb.Empty
};

KeyStoreService.multicastAccountPrivateKeys = {
  methodName: "multicastAccountPrivateKeys",
  service: KeyStoreService,
  requestStream: false,
  responseStream: false,
  requestType: security_pb.PbRsaEncryptedForDevices,
  responseType: groups_pb.Empty
};

exports.KeyStoreService = KeyStoreService;

function KeyStoreServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

KeyStoreServiceClient.prototype.loadPublicKeys = function loadPublicKeys(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(KeyStoreService.loadPublicKeys, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

KeyStoreServiceClient.prototype.shareAccountPrivateKey = function shareAccountPrivateKey(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(KeyStoreService.shareAccountPrivateKey, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

KeyStoreServiceClient.prototype.multicastAccountPrivateKeys = function multicastAccountPrivateKeys(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(KeyStoreService.multicastAccountPrivateKeys, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.KeyStoreServiceClient = KeyStoreServiceClient;

var SessionStoreService = (function () {
  function SessionStoreService() {}
  SessionStoreService.serviceName = "SessionStoreService";
  return SessionStoreService;
}());

SessionStoreService.setSessionKey = {
  methodName: "setSessionKey",
  service: SessionStoreService,
  requestStream: false,
  responseStream: false,
  requestType: security_pb.PbSessionKey,
  responseType: groups_pb.Empty
};

SessionStoreService.updateSessionKeys = {
  methodName: "updateSessionKeys",
  service: SessionStoreService,
  requestStream: false,
  responseStream: false,
  requestType: security_pb.PbSessionKeys,
  responseType: groups_pb.Empty
};

SessionStoreService.getSessionKey = {
  methodName: "getSessionKey",
  service: SessionStoreService,
  requestStream: false,
  responseStream: false,
  requestType: security_pb.PbSession,
  responseType: security_pb.PbKeyList
};

SessionStoreService.requestPeerUsers = {
  methodName: "requestPeerUsers",
  service: SessionStoreService,
  requestStream: false,
  responseStream: false,
  requestType: security_pb.PbChatSessions,
  responseType: groups_pb.Empty
};

exports.SessionStoreService = SessionStoreService;

function SessionStoreServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

SessionStoreServiceClient.prototype.setSessionKey = function setSessionKey(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SessionStoreService.setSessionKey, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SessionStoreServiceClient.prototype.updateSessionKeys = function updateSessionKeys(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SessionStoreService.updateSessionKeys, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SessionStoreServiceClient.prototype.getSessionKey = function getSessionKey(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SessionStoreService.getSessionKey, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SessionStoreServiceClient.prototype.requestPeerUsers = function requestPeerUsers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SessionStoreService.requestPeerUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.SessionStoreServiceClient = SessionStoreServiceClient;

