<div class="tm-signin-page">
<div class="tm-signin-holder">
  <div class="tm-signin-header">
    <div class="tm-logo"></div>
  </div>

  <div id="loading-teamy" class="teamy-start-loading">
    <div class="lottie-anim" lottie [options]="options" (animationCreated)="handleAnimation($event)"></div>
  </div>

  <div class="tm-signin-start" *ngIf="showBlock === 'select-signin-type'">
    <h1>Welcome to Teamy</h1>
    <h3>Organised and fast messenger for you and your friends</h3>

    <div class="tm-btn ico-google" (click)="googleLogin()">Continue with Google</div>
    <div class="tm-btn tm-btn-grey" (click)="goBlockSigninViaEmail()">Continue with email</div>
  </div>

  <div class="signup-inner clear-fix" *ngIf="showBlock === 'signin-via-email'">
    <h2>Continue with email</h2>

    <form (ngSubmit)="emailSignInForm.form.valid && signIn()" #emailSignInForm="ngForm" novalidate>
      <div class="tm-input-item" [ngClass]="{'is-invalid': emailSignInForm.submitted && username.invalid}">
        <div class="tm-input-ext">
          <input type="text" required id="loginUser" name="loginUser" autocomplete="new-password" spellcheck="false" [(ngModel)]="loginUser" #username="ngModel" email/>
          <div class="tm-input-placeholder">Enter your email address</div>
        </div>
        <div class="err-message">Please, enter your email address.</div>
      </div>

      <button type="submit" class="tm-btn">Continue</button>
    </form>

    <div class="tm-link-small" (click)="googleLogin()">Use Google account</div>
  </div>

  <div class="signup-inner clear-fix" *ngIf="showBlock === 'avatar-and-username'">
    <h2>Set up your profile</h2>

    <form (ngSubmit)="enterNameAndAvatar.form.valid && signIn()" #enterNameAndAvatar="ngForm" novalidate>
      <!--<div class="tm-acc-avatar" (click)="fileInput.click()"></div>-->
      <!--<input type="file" style="display: none" #fileInput (change)="setAvatarFile(fileInput.files)" />-->
      <!--<img [src]="avatarFileUrl" />-->

      <div class="tm-input-item" [ngClass]="{'is-invalid': enterNameAndAvatar.submitted && userFullName.invalid}">
        <div class="tm-input-ext">
          <input type="text" id="signUpUsername" name="newUserName" autocomplete="new-password" spellcheck="false" [(ngModel)]="signUpUsername" #userFullName="ngModel" required/>
          <div class="tm-input-placeholder">Enter your full name</div>
        </div>
        <div class="err-message">Enter your name</div>
      </div>

      <button type="submit" class="tm-btn">Create account</button>
    </form>

    <div class="tm-link-small back-link" (click)="goBlockSigninViaEmail()">Go back</div>
  </div>

  <div class="signup-inner clear-fix enter-signin-code" *ngIf="showBlock === 'email-verification'">
    <h2>Look for an email verifying your account</h2>
    <div class="verif-illustration"></div>
    <h3>Email with our verification link has been sent to<br> <strong>{{loginUser}}</strong></h3>

    <div class="tm-link-small" (click)="resendInstructions()" [attr.disabled]="waitingTimerInProgress">Resend link</div>
    <div class="tm-verif-message" [class.show-notification]="verificationMessage" [ngClass]="verificationMessageType">{{verificationMessage}}</div>
    <div class="tm-link-small no-margin" (click)="goBlockSigninViaEmail()">Use another email</div>
    <div class="tm-link-small back-link" (click)="goBlockSigninViaEmail()">Go back</div>
  </div>

  <div class="signup-inner clear-fix enter-signin-code" *ngIf="showBlock === 'magic-sent'">
    <h2>Look for an email verifying your account</h2>
    <div class="verif-illustration"></div>
    <h3>Email with our magic link has been sent to<br> <strong>{{loginUser}}</strong></h3>

    <div class="tm-link-small" (click)="resendMagicLink()" [attr.disabled]="waitingTimerInProgress">Resend link</div>
    <div class="tm-verif-message" [class.show-notification]="verificationMessage" [ngClass]="verificationMessageType">{{verificationMessage}}</div>
    <div class="tm-link-small no-margin" (click)="goBlockSigninViaEmail()">Use another email</div>
    <div class="tm-link-small back-link" (click)="goBlockSigninViaEmail()">Go back</div>
  </div>

  <div class="signup-inner enter-signin-code clear-fix" *ngIf="showBlock === 'enter-signin-code'">
    <h2>Enter code</h2>
    <h3>We've sent the code to the Teamy app on your other device. Please enter the code below.</h3>

    <form class="enter-code-holder" (keydown.backspace)="codeBackspace($event)">
      <input type="text" inputmode="numeric" pattern="[0-9]" oneDigitOnly autocomplete="new-password" maxlength="1" tabindex="1" name="pin1" [(ngModel)]="pin1" id="pin1"/>
      <input type="text" inputmode="numeric" pattern="[0-9]" oneDigitOnly autocomplete="new-password" maxlength="1" tabindex="2" name="pin2" disabled="{{isPinDisabled(2)}}" [(ngModel)]="pin2" />
      <input type="text" inputmode="numeric" pattern="[0-9]" oneDigitOnly autocomplete="new-password" maxlength="1" tabindex="3" name="pin3" disabled="{{isPinDisabled(3)}}" [(ngModel)]="pin3" />
      <input type="text" inputmode="numeric" pattern="[0-9]" oneDigitOnly autocomplete="new-password" maxlength="1" tabindex="4" name="pin4" disabled="{{isPinDisabled(4)}}" [(ngModel)]="pin4" />
      <input type="text" inputmode="numeric" pattern="[0-9]" oneDigitOnly autocomplete="new-password" maxlength="1" tabindex="5" name="pin5" disabled="{{isPinDisabled(5)}}" [(ngModel)]="pin5" />
      <input type="text" inputmode="numeric" pattern="[0-9]" oneDigitOnly autocomplete="new-password" maxlength="1" tabindex="6" name="pin6" disabled="{{isPinDisabled(6)}}" [(ngModel)]="pin6" (keydown)="postSignInWithCode()"/>
    </form>

    <div class="tm-link-small" (click)="sendMagicLink()">Send me a magic link to the email</div>
    <div class="tm-link-small back-link" (click)="goBlockSigninViaEmail()">Go back</div>
  </div>

  <div class="signup-inner enter-signin-code clear-fix" *ngIf="showVerificationBlock === 'validation-loading'">
    <h2>Account is being verified</h2>
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>

  <div class="signup-inner enter-signin-code clear-fix" *ngIf="showVerificationBlock === 'validation-ok'">
    <h2>Account verified</h2>
    <div class="illustration-ok"></div>
    <h3>You are now ready to use Teamy.</h3>
  </div>

  <div class="signup-inner enter-signin-code clear-fix" *ngIf="showVerificationBlock === 'validation-error'">
    <h2>Hmm...that didn't work</h2>
    <div class="illustration-error"></div>
    <h3>It's possible your validation link expired or got mangled somehow. Or you've already used it and you're already validated.</h3>

    <div class="tm-link-small" (click)="resendInstructions()">Resend link</div>
    <div class="tm-verif-message" [class.show-notification]="verificationMessage">{{verificationMessage}}</div>
    <div class="tm-link-small no-margin" (click)="goBlockSigninViaEmail()">Use another email</div>
    <div class="tm-link-small back-link" (click)="goBlockSigninViaEmail()">Go back</div>
  </div>

  <div class="signup-inner enter-signin-code clear-fix" *ngIf="showVerificationBlock === 'magic-error'">
    <h2>Hmm...that didn't work</h2>
    <div class="illustration-error"></div>
    <h3>It's possible your magic link expired or got mangled somehow. Or you've already used it and you're already validated.</h3>

    <div class="tm-link-small" (click)="resendMagicLink()">Resend link</div>
    <div class="tm-verif-message" [class.show-notification]="verificationMessage">{{verificationMessage}}</div>
    <div class="tm-link-small no-margin" (click)="goBlockSigninViaEmail()">Use another email</div>
    <div class="tm-link-small back-link" (click)="goBlockSigninViaEmail()">Go back</div>
  </div>

  <div class="signup-inner enter-signin-code clear-fix" *ngIf="showBlock === 'code-validation-error'">
    <h2>Invalid code</h2>
    <div class="illustration-error"></div>
    <h3>Oops! The code you entered is invalid. Try again!</h3>

    <div class="tm-link-small" (click)="goToEnterSigninCode()">Try again</div>
    <div class="tm-link-small back-link" (click)="goBlockSigninViaEmail()">Go back</div>
  </div>

  <div class="tm-signin-footer">
    By continuing you agree to our <a href="https://teamy.chat/privacy.html" target="_blank">Privacy Policy</a>
  </div>
</div>
</div>
