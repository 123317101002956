import {PbRsaEncrypted, PbUser} from '../api/groups_pb';
import * as jspb from 'google-protobuf';

export class TeamyAccount {
  constructor(public user: PbUser,
              public accountPrivateKey: PbRsaEncrypted | undefined,
              public accountKeyVersion: number,
              public tokenTeamy: string,
              public devicePublicKeys: jspb.Map<string, Uint8Array | string> | undefined, // devices to be notified of the new account private key generated
  ) {

  }
}
