import {Injectable} from '@angular/core';
import {PbKey} from './api/security_pb';
import {PbRsaEncrypted} from './api/groups_pb';

@Injectable({
  providedIn: 'root'
})
export class CypherService {

  generateRsaKeyPair() {
    return crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 2048, // used to be 4096 but Android devices generate keys too slowly //can be 1024, 2048, or 4096

        // publicExponent: new Uint8Array([1, 0, 1]),  // 24 bit representation of 65537
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),  // Java sets it to 65537,

        // hash: {name: "SHA-256"}, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
        hash: {name: 'SHA-1'}, // hope this will be compatible with Java's "RSA/ECB/OAEPWithSHA-1AndMGF1Padding";
      },
      true, // whether the key is extractable (i.e. can be used in exportKey)
      ['encrypt', 'decrypt']
    );
  }

  generateAesKey() {
    return crypto.subtle.generateKey({
        name: 'AES-CBC',
        length: 128, // can be  128, 192, or 256
      },
      true, // whether the key is extractable (i.e. can be used in exportKey)
      ['encrypt', 'decrypt'] // can "encrypt", "decrypt" or  "wrapKey", or "unwrapKey"
    );
  }

  exportAesKey(key: CryptoKey) {
    return crypto.subtle.exportKey(
      'raw', // can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      key // can be a publicKey or privateKey, as long as extractable was true

      // "pkcs8", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      // key.privateKey //can be a publicKey or privateKey, as long as extractable was true
    );
  }

  exportPublicKey(publicKey: CryptoKey) {
    return crypto.subtle.exportKey(
      'spki', // can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      publicKey // can be a publicKey or privateKey, as long as extractable was true

      // "pkcs8", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      // key.privateKey //can be a publicKey or privateKey, as long as extractable was true
    );
  }

  exportPrivateKey(privateKey: CryptoKey) {
    return crypto.subtle.exportKey(
      'pkcs8', // can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      privateKey // can be a publicKey or privateKey, as long as extractable was true

      // "pkcs8", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      // key.privateKey //can be a publicKey or privateKey, as long as extractable was true
    );
  }

  encryptSymmetric(key: CryptoKey, bytes: Uint8Array) {
    if (!key) { throw Error('encryptSymmetric: no CryptoKey'); }
    return crypto.subtle.encrypt(
      {
        name: 'AES-CBC',
        // @todo Don't re-use initialization vectors!
        // Always generate a new iv every time your encrypt!
        // iv: window.crypto.getRandomValues(new Uint8Array(16)),
        iv: new Uint8Array(16)
      },
      key,
      bytes);
  }

  decryptSymmetric(key: CryptoKey, bytes: Uint8Array): PromiseLike<ArrayBuffer> {
    return crypto.subtle.decrypt(
      {
        name: 'AES-CBC',
        iv: new Uint8Array(16), // The initialization vector you used to encrypt
      },
      key, // from generateKey or importKey above
      bytes // ArrayBuffer of the data
    );
  }

//   convertPrivateKeyFrom_iOS(iosKey: Uint8Array): Uint8Array {
//     let pkcs8example: string = `MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCsqvFLLbkjDVAJrc1FQWW51gjZ
// c1XISO5Zzi2JSkjOWnHbW3Nbws2mxxVyoSSYMdMek6n4KU8pBKewIFpfvdQu8R0tKAumifgyCWu0
// equ3KvVTV9eM4W7WArEbrugN9oj5KGIiNqAdUk898fSJjVERiDNUr6Oukl3o3ngeSNakK3buQ69q
// bsbyQyCXoX5w6GfG4Ci22IJrGVjrcDi6MFDKUOKIgfyJElF1rHbUX2P/aP1BMqIGwvE5bOsJnBGp
// uZPdQrjEASpozu8N48Mwv6GiFBn7av5tCw4I223PIeXuyT/fRmAG4uas+MjUl7JoO9DD3h0KCnG2
// +ewGcv1xx0o5AgMBAAECggEAAfd+gR83tSUBLOMMbAYHLRs5hZSRPRNLl71H+oDcnHEeS+PaSAjL
// g+NPpLseKhejMn13GasKykpRyKlNrjqA0LoRXYLPwyleKN+/mNIu44q7OaROAb5Nn2VvdAPFg+cz
// fzcNCxu5LixSV7/KS8jOzBbTktrfT+094ZmrH5kvzLXnOdc/lmh56bQl5w8/nhbM+uRGAObAyyM+
// sHkvzx8nl/izhYoXv6fNm4u9DPqDsXgR5xW0jee7T/uviH/Bq1/8280ajwAQoPMvEvga0lGpO7D2
// rVhRC87TaKlsyJMURMUFWc0ttqb0gcHqDXYxoEAHzTzPi91uO+/p1A/YjhPswQKBgQDk1jmFU6kK
// cFizOqzUfBD9GtZmoKuFru6lHnTDnsxrOdhw3a2MbFDNl7yICtfHX2Dl+OLS5dwv/KYMr5AW0nQ7
// 3marL/XF/MJw7zbqaZllFwN9epEHWFPDxbJuJ4fqw7C2JOWfpiO4zs4lJfsitY2o78ZFdWQqEQv+
// 9Kh3K62r8QKBgQDBKeGNJKOtA4YVKG9fmHLp1Z4c53Lg/q7Kh+xxZoFQsBc5g94c3aNrDlf64w5T
// ahnLyLQRyo40C+jQOmXFTFQuZsgKHQEPXpEzIuaICw5ifO/oA67K+KOrJTXgno+TT7DCX32NgqOw
// n8eYwDAqCY9x+1ILIcZPaNYsLYJMAFHqyQKBgQDKu5OYMAcJjYC3wJrPYN74LWeW4m8y70jTlkjh
// 6fS2Zjf/vhT73BY2HmDTsa97HV/OPhJNv4C0LsO5Z2xjG64IQD97F/FIp2ZoATYItOdGLP5Vk1mb
// RUBop+QtPO5oKViL9eJM7zakiusM9DoqhOfsrAhfDuJfdlzHUl9RvKuPMQKBgGHPNC/FCsAT1bQm
// +mY8iouPKHXYWJpHO/i/5ODzF63kzI3KEREqxZHy/GfEyz8/rXo70bpkh6chFK1DtL63zLSGROqi
// DyRaLUnodTUT7B/AxbYFD2AlkLKbr/FiOK9OHeH+elPKf9pyvZ3CS2Et4B3GNZ//ZSxWmIRcMusL
// Cm/JAoGAAbpfZV9RzuGwbfDgWSjLwlUnm+p3zS3XDbNbmalVwsEYPElQR105zMUycTWn1ldqLnmE
// UjOy0B97jZ93zj2b4kJtxr0J/pwUiYeGY0dJzotL1Pu7jXCS70l1bbdasboT6Z6IR57QngsSJATz
// Tb7toKrDgBE3lzivese2hbiTruI=`;
//     let pkcs8exampleBytes: Uint8Array = Uint8Array.from(atob(pkcs8example) as any, (c => c.charCodeAt(0)) as any);
//     let pkcs8Parsed: BERElement = new BERElement();
//     pkcs8Parsed.fromBytes(pkcs8exampleBytes);
//
//     let el3 = new BERElement();
//     el3.tagNumber = ASN1UniversalType.octetString;
//     el3.octetString = iosKey;
//
//     let res = new BERElement();
//     res.tagNumber = ASN1UniversalType.sequence;
//     res.sequence = [pkcs8Parsed.sequence[0], pkcs8Parsed.sequence[1], el3];
//     return res.toBytes();
//
//     // console.log('testASN1 from bytes', el2.fromBytes(b), b);
//     // console.log('testASN1 sequence', el2.sequence);
//     //
//     // let elNew: BERElement = new BERElement();
//     // elNew.tagNumber = ASN1UniversalType.integer;
//     // elNew.integer = 5;
//     // console.log('elNew ', elNew.toBytes());
//     //
//     // let elNew2 = new BERElement();
//     // elNew2.tagNumber = ASN1UniversalType.sequence;
//     // elNew2.sequence = el2.sequence;
//     // console.log('elNew2 ', elNew2.toBytes());
//     // debugger;
//   }


  importPrivateKey(bytes: Uint8Array) {
//    if (bytes.length < 1210) {
      // console.log('importPrivateKey short', bytes, btoa(bytes as any));
      // bytes = this.convertPrivateKeyFrom_iOS(bytes);
      // console.log('importPrivateKey short converted', bytes, btoa(bytes as any));

//       let buf = new Uint8Array(`30 82 04 BD 02 01 00 30  0D 06 09 2A 86 48 86 F7
// 0D 01 01 01 05 00 04 82  04 A7`.split(/\s+/).map(v => parseInt(v, 16)));
//       let elements: Iterable<number> = [...buf, ...bytes];
//       bytes = new Uint8Array(elements);
//       /*
//       this is an eample of a key which can be imported in the browser
//       (first 26 bytes of ASN1 header with     OBJECT IDENTIFIER 1.2.840.113549.1.1.1 rsaEncryption (PKCS #1)
//       are missing in keys natively exported in iOS, so we have to add the header)
//
//             MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCsqvFLLbkjDVAJrc1FQWW51gjZ
//             c1XISO5Zzi2JSkjOWnHbW3Nbws2mxxVyoSSYMdMek6n4KU8pBKewIFpfvdQu8R0tKAumifgyCWu0
//             equ3KvVTV9eM4W7WArEbrugN9oj5KGIiNqAdUk898fSJjVERiDNUr6Oukl3o3ngeSNakK3buQ69q
//             bsbyQyCXoX5w6GfG4Ci22IJrGVjrcDi6MFDKUOKIgfyJElF1rHbUX2P/aP1BMqIGwvE5bOsJnBGp
//             uZPdQrjEASpozu8N48Mwv6GiFBn7av5tCw4I223PIeXuyT/fRmAG4uas+MjUl7JoO9DD3h0KCnG2
//             +ewGcv1xx0o5AgMBAAECggEAAfd+gR83tSUBLOMMbAYHLRs5hZSRPRNLl71H+oDcnHEeS+PaSAjL
//             g+NPpLseKhejMn13GasKykpRyKlNrjqA0LoRXYLPwyleKN+/mNIu44q7OaROAb5Nn2VvdAPFg+cz
//             fzcNCxu5LixSV7/KS8jOzBbTktrfT+094ZmrH5kvzLXnOdc/lmh56bQl5w8/nhbM+uRGAObAyyM+
//             sHkvzx8nl/izhYoXv6fNm4u9DPqDsXgR5xW0jee7T/uviH/Bq1/8280ajwAQoPMvEvga0lGpO7D2
//             rVhRC87TaKlsyJMURMUFWc0ttqb0gcHqDXYxoEAHzTzPi91uO+/p1A/YjhPswQKBgQDk1jmFU6kK
//             cFizOqzUfBD9GtZmoKuFru6lHnTDnsxrOdhw3a2MbFDNl7yICtfHX2Dl+OLS5dwv/KYMr5AW0nQ7
//             3marL/XF/MJw7zbqaZllFwN9epEHWFPDxbJuJ4fqw7C2JOWfpiO4zs4lJfsitY2o78ZFdWQqEQv+
//             9Kh3K62r8QKBgQDBKeGNJKOtA4YVKG9fmHLp1Z4c53Lg/q7Kh+xxZoFQsBc5g94c3aNrDlf64w5T
//             ahnLyLQRyo40C+jQOmXFTFQuZsgKHQEPXpEzIuaICw5ifO/oA67K+KOrJTXgno+TT7DCX32NgqOw
//             n8eYwDAqCY9x+1ILIcZPaNYsLYJMAFHqyQKBgQDKu5OYMAcJjYC3wJrPYN74LWeW4m8y70jTlkjh
//             6fS2Zjf/vhT73BY2HmDTsa97HV/OPhJNv4C0LsO5Z2xjG64IQD97F/FIp2ZoATYItOdGLP5Vk1mb
//             RUBop+QtPO5oKViL9eJM7zakiusM9DoqhOfsrAhfDuJfdlzHUl9RvKuPMQKBgGHPNC/FCsAT1bQm
//             +mY8iouPKHXYWJpHO/i/5ODzF63kzI3KEREqxZHy/GfEyz8/rXo70bpkh6chFK1DtL63zLSGROqi
//             DyRaLUnodTUT7B/AxbYFD2AlkLKbr/FiOK9OHeH+elPKf9pyvZ3CS2Et4B3GNZ//ZSxWmIRcMusL
//             Cm/JAoGAAbpfZV9RzuGwbfDgWSjLwlUnm+p3zS3XDbNbmalVwsEYPElQR105zMUycTWn1ldqLnmE
//             UjOy0B97jZ93zj2b4kJtxr0J/pwUiYeGY0dJzotL1Pu7jXCS70l1bbdasboT6Z6IR57QngsSJATz
//             Tb7toKrDgBE3lzivese2hbiTruI=
//
//       */
//    }
    return crypto.subtle.importKey(
      'pkcs8',
      bytes,
      // "RSA-OAEP",
      {   // these are the algorithm options
        name: 'RSA-OAEP',
        // hash: {name: "SHA-256"}, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
        hash: {name: 'SHA-1'}, // hope this will be compatible with Java's "RSA/ECB/OAEPWithSHA-1AndMGF1Padding";
      },
      true, // whether the key is extractable (i.e. can be used in exportKey)
      ['decrypt'] // "encrypt" or "wrapKey" for public key import or
      // "decrypt" or "unwrapKey" for private key imports)
    );
    // crypto.subtle.importKey('pkcs8',bytes,{name: 'RSA-OAEP',hash: {name: 'SHA-1'}},true,['decrypt']);
  }

  importPublicKey(bytes: Uint8Array) {
    return crypto.subtle.importKey(
      'spki',
      bytes,
      // "RSA-OAEP",
      {   // these are the algorithm options
        name: 'RSA-OAEP',
        // hash: {name: "SHA-256"}, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
        hash: {name: 'SHA-1'}, // hope this will be compatible with Java's "RSA/ECB/OAEPWithSHA-1AndMGF1Padding";
      },
      true, // whether the key is extractable (i.e. can be used in exportKey)
      ['encrypt'] // "encrypt" or "wrapKey" for public key import or
      // "decrypt" or "unwrapKey" for private key imports)
    );
  }

  importAesKey(bytes: ArrayBuffer) {
    return crypto.subtle.importKey(
      'raw', // can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      bytes, // can be a publicKey or privateKey, as long as extractable was true

      // "pkcs8", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      // key.privateKey //can be a publicKey or privateKey, as long as extractable was true

      {
        name: 'AES-CBC',
        length: 128, // can be  128, 192, or 256
      },
      true, // whether the key is extractable (i.e. can be used in exportKey)
      ['encrypt', 'decrypt'] // can "encrypt", "decrypt" or  "wrapKey", or "unwrapKey"
    );
  }

  uint8ArrayToBase64(array: Uint8Array): string {
    return btoa(String.fromCharCode(...array));
  }

  async encryptAsymmetric(pubKey: PbKey, bytes: ArrayBuffer) {
    console.log('encryptAsymmetric beg importPublicKey', pubKey.getId(), pubKey.getVersion(), pubKey.getKey_asB64());
    const publicKey = await this.importPublicKey(pubKey.getKey_asU8());
    console.log('encryptAsymmetric end importPublicKey', pubKey.getId(), pubKey.getVersion());
    return crypto.subtle.encrypt({name: 'RSA-OAEP'}, publicKey, bytes);
  }

  async encryptRsa(pubKey: PbKey, bytes: Map<number, ArrayBuffer>, accountKeyVersion: number) {
    const result = new PbRsaEncrypted();

    const aesKey: CryptoKey = await this.generateAesKey(); // random AES key
    // const aesKey: CryptoKey = await crypto.subtle.importKey('raw', new Uint8Array(16), {
    //   name: 'AES-CBC',
    //   length: 128
    // }, true, ['encrypt', 'decrypt']);

    const exportedAesKey: ArrayBuffer = await this.exportAesKey(aesKey);
    console.log('encryptRsa aesKeyPlainBytes', exportedAesKey, this.uint8ArrayToBase64(new Uint8Array(exportedAesKey)));
    const encryptedAesKey: ArrayBuffer = await this.encryptAsymmetric(pubKey, exportedAesKey);
    const aesKeyEncryptedBytes = new Uint8Array(encryptedAesKey);
    console.log('encryptRsa aesKeyEncryptedBytes', aesKeyEncryptedBytes, this.uint8ArrayToBase64(aesKeyEncryptedBytes));
    result.setRsaencryptedaeskey(aesKeyEncryptedBytes);
    for (const [version, buf] of bytes) {
      console.log('encrypting for another device account private key version: ' + version);
      const encryptedData: ArrayBuffer = await this.encryptSymmetric(aesKey, new Uint8Array(buf));
      result.getVersioneditemsMap().set(version, new Uint8Array(encryptedData));
    }
    result.setVersion(accountKeyVersion);
    return result;
  }

  // async decryptRsa(privateKey: CryptoKey, pbRsaEncrypted: PbRsaEncrypted) {
  //   const decryptedAesKey: ArrayBuffer = await crypto.subtle.decrypt(
  //     {
  //       name: 'RSA-OAEP',
  //       //label: Uint8Array([...]) //optional
  //     },
  //     privateKey,
  //     pbRsaEncrypted.getRsaencryptedaeskey_asU8());
  //   console.log('decryptRsa decryptedAesKey', decryptedAesKey);
  //   const aesKey: CryptoKey = await crypto.subtle.importKey(
  //     'raw', //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
  //     decryptedAesKey, //can be a publicKey or privateKey, as long as extractable was true
  //
  //     // "pkcs8", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
  //     // key.privateKey //can be a publicKey or privateKey, as long as extractable was true
  //
  //     {
  //       name: 'AES-CBC',
  //       length: 128, //can be  128, 192, or 256
  //     },
  //     true, //whether the key is extractable (i.e. can be used in exportKey)
  //     ['encrypt', 'decrypt'] //can "encrypt", "decrypt" or  "wrapKey", or "unwrapKey"
  //   );
  //   let currentVersionAccountKeyEncrypted: Uint8Array = pbRsaEncrypted.getVersioneditemsMap().get(pbRsaEncrypted.getVersion()) as Uint8Array;
  //   console.log('decryptRsa currentVersionAccountKeyEncrypted', currentVersionAccountKeyEncrypted);
  //   const result: ArrayBuffer = await this.decryptSymmetric(aesKey, currentVersionAccountKeyEncrypted);
  //   //const result: ArrayBuffer = await this.decryptSymmetric(aesKey, pbRsaEncrypted.getAesencrypteddata_asU8());
  //   return result;
  // }

  async decryptRsaMap(privateKey: CryptoKey, pbRsaEncrypted: PbRsaEncrypted) {
    const decryptedAesKey: ArrayBuffer = await crypto.subtle.decrypt(
      {
        name: 'RSA-OAEP',
        // label: Uint8Array([...]) //optional
      },
      privateKey,
      pbRsaEncrypted.getRsaencryptedaeskey_asU8());
    console.log('decryptRsa decryptedAesKey', decryptedAesKey);
    const aesKey: CryptoKey = await crypto.subtle.importKey('raw', decryptedAesKey, {
        name: 'AES-CBC',
        length: 128/*can be  128, 192, or 256*/
      },
      true, // whether the key is extractable (i.e. can be used in exportKey)
      ['encrypt', 'decrypt'] // can "encrypt", "decrypt" or  "wrapKey", or "unwrapKey"
    );
    const result = new Map<number, ArrayBuffer>();
    for (const [version, encryptedAccountPrivateKey] of pbRsaEncrypted.getVersioneditemsMap().getEntryList()) {
      const decrypted: ArrayBuffer = await this.decryptSymmetric(aesKey, encryptedAccountPrivateKey as Uint8Array);
      result.set(version, decrypted);
    }
    return result;
  }

}
