// package: 
// file: search.proto

var search_pb = require("./search_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var SearchService = (function () {
  function SearchService() {}
  SearchService.serviceName = "SearchService";
  return SearchService;
}());

SearchService.SearchSuggest = {
  methodName: "SearchSuggest",
  service: SearchService,
  requestStream: false,
  responseStream: false,
  requestType: search_pb.PbSearch,
  responseType: search_pb.PbSearchSuggestions
};

SearchService.Search = {
  methodName: "Search",
  service: SearchService,
  requestStream: false,
  responseStream: false,
  requestType: search_pb.PbSearch,
  responseType: search_pb.PbSearchResults
};

exports.SearchService = SearchService;

function SearchServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

SearchServiceClient.prototype.searchSuggest = function searchSuggest(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SearchService.SearchSuggest, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SearchServiceClient.prototype.search = function search(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SearchService.Search, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.SearchServiceClient = SearchServiceClient;

