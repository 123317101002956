import {Injectable} from '@angular/core';
import {PbLink, PbLinkRequest} from '../api/events_pb';
import {PbChatMessage} from '../api/groupchats_pb';
import {GrpcDataService} from './grpc-data.service';
import {EventServiceClient} from '../api/events_pb_service';

@Injectable({
  providedIn: 'root'
})
export class LinkParseService {

  eventService: EventServiceClient;

  private messageLinks = new Map<string, string>(); // messageId => url
  private parsedLinks = new Map<string, PbLink>(); // url => parse results

  constructor(
    private grpcDataService: GrpcDataService,
  ) {
    const grpcURL = this.grpcDataService.grpcURL;
    this.eventService = new EventServiceClient(grpcURL, undefined);
  }

  addParsedLink(link: PbLink) {
    this.parsedLinks.set(link.getUrl(), link);
  }

  hasLinks(msg: PbChatMessage) {
    if (!this.messageLinks.has(msg.getId())) {
      return false;
    }
    const url = this.messageLinks.get(msg.getId());
    return !!url && this.parsedLinks.has(url);
  }

  getParsedLink(msg: PbChatMessage): PbLink | undefined {
    const key = this.messageLinks.get(msg.getId());
    if (!key) {
      return undefined;
    }
    return this.parsedLinks.get(key);
  }


  async processLinks(message: PbChatMessage) {
    const urlRegex = /(https?:\/\/[^ ]*)/;
    const regExpMatchArray = message.getBody().match(urlRegex);
    if (regExpMatchArray) {
      const url = regExpMatchArray[1];
      console.log('url found in message', url);
      if (!this.parsedLinks.has(url)) {
        const linkRequest = new PbLinkRequest();
        linkRequest.addUrl(url);
        await this.grpcDataService.call(this.eventService, this.eventService.parseLinks, linkRequest);
      }
      this.messageLinks.set(message.getId(), url);
    }
  }


}
