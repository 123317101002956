import {EventEmitter, Injectable} from '@angular/core';
import {GroupChatDataService} from './groupchats/groupchat-data.service';
import {ChatDataService} from './chats/chat-data.service';
import {PbChatActivities, PbChatActivity, PbChatMessage, PbGroupChat} from './api/groupchats_pb';
import {GroupDataService} from './groups/group-data.service';
import {PbSearchResults} from './api/search_pb';
import {GroupUtils} from './util/group.utils';
import {GrpcDataService} from './login/grpc-data.service';
import {ChatDisplayService} from './chats/chat-display.service';
import {CommonUtils} from './util/common.utils';
import {MessageUtils} from './util/message.utils';
import {Subject} from 'rxjs';
import {windowTime, map, mergeAll, take} from 'rxjs/operators';

const TYPING_DELAY_AFTER_SEND_SEC = 3;
const TYPING_STALE_MS = 5_000;

class UserTyping {
  constructor(userId: string, chatId: string) {
    this.userId = userId;
    this.chatId = chatId;
    this.time = new Date();
  }

  isStale() {
    return Date.now() >= this.time.getTime() + TYPING_STALE_MS;
  }

  userId: string;
  chatId: string;
  time: Date;
}

@Injectable({
  providedIn: 'root'
})
export class ChatDbService {

  private findAllCalled = false;
  private db = new Map<string, PbGroupChat[]>(); // key=groupId or "" for private chats
  private archivedChats = new Map<string, PbGroupChat[]>(); // key=groupId
  private historySeen = new Map<string, PbChatMessage>(); // chatId to message
  private historyDelivered = new Map<string, PbChatMessage>(); // chatId to message

  private messageDeliveredTs = new Map<string, number>(); // chatId to timestamp of last message confirmed as delivered
  private messageSeenTs = new Map<string, number>(); // chatId to timestamp of last message confirmed as seen

  public chatUpdateEvents = new EventEmitter<PbGroupChat>();
  public messageReceivedEvents = new EventEmitter<PbChatMessage>();

  // private chatActivities = new Map<string, Array<string>>(); // chatId to users typing
  // private chatActivitiesResetTimer?: number;
  private usersTyping = new Map<string, UserTyping>(); // map by userId
  private localChatActivities = new Map<string, Subject<PbChatActivity>>();

  private lastSendTimeByChat = new Map<string, Date>();
  private notRegisteredChats = new Set<string>();

  constructor(private groupChatDataService: GroupChatDataService,
              private chatDataService: ChatDataService,
              private chatDisplayService: ChatDisplayService,
              private grpcDataService: GrpcDataService,
              private groupDataService: GroupDataService,
  ) {
  }

  setNotRegisteredChat(chatId: string) {
    this.notRegisteredChats.add(chatId);
  }

  saveSendTime(chatId: string) {
    this.lastSendTimeByChat.set(chatId, new Date());
  }

  isLastSendTimeWithinSec(chatId: string, seconds: number) {
    const time = this.lastSendTimeByChat.get(chatId);
    if (time) {
      return time.getTime() > Date.now() - seconds * 1000;
    }
    return false;
  }

  getTypingUsers(chatId: string) {
    const userIds = [];
    for (const ut of this.usersTyping.values()) {
      if (ut.chatId === chatId && !ut.isStale()) {
        userIds.push(ut.userId);
      }
    }

    // const userIds = this.chatActivities.get(chatId);
    // if (!userIds) {
    //   return [];
    // }
    return userIds;
  }

  updateActivities(activities: PbChatActivities) {
    for (const userId of activities.getUserstypingList()) {
      this.usersTyping.set(userId, new UserTyping(userId, activities.getChatid()));
    }
    setTimeout(() => {
      // just causes Angular view recalculation, thus hiding stale 'Typing...' labels
    }, TYPING_STALE_MS + 100);

    // this.chatActivities.set(activities.getChatid(), activities.getUserstypingList());
    // if (this.chatActivitiesResetTimer) {
    //   clearTimeout(this.chatActivitiesResetTimer);
    // }
    // this.chatActivitiesResetTimer = setTimeout(() => {
    //   this.chatActivities.delete(activities.getChatid());
    // }, 5_000);
  }

  /*
  https://rxjs.dev/api/operators/windowTime
  const result = clicks.pipe(
    windowTime(1000),
    map(win => win.pipe(take(2))), // each window has at most 2 emissions
    mergeAll(),                    // flatten the Observable-of-Observables
  );
   */


  localChatActivity(chatId: string) {
    if (this.isLastSendTimeWithinSec(chatId, TYPING_DELAY_AFTER_SEND_SEC)) {
      return; // ignore, too soon after sending
    }
    let subj = this.localChatActivities.get(chatId);
    if (!subj) {
      subj = new Subject<PbChatActivity>();
      this.localChatActivities.set(chatId, subj);

      const limitedFreq = subj.pipe(
        windowTime(3_000),
        map(win => win.pipe(take(1))), // each window has at most 1 emission(s)
        mergeAll(),                    // flatten the Observable-of-Observables
      );
      limitedFreq.subscribe((localActivity) => this.sendChatActivity(localActivity));
    }
    const a = new PbChatActivity();
    a.setChatid(chatId);
    subj.next(a);
  }

  private sendChatActivity(a: PbChatActivity) {
    if (this.isLastSendTimeWithinSec(a.getChatid(), TYPING_DELAY_AFTER_SEND_SEC)) {
      return; // ignore, too soon after sending
    }
    if (GroupUtils.isGroupChat(a.getChatid())) {
      this.groupChatDataService.chatActivity(a);
    } else {
      this.chatDataService.chatActivity(a);
    }
  }

  updateDeliveredTs(chatId: string, timestamp: number) {
    this.messageDeliveredTs.set(chatId, timestamp);
  }

  updateSeenTs(chatId: string, timestamp: number) {
    this.messageSeenTs.set(chatId, timestamp);
  }

  isDelivered(msg: PbChatMessage) {
    if (!msg) {
      return false;
    }
    const chatId = msg.getChatid();
    if (!this.messageDeliveredTs.has(chatId)) {
      return false;
    }
    const ts = this.messageDeliveredTs.get(chatId);
    return !!ts && msg.getCreationdate() <= ts;
  }

  isSeen(msg: PbChatMessage) {
    if (!msg) {
      return false;
    }
    const chatId = msg.getChatid();
    if (!this.messageSeenTs.has(chatId)) {
      return false;
    }
    const ts = this.messageSeenTs.get(chatId);
    return !!ts && msg.getCreationdate() <= ts;
  }

  async getChats(groupId: string) {
    if (!this.db.has(groupId)) {
      let chats: PbGroupChat[];
      if (groupId !== '') {
        chats = await this.groupChatDataService.getAll(groupId);
      } else {
        chats = await this.chatDataService.getAll();
        const totalUnreadMessages = this.sumUnreadMessages(chats);
        if (totalUnreadMessages) {
          GroupUtils.PEOPLE_GROUP.setUnreadMessagesCount(totalUnreadMessages);
        }
        const totalUnreadAlertMessages = this.sumUnreadAlertMessages(chats);
        if (totalUnreadAlertMessages) {
          GroupUtils.PEOPLE_GROUP.setUnreadAlertMessagesCount(totalUnreadAlertMessages);
        }
        console.log('chat-db.service chatDataService.getAll()', chats.map(chat => chat.getId()));
      }
      this.db.set(groupId, chats);
    }
    return this.db.get(groupId);
  }

  private sumUnreadMessages(chats: PbGroupChat[]) {
    return chats.map(chat => chat.getUnreadMessagesCount()).reduce((a, b) => a + b, 0);
  }

  private sumUnreadAlertMessages(chats: PbGroupChat[]) {
    return chats.map(chat => chat.getUnreadAlertMessagesCount()).reduce((a, b) => a + b, 0);
  }

  async getArchivedChats(groupId: string) {
    if (!this.archivedChats.has(groupId)) {
      const chats: PbGroupChat[] = await this.groupChatDataService.getAllArchived(groupId);
      this.archivedChats.set(groupId, chats);
    }
    return this.archivedChats.get(groupId);
  }

  findCachedChat(chatId: string): PbGroupChat | undefined {
    // console.log('findCachedChat', this.db.get('').map(chat => chat.getId()));
    let foundChat: PbGroupChat | undefined;
    let foundCount = 0;
    for (const chats of this.db.values()) {
      for (const chat of chats) {
        if (chat.getId() === chatId) {
          if (chatId.includes('@') && !chat.getGroupId()) {
            throw new Error('findCachedChat mixed group or private chat: ' + chat.toObject());
          }
          foundChat = chat;
          foundCount++;
        }
      }
    }
    if (foundCount === 0) {
      return undefined;
    }
    if (foundCount === 1) {
      return foundChat;
    }
    throw new Error(`findCachedChat chatId=[${chatId}] foundCount=${foundCount} ${foundChat?.toObject()}`);
  }

  async findOrCreatePrivateChat(userId: string) {
    const chat = await this.findChat(false, userId);
    if (chat) {
      return chat;
    }
    const privateChats = await this.getChats(GroupUtils.PEOPLE_GROUP_ID); // cached collection reference
    const newChat = new PbGroupChat();
    newChat.setId(userId);
    privateChats?.push(newChat);
    return newChat;
  }

  async findChat(groupChat: boolean, chatId: string): Promise<PbGroupChat> {
    const chat = this.findCachedChat(chatId);
    if (chat) {
      return chat;
    }
    if (groupChat) {
      return this.groupChatDataService.find(chatId);
    } else {
      return this.chatDataService.find(chatId);
    }
  }

  async getAll() {
    if (!this.findAllCalled) {
      const all: PbSearchResults = await this.groupDataService.globalSearchServer('');
      console.log('chat-db.service getAll()', all.toObject());
      if (all.getChatsList().some(chat => chat.getId().includes('@'))) {
        throw new Error('group chat among private chats? ' + all.getChatsList().map(chat => ' ' + chat.getId()));
      }
      this.db.set('', all.getChatsList());
      for (const groupChat of all.getGroupchatsList()) {
        const chats = this.db.get(groupChat.getGroupId());
        if (!chats) {
          this.db.set(groupChat.getGroupId(), [groupChat]);
        } else {
          this.update(chats, groupChat);
        }
      }
      this.findAllCalled = true;
    }
    const flat = this.getAllCached();
    console.log('chat-db getAll db=', this.db, 'flat=', flat.map(chat => chat.getId()));
    // if (this.db.get('').some(chat => chat.getId().includes('@'))) {
    //   debugger;
    // }

    return flat;
  }

  getAllCached() {
    const flat: PbGroupChat[] = []; // [...this.db.get('')];
    this.db.forEach((array, groupId) => {
      flat.push(...array);
    });
    return flat;
  }

  update(array: PbGroupChat[], chat: PbGroupChat) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].getId() === chat.getId()) {
        array[i] = chat;
        return;
      }
    }
    array.push(chat);
  }

  updateChatEvent(updChat: PbGroupChat) {
    console.log(`event.getGroupchatupdate() ${updChat.getId()} ${updChat.getDescription()}`);
    this.updateChatInList(updChat, this.db, false);
    this.updateChatInList(updChat, this.archivedChats, true);
    this.chatUpdateEvents.emit(updChat);
  }

  updateChatInList(updChat: PbGroupChat, groupToChatsMap: Map<string, PbGroupChat[]>, archivedMap: boolean) {
    let include = updChat.getArchived() === archivedMap;
    if (updChat.getMembersOnly()) {
      if (!updChat.getUsersList().includes(this.grpcDataService.userId)) {
        include = false; // we have been excluded from this chat
      }
    }

    const groupChats = groupToChatsMap.get(updChat.getGroupId());
    if (groupChats) {

      let foundIdx = -1;
      groupChats.forEach((chat, idx) => {
        if (chat.getId() === updChat.getId()) {
          foundIdx = idx;
        }
      });
      if (foundIdx >= 0) {
        if (!include) {
          groupChats.splice(foundIdx, 1);
          // @todo if removed chat was selected (current) in view - what should happen? currently no topic becomes selected in the list
        } else {
          groupChats[foundIdx] = updChat;
        }
      } else {
        if (include) {
          groupChats.push(updChat); // new chat
        }
      }
    }
  }

  async messageEventReceived(message: PbChatMessage) {
    this.usersTyping.delete(message.getFrom()); // this user has just finished typing for now
    // for (const userId of this.getTypingUsers(message.getChatid())) {
    //   this.usersTyping.delete(userId);
    // }
    //this.chatActivities.delete(message.getChatid());
    let chat = this.findCachedChat(message.getChatid());
    if (!chat && message.getType() === PbChatMessage.Type.CHAT) {
      console.log('chat from new user, retrieving from server', message.getChatid());
      chat = await this.findChat(false, message.getChatid());
      if (chat) {
        this.updateChatInList(chat, this.db, false);
      }
    }
    const newMessage = (message.getUpdatedate() === 0 /*system message*/ || message.getUpdatedate() === message.getCreationdate());
    if (chat && (newMessage || (chat.getLastMessage() && chat.getLastMessage()?.getId() === message.getId()))) {
      chat.setLastMessage(message); // set new message as last message or updated existing last message if it has been just updated
    }
    if (chat && newMessage) {
      // new message in chat
      const actionableMessage = message.getFrom() !== this.grpcDataService.userId;
      console.log('chat-db.service.messageEventReceived() ', chat ? chat.toObject() : 'chat not found: ' + message.getChatid(), actionableMessage, message.getChatid());
      if (actionableMessage) {
        // we always increment the counter immediately, sometimes auto-reset it after some seconds
        const counterNeeded = true; // this.chatDisplayService.isChatCounterNeeded(message.getChatid());
        const counterAutoReset = counterNeeded && CommonUtils.isActive() && this.chatDisplayService.isChatCounterAutoResetNeeded(message.getChatid());
        if (!CommonUtils.isActive() || counterNeeded) { // don't uncrement if chat is now open
          const counterIncrementedValue = chat.getUnreadMessagesCount() + 1;
          chat.setUnreadMessagesCount(counterIncrementedValue);
          if (MessageUtils.isAlertMessage(message, this.grpcDataService.userId)) {
            chat.setUnreadAlertMessagesCount(chat.getUnreadAlertMessagesCount() + 1);
          }
          if (counterAutoReset) {
            const constChat = chat;
            setTimeout(() => {
              this.scrolledChatEnd(constChat, message);
              // if (chat.getUnreadMessagesCount() === counterIncrementedValue) { // if there were no messages after this one
              //   chat.setUnreadmessagescount(0);
              // }
            }, GroupUtils.COUNTER_DELAY);
          }
        }
      }
    }
    this.messageReceivedEvents.emit(newMessage ? message : undefined);
  }

  scrolledChatEnd(selectedChat: PbGroupChat, lastDisplayedMessage?: PbChatMessage) {
    // user scrolled to the end of the chat, reset counters
    if (lastDisplayedMessage && (this.grpcDataService.userId !== lastDisplayedMessage.getFrom() || lastDisplayedMessage.hasChatupdate())) {
      // also mark even my own message if it is a system message: for example after group creation
      if (navigator.onLine) {
        if (!this.notRegisteredChats.has(selectedChat.getId())) {
          this.markHistoryAsSeen(selectedChat.getId(), lastDisplayedMessage);
        }
      } else {
        console.log('scrolledChatEnd: markHistoryAsSeen cancelled, no internet connection');
      }
    }
    // let chat = this.findCachedChat(selectedChat.getId());
    // if (chat) {
    //   chat.setUnreadmessagescount(0);
    // }
  }

  onChatCountersUpdate(chatId: string, unreadMessages: number, unreadAlertMessages: number, lastViewed: number) {
    const chat = this.findCachedChat(chatId);
    if (chat) {
      chat.setUnreadMessagesCount(unreadMessages);
      chat.setUnreadAlertMessagesCount(unreadAlertMessages);
      chat.setLastViewed(lastViewed);
    }
  }

  getGroupUnreadMessagesCount(groupId: string) {
    const chats = this.db.get(groupId);
    if (!chats) {
      return 0;
    }
    return this.sumUnreadMessages(chats);
  }

  getGroupUnreadAlertMessagesCount(groupId: string) {
    const chats = this.db.get(groupId);
    if (!chats) {
      return 0;
    }
    return this.sumUnreadAlertMessages(chats);
  }

  async markHistoryAsSeen(chatId: string, lastDisplayedMessage: PbChatMessage) {
    const messageId: string = lastDisplayedMessage.getId();
    if (this.historySeen.has(chatId) && this.historySeen.get(chatId)?.getId() === messageId) {
      return; // avoid sending duplicate call to server
    }
    this.historySeen.set(chatId, lastDisplayedMessage);
    if (GroupUtils.isGroupChat(chatId)) {
      this.groupChatDataService.markHistoryAsSeen(chatId, messageId);
    } else {
      this.chatDataService.markHistoryAsSeen(chatId, messageId);
    }
  }

  async markHistoryAsDelivered(chatId: string, lastReceivedMessage: PbChatMessage) {
    console.log('markHistoryAsDelivered');
    const messageId: string = lastReceivedMessage.getId();
    if (this.historyDelivered.has(chatId) && this.historyDelivered.get(chatId)?.getId() === messageId) {
      return; // avoid sending duplicate call to server
    }
    this.historyDelivered.set(chatId, lastReceivedMessage);
    if (GroupUtils.isGroupChat(chatId)) {
      this.groupChatDataService.markHistoryAsDelivered(chatId, messageId);
    } else {
      this.chatDataService.markHistoryAsDelivered(chatId, messageId);
    }
  }
}
