import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {fromPromise} from 'rxjs/internal-compatibility';

import {Empty, PbFindAllResults, PbGroup, PbGroupCreate, PbGroupFind, PbGroupIdList, PbGroupList, PbGroupMemberList, PbGroupMembers} from '../api/groups_pb';
import {GroupServiceClient, ServiceError} from '../api/groups_pb_service';
import {GrpcDataService} from '../login/grpc-data.service';
import {SearchServiceClient} from '../api/search_pb_service';
import {PbSearch, PbSearchResults, PbSearchSuggestions} from '../api/search_pb';
import {SearchResults} from './search-results';
import {MessageDbService} from '../message-db.service';
import {GroupUtils} from '../util/group.utils';
import {PbGrpMember} from './pb-grp-member';

@Injectable({
  providedIn: 'root'
})
export class GroupDataService {
  groupService = new GroupServiceClient(this.grpcDataService.grpcURL, undefined);
  searchService = new SearchServiceClient(this.grpcDataService.grpcURL, undefined);

  constructor(private grpcDataService: GrpcDataService,
              private messageDb: MessageDbService) {
  }

  // searchSuggest$(search: string): Observable<string[]> {
  //   const pbSearch = new PbSearch();
  //   pbSearch.setQuery(search.trim());
  //   return fromPromise(this.grpcDataService.call(this.searchService, this.searchService.searchSuggest, pbSearch))
  //     .pipe(
  //       map((pbSearchSuggestions: PbSearchSuggestions) => {
  //         const nonEmpty = [...pbSearchSuggestions.getSuggestionList(), ...this.messageDb.searchSuggest(search)]
  //           .filter(s => s.length > 0);
  //         return [...new Set<string>(nonEmpty)].sort();
  //       }),
  //       tap(suggestions => console.log('search suggestions', suggestions))
  //     );
  // }

  async globalSearch(search: string) {
    const serverResults = await this.globalSearchServer(search);
    return new SearchResults(
      serverResults.getChatsList().filter(chat => chat.getId() !== GroupUtils.TEAMY_BOT),
      serverResults.getGroupchatsList(),
      this.messageDb.globalSearch(search));
  }

  async globalSearchServer(search: string) {
    const pbSearch = new PbSearch();
    pbSearch.setQuery(search.trim());
    const serverResults: PbSearchResults = await this.grpcDataService.call(this.searchService, this.searchService.search, pbSearch);
    return serverResults;
  }

  async addMembers(groupId: string, memberList: string[]) {
    const memb = new PbGroupMembers();
    memb.setGroupid(groupId);
    memb.setUserList(memberList);
    const res: PbGroupMemberList = await this.grpcDataService.call<PbGroupMembers, PbGroupMemberList>(this.groupService, this.groupService.addMembers, memb);
    return res;
  }

  create(description: string, memberList: string[]): Observable<PbGroup> {
    const gc = new PbGroupCreate();
    gc.setDescription(description);
    gc.setMemberList(memberList);
    return Observable.create((observer: Subscriber<PbGroup>) => {
      this.grpcDataService.call<PbGroupCreate, PbGroup>(this.groupService, this.groupService.createGroup, gc).then(group => observer.next(group));
    });
  }

  async getMembers(groupId: string) {
    const find = new PbGroupFind();
    find.setGroupid(groupId);
    const response = await this.grpcDataService.call<PbGroupFind, PbGroupMemberList>(this.groupService, this.groupService.getMembers, find);
    return response.getGroupmemberList().map(m => m as PbGrpMember); // server makes sure getUser() is always defined here
  }

  async saveOrder(groupIds: string[]) {
    const req = new PbGroupIdList();
    req.setGroupidList(groupIds);
    const response = await this.grpcDataService.call<PbGroupIdList, Empty>(this.groupService, this.groupService.saveOrder, req);
  }

  async getOrderGroupIds() {
    const response = await this.grpcDataService.call<Empty, PbGroupIdList>(this.groupService, this.groupService.getOrder, new Empty());
    return response.getGroupidList();
  }

  leaveTeam(groupId: string) {
    const remove = new PbGroupMembers();
    remove.setGroupid(groupId);
    remove.addUser(this.grpcDataService.userId);
    this.grpcDataService.call<PbGroupMembers, PbGroupMemberList>(this.groupService, this.groupService.removeMembers, remove);
  }

  update(group: PbGroup): Observable<PbGroup> {
    return Observable.create((observer: Subscriber<PbGroup>) => {
      this.grpcDataService.call<PbGroup, PbGroup>(this.groupService, this.groupService.updateGroup, group).then(resp => observer.next(resp));
    });
  }

  async updateGroup(group: PbGroup){
    return await this.grpcDataService.call<PbGroup, PbGroup>(this.groupService, this.groupService.updateGroup, group);
  }

  async getGroups() {
    console.log('group-data.service getGroups');
    const request = new PbGroupFind();
    const result: PbGroupList = await this.grpcDataService.call(this.groupService, this.groupService.find, request);
    return result.getGroupList();
  }

  async getAll() {
    return await this.grpcDataService.call(this.groupService, this.groupService.findAll, new Empty()) as PbFindAllResults;
  }

  async getGroup(groupId: string) {
    console.log('group-data.service getGroup ' + groupId);
    const request = new PbGroupFind();
    request.setGroupid(groupId);
    const result: PbGroupList = await this.grpcDataService.call(this.groupService, this.groupService.find, request);
    const group: PbGroup = result.getGroupList()[0];
    console.log('group-data.service getGroup result ' + groupId, group);
    return group;
  }
}
