import {PbGroupChat} from '../api/groupchats_pb';

export class ChatUtils{

  static lastMessageTime(chat: PbGroupChat): number {
    const lastmessage = chat.getLastMessage();
    if (!lastmessage) {
      return -1;
    }
    return lastmessage.getCreationdate();
  }

  static compare(pinnedFirst: boolean, chatA: PbGroupChat, chatB: PbGroupChat) {
    if (pinnedFirst) {
      if (chatA.getPin() && !chatB.getPin()) {
        return -1;
      } // put pinned first
      if (!chatA.getPin() && chatB.getPin()) {
        return 1;
      }
    }

    if (this.lastMessageTime(chatA) > this.lastMessageTime(chatB)) {
      return -1;
    } // put most recent first
    if (this.lastMessageTime(chatA) < this.lastMessageTime(chatB)) {
      return 1;
    }
    return 0;
  }

}
