import {PbChatFile, PbChatMessage} from '../api/groupchats_pb';

export class MessageUtils {

  static mentionsRegexp = /@([\w_.+]+)\s\(([^)]+)\)/g;

  // static describeSystemMessage(msg: PbChatMessage): string {
  //   const update = msg.getChatupdate();
  //   switch (update.getType()) { //@todo can this be automated with Typescript Enums?
  //     case UpdateType.UNDEFINED:
  //       return 'UNDEFINED';
  //     case UpdateType.RENAME:
  //       return 'renamed the topic to \'' + update.getDescription() + '\'';
  //     case UpdateType.AVATAR:
  //       return 'changed AVATAR';
  //     case UpdateType.ARCHIVED:
  //       return 'archived this topic';
  //     case UpdateType.UNARCHIVED:
  //       return 'unarchived this topic';
  //
  //     case UpdateType.USER_ADD_CHAT:
  //       return 'added new chat members: ' + update.getUsersList().join(', ');
  //     case UpdateType.USER_ADD_TEAM:
  //       return 'added new team members: ' + update.getUsersList().join(', ');
  //     case UpdateType.USER_REMOVE_CHAT:
  //       return 'removed chat members: ' + update.getUsersList();
  //     case UpdateType.USER_REMOVE_TEAM:
  //       return 'removed team members: ' + update.getUsersList();
  //
  //     case UpdateType.APPROVE_DEVICE:
  //       return msg.getBody();
  //     case UpdateType.CHAT_CREATE:
  //       if (msg.getFrom() === 'teamy.bot') return "We created general topic for your team";
  //       return 'created the topic \'' + update.getDescription() + '\' with ' + update.getUsersList().length + ' teammates';
  //   }
  //   return '??? (unknown system message) ' + update.getType(); //@todo can label be automatically found with Typescript Enums?
  // }

  static getGroupId(msg: PbChatMessage) {
    if (msg.getChatid().includes('@')) {
      return msg.getChatid().substr(msg.getChatid().indexOf('@') + 1);
    } else {
      return '';
    }
  }

  // /**
  //  * Used for 'Last message' in the list of chats
  //  * @param msg
  //  */
  // static getBody(msg: PbChatMessage): string {
  //   if (!msg) return "(no messages yet)";
  //   if (msg.getBody()) return msg.getBody();
  //   //if (msg.getFile()) return msg.getFile().getName();
  //   if (this.isFileVideo(msg.getFile())) return "Sent a video";
  //   if (msg.getFile() && msg.getFile().getPreview()) return "Sent a photo";
  //   if (msg.getFile()) return "Sent a file";
  //   if (msg.getEncrypted_asU8().byteLength > 0) return "(encrypted)";
  //   if (msg.getChatupdate()) {
  //     if (msg.getChatupdate().getType() === UpdateType.CHAT_CREATE && msg.getFrom() !== 'teamy.bot') {
  //       return "Created new topic";
  //     }
  //     return MessageUtils.describeSystemMessage(msg);
  //   }
  //   return "...";
  // }

  static isFileVideo(file?: PbChatFile) {
    return file && file.getMimetype() && file.getMimetype().split('/')[0] === 'video';
  }

  static setMentionUserIds(msg: PbChatMessage, body: string) {
    // sendNewMessage body=@4shared.mx4_gmail.com (Testi Oviy) mentions3
    console.log(`sendNewMessage body=${body}`);
    // [..."@4shared.mx4_gmail.com (Testi Oviy) mentions3".matchAll( /@([\w_.+]+)\s\(([^)]+)\)/g )][0]
    for (const match of body.matchAll(MessageUtils.mentionsRegexp)) {
      msg.addMentionuserids(match[1]);
      // const mentionedUser = new PbUser();
      // mentionedUser.setId(match[1]);
      // mentionedUser.setFullname(match[2]);
      // console.log('Sending mention:', mentionedUser.toObject());
      // msg.addMentions(mentionedUser);
    }
  }

  static isAlertMessage(msg: PbChatMessage, currentUserId?: string) {
    if (msg.getQuote()?.getFrom() === currentUserId) {
      return true; // reply to a quote by this user
    }

    for (const match of msg.getBody().matchAll(MessageUtils.mentionsRegexp)) {
      const uid = match[1];
      if (uid === currentUserId) {
        return true; // or a direct mention
      }
    }

    return false;
  }

}
