import {PbChatMessage, PbGroupChat} from '../api/groupchats_pb';
import {PbUser} from '../api/groups_pb';

export class SearchResults {
  constructor(public chats: PbGroupChat[],
              public groupChats: PbGroupChat[],
              public messages: PbChatMessage[]) {

  }

  otherTeammates: PbGroupChat[] = [];
  nonExistentChats: PbGroupChat[] = [];

  get empty() {
    return this.chats.length + this.groupChats.length + this.messages.length + this.otherTeammates.length + this.nonExistentChats.length < 1;
  }
}
