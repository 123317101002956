import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ModalService} from '../dialog/modal.service';
import {LoginDataService} from '../login/login-data.service';
import {PbUser} from '../api/groups_pb';
import {UserDbService} from '../user-db.service';
import {CloudStorageService} from '../cloud-storage.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit, OnChanges {
  public selectedTab = 'profile';
  @Input() colorTheme = '';
  @Output() colorThemeChange = new EventEmitter<string>();
  // @Output() userNameChanged = new EventEmitter<string>();
  isEditingName = false;
  userName = '';

  @ViewChild('inputToFocus', {static: false}) newNameInput?: ElementRef;

  private internalColorThemeName = '';
  rainbowChatBubbles = false;
  cardView = false;

  constructor(
    private modalService: ModalService,
    private cloudStorageService: CloudStorageService,
    private loginDataService: LoginDataService,
    private userDbService: UserDbService) {
  }

  ngOnInit() {
    this.rainbowChatBubbles = this.userDbService.rainbowChatBubbles;
    this.cardView = this.userDbService.isCardViewEnabled;
  }

  async setUserAvatar(files: FileList | null) {
    const file = files?.item(0);
    if (file) {
      console.log(`setting avatar file ${file.name} size=${file.size} type=${file.type}`);

      if (file.type.startsWith('image/')) {
        const {id: newId, name: newName} = await this.cloudStorageService.uploadFile('avatar', '', file);
        const prefix = 'sourceId:';
        this.loginDataService.updateUserAvatar(prefix + newId);
      }
    }
  }

  rainbowChange() {
    this.userDbService.rainbowChatBubbles = this.rainbowChatBubbles;
  }

  cardViewChange() {
    console.log('cardView=', this.cardView);
    localStorage.setItem('cardView', '' + this.cardView);
    this.userDbService.isCardViewEnabled = this.cardView;
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  currentUser(): PbUser {
    return this.loginDataService.user;
  }

  saveNewUSerName() {
    if (!this.userName.length) {
      return;
    }

    this.loginDataService.updateUserFullName(this.userName);
    // this.currentUser().setGivenname(this.userName);
    // this.loginDataService.updateUser();
    this.isEditingName = false;
    // this.userNameChanged.emit(this.userName);
  }

  async logout() {
    await this.loginDataService.signOut();
    setTimeout(() => location.reload(), 500);
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  set colorThemeName(value: string) {
    console.log('set colorThemeName ' + value);
    this.internalColorThemeName = value;
    this.colorThemeChange.emit(value);
  }

  get colorThemeName() {
    return this.internalColorThemeName;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.internalColorThemeName = this.colorTheme;
  }

  focusInput() {
    setTimeout(() => this.newNameInput?.nativeElement.focus());
  }

  editNameStart() {
    this.userName = this.currentUser().getFullname();
    this.isEditingName = true;
    this.focusInput();
  }
}
