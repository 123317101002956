<!--<app-login *ngIf="!loggedIn || loggingIn"-->
<app-login *ngIf="showValidationBlock !== 'hide-signin'" [loggingIn]="loggingIn" [showVerificationBlock]="showValidationBlock" [linkEmail]="linkEmail"></app-login>

<div class="app-screen" *ngIf="loggedIn" [ngClass]="{
                     'isFirefox': isFireFox(),
                     'tm-card-view': userDbService.isCardViewEnabled}">

  <app-settings [(colorTheme)]="themeClassName"></app-settings>
  <app-chooseusers #chooseUsersDialog
                   [selectedGroup]="selectedGroup"
                   [selectedChat]="selectedChat"
                   (showNotifyBlock)="showNotifyBlock($event)"
                   (selectedGroupChanged)="setSelected($event)"
                   (notifyContactsCollected)="appNewtopic.buildSelectedContactsList($event)"
  ></app-chooseusers>

  <app-newtopic #appNewtopic
                [selectedGroup]="selectedGroup"
                (notifyShowInviteToChat)="chooseUsersDialog.onShowInviteToPrivateChat($event); openModal('newteam-modal');"
                (notifyNewTopicCreated)="appChatsList.onChatSelect(-1, $event)"
                (showNotifyBlock)="showNotifyBlock($event)"></app-newtopic>

  <div class="tm-header noselect">
    <div class="tm-header-logo" (click)="logStateInfo()"></div>
    <div class="tm-header-search">
      <div class="tm-clear-search" (click)="clearSearch()" *ngIf="searchField !== ''"></div>
      <input type="text" id="searchField" placeholder="Search" autocomplete="off" [(ngModel)]="searchField" (ngModelChange)="globalSearch()" (focus)="searchOnReturnFocus()" />
    </div>
    <div (click)="openModal('settings-modal')" class="tm-header-userinfo">
      <app-avatar [userId]="loginUser.getId()"></app-avatar>
      <app-fullname [userId]="loginUser.getId()"></app-fullname>
    </div>
  </div>

  <div class="tm-general-section" [class.tm-is-home]="isHomeSelected()">
    <div class="tm-notify-block" [ngClass]="notificationType" *ngIf="notificationType.length" [innerHTML]="notificationText"></div>

    <div class="tm-groups noselect styled-scrollbar" #groupsScrollContainer cdkDropList
         (scroll)="showShadows($event)"
         [class.have-scroll]="groupsHaveScroll"
         [class.drop-shadow]="showTeamsListScrollShadow"
         (cdkDropListDropped)="dropGroupElement($event)">
      <div class="tm-groups-holder">
        <div class="drag-n-drop-boundary">
          <div class="tm-go-group" *ngFor="let group of leftGroups; trackBy: trackGroup" cdkDrag
               cdkDragLockAxis="y"
               cdkDragBoundary=".drag-n-drop-boundary"
               vsDragScroll [vsDragScrollContainer]="groupsScrollContainer"
               [cdkDragDisabled]="!isRealGroup(group)"
               (click)="setSelected(group)"
               [class.selected]="isGroupSelected(group.getId())"
               [class.is-muted]="group.getMute()">
            <app-groupavatar [group]="group" class="tm-group-avatar"></app-groupavatar>
            <div class="tm-group-name tm-string-shortener" title="{{group.getDescription()}}" [innerHTML]="group.getDescription() | emojiToSpan"></div>
            <div class="tm-group-status" *ngIf="group.getUnreadMessagesCount()>0">{{group.getUnreadMessagesCount()}}</div>
            <div class="tm-group-status mention-notify" *ngIf="group.getUnreadAlertMessagesCount()>0">@</div>
            <div class="tm-pin-group tm-pin-button" *ngIf="group.getPin()"></div>
            <div class="drag-handler" cdkDragHandle></div>
          </div>
        </div>
        <div class="tm-new-group" (click)="chooseUsersDialog.onShowContactsPopup(); openModal('newteam-modal')">
          <div class="tm-group-name">New Team</div>
        </div>
      </div>
    </div>

    <app-chatslist #appChatsList
                   [isHome]="isHomeSelected()"
                   [isPeople]="isPeopleSelected()"
                   [realGroup]="isRealGroupSelected() ? selectedGroup : undefined"
                   [showContacts]="showContacts"
                   [search$]="search$"
                   [allChats]="chatNavigation"
                   [chatsUp]="chatsUp"
                   [selectedChat]="selectedChat"
                   (infoTypeChanged)="setInfoType(null, $event)"
                   (chatIndexChanged)="chatScrollIndexChanged($event)"
                   (selectedChatChanged)="selectedChatChanged($event)"
                   (scrollToMessageInChat)="scrollToMessageBridge($event)"
                   (showNotifyBlock)="showNotifyBlock($event)"
                   [hideListZoneLoading]="hideListZoneLoading"
                   [ngClass]="{
                     'isOnHome': isHomeSelected(),
                     'isOnPeople': isPeopleSelected(),
                     'isOnGroup': !isHomeSelected() && !isPeopleSelected(),
                     'isOnSearch': isSearchActive}"
    ></app-chatslist>

    <div class="chat-component-holder styled-scrollbar" id="chatsWrapper" [@chatCardAnimation]="chatCardAnimationValue()">
      <div class="tm-wide-chat-sizer">
      <app-chat *ngFor="let chatDisplay of chatDisplays; index as i" id="appChat_{{chatDisplay.selectedChat.getId()}}"
                [chatDisplay]="chatDisplay"
                [isHome]="isHomeSelected()"
                [infoType]="infoType"
                [messageTargetToScroll]="messageTarget"
                (navigate)="navigateFullView(chatDisplay.selectedChat)"
                (infoTypeChanged)="setInfoType(chatDisplay.selectedChat, $event)"
                (showForwardMessage)="chooseUsersDialog.onShowForwardMessage($event); openModal('newteam-modal')"
                (showCreateNewTeam)="chooseUsersDialog.onShowContactsPopup(); openModal('newteam-modal')"
                (showTeamMembers)="showTeamMembers($event)"
                (chatHistoryIsLoaded)="hideLoading($event)"
                (directChatInfoInGroupchat)="setInfoType($event, 'CHAT')"
                [ngClass]="{
                 'isOnHome': isHomeSelected(),
                 'isFirst': i===0,
                 'tm-chat-card-animate': isChatCardAnimated(chatDisplay.selectedChat)}"
      ></app-chat>
      </div>
    </div>

    <app-info *ngIf="infoType && infoType.length"
              [selectedGroup]="getAppInfoGroup()"
              [selectedChat]="selectedChat"
              [infoType]="infoType"
              (notifyShowInviteToTeam)="chooseUsersDialog.onShowInviteToTeam(); openModal('newteam-modal')"
              (notifyShowInviteToChat)="chooseUsersDialog.onShowInviteToChat(); openModal('newteam-modal')"
              (infoTypeChanged)="setInfoType(null, $event)"
              (openPrivateChat)="openPrivateChat($event)"
              (navigateToTeam)="navigateFullView(selectedChat)"
              (showNotifyBlock)="showNotifyBlock($event)"
    ></app-info>

    <app-teaminfo
      [selectedGroup]="getAppInfoGroup()"
      [selectedChat]="selectedChat"
      [infoType]="infoType"
      (notifyShowInviteToTeam)="chooseUsersDialog.onShowInviteToTeam(); openModal('newteam-modal')"
      (notifyShowInviteToChat)="chooseUsersDialog.onShowInviteToChat(); openModal('newteam-modal')"
      (infoTypeChanged)="setInfoType(null, $event)"
      (openPrivateChat)="openPrivateChat($event)"
    ></app-teaminfo>

    <div class="tm-chat-loading" [class.hide-chat-loading]="hideChatZoneLoading">
      <div class="tm-loading-wrapper">
        <div class="tm-loading-header">
          <div class="tm-loading-ava-1"></div>
        </div>
        <div class="tm-loading-body">
          <div class="tm-loading-ava-2"></div>
          <div class="tm-loading-bubble-1"></div>
          <div class="tm-loading-bubble-2"></div>
          <div class="tm-loading-bubble-3"></div>
          <div class="tm-loading-bubble-4"></div>
        </div>
        <div class="tm-loading-footer">
          <div class="tm-loading-sq-button-1"></div>
          <div class="tm-loading-sq-button-2"></div>
          <div class="tm-loading-msg-line"></div>
        </div>
      </div>
    </div>
  </div>
</div>

