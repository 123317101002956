// source: events.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var groups_pb = require('./groups_pb.js');
goog.object.extend(proto, groups_pb);
var groupchats_pb = require('./groupchats_pb.js');
goog.object.extend(proto, groupchats_pb);
var security_pb = require('./security_pb.js');
goog.object.extend(proto, security_pb);
goog.exportSymbol('proto.PbEvent', null, global);
goog.exportSymbol('proto.PbEvent.EventCase', null, global);
goog.exportSymbol('proto.PbEventHistory', null, global);
goog.exportSymbol('proto.PbEventHistoryFind', null, global);
goog.exportSymbol('proto.PbEventSubscribe', null, global);
goog.exportSymbol('proto.PbEventSubscribed', null, global);
goog.exportSymbol('proto.PbLink', null, global);
goog.exportSymbol('proto.PbLinkRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.PbEvent.oneofGroups_);
};
goog.inherits(proto.PbEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbEvent.displayName = 'proto.PbEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbEventSubscribe = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbEventSubscribe, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbEventSubscribe.displayName = 'proto.PbEventSubscribe';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbEventSubscribed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbEventSubscribed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbEventSubscribed.displayName = 'proto.PbEventSubscribed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbEventHistoryFind = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbEventHistoryFind, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbEventHistoryFind.displayName = 'proto.PbEventHistoryFind';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbEventHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PbEventHistory.repeatedFields_, null);
};
goog.inherits(proto.PbEventHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbEventHistory.displayName = 'proto.PbEventHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbLink.displayName = 'proto.PbLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PbLinkRequest.repeatedFields_, null);
};
goog.inherits(proto.PbLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbLinkRequest.displayName = 'proto.PbLinkRequest';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.PbEvent.oneofGroups_ = [[1,2,16,3,4,5,8,9,10,11,12,13,14,15,18,19,20,21,22,23,24,25,27]];

/**
 * @enum {number}
 */
proto.PbEvent.EventCase = {
  EVENT_NOT_SET: 0,
  CHATMESSAGE: 1,
  CHATUPDATE: 2,
  GROUPCHATUPDATE: 16,
  GROUPUPDATE: 3,
  GROUPINVITE: 4,
  GROUPLEAVE: 5,
  MESSAGESEEN: 8,
  CHATACTIVITIES: 9,
  USERUPDATE: 10,
  MESSAGEDELIVERED: 11,
  EVENTSUBSCRIBED: 12,
  CHATCOUNTERSUPDATE: 13,
  GROUPCOUNTERSUPDATE: 14,
  GROUPCHATCOUNTERSUPDATE: 15,
  USERSTATUSUPDATE: 18,
  CHATMERGED: 19,
  PEERSESSIONKEYSREQUEST: 20,
  SESSIONKEYSUPDATED: 21,
  ACCOUNTKEYUPDATE: 22,
  CONTACTSUPDATED: 23,
  CONTACTSDELETED: 24,
  LOGGEDOFF: 25,
  PARSEDLINK: 27
};

/**
 * @return {proto.PbEvent.EventCase}
 */
proto.PbEvent.prototype.getEventCase = function() {
  return /** @type {proto.PbEvent.EventCase} */(jspb.Message.computeOneofCase(this, proto.PbEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.PbEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatmessage: (f = msg.getChatmessage()) && groupchats_pb.PbChatMessage.toObject(includeInstance, f),
    chatupdate: (f = msg.getChatupdate()) && groupchats_pb.PbGroupChat.toObject(includeInstance, f),
    groupchatupdate: (f = msg.getGroupchatupdate()) && groupchats_pb.PbGroupChat.toObject(includeInstance, f),
    groupupdate: (f = msg.getGroupupdate()) && groups_pb.PbGroup.toObject(includeInstance, f),
    groupinvite: (f = msg.getGroupinvite()) && groups_pb.PbGroup.toObject(includeInstance, f),
    groupleave: (f = msg.getGroupleave()) && groups_pb.PbGroup.toObject(includeInstance, f),
    messageseen: (f = msg.getMessageseen()) && groupchats_pb.PbChatTimestamp.toObject(includeInstance, f),
    chatactivities: (f = msg.getChatactivities()) && groupchats_pb.PbChatActivities.toObject(includeInstance, f),
    userupdate: (f = msg.getUserupdate()) && groups_pb.PbUser.toObject(includeInstance, f),
    messagedelivered: (f = msg.getMessagedelivered()) && groupchats_pb.PbChatTimestamp.toObject(includeInstance, f),
    eventsubscribed: (f = msg.getEventsubscribed()) && proto.PbEventSubscribed.toObject(includeInstance, f),
    chatcountersupdate: (f = msg.getChatcountersupdate()) && groupchats_pb.PbGroupChat.toObject(includeInstance, f),
    groupcountersupdate: (f = msg.getGroupcountersupdate()) && groups_pb.PbGroup.toObject(includeInstance, f),
    groupchatcountersupdate: (f = msg.getGroupchatcountersupdate()) && groupchats_pb.PbGroupChat.toObject(includeInstance, f),
    userstatusupdate: (f = msg.getUserstatusupdate()) && groups_pb.PbUser.toObject(includeInstance, f),
    chatmerged: (f = msg.getChatmerged()) && groupchats_pb.PbGroupChat.toObject(includeInstance, f),
    peersessionkeysrequest: (f = msg.getPeersessionkeysrequest()) && security_pb.PbChatSessions.toObject(includeInstance, f),
    sessionkeysupdated: (f = msg.getSessionkeysupdated()) && security_pb.PbSessionKeys.toObject(includeInstance, f),
    accountkeyupdate: (f = msg.getAccountkeyupdate()) && security_pb.PbRsaEncryptedForDevices.toObject(includeInstance, f),
    contactsupdated: (f = msg.getContactsupdated()) && groups_pb.PbContactList.toObject(includeInstance, f),
    contactsdeleted: (f = msg.getContactsdeleted()) && groups_pb.PbContactList.toObject(includeInstance, f),
    loggedoff: (f = msg.getLoggedoff()) && groups_pb.Empty.toObject(includeInstance, f),
    parsedlink: (f = msg.getParsedlink()) && proto.PbLink.toObject(includeInstance, f),
    from: jspb.Message.getFieldWithDefault(msg, 7, ""),
    fromdeviceid: jspb.Message.getFieldWithDefault(msg, 26, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbEvent}
 */
proto.PbEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbEvent;
  return proto.PbEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbEvent}
 */
proto.PbEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new groupchats_pb.PbChatMessage;
      reader.readMessage(value,groupchats_pb.PbChatMessage.deserializeBinaryFromReader);
      msg.setChatmessage(value);
      break;
    case 2:
      var value = new groupchats_pb.PbGroupChat;
      reader.readMessage(value,groupchats_pb.PbGroupChat.deserializeBinaryFromReader);
      msg.setChatupdate(value);
      break;
    case 16:
      var value = new groupchats_pb.PbGroupChat;
      reader.readMessage(value,groupchats_pb.PbGroupChat.deserializeBinaryFromReader);
      msg.setGroupchatupdate(value);
      break;
    case 3:
      var value = new groups_pb.PbGroup;
      reader.readMessage(value,groups_pb.PbGroup.deserializeBinaryFromReader);
      msg.setGroupupdate(value);
      break;
    case 4:
      var value = new groups_pb.PbGroup;
      reader.readMessage(value,groups_pb.PbGroup.deserializeBinaryFromReader);
      msg.setGroupinvite(value);
      break;
    case 5:
      var value = new groups_pb.PbGroup;
      reader.readMessage(value,groups_pb.PbGroup.deserializeBinaryFromReader);
      msg.setGroupleave(value);
      break;
    case 8:
      var value = new groupchats_pb.PbChatTimestamp;
      reader.readMessage(value,groupchats_pb.PbChatTimestamp.deserializeBinaryFromReader);
      msg.setMessageseen(value);
      break;
    case 9:
      var value = new groupchats_pb.PbChatActivities;
      reader.readMessage(value,groupchats_pb.PbChatActivities.deserializeBinaryFromReader);
      msg.setChatactivities(value);
      break;
    case 10:
      var value = new groups_pb.PbUser;
      reader.readMessage(value,groups_pb.PbUser.deserializeBinaryFromReader);
      msg.setUserupdate(value);
      break;
    case 11:
      var value = new groupchats_pb.PbChatTimestamp;
      reader.readMessage(value,groupchats_pb.PbChatTimestamp.deserializeBinaryFromReader);
      msg.setMessagedelivered(value);
      break;
    case 12:
      var value = new proto.PbEventSubscribed;
      reader.readMessage(value,proto.PbEventSubscribed.deserializeBinaryFromReader);
      msg.setEventsubscribed(value);
      break;
    case 13:
      var value = new groupchats_pb.PbGroupChat;
      reader.readMessage(value,groupchats_pb.PbGroupChat.deserializeBinaryFromReader);
      msg.setChatcountersupdate(value);
      break;
    case 14:
      var value = new groups_pb.PbGroup;
      reader.readMessage(value,groups_pb.PbGroup.deserializeBinaryFromReader);
      msg.setGroupcountersupdate(value);
      break;
    case 15:
      var value = new groupchats_pb.PbGroupChat;
      reader.readMessage(value,groupchats_pb.PbGroupChat.deserializeBinaryFromReader);
      msg.setGroupchatcountersupdate(value);
      break;
    case 18:
      var value = new groups_pb.PbUser;
      reader.readMessage(value,groups_pb.PbUser.deserializeBinaryFromReader);
      msg.setUserstatusupdate(value);
      break;
    case 19:
      var value = new groupchats_pb.PbGroupChat;
      reader.readMessage(value,groupchats_pb.PbGroupChat.deserializeBinaryFromReader);
      msg.setChatmerged(value);
      break;
    case 20:
      var value = new security_pb.PbChatSessions;
      reader.readMessage(value,security_pb.PbChatSessions.deserializeBinaryFromReader);
      msg.setPeersessionkeysrequest(value);
      break;
    case 21:
      var value = new security_pb.PbSessionKeys;
      reader.readMessage(value,security_pb.PbSessionKeys.deserializeBinaryFromReader);
      msg.setSessionkeysupdated(value);
      break;
    case 22:
      var value = new security_pb.PbRsaEncryptedForDevices;
      reader.readMessage(value,security_pb.PbRsaEncryptedForDevices.deserializeBinaryFromReader);
      msg.setAccountkeyupdate(value);
      break;
    case 23:
      var value = new groups_pb.PbContactList;
      reader.readMessage(value,groups_pb.PbContactList.deserializeBinaryFromReader);
      msg.setContactsupdated(value);
      break;
    case 24:
      var value = new groups_pb.PbContactList;
      reader.readMessage(value,groups_pb.PbContactList.deserializeBinaryFromReader);
      msg.setContactsdeleted(value);
      break;
    case 25:
      var value = new groups_pb.Empty;
      reader.readMessage(value,groups_pb.Empty.deserializeBinaryFromReader);
      msg.setLoggedoff(value);
      break;
    case 27:
      var value = new proto.PbLink;
      reader.readMessage(value,proto.PbLink.deserializeBinaryFromReader);
      msg.setParsedlink(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromdeviceid(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatmessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      groupchats_pb.PbChatMessage.serializeBinaryToWriter
    );
  }
  f = message.getChatupdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      groupchats_pb.PbGroupChat.serializeBinaryToWriter
    );
  }
  f = message.getGroupchatupdate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      groupchats_pb.PbGroupChat.serializeBinaryToWriter
    );
  }
  f = message.getGroupupdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      groups_pb.PbGroup.serializeBinaryToWriter
    );
  }
  f = message.getGroupinvite();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      groups_pb.PbGroup.serializeBinaryToWriter
    );
  }
  f = message.getGroupleave();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      groups_pb.PbGroup.serializeBinaryToWriter
    );
  }
  f = message.getMessageseen();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      groupchats_pb.PbChatTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getChatactivities();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      groupchats_pb.PbChatActivities.serializeBinaryToWriter
    );
  }
  f = message.getUserupdate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      groups_pb.PbUser.serializeBinaryToWriter
    );
  }
  f = message.getMessagedelivered();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      groupchats_pb.PbChatTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getEventsubscribed();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.PbEventSubscribed.serializeBinaryToWriter
    );
  }
  f = message.getChatcountersupdate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      groupchats_pb.PbGroupChat.serializeBinaryToWriter
    );
  }
  f = message.getGroupcountersupdate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      groups_pb.PbGroup.serializeBinaryToWriter
    );
  }
  f = message.getGroupchatcountersupdate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      groupchats_pb.PbGroupChat.serializeBinaryToWriter
    );
  }
  f = message.getUserstatusupdate();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      groups_pb.PbUser.serializeBinaryToWriter
    );
  }
  f = message.getChatmerged();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      groupchats_pb.PbGroupChat.serializeBinaryToWriter
    );
  }
  f = message.getPeersessionkeysrequest();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      security_pb.PbChatSessions.serializeBinaryToWriter
    );
  }
  f = message.getSessionkeysupdated();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      security_pb.PbSessionKeys.serializeBinaryToWriter
    );
  }
  f = message.getAccountkeyupdate();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      security_pb.PbRsaEncryptedForDevices.serializeBinaryToWriter
    );
  }
  f = message.getContactsupdated();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      groups_pb.PbContactList.serializeBinaryToWriter
    );
  }
  f = message.getContactsdeleted();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      groups_pb.PbContactList.serializeBinaryToWriter
    );
  }
  f = message.getLoggedoff();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      groups_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getParsedlink();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.PbLink.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFromdeviceid();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
};


/**
 * optional PbChatMessage chatMessage = 1;
 * @return {?proto.PbChatMessage}
 */
proto.PbEvent.prototype.getChatmessage = function() {
  return /** @type{?proto.PbChatMessage} */ (
    jspb.Message.getWrapperField(this, groupchats_pb.PbChatMessage, 1));
};


/**
 * @param {?proto.PbChatMessage|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setChatmessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearChatmessage = function() {
  return this.setChatmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasChatmessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PbGroupChat chatUpdate = 2;
 * @return {?proto.PbGroupChat}
 */
proto.PbEvent.prototype.getChatupdate = function() {
  return /** @type{?proto.PbGroupChat} */ (
    jspb.Message.getWrapperField(this, groupchats_pb.PbGroupChat, 2));
};


/**
 * @param {?proto.PbGroupChat|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setChatupdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearChatupdate = function() {
  return this.setChatupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasChatupdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PbGroupChat groupChatUpdate = 16;
 * @return {?proto.PbGroupChat}
 */
proto.PbEvent.prototype.getGroupchatupdate = function() {
  return /** @type{?proto.PbGroupChat} */ (
    jspb.Message.getWrapperField(this, groupchats_pb.PbGroupChat, 16));
};


/**
 * @param {?proto.PbGroupChat|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setGroupchatupdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearGroupchatupdate = function() {
  return this.setGroupchatupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasGroupchatupdate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional PbGroup groupUpdate = 3;
 * @return {?proto.PbGroup}
 */
proto.PbEvent.prototype.getGroupupdate = function() {
  return /** @type{?proto.PbGroup} */ (
    jspb.Message.getWrapperField(this, groups_pb.PbGroup, 3));
};


/**
 * @param {?proto.PbGroup|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setGroupupdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearGroupupdate = function() {
  return this.setGroupupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasGroupupdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PbGroup groupInvite = 4;
 * @return {?proto.PbGroup}
 */
proto.PbEvent.prototype.getGroupinvite = function() {
  return /** @type{?proto.PbGroup} */ (
    jspb.Message.getWrapperField(this, groups_pb.PbGroup, 4));
};


/**
 * @param {?proto.PbGroup|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setGroupinvite = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearGroupinvite = function() {
  return this.setGroupinvite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasGroupinvite = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PbGroup groupLeave = 5;
 * @return {?proto.PbGroup}
 */
proto.PbEvent.prototype.getGroupleave = function() {
  return /** @type{?proto.PbGroup} */ (
    jspb.Message.getWrapperField(this, groups_pb.PbGroup, 5));
};


/**
 * @param {?proto.PbGroup|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setGroupleave = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearGroupleave = function() {
  return this.setGroupleave(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasGroupleave = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PbChatTimestamp messageSeen = 8;
 * @return {?proto.PbChatTimestamp}
 */
proto.PbEvent.prototype.getMessageseen = function() {
  return /** @type{?proto.PbChatTimestamp} */ (
    jspb.Message.getWrapperField(this, groupchats_pb.PbChatTimestamp, 8));
};


/**
 * @param {?proto.PbChatTimestamp|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setMessageseen = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearMessageseen = function() {
  return this.setMessageseen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasMessageseen = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PbChatActivities chatActivities = 9;
 * @return {?proto.PbChatActivities}
 */
proto.PbEvent.prototype.getChatactivities = function() {
  return /** @type{?proto.PbChatActivities} */ (
    jspb.Message.getWrapperField(this, groupchats_pb.PbChatActivities, 9));
};


/**
 * @param {?proto.PbChatActivities|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setChatactivities = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearChatactivities = function() {
  return this.setChatactivities(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasChatactivities = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PbUser userUpdate = 10;
 * @return {?proto.PbUser}
 */
proto.PbEvent.prototype.getUserupdate = function() {
  return /** @type{?proto.PbUser} */ (
    jspb.Message.getWrapperField(this, groups_pb.PbUser, 10));
};


/**
 * @param {?proto.PbUser|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setUserupdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearUserupdate = function() {
  return this.setUserupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasUserupdate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PbChatTimestamp messageDelivered = 11;
 * @return {?proto.PbChatTimestamp}
 */
proto.PbEvent.prototype.getMessagedelivered = function() {
  return /** @type{?proto.PbChatTimestamp} */ (
    jspb.Message.getWrapperField(this, groupchats_pb.PbChatTimestamp, 11));
};


/**
 * @param {?proto.PbChatTimestamp|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setMessagedelivered = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearMessagedelivered = function() {
  return this.setMessagedelivered(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasMessagedelivered = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PbEventSubscribed eventSubscribed = 12;
 * @return {?proto.PbEventSubscribed}
 */
proto.PbEvent.prototype.getEventsubscribed = function() {
  return /** @type{?proto.PbEventSubscribed} */ (
    jspb.Message.getWrapperField(this, proto.PbEventSubscribed, 12));
};


/**
 * @param {?proto.PbEventSubscribed|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setEventsubscribed = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearEventsubscribed = function() {
  return this.setEventsubscribed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasEventsubscribed = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PbGroupChat chatCountersUpdate = 13;
 * @return {?proto.PbGroupChat}
 */
proto.PbEvent.prototype.getChatcountersupdate = function() {
  return /** @type{?proto.PbGroupChat} */ (
    jspb.Message.getWrapperField(this, groupchats_pb.PbGroupChat, 13));
};


/**
 * @param {?proto.PbGroupChat|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setChatcountersupdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearChatcountersupdate = function() {
  return this.setChatcountersupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasChatcountersupdate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PbGroup groupCountersUpdate = 14;
 * @return {?proto.PbGroup}
 */
proto.PbEvent.prototype.getGroupcountersupdate = function() {
  return /** @type{?proto.PbGroup} */ (
    jspb.Message.getWrapperField(this, groups_pb.PbGroup, 14));
};


/**
 * @param {?proto.PbGroup|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setGroupcountersupdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearGroupcountersupdate = function() {
  return this.setGroupcountersupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasGroupcountersupdate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional PbGroupChat groupChatCountersUpdate = 15;
 * @return {?proto.PbGroupChat}
 */
proto.PbEvent.prototype.getGroupchatcountersupdate = function() {
  return /** @type{?proto.PbGroupChat} */ (
    jspb.Message.getWrapperField(this, groupchats_pb.PbGroupChat, 15));
};


/**
 * @param {?proto.PbGroupChat|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setGroupchatcountersupdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearGroupchatcountersupdate = function() {
  return this.setGroupchatcountersupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasGroupchatcountersupdate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional PbUser userStatusUpdate = 18;
 * @return {?proto.PbUser}
 */
proto.PbEvent.prototype.getUserstatusupdate = function() {
  return /** @type{?proto.PbUser} */ (
    jspb.Message.getWrapperField(this, groups_pb.PbUser, 18));
};


/**
 * @param {?proto.PbUser|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setUserstatusupdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearUserstatusupdate = function() {
  return this.setUserstatusupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasUserstatusupdate = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional PbGroupChat chatMerged = 19;
 * @return {?proto.PbGroupChat}
 */
proto.PbEvent.prototype.getChatmerged = function() {
  return /** @type{?proto.PbGroupChat} */ (
    jspb.Message.getWrapperField(this, groupchats_pb.PbGroupChat, 19));
};


/**
 * @param {?proto.PbGroupChat|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setChatmerged = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearChatmerged = function() {
  return this.setChatmerged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasChatmerged = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional PbChatSessions peerSessionKeysRequest = 20;
 * @return {?proto.PbChatSessions}
 */
proto.PbEvent.prototype.getPeersessionkeysrequest = function() {
  return /** @type{?proto.PbChatSessions} */ (
    jspb.Message.getWrapperField(this, security_pb.PbChatSessions, 20));
};


/**
 * @param {?proto.PbChatSessions|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setPeersessionkeysrequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearPeersessionkeysrequest = function() {
  return this.setPeersessionkeysrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasPeersessionkeysrequest = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional PbSessionKeys sessionKeysUpdated = 21;
 * @return {?proto.PbSessionKeys}
 */
proto.PbEvent.prototype.getSessionkeysupdated = function() {
  return /** @type{?proto.PbSessionKeys} */ (
    jspb.Message.getWrapperField(this, security_pb.PbSessionKeys, 21));
};


/**
 * @param {?proto.PbSessionKeys|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setSessionkeysupdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearSessionkeysupdated = function() {
  return this.setSessionkeysupdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasSessionkeysupdated = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional PbRsaEncryptedForDevices accountKeyUpdate = 22;
 * @return {?proto.PbRsaEncryptedForDevices}
 */
proto.PbEvent.prototype.getAccountkeyupdate = function() {
  return /** @type{?proto.PbRsaEncryptedForDevices} */ (
    jspb.Message.getWrapperField(this, security_pb.PbRsaEncryptedForDevices, 22));
};


/**
 * @param {?proto.PbRsaEncryptedForDevices|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setAccountkeyupdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearAccountkeyupdate = function() {
  return this.setAccountkeyupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasAccountkeyupdate = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional PbContactList contactsUpdated = 23;
 * @return {?proto.PbContactList}
 */
proto.PbEvent.prototype.getContactsupdated = function() {
  return /** @type{?proto.PbContactList} */ (
    jspb.Message.getWrapperField(this, groups_pb.PbContactList, 23));
};


/**
 * @param {?proto.PbContactList|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setContactsupdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearContactsupdated = function() {
  return this.setContactsupdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasContactsupdated = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional PbContactList contactsDeleted = 24;
 * @return {?proto.PbContactList}
 */
proto.PbEvent.prototype.getContactsdeleted = function() {
  return /** @type{?proto.PbContactList} */ (
    jspb.Message.getWrapperField(this, groups_pb.PbContactList, 24));
};


/**
 * @param {?proto.PbContactList|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setContactsdeleted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearContactsdeleted = function() {
  return this.setContactsdeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasContactsdeleted = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional Empty loggedOff = 25;
 * @return {?proto.Empty}
 */
proto.PbEvent.prototype.getLoggedoff = function() {
  return /** @type{?proto.Empty} */ (
    jspb.Message.getWrapperField(this, groups_pb.Empty, 25));
};


/**
 * @param {?proto.Empty|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setLoggedoff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearLoggedoff = function() {
  return this.setLoggedoff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasLoggedoff = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional PbLink parsedLink = 27;
 * @return {?proto.PbLink}
 */
proto.PbEvent.prototype.getParsedlink = function() {
  return /** @type{?proto.PbLink} */ (
    jspb.Message.getWrapperField(this, proto.PbLink, 27));
};


/**
 * @param {?proto.PbLink|undefined} value
 * @return {!proto.PbEvent} returns this
*/
proto.PbEvent.prototype.setParsedlink = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.PbEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.clearParsedlink = function() {
  return this.setParsedlink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbEvent.prototype.hasParsedlink = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional string from = 7;
 * @return {string}
 */
proto.PbEvent.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string fromDeviceId = 26;
 * @return {string}
 */
proto.PbEvent.prototype.getFromdeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.setFromdeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional int64 timestamp = 17;
 * @return {number}
 */
proto.PbEvent.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbEvent} returns this
 */
proto.PbEvent.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbEventSubscribe.prototype.toObject = function(opt_includeInstance) {
  return proto.PbEventSubscribe.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbEventSubscribe} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbEventSubscribe.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    devicename: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbEventSubscribe}
 */
proto.PbEventSubscribe.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbEventSubscribe;
  return proto.PbEventSubscribe.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbEventSubscribe} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbEventSubscribe}
 */
proto.PbEventSubscribe.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbEventSubscribe.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbEventSubscribe.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbEventSubscribe} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbEventSubscribe.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string clientName = 1;
 * @return {string}
 */
proto.PbEventSubscribe.prototype.getClientname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbEventSubscribe} returns this
 */
proto.PbEventSubscribe.prototype.setClientname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deviceName = 2;
 * @return {string}
 */
proto.PbEventSubscribe.prototype.getDevicename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbEventSubscribe} returns this
 */
proto.PbEventSubscribe.prototype.setDevicename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbEventSubscribed.prototype.toObject = function(opt_includeInstance) {
  return proto.PbEventSubscribed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbEventSubscribed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbEventSubscribed.toObject = function(includeInstance, msg) {
  var f, obj = {
    servertime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountkeyversion: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbEventSubscribed}
 */
proto.PbEventSubscribed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbEventSubscribed;
  return proto.PbEventSubscribed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbEventSubscribed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbEventSubscribed}
 */
proto.PbEventSubscribed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServertime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountkeyversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbEventSubscribed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbEventSubscribed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbEventSubscribed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbEventSubscribed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServertime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAccountkeyversion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 serverTime = 1;
 * @return {number}
 */
proto.PbEventSubscribed.prototype.getServertime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbEventSubscribed} returns this
 */
proto.PbEventSubscribed.prototype.setServertime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 accountKeyVersion = 2;
 * @return {number}
 */
proto.PbEventSubscribed.prototype.getAccountkeyversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbEventSubscribed} returns this
 */
proto.PbEventSubscribed.prototype.setAccountkeyversion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbEventHistoryFind.prototype.toObject = function(opt_includeInstance) {
  return proto.PbEventHistoryFind.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbEventHistoryFind} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbEventHistoryFind.toObject = function(includeInstance, msg) {
  var f, obj = {
    aftertime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbEventHistoryFind}
 */
proto.PbEventHistoryFind.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbEventHistoryFind;
  return proto.PbEventHistoryFind.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbEventHistoryFind} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbEventHistoryFind}
 */
proto.PbEventHistoryFind.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAftertime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbEventHistoryFind.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbEventHistoryFind.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbEventHistoryFind} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbEventHistoryFind.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAftertime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 afterTime = 1;
 * @return {number}
 */
proto.PbEventHistoryFind.prototype.getAftertime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbEventHistoryFind} returns this
 */
proto.PbEventHistoryFind.prototype.setAftertime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 limit = 2;
 * @return {number}
 */
proto.PbEventHistoryFind.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbEventHistoryFind} returns this
 */
proto.PbEventHistoryFind.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PbEventHistory.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbEventHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.PbEventHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbEventHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbEventHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventList: jspb.Message.toObjectList(msg.getEventList(),
    proto.PbEvent.toObject, includeInstance),
    nodata: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbEventHistory}
 */
proto.PbEventHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbEventHistory;
  return proto.PbEventHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbEventHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbEventHistory}
 */
proto.PbEventHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.PbEvent;
      reader.readMessage(value,proto.PbEvent.deserializeBinaryFromReader);
      msg.addEvent(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNodata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbEventHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbEventHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbEventHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbEventHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.PbEvent.serializeBinaryToWriter
    );
  }
  f = message.getNodata();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated PbEvent event = 1;
 * @return {!Array<!proto.PbEvent>}
 */
proto.PbEventHistory.prototype.getEventList = function() {
  return /** @type{!Array<!proto.PbEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PbEvent, 1));
};


/**
 * @param {!Array<!proto.PbEvent>} value
 * @return {!proto.PbEventHistory} returns this
*/
proto.PbEventHistory.prototype.setEventList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.PbEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PbEvent}
 */
proto.PbEventHistory.prototype.addEvent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.PbEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PbEventHistory} returns this
 */
proto.PbEventHistory.prototype.clearEventList = function() {
  return this.setEventList([]);
};


/**
 * optional bool noData = 2;
 * @return {boolean}
 */
proto.PbEventHistory.prototype.getNodata = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PbEventHistory} returns this
 */
proto.PbEventHistory.prototype.setNodata = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbLink.prototype.toObject = function(opt_includeInstance) {
  return proto.PbLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    canonicalurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isimage: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    icon: jspb.Message.getFieldWithDefault(msg, 7, ""),
    image: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbLink}
 */
proto.PbLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbLink;
  return proto.PbLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbLink}
 */
proto.PbLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanonicalurl(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsimage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCanonicalurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsimage();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.PbLink.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbLink} returns this
 */
proto.PbLink.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.PbLink.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbLink} returns this
 */
proto.PbLink.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.PbLink.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbLink} returns this
 */
proto.PbLink.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string canonicalUrl = 4;
 * @return {string}
 */
proto.PbLink.prototype.getCanonicalurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbLink} returns this
 */
proto.PbLink.prototype.setCanonicalurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isImage = 5;
 * @return {boolean}
 */
proto.PbLink.prototype.getIsimage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PbLink} returns this
 */
proto.PbLink.prototype.setIsimage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string icon = 7;
 * @return {string}
 */
proto.PbLink.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbLink} returns this
 */
proto.PbLink.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string image = 8;
 * @return {string}
 */
proto.PbLink.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbLink} returns this
 */
proto.PbLink.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PbLinkRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.PbLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    urlList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbLinkRequest}
 */
proto.PbLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbLinkRequest;
  return proto.PbLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbLinkRequest}
 */
proto.PbLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrlList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string url = 1;
 * @return {!Array<string>}
 */
proto.PbLinkRequest.prototype.getUrlList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.PbLinkRequest} returns this
 */
proto.PbLinkRequest.prototype.setUrlList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.PbLinkRequest} returns this
 */
proto.PbLinkRequest.prototype.addUrl = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PbLinkRequest} returns this
 */
proto.PbLinkRequest.prototype.clearUrlList = function() {
  return this.setUrlList([]);
};


goog.object.extend(exports, proto);
