<jw-modal id="newteam-modal">
    <div class="tm-modal-header">
      <h6 *ngIf="!inviteToGroup && !inviteToChat && !forwardMessage">Create new team</h6>
      <h6 *ngIf="inviteToGroup || inviteToChat">Invite people</h6>
<!--      <h6 *ngIf="inviteToGroup">Invite people to team: {{inviteToGroup.getDescription()}}</h6>-->
<!--      <h6 *ngIf="inviteToChat">Invite group members to chat: {{inviteToChat.getDescription()}}</h6>-->
      <h6 *ngIf="forwardMessage">Forward</h6>
    </div>
  <!--<textarea>{{users()}}</textarea>-->
    <div class="tm-modal-body">
      <div class="tm-modal-wrapper team-list-modal" *ngIf="contactsPage" style="width: 344px;" #userListWrapper>
        <div class="tm-search-users">
          <input type="search" class="tm-simple-input" [(ngModel)]="search" (input)="clearErrors()" placeholder="Search people" id="usersSearchField">
          <div class="clear-search" *ngIf="search" (click)="clearSearch()"></div>
        </div>

        <div class="newteam-contacts-scroller">

          <div class="tm-bubbles-holder styled-scrollbar" *ngIf="selectedContacts && selectedContacts.size !== 0" #bubblesList
               [class.has-scroll]="bubblesOverflowed"
               (scroll)="scrollBubbles()">
            <div class="tm-selected-bubble" *ngFor="let selUser of selectedPrivateChats()" (click)="toggleSelection(selUser)">
              <app-avatar class="size-s" [userId]="selUser"></app-avatar>
              <app-fullname [userId]="selUser"></app-fullname>
            </div>

            <div class="tm-selected-bubble" *ngFor="let chat of selectedGroupChats()" (click)="toggleSelection(chat.getId())">
              <app-avatar [groupChat]="chat" class="tm-avatar-inner size-s"></app-avatar>
              <span class="tm-user-fullname tm-string-shortener">{{chat.getDescription()}}</span>
              <app-fullname [groupId]="chat.getGroupId()" class="tm-recents-chatname"></app-fullname>
            </div>
          </div>

          <div class="drop-shadow" [ngClass]="{'reached-top': isNearTop}"></div>
          <div class="contact-list-holder styled-scrollbar" (scroll)="scrolled($event)" [ngClass]="{'reached-bottom': isNearBottom}" #peopleList>

            <!--recents-->
            <div class="tm-users-top clear-fix" *ngIf="!search">
              <div class="tm-chat-subtitle" *ngIf="!inviteToChat">Recents</div>
              <div class="tm-chat-subtitle" *ngIf="inviteToChat">Teammates</div>

              <div class="tm-user-item clear-fix" *ngFor="let userId of recentUsers()" (click)="toggleSelection(userId)" [class.selected]="selectedContacts.has(userId)">
                <app-avatar [userId]="userId"></app-avatar>
                <app-fullname [userId]="userId"></app-fullname>
              </div>

              <div *ngIf="forwardMessage">
                <div class="tm-user-item tm-topic-item" *ngFor="let chat of filteredAllGroupChats()" (click)="toggleSelection(chat.getId())" [class.selected]="selectedContacts.has(chat.getId())">
                  <app-avatar [groupChat]="chat" class="tm-ava-big"></app-avatar>
                  <div class="tm-recent-username tm-string-shortener" [innerHTML]="chat.getDescription() | emojiToSpan"></div>
                  <app-fullname [groupId]="chat.getGroupId()" class="tm-recents-chatname"></app-fullname>
                </div>
              </div>
            </div>

            <!--contact list-->
            <!--user not in contacts-->
            <div class="tm-user-item invite-new-to-teamy clear-fix" *ngIf="newUserFromSearch()"
                 (click)="validateNewContact()"
                 [ngClass]="addNewIconClass"
                 [class.selected]="selectedContacts.has(newUserFromSearchID())">
              <div class="tm-invite-avatar"></div>
              <div class="tm-recent-username tm-string-shortener">{{newUserFromSearch()}}</div>
            </div>
            <div class="new-contact-error" *ngIf="phoneNumberError">Phone number you've entered is not valid</div>
            <div class="new-contact-error" *ngIf="emailError">Email you've entered is not valid</div>

            <!--users from contacts-->
            <div class="tm-users-list" *ngFor="let alphaLetter of alphabet">
              <div class="tm-chat-subtitle">{{alphaLetter.letter}}</div>
              <div *ngFor="let userByLetter of alphaLetter.users; trackBy: trackUserId" class="tm-user-item"
                   [class.selected]="selectedContacts.has(userByLetter.getId())"
                   (click)="toggleSelection(userByLetter.getId())">
                <app-avatar [userId]="userByLetter.getId()"></app-avatar>
                <app-fullname [userId]="userByLetter.getId()"></app-fullname>
              </div>
            </div>

            <!--existing groups-->
            <div class="tm-users-list filtered-list" *ngIf="filteredAllGroupChats() && filteredAllGroupChats()?.length">
              <div class="tm-chat-subtitle">Topics</div>
              <div class="tm-user-item tm-topic-item filtered-topic-item" *ngFor="let chat of filteredAllGroupChats()"
                   (click)="toggleSelection(chat.getId())"
                   [class.selected]="selectedContacts.has(chat.getId())">
                <app-avatar [groupChat]="chat" class="float-left"></app-avatar>
                <div class="tm-recent-username tm-string-shortener" [innerHTML]="chat.getDescription() | emojiToSpan"></div>
                <app-fullname [groupId]="chat.getGroupId()" class="tm-recents-chatname"></app-fullname>
              </div>
            </div>

          </div>
        </div>

        <div class="clear-fix tm-modal-buttons-holder">
          <button class="tm-btn float-right" *ngIf="!inviteToGroup && !inviteToChat && !forwardMessage && !collectUsers" (click)="createGroup()" [disabled]="!selectedContacts.size">Create</button>
          <button class="tm-btn float-right" *ngIf="inviteToGroup || inviteToChat && !collectUsers" (click)="invite()" [disabled]="!selectedContacts.size">Invite</button>
          <button class="tm-btn float-right" *ngIf="collectUsers && !inviteToGroup && !forwardMessage" (click)="returnSelectedContacts()" [disabled]="!selectedContacts.size">Invite</button>
          <button class="tm-btn float-right" *ngIf="forwardMessage" (click)="forward()" [disabled]="!selectedContacts.size">Forward</button>
          <button class="tm-btn tm-btn-transp float-right" (click)="closeDialog()">Cancel</button>
        </div>
      </div>
    </div>
</jw-modal>
