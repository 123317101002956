<div class="tm-chats-list noselect" [ngClass]="{
  'tm-group-chats': !isPeople || searchResults,
  'people-page-list': isPeople,
  'home-page-list': isHome,
  'search-results-list': searchResults,
  'hide-list-loading' : hideListZoneLoading}">

  <div class="tm-contacts-header" *ngIf="!searchResults">
    <h6 class="tm-chat-title tm-string-shortener" [innerHTML]="sectionTitle | emojiToSpan: 20 : 64"></h6>
    <div class="tm-team-info-button" (click)="showTeamInfo()" *ngIf="realGroup"></div>
  </div>

  <div class="tm-contacts-header" *ngIf="searchResults">
    <h6 class="tm-chat-title tm-string-shortener" title="{{currentSearch}}">Search results for "{{currentSearch}}"</h6>
  </div>

  <div #chatsListScroll class="tm-chats-list-holder styled-scrollbar" *ngIf="!searchResults" [@chatsAnimation]="chatsAnimationValue()">
    <div class="clear-fix tm-chats-subtitles-holder" *ngIf="realGroup">
      <div class="tm-chat-subtitle float-left">Topics</div>
      <div class="tm-chat-subtitle float-right tm-new-topic" (click)="openModal('newtopic-modal')">+ New topic</div>
    </div>

    <div *ngFor="let chat of allChats; index as i" class="tm-chat-item t-0" (click)="onChatSelect(i, chat)" (contextmenu)="onContextMenu($event, chat)"
         (mouseenter)="onMouseEnter(chat.getId())" (mouseleave)="onMouseLeave(chat.getId())"
         [ngClass]="{
               'tm-chat-item-animate': isChatAnimated(chat),
               'is-muted': chat.getMute(),
               'has-notif': chat.getUnreadMessagesCount()>0,
               'has-mention': chat.getUnreadAlertMessagesCount()>0,
               'selected': isChatSelected(chat),
               'no-messages-yet': getLastMessageBody(getChatLastVisibleMsg(chat)) === ''
               }">
      <div class="single-chat-item" [class.is-single-on-home]="isHome" *ngIf="!chat.getGroupId()">
        <app-avatar [userId]="chat.getId()"></app-avatar>
        <app-fullname [userId]="chat.getId()" [withYou]="true"></app-fullname>
      </div>

      <div class="multi-chat-item" *ngIf="chat.getGroupId() && getChatLastVisibleMsg(chat)">
        <app-avatar [groupChat]="chat"></app-avatar>
        <app-avatar [userId]="getChatLastVisibleMsg(chat)?.getFrom()" class="size-xs" *ngIf="!isHome"></app-avatar>
        <div class="tm-user-fullname tm-string-shortener">
          <span class="ico-lock" *ngIf="chat.getMembersOnly()"></span>
          <span [innerHTML]="chat.getDescription() | emojiToSpan"></span></div>
        <div class="tm-chat-groupname tm-user-fullname home-in-group tm-string-shortener" *ngIf="isHome" (click)="onChatSelect(-1, chat)">
          in <app-fullname [groupId]="chat.getGroupId()"></app-fullname>
        </div>
        <app-fullname [userId]="getChatLastVisibleMsg(chat)?.getFrom()" class="tm-topic-last-message" *ngIf="!isHome"></app-fullname>
      </div>

      <div class="multi-chat-item" *ngIf="chat.getGroupId() && !getChatLastVisibleMsg(chat)">
        <app-avatar [groupChat]="chat"></app-avatar>
        <div class="tm-user-fullname tm-string-shortener">
          <span class="ico-lock" *ngIf="chat.getMembersOnly()"></span>
          <span [innerHTML]="chat.getDescription() | emojiToSpan"></span></div>
        <div class="tm-chat-groupname tm-user-fullname home-in-group tm-string-shortener" *ngIf="isHome" (click)="onChatSelect(-1, chat)">
          in <app-fullname [groupId]="chat.getGroupId()"></app-fullname>
        </div>
      </div>

      <div class="tm-message-count" *ngIf="chat.getUnreadMessagesCount()>0">{{chat.getUnreadMessagesCount()}}</div>
      <div class="tm-message-count mention-notify" *ngIf="chat.getUnreadAlertMessagesCount()>0">@</div>
      <div *ngIf="!isHome">
        <div class="tm-message-excerpt tm-string-shortener" [innerHTML]="getLastMessageBody(getChatLastVisibleMsg(chat)) | emojiToSpan" *ngIf="getLastMessageBody(getChatLastVisibleMsg(chat)) !== ''"></div>
        <div class="tm-message-excerpt no-messages">No messages here yet…</div>
        <div class="tm-message-time tm-string-shortener" *ngIf="getChatLastVisibleMsg(chat)">{{formatDateLastMessage(getChatLastVisibleMsg(chat)?.getCreationdate())}}</div>
        <div class="tm-item-pinned tm-pin-button" *ngIf="chat.getPin()"></div>
      </div>
    </div>

    <!--div *ngIf="isPeople"-->
    <div *ngIf="showContacts && getContacts()?.length">
      <div class="tm-chat-subtitle">Contacts</div>
      <div *ngFor="let contact of getContacts(); trackBy: trackContact" class="tm-chat-item t-4" [class.selected]="isContactSelected(contact)" (click)="startChatWithContact(contact)">
        <div class="single-chat-item is-single-on-home">
          <app-avatar [contact]="contact"></app-avatar>
          <span class="tm-user-fullname tm-string-shortener">{{contact.getFullname()}}</span>
        </div>
      </div>
    </div>
  </div>

<!--  search results-->
  <div class="tm-chats-list-holder search-results styled-scrollbar" *ngIf="searchResults">
    <div class="tm-chat-subtitle" *ngIf="searchResults.empty">No Results</div>

    <div *ngIf="searchResults.nonExistentChats.length" class="search-user">
      <div *ngFor="let chat of searchResults.nonExistentChats" class="tm-chat-item t-1" (click)="onChatSelect(-1, chat)" [class.is-muted]="chat.getMute()">
        <app-avatar [userId]="chat.getId()"></app-avatar>
        <app-fullname [userId]="chat.getId()" [highlightText]="currentSearch"></app-fullname>
      </div>
    </div>

    <div *ngIf="searchResults.chats?.length" class="search-user">
      <div class="tm-chat-subtitle">
        {{ searchResults.chats?.length | i18nPlural : {'=1': '1 Person', 'other': '# People'} }}
      </div>
      <div *ngFor="let chat of searchResults.chats" class="tm-chat-item t-1" (click)="onChatSelect(-1, chat)" [class.is-muted]="chat.getMute()">
        <app-avatar [userId]="chat.getId()"></app-avatar>
        <app-fullname [userId]="chat.getId()" [highlightText]="currentSearch"></app-fullname>

        <div class="tm-message-count" *ngIf="chat.getUnreadMessagesCount()>0">{{chat.getUnreadMessagesCount()}}</div>
        <div class="tm-item-pinned tm-pin-button" *ngIf="chat.getPin()"></div>
      </div>
    </div>

    <div *ngIf="searchResults.groupChats?.length" class="search-direct-chat">
      <div class="tm-chat-subtitle">
        {{ searchResults.groupChats?.length | i18nPlural : {'=1': '1 Topic', 'other': '# Topics'} }}
      </div>
      <div *ngFor="let chat of searchResults.groupChats" class="tm-chat-item t-2" (click)="onChatSelect(-1, chat)" [class.is-muted]="chat.getMute()">
        <app-avatar [groupChat]="chat"></app-avatar>
        <div class="tm-user-fullname tm-string-shortener"><span class="ico-lock" *ngIf="chat.getMembersOnly()"></span><span [innerHtml]="getChatDescriptionHighlighted(chat)"></span></div>
        <app-fullname [groupId]="chat.getGroupId()" class="tm-user-fullname"></app-fullname>
      </div>
    </div>

    <div *ngIf="searchResults.messages?.length">
      <div class="tm-chat-subtitle">
        {{ searchResults.messages?.length | i18nPlural : {'=1': '1 Message', 'other': '# Messages'} }}
      </div>
      <div *ngFor="let message of searchResults.messages" class="search-message">

        <div *ngFor="let chat of isPrivateChatMessage(message)" class="tm-chat-item t-3" (click)="goToMessageFromSearch(chat, message)" [class.is-muted]="chat.getMute()">
          <app-avatar [userId]="chat.getId()"></app-avatar>
          <app-fullname [userId]="chat.getId()"></app-fullname>

          <div class="tm-message-count" *ngIf="chat.getUnreadMessagesCount()>0">{{chat.getUnreadMessagesCount()}}</div>
          <div class="tm-message-excerpt tm-string-shortener" *ngIf="message" [innerHtml]="getBodyHighlighted(message) | emojiToSpan"></div>
          <div class="tm-message-time tm-string-shortener" *ngIf="message">{{formatDateLastMessage(message.getCreationdate())}}</div>
          <div class="tm-item-pinned tm-pin-button" *ngIf="chat.getPin()"></div>
        </div>

        <div *ngFor="let chat of isGroupChatMessage(message)" class="tm-chat-item t-0" (click)="goToMessageFromSearch(chat, message)" [class.is-muted]="chat.getMute()">
          <app-avatar [groupChat]="chat"></app-avatar>
          <app-avatar [userId]="message.getFrom()" class="size-xs"></app-avatar>
          <div class="tm-user-fullname tm-string-shortener chat-fullname search-groupchat-result">
            {{chat.getDescription()}}
            <span>in</span>
            <app-fullname [groupId]="chat.getGroupId()" class="tm-user-fullname team-and-topic-name" (click)="onChatSelect(-1, chat)"></app-fullname></div>
          <app-fullname [userId]="message.getFrom()" class="tm-topic-last-message"></app-fullname>

          <div class="tm-message-count" *ngIf="chat.getUnreadMessagesCount()>0">{{chat.getUnreadMessagesCount()}}</div>
          <div class="tm-message-excerpt tm-string-shortener" *ngIf="message" [innerHtml]="getBodyHighlighted(message) | emojiToSpan"></div>
          <div class="tm-message-time tm-string-shortener" *ngIf="message">{{formatDateLastMessage(message.getCreationdate())}}</div>
          <div class="tm-item-pinned tm-pin-button" *ngIf="chat.getPin()"></div>
        </div>
      </div>
    </div>

    <div *ngIf="searchResults.otherTeammates?.length" class="search-user">
      <div class="tm-chat-subtitle">
        Other teammates: {{ searchResults.otherTeammates?.length | i18nPlural : {'=1': '1 Person', 'other': '# People'} }}
      </div>
      <div *ngFor="let chat of searchResults.otherTeammates" class="tm-chat-item t-1" (click)="onChatSelect(-1, chat)" [class.is-muted]="chat.getMute()">
        <app-avatar [userId]="chat.getId()"></app-avatar>
        <app-fullname [userId]="chat.getId()" [highlightText]="currentSearch"></app-fullname>

        <div class="tm-message-count" *ngIf="chat.getUnreadMessagesCount()>0">{{chat.getUnreadMessagesCount()}}</div>
        <div class="tm-item-pinned tm-pin-button" *ngIf="chat.getPin()"></div>
      </div>
    </div>

  </div>

  <div style="visibility: hidden; position: fixed"
       [style.left]="contextMenuPosition.x"
       [style.top]="contextMenuPosition.y"
       [matMenuTriggerFor]="contextMenu">
  </div>

  <mat-menu #contextMenu="matMenu" class="tm-dropdown" hasBackdrop="true" backdropClass="backdrop-chatslist-context">
    <ng-template matMenuContent let-item="item">
      <button mat-menu-item class="dropdown-item ico-pin" (click)="togglePin(item)" *ngIf="!searchResults && !isHome">
        <span *ngIf="!item.getPin()">Pin</span>
        <span *ngIf="item.getPin()">Unpin</span>
      </button>
      <button mat-menu-item class="dropdown-item ico-mute" (click)="toggleMute(item)" *ngIf="!searchResults && !isHome">
        <span *ngIf="!item.getMute()">Mute</span>
        <span *ngIf="item.getMute()">Unmute</span>
      </button>
      <button mat-menu-item class="dropdown-item ico-archive" (click)="archiveTopic(item)" *ngIf="!searchResults && !isHome && item.getGroupId()">
        <span>Archive</span>
      </button>
      <button mat-menu-item class="dropdown-item ico-open" (click)="onChatSelect(-1, item)" *ngIf="searchResults || isHome"><span>Open</span></button>
    </ng-template>
  </mat-menu>

  <div class="tm-list-loading"></div>
</div>
