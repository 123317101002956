// package: 
// file: events.proto

var events_pb = require("./events_pb");
var groups_pb = require("./groups_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var EventService = (function () {
  function EventService() {}
  EventService.serviceName = "EventService";
  return EventService;
}());

EventService.ReceiveEventsStream = {
  methodName: "ReceiveEventsStream",
  service: EventService,
  requestStream: false,
  responseStream: true,
  requestType: events_pb.PbEventSubscribe,
  responseType: events_pb.PbEvent
};

EventService.EventsHistory = {
  methodName: "EventsHistory",
  service: EventService,
  requestStream: false,
  responseStream: false,
  requestType: events_pb.PbEventHistoryFind,
  responseType: events_pb.PbEventHistory
};

EventService.ParseLinks = {
  methodName: "ParseLinks",
  service: EventService,
  requestStream: false,
  responseStream: false,
  requestType: events_pb.PbLinkRequest,
  responseType: groups_pb.Empty
};

exports.EventService = EventService;

function EventServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

EventServiceClient.prototype.receiveEventsStream = function receiveEventsStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(EventService.ReceiveEventsStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

EventServiceClient.prototype.eventsHistory = function eventsHistory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EventService.EventsHistory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EventServiceClient.prototype.parseLinks = function parseLinks(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EventService.ParseLinks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.EventServiceClient = EventServiceClient;

