// source: groupchats.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var groups_pb = require('./groups_pb.js');
goog.object.extend(proto, groups_pb);
goog.exportSymbol('proto.PbApproveDevice', null, global);
goog.exportSymbol('proto.PbChatActivities', null, global);
goog.exportSymbol('proto.PbChatActivity', null, global);
goog.exportSymbol('proto.PbChatCallEvent', null, global);
goog.exportSymbol('proto.PbChatFile', null, global);
goog.exportSymbol('proto.PbChatLocation', null, global);
goog.exportSymbol('proto.PbChatMessage', null, global);
goog.exportSymbol('proto.PbChatMessage.Type', null, global);
goog.exportSymbol('proto.PbChatMessageList', null, global);
goog.exportSymbol('proto.PbChatTimestamp', null, global);
goog.exportSymbol('proto.PbChatUpdate', null, global);
goog.exportSymbol('proto.PbChatUpdate.UpdateType', null, global);
goog.exportSymbol('proto.PbGroupChat', null, global);
goog.exportSymbol('proto.PbGroupChatFind', null, global);
goog.exportSymbol('proto.PbGroupChatList', null, global);
goog.exportSymbol('proto.PbGroupChatMembers', null, global);
goog.exportSymbol('proto.PbHistoryFind', null, global);
goog.exportSymbol('proto.PbHistoryMessage', null, global);
goog.exportSymbol('proto.PbPromotion', null, global);
goog.exportSymbol('proto.PbSendMessageResult', null, global);
goog.exportSymbol('proto.PbSendMessageResult.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbGroupChatMembers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PbGroupChatMembers.repeatedFields_, null);
};
goog.inherits(proto.PbGroupChatMembers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbGroupChatMembers.displayName = 'proto.PbGroupChatMembers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbGroupChatList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PbGroupChatList.repeatedFields_, null);
};
goog.inherits(proto.PbGroupChatList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbGroupChatList.displayName = 'proto.PbGroupChatList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbChatMessageList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PbChatMessageList.repeatedFields_, null);
};
goog.inherits(proto.PbChatMessageList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbChatMessageList.displayName = 'proto.PbChatMessageList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbGroupChatFind = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbGroupChatFind, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbGroupChatFind.displayName = 'proto.PbGroupChatFind';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbGroupChat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PbGroupChat.repeatedFields_, null);
};
goog.inherits(proto.PbGroupChat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbGroupChat.displayName = 'proto.PbGroupChat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbChatMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PbChatMessage.repeatedFields_, null);
};
goog.inherits(proto.PbChatMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbChatMessage.displayName = 'proto.PbChatMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbPromotion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbPromotion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbPromotion.displayName = 'proto.PbPromotion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbChatLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbChatLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbChatLocation.displayName = 'proto.PbChatLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbChatCallEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbChatCallEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbChatCallEvent.displayName = 'proto.PbChatCallEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbChatFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbChatFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbChatFile.displayName = 'proto.PbChatFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbChatUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PbChatUpdate.repeatedFields_, null);
};
goog.inherits(proto.PbChatUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbChatUpdate.displayName = 'proto.PbChatUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbApproveDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbApproveDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbApproveDevice.displayName = 'proto.PbApproveDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbHistoryFind = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbHistoryFind, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbHistoryFind.displayName = 'proto.PbHistoryFind';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbHistoryMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbHistoryMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbHistoryMessage.displayName = 'proto.PbHistoryMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbChatTimestamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbChatTimestamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbChatTimestamp.displayName = 'proto.PbChatTimestamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbChatActivity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PbChatActivity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbChatActivity.displayName = 'proto.PbChatActivity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbChatActivities = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PbChatActivities.repeatedFields_, null);
};
goog.inherits(proto.PbChatActivities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbChatActivities.displayName = 'proto.PbChatActivities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PbSendMessageResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.PbSendMessageResult.oneofGroups_);
};
goog.inherits(proto.PbSendMessageResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PbSendMessageResult.displayName = 'proto.PbSendMessageResult';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PbGroupChatMembers.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbGroupChatMembers.prototype.toObject = function(opt_includeInstance) {
  return proto.PbGroupChatMembers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbGroupChatMembers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbGroupChatMembers.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbGroupChatMembers}
 */
proto.PbGroupChatMembers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbGroupChatMembers;
  return proto.PbGroupChatMembers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbGroupChatMembers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbGroupChatMembers}
 */
proto.PbGroupChatMembers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbGroupChatMembers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbGroupChatMembers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbGroupChatMembers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbGroupChatMembers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string chatId = 1;
 * @return {string}
 */
proto.PbGroupChatMembers.prototype.getChatid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbGroupChatMembers} returns this
 */
proto.PbGroupChatMembers.prototype.setChatid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string user = 2;
 * @return {!Array<string>}
 */
proto.PbGroupChatMembers.prototype.getUserList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.PbGroupChatMembers} returns this
 */
proto.PbGroupChatMembers.prototype.setUserList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.PbGroupChatMembers} returns this
 */
proto.PbGroupChatMembers.prototype.addUser = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PbGroupChatMembers} returns this
 */
proto.PbGroupChatMembers.prototype.clearUserList = function() {
  return this.setUserList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PbGroupChatList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbGroupChatList.prototype.toObject = function(opt_includeInstance) {
  return proto.PbGroupChatList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbGroupChatList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbGroupChatList.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatList: jspb.Message.toObjectList(msg.getChatList(),
    proto.PbGroupChat.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbGroupChatList}
 */
proto.PbGroupChatList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbGroupChatList;
  return proto.PbGroupChatList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbGroupChatList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbGroupChatList}
 */
proto.PbGroupChatList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.PbGroupChat;
      reader.readMessage(value,proto.PbGroupChat.deserializeBinaryFromReader);
      msg.addChat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbGroupChatList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbGroupChatList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbGroupChatList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbGroupChatList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.PbGroupChat.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PbGroupChat chat = 1;
 * @return {!Array<!proto.PbGroupChat>}
 */
proto.PbGroupChatList.prototype.getChatList = function() {
  return /** @type{!Array<!proto.PbGroupChat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PbGroupChat, 1));
};


/**
 * @param {!Array<!proto.PbGroupChat>} value
 * @return {!proto.PbGroupChatList} returns this
*/
proto.PbGroupChatList.prototype.setChatList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.PbGroupChat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PbGroupChat}
 */
proto.PbGroupChatList.prototype.addChat = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.PbGroupChat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PbGroupChatList} returns this
 */
proto.PbGroupChatList.prototype.clearChatList = function() {
  return this.setChatList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PbChatMessageList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbChatMessageList.prototype.toObject = function(opt_includeInstance) {
  return proto.PbChatMessageList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbChatMessageList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatMessageList.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageList: jspb.Message.toObjectList(msg.getMessageList(),
    proto.PbChatMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbChatMessageList}
 */
proto.PbChatMessageList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbChatMessageList;
  return proto.PbChatMessageList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbChatMessageList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbChatMessageList}
 */
proto.PbChatMessageList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.PbChatMessage;
      reader.readMessage(value,proto.PbChatMessage.deserializeBinaryFromReader);
      msg.addMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbChatMessageList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbChatMessageList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbChatMessageList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatMessageList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.PbChatMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PbChatMessage message = 1;
 * @return {!Array<!proto.PbChatMessage>}
 */
proto.PbChatMessageList.prototype.getMessageList = function() {
  return /** @type{!Array<!proto.PbChatMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PbChatMessage, 1));
};


/**
 * @param {!Array<!proto.PbChatMessage>} value
 * @return {!proto.PbChatMessageList} returns this
*/
proto.PbChatMessageList.prototype.setMessageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.PbChatMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PbChatMessage}
 */
proto.PbChatMessageList.prototype.addMessage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.PbChatMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PbChatMessageList} returns this
 */
proto.PbChatMessageList.prototype.clearMessageList = function() {
  return this.setMessageList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbGroupChatFind.prototype.toObject = function(opt_includeInstance) {
  return proto.PbGroupChatFind.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbGroupChatFind} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbGroupChatFind.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    chatid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbGroupChatFind}
 */
proto.PbGroupChatFind.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbGroupChatFind;
  return proto.PbGroupChatFind.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbGroupChatFind} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbGroupChatFind}
 */
proto.PbGroupChatFind.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbGroupChatFind.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbGroupChatFind.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbGroupChatFind} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbGroupChatFind.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChatid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string groupId = 1;
 * @return {string}
 */
proto.PbGroupChatFind.prototype.getGroupid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbGroupChatFind} returns this
 */
proto.PbGroupChatFind.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string chatId = 2;
 * @return {string}
 */
proto.PbGroupChatFind.prototype.getChatid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbGroupChatFind} returns this
 */
proto.PbGroupChatFind.prototype.setChatid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool archived = 3;
 * @return {boolean}
 */
proto.PbGroupChatFind.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PbGroupChatFind} returns this
 */
proto.PbGroupChatFind.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PbGroupChat.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbGroupChat.prototype.toObject = function(opt_includeInstance) {
  return proto.PbGroupChat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbGroupChat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbGroupChat.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creationDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    membersOnly: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    usersList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    pin: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    mute: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    unreadMessagesCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    unreadAlertMessagesCount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    lastMessage: (f = msg.getLastMessage()) && proto.PbChatMessage.toObject(includeInstance, f),
    lastViewed: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbGroupChat}
 */
proto.PbGroupChat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbGroupChat;
  return proto.PbGroupChat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbGroupChat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbGroupChat}
 */
proto.PbGroupChat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMembersOnly(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsers(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPin(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMute(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnreadMessagesCount(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnreadAlertMessagesCount(value);
      break;
    case 12:
      var value = new proto.PbChatMessage;
      reader.readMessage(value,proto.PbChatMessage.deserializeBinaryFromReader);
      msg.setLastMessage(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastViewed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbGroupChat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbGroupChat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbGroupChat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbGroupChat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreationDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMembersOnly();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPin();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getMute();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getUnreadMessagesCount();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getUnreadAlertMessagesCount();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getLastMessage();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.PbChatMessage.serializeBinaryToWriter
    );
  }
  f = message.getLastViewed();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.PbGroupChat.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.PbGroupChat.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 creation_date = 3;
 * @return {number}
 */
proto.PbGroupChat.prototype.getCreationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.setCreationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.PbGroupChat.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string avatar_url = 5;
 * @return {string}
 */
proto.PbGroupChat.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool members_only = 6;
 * @return {boolean}
 */
proto.PbGroupChat.prototype.getMembersOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.setMembersOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated string users = 7;
 * @return {!Array<string>}
 */
proto.PbGroupChat.prototype.getUsersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.setUsersList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.addUsers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional bool archived = 8;
 * @return {boolean}
 */
proto.PbGroupChat.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool pin = 9;
 * @return {boolean}
 */
proto.PbGroupChat.prototype.getPin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.setPin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool mute = 10;
 * @return {boolean}
 */
proto.PbGroupChat.prototype.getMute = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.setMute = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 unread_messages_count = 11;
 * @return {number}
 */
proto.PbGroupChat.prototype.getUnreadMessagesCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.setUnreadMessagesCount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 unread_alert_messages_count = 14;
 * @return {number}
 */
proto.PbGroupChat.prototype.getUnreadAlertMessagesCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.setUnreadAlertMessagesCount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional PbChatMessage last_message = 12;
 * @return {?proto.PbChatMessage}
 */
proto.PbGroupChat.prototype.getLastMessage = function() {
  return /** @type{?proto.PbChatMessage} */ (
    jspb.Message.getWrapperField(this, proto.PbChatMessage, 12));
};


/**
 * @param {?proto.PbChatMessage|undefined} value
 * @return {!proto.PbGroupChat} returns this
*/
proto.PbGroupChat.prototype.setLastMessage = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.clearLastMessage = function() {
  return this.setLastMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbGroupChat.prototype.hasLastMessage = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int64 last_viewed = 13;
 * @return {number}
 */
proto.PbGroupChat.prototype.getLastViewed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbGroupChat} returns this
 */
proto.PbGroupChat.prototype.setLastViewed = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PbChatMessage.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbChatMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.PbChatMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbChatMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    chatid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    from: jspb.Message.getFieldWithDefault(msg, 3, ""),
    author: jspb.Message.getFieldWithDefault(msg, 16, ""),
    body: jspb.Message.getFieldWithDefault(msg, 4, ""),
    creationdate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    updatedate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    file: (f = msg.getFile()) && proto.PbChatFile.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 8, 0),
    incoming: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    chatupdate: (f = msg.getChatupdate()) && proto.PbChatUpdate.toObject(includeInstance, f),
    delivered: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    seen: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    quote: (f = msg.getQuote()) && proto.PbChatMessage.toObject(includeInstance, f),
    encrypted: msg.getEncrypted_asB64(),
    sessionid: jspb.Message.getFieldWithDefault(msg, 15, ""),
    location: (f = msg.getLocation()) && proto.PbChatLocation.toObject(includeInstance, f),
    callevent: (f = msg.getCallevent()) && proto.PbChatCallEvent.toObject(includeInstance, f),
    promotion: (f = msg.getPromotion()) && proto.PbPromotion.toObject(includeInstance, f),
    mentionuseridsList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 18, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbChatMessage}
 */
proto.PbChatMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbChatMessage;
  return proto.PbChatMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbChatMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbChatMessage}
 */
proto.PbChatMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationdate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedate(value);
      break;
    case 7:
      var value = new proto.PbChatFile;
      reader.readMessage(value,proto.PbChatFile.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    case 8:
      var value = /** @type {!proto.PbChatMessage.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncoming(value);
      break;
    case 10:
      var value = new proto.PbChatUpdate;
      reader.readMessage(value,proto.PbChatUpdate.deserializeBinaryFromReader);
      msg.setChatupdate(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDelivered(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeen(value);
      break;
    case 13:
      var value = new proto.PbChatMessage;
      reader.readMessage(value,proto.PbChatMessage.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    case 14:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEncrypted(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionid(value);
      break;
    case 17:
      var value = new proto.PbChatLocation;
      reader.readMessage(value,proto.PbChatLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 21:
      var value = new proto.PbChatCallEvent;
      reader.readMessage(value,proto.PbChatCallEvent.deserializeBinaryFromReader);
      msg.setCallevent(value);
      break;
    case 19:
      var value = new proto.PbPromotion;
      reader.readMessage(value,proto.PbPromotion.deserializeBinaryFromReader);
      msg.setPromotion(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addMentionuserids(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbChatMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbChatMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbChatMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChatid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuthor();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreationdate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUpdatedate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.PbChatFile.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getIncoming();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getChatupdate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.PbChatUpdate.serializeBinaryToWriter
    );
  }
  f = message.getDelivered();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getSeen();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.PbChatMessage.serializeBinaryToWriter
    );
  }
  f = message.getEncrypted_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      14,
      f
    );
  }
  f = message.getSessionid();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.PbChatLocation.serializeBinaryToWriter
    );
  }
  f = message.getCallevent();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.PbChatCallEvent.serializeBinaryToWriter
    );
  }
  f = message.getPromotion();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.PbPromotion.serializeBinaryToWriter
    );
  }
  f = message.getMentionuseridsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.PbChatMessage.Type = {
  UNDEFINED: 0,
  CHAT: 1,
  GROUPCHAT: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.PbChatMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string chatId = 2;
 * @return {string}
 */
proto.PbChatMessage.prototype.getChatid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setChatid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string from = 3;
 * @return {string}
 */
proto.PbChatMessage.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string author = 16;
 * @return {string}
 */
proto.PbChatMessage.prototype.getAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string body = 4;
 * @return {string}
 */
proto.PbChatMessage.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 creationDate = 5;
 * @return {number}
 */
proto.PbChatMessage.prototype.getCreationdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setCreationdate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 updateDate = 6;
 * @return {number}
 */
proto.PbChatMessage.prototype.getUpdatedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setUpdatedate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional PbChatFile file = 7;
 * @return {?proto.PbChatFile}
 */
proto.PbChatMessage.prototype.getFile = function() {
  return /** @type{?proto.PbChatFile} */ (
    jspb.Message.getWrapperField(this, proto.PbChatFile, 7));
};


/**
 * @param {?proto.PbChatFile|undefined} value
 * @return {!proto.PbChatMessage} returns this
*/
proto.PbChatMessage.prototype.setFile = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbChatMessage.prototype.hasFile = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Type type = 8;
 * @return {!proto.PbChatMessage.Type}
 */
proto.PbChatMessage.prototype.getType = function() {
  return /** @type {!proto.PbChatMessage.Type} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.PbChatMessage.Type} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional bool incoming = 9;
 * @return {boolean}
 */
proto.PbChatMessage.prototype.getIncoming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setIncoming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional PbChatUpdate chatUpdate = 10;
 * @return {?proto.PbChatUpdate}
 */
proto.PbChatMessage.prototype.getChatupdate = function() {
  return /** @type{?proto.PbChatUpdate} */ (
    jspb.Message.getWrapperField(this, proto.PbChatUpdate, 10));
};


/**
 * @param {?proto.PbChatUpdate|undefined} value
 * @return {!proto.PbChatMessage} returns this
*/
proto.PbChatMessage.prototype.setChatupdate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.clearChatupdate = function() {
  return this.setChatupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbChatMessage.prototype.hasChatupdate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool delivered = 11;
 * @return {boolean}
 */
proto.PbChatMessage.prototype.getDelivered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setDelivered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool seen = 12;
 * @return {boolean}
 */
proto.PbChatMessage.prototype.getSeen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setSeen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional PbChatMessage quote = 13;
 * @return {?proto.PbChatMessage}
 */
proto.PbChatMessage.prototype.getQuote = function() {
  return /** @type{?proto.PbChatMessage} */ (
    jspb.Message.getWrapperField(this, proto.PbChatMessage, 13));
};


/**
 * @param {?proto.PbChatMessage|undefined} value
 * @return {!proto.PbChatMessage} returns this
*/
proto.PbChatMessage.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbChatMessage.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bytes encrypted = 14;
 * @return {!(string|Uint8Array)}
 */
proto.PbChatMessage.prototype.getEncrypted = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * optional bytes encrypted = 14;
 * This is a type-conversion wrapper around `getEncrypted()`
 * @return {string}
 */
proto.PbChatMessage.prototype.getEncrypted_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEncrypted()));
};


/**
 * optional bytes encrypted = 14;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEncrypted()`
 * @return {!Uint8Array}
 */
proto.PbChatMessage.prototype.getEncrypted_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEncrypted()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setEncrypted = function(value) {
  return jspb.Message.setProto3BytesField(this, 14, value);
};


/**
 * optional string sessionId = 15;
 * @return {string}
 */
proto.PbChatMessage.prototype.getSessionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional PbChatLocation location = 17;
 * @return {?proto.PbChatLocation}
 */
proto.PbChatMessage.prototype.getLocation = function() {
  return /** @type{?proto.PbChatLocation} */ (
    jspb.Message.getWrapperField(this, proto.PbChatLocation, 17));
};


/**
 * @param {?proto.PbChatLocation|undefined} value
 * @return {!proto.PbChatMessage} returns this
*/
proto.PbChatMessage.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbChatMessage.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional PbChatCallEvent callEvent = 21;
 * @return {?proto.PbChatCallEvent}
 */
proto.PbChatMessage.prototype.getCallevent = function() {
  return /** @type{?proto.PbChatCallEvent} */ (
    jspb.Message.getWrapperField(this, proto.PbChatCallEvent, 21));
};


/**
 * @param {?proto.PbChatCallEvent|undefined} value
 * @return {!proto.PbChatMessage} returns this
*/
proto.PbChatMessage.prototype.setCallevent = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.clearCallevent = function() {
  return this.setCallevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbChatMessage.prototype.hasCallevent = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional PbPromotion promotion = 19;
 * @return {?proto.PbPromotion}
 */
proto.PbChatMessage.prototype.getPromotion = function() {
  return /** @type{?proto.PbPromotion} */ (
    jspb.Message.getWrapperField(this, proto.PbPromotion, 19));
};


/**
 * @param {?proto.PbPromotion|undefined} value
 * @return {!proto.PbChatMessage} returns this
*/
proto.PbChatMessage.prototype.setPromotion = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.clearPromotion = function() {
  return this.setPromotion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbChatMessage.prototype.hasPromotion = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated string mentionUserIds = 20;
 * @return {!Array<string>}
 */
proto.PbChatMessage.prototype.getMentionuseridsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setMentionuseridsList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.addMentionuserids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.clearMentionuseridsList = function() {
  return this.setMentionuseridsList([]);
};


/**
 * optional bool deleted = 18;
 * @return {boolean}
 */
proto.PbChatMessage.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PbChatMessage} returns this
 */
proto.PbChatMessage.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbPromotion.prototype.toObject = function(opt_includeInstance) {
  return proto.PbPromotion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbPromotion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbPromotion.toObject = function(includeInstance, msg) {
  var f, obj = {
    image: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linktext: jspb.Message.getFieldWithDefault(msg, 2, ""),
    link: jspb.Message.getFieldWithDefault(msg, 3, ""),
    htmltext: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbPromotion}
 */
proto.PbPromotion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbPromotion;
  return proto.PbPromotion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbPromotion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbPromotion}
 */
proto.PbPromotion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinktext(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtmltext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbPromotion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbPromotion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbPromotion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbPromotion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinktext();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHtmltext();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string image = 1;
 * @return {string}
 */
proto.PbPromotion.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbPromotion} returns this
 */
proto.PbPromotion.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string linkText = 2;
 * @return {string}
 */
proto.PbPromotion.prototype.getLinktext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbPromotion} returns this
 */
proto.PbPromotion.prototype.setLinktext = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string link = 3;
 * @return {string}
 */
proto.PbPromotion.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbPromotion} returns this
 */
proto.PbPromotion.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string htmlText = 4;
 * @return {string}
 */
proto.PbPromotion.prototype.getHtmltext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbPromotion} returns this
 */
proto.PbPromotion.prototype.setHtmltext = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbChatLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.PbChatLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbChatLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    liveuntiltime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbChatLocation}
 */
proto.PbChatLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbChatLocation;
  return proto.PbChatLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbChatLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbChatLocation}
 */
proto.PbChatLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLiveuntiltime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbChatLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbChatLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbChatLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLiveuntiltime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.PbChatLocation.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PbChatLocation} returns this
 */
proto.PbChatLocation.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.PbChatLocation.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PbChatLocation} returns this
 */
proto.PbChatLocation.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 liveUntilTime = 3;
 * @return {number}
 */
proto.PbChatLocation.prototype.getLiveuntiltime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbChatLocation} returns this
 */
proto.PbChatLocation.prototype.setLiveuntiltime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.PbChatLocation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatLocation} returns this
 */
proto.PbChatLocation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.PbChatLocation.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatLocation} returns this
 */
proto.PbChatLocation.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbChatCallEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.PbChatCallEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbChatCallEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatCallEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    callended: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbChatCallEvent}
 */
proto.PbChatCallEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbChatCallEvent;
  return proto.PbChatCallEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbChatCallEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbChatCallEvent}
 */
proto.PbChatCallEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCallended(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbChatCallEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbChatCallEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbChatCallEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatCallEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCallended();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string eventId = 1;
 * @return {string}
 */
proto.PbChatCallEvent.prototype.getEventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatCallEvent} returns this
 */
proto.PbChatCallEvent.prototype.setEventid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.PbChatCallEvent.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatCallEvent} returns this
 */
proto.PbChatCallEvent.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool callEnded = 3;
 * @return {boolean}
 */
proto.PbChatCallEvent.prototype.getCallended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PbChatCallEvent} returns this
 */
proto.PbChatCallEvent.prototype.setCallended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbChatFile.prototype.toObject = function(opt_includeInstance) {
  return proto.PbChatFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbChatFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mimetype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    size: jspb.Message.getFieldWithDefault(msg, 3, 0),
    data: jspb.Message.getFieldWithDefault(msg, 4, ""),
    preview: jspb.Message.getFieldWithDefault(msg, 5, ""),
    width: jspb.Message.getFieldWithDefault(msg, 6, 0),
    height: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbChatFile}
 */
proto.PbChatFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbChatFile;
  return proto.PbChatFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbChatFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbChatFile}
 */
proto.PbChatFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMimetype(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreview(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbChatFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbChatFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbChatFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMimetype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPreview();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.PbChatFile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatFile} returns this
 */
proto.PbChatFile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mimeType = 2;
 * @return {string}
 */
proto.PbChatFile.prototype.getMimetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatFile} returns this
 */
proto.PbChatFile.prototype.setMimetype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 size = 3;
 * @return {number}
 */
proto.PbChatFile.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbChatFile} returns this
 */
proto.PbChatFile.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string data = 4;
 * @return {string}
 */
proto.PbChatFile.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatFile} returns this
 */
proto.PbChatFile.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string preview = 5;
 * @return {string}
 */
proto.PbChatFile.prototype.getPreview = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatFile} returns this
 */
proto.PbChatFile.prototype.setPreview = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 width = 6;
 * @return {number}
 */
proto.PbChatFile.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbChatFile} returns this
 */
proto.PbChatFile.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 height = 7;
 * @return {number}
 */
proto.PbChatFile.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbChatFile} returns this
 */
proto.PbChatFile.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PbChatUpdate.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbChatUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.PbChatUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbChatUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    avatarurl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    usersList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    approvedevice: (f = msg.getApprovedevice()) && proto.PbApproveDevice.toObject(includeInstance, f),
    sample: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbChatUpdate}
 */
proto.PbChatUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbChatUpdate;
  return proto.PbChatUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbChatUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbChatUpdate}
 */
proto.PbChatUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.PbChatUpdate.UpdateType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarurl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsers(value);
      break;
    case 5:
      var value = new proto.PbApproveDevice;
      reader.readMessage(value,proto.PbApproveDevice.deserializeBinaryFromReader);
      msg.setApprovedevice(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSample(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbChatUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbChatUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbChatUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAvatarurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getApprovedevice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.PbApproveDevice.serializeBinaryToWriter
    );
  }
  f = message.getSample();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.PbChatUpdate.UpdateType = {
  UNDEFINED: 0,
  RENAME: 1,
  AVATAR: 2,
  ARCHIVED: 3,
  UNARCHIVED: 4,
  USER_ADD_TEAM: 5,
  USER_REMOVE_TEAM: 6,
  CHAT_CREATE: 7,
  APPROVE_DEVICE: 8,
  USER_ADD_CHAT: 9,
  USER_REMOVE_CHAT: 10
};

/**
 * optional UpdateType type = 1;
 * @return {!proto.PbChatUpdate.UpdateType}
 */
proto.PbChatUpdate.prototype.getType = function() {
  return /** @type {!proto.PbChatUpdate.UpdateType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.PbChatUpdate.UpdateType} value
 * @return {!proto.PbChatUpdate} returns this
 */
proto.PbChatUpdate.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.PbChatUpdate.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatUpdate} returns this
 */
proto.PbChatUpdate.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string avatarUrl = 3;
 * @return {string}
 */
proto.PbChatUpdate.prototype.getAvatarurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatUpdate} returns this
 */
proto.PbChatUpdate.prototype.setAvatarurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string users = 4;
 * @return {!Array<string>}
 */
proto.PbChatUpdate.prototype.getUsersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.PbChatUpdate} returns this
 */
proto.PbChatUpdate.prototype.setUsersList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.PbChatUpdate} returns this
 */
proto.PbChatUpdate.prototype.addUsers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PbChatUpdate} returns this
 */
proto.PbChatUpdate.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional PbApproveDevice approveDevice = 5;
 * @return {?proto.PbApproveDevice}
 */
proto.PbChatUpdate.prototype.getApprovedevice = function() {
  return /** @type{?proto.PbApproveDevice} */ (
    jspb.Message.getWrapperField(this, proto.PbApproveDevice, 5));
};


/**
 * @param {?proto.PbApproveDevice|undefined} value
 * @return {!proto.PbChatUpdate} returns this
*/
proto.PbChatUpdate.prototype.setApprovedevice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbChatUpdate} returns this
 */
proto.PbChatUpdate.prototype.clearApprovedevice = function() {
  return this.setApprovedevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbChatUpdate.prototype.hasApprovedevice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool sample = 6;
 * @return {boolean}
 */
proto.PbChatUpdate.prototype.getSample = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PbChatUpdate} returns this
 */
proto.PbChatUpdate.prototype.setSample = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbApproveDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.PbApproveDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbApproveDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbApproveDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publickey: msg.getPublickey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbApproveDevice}
 */
proto.PbApproveDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbApproveDevice;
  return proto.PbApproveDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbApproveDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbApproveDevice}
 */
proto.PbApproveDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPublickey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbApproveDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbApproveDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbApproveDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbApproveDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublickey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.PbApproveDevice.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbApproveDevice} returns this
 */
proto.PbApproveDevice.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes publicKey = 2;
 * @return {!(string|Uint8Array)}
 */
proto.PbApproveDevice.prototype.getPublickey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes publicKey = 2;
 * This is a type-conversion wrapper around `getPublickey()`
 * @return {string}
 */
proto.PbApproveDevice.prototype.getPublickey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPublickey()));
};


/**
 * optional bytes publicKey = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPublickey()`
 * @return {!Uint8Array}
 */
proto.PbApproveDevice.prototype.getPublickey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPublickey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.PbApproveDevice} returns this
 */
proto.PbApproveDevice.prototype.setPublickey = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbHistoryFind.prototype.toObject = function(opt_includeInstance) {
  return proto.PbHistoryFind.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbHistoryFind} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbHistoryFind.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    beforecreationdate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    aftercreationdate: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbHistoryFind}
 */
proto.PbHistoryFind.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbHistoryFind;
  return proto.PbHistoryFind.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbHistoryFind} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbHistoryFind}
 */
proto.PbHistoryFind.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBeforecreationdate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAftercreationdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbHistoryFind.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbHistoryFind.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbHistoryFind} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbHistoryFind.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBeforecreationdate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAftercreationdate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string chatId = 1;
 * @return {string}
 */
proto.PbHistoryFind.prototype.getChatid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbHistoryFind} returns this
 */
proto.PbHistoryFind.prototype.setChatid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 beforeCreationDate = 2;
 * @return {number}
 */
proto.PbHistoryFind.prototype.getBeforecreationdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbHistoryFind} returns this
 */
proto.PbHistoryFind.prototype.setBeforecreationdate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.PbHistoryFind.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbHistoryFind} returns this
 */
proto.PbHistoryFind.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 afterCreationDate = 4;
 * @return {number}
 */
proto.PbHistoryFind.prototype.getAftercreationdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbHistoryFind} returns this
 */
proto.PbHistoryFind.prototype.setAftercreationdate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbHistoryMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.PbHistoryMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbHistoryMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbHistoryMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbHistoryMessage}
 */
proto.PbHistoryMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbHistoryMessage;
  return proto.PbHistoryMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbHistoryMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbHistoryMessage}
 */
proto.PbHistoryMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbHistoryMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbHistoryMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbHistoryMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbHistoryMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string chatId = 1;
 * @return {string}
 */
proto.PbHistoryMessage.prototype.getChatid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbHistoryMessage} returns this
 */
proto.PbHistoryMessage.prototype.setChatid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string messageId = 2;
 * @return {string}
 */
proto.PbHistoryMessage.prototype.getMessageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbHistoryMessage} returns this
 */
proto.PbHistoryMessage.prototype.setMessageid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbChatTimestamp.prototype.toObject = function(opt_includeInstance) {
  return proto.PbChatTimestamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbChatTimestamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatTimestamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lasttimestamp: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbChatTimestamp}
 */
proto.PbChatTimestamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbChatTimestamp;
  return proto.PbChatTimestamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbChatTimestamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbChatTimestamp}
 */
proto.PbChatTimestamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatid(value);
      break;
    case 2:
      var value = /** @type {!proto.PbChatMessage.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLasttimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbChatTimestamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbChatTimestamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbChatTimestamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatTimestamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLasttimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string chatId = 1;
 * @return {string}
 */
proto.PbChatTimestamp.prototype.getChatid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatTimestamp} returns this
 */
proto.PbChatTimestamp.prototype.setChatid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PbChatMessage.Type type = 2;
 * @return {!proto.PbChatMessage.Type}
 */
proto.PbChatTimestamp.prototype.getType = function() {
  return /** @type {!proto.PbChatMessage.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.PbChatMessage.Type} value
 * @return {!proto.PbChatTimestamp} returns this
 */
proto.PbChatTimestamp.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 lastTimestamp = 3;
 * @return {number}
 */
proto.PbChatTimestamp.prototype.getLasttimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.PbChatTimestamp} returns this
 */
proto.PbChatTimestamp.prototype.setLasttimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbChatActivity.prototype.toObject = function(opt_includeInstance) {
  return proto.PbChatActivity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbChatActivity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatActivity.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbChatActivity}
 */
proto.PbChatActivity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbChatActivity;
  return proto.PbChatActivity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbChatActivity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbChatActivity}
 */
proto.PbChatActivity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbChatActivity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbChatActivity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbChatActivity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatActivity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string chatId = 1;
 * @return {string}
 */
proto.PbChatActivity.prototype.getChatid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatActivity} returns this
 */
proto.PbChatActivity.prototype.setChatid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PbChatActivities.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbChatActivities.prototype.toObject = function(opt_includeInstance) {
  return proto.PbChatActivities.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbChatActivities} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatActivities.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userstypingList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbChatActivities}
 */
proto.PbChatActivities.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbChatActivities;
  return proto.PbChatActivities.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbChatActivities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbChatActivities}
 */
proto.PbChatActivities.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserstyping(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbChatActivities.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbChatActivities.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbChatActivities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbChatActivities.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserstypingList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string chatId = 1;
 * @return {string}
 */
proto.PbChatActivities.prototype.getChatid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PbChatActivities} returns this
 */
proto.PbChatActivities.prototype.setChatid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string usersTyping = 2;
 * @return {!Array<string>}
 */
proto.PbChatActivities.prototype.getUserstypingList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.PbChatActivities} returns this
 */
proto.PbChatActivities.prototype.setUserstypingList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.PbChatActivities} returns this
 */
proto.PbChatActivities.prototype.addUserstyping = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PbChatActivities} returns this
 */
proto.PbChatActivities.prototype.clearUserstypingList = function() {
  return this.setUserstypingList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.PbSendMessageResult.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.PbSendMessageResult.ResultCase = {
  RESULT_NOT_SET: 0,
  CHATMESSAGE: 1
};

/**
 * @return {proto.PbSendMessageResult.ResultCase}
 */
proto.PbSendMessageResult.prototype.getResultCase = function() {
  return /** @type {proto.PbSendMessageResult.ResultCase} */(jspb.Message.computeOneofCase(this, proto.PbSendMessageResult.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PbSendMessageResult.prototype.toObject = function(opt_includeInstance) {
  return proto.PbSendMessageResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PbSendMessageResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbSendMessageResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatmessage: (f = msg.getChatmessage()) && proto.PbChatMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PbSendMessageResult}
 */
proto.PbSendMessageResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PbSendMessageResult;
  return proto.PbSendMessageResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PbSendMessageResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PbSendMessageResult}
 */
proto.PbSendMessageResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.PbChatMessage;
      reader.readMessage(value,proto.PbChatMessage.deserializeBinaryFromReader);
      msg.setChatmessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PbSendMessageResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PbSendMessageResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PbSendMessageResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PbSendMessageResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatmessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.PbChatMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional PbChatMessage chatMessage = 1;
 * @return {?proto.PbChatMessage}
 */
proto.PbSendMessageResult.prototype.getChatmessage = function() {
  return /** @type{?proto.PbChatMessage} */ (
    jspb.Message.getWrapperField(this, proto.PbChatMessage, 1));
};


/**
 * @param {?proto.PbChatMessage|undefined} value
 * @return {!proto.PbSendMessageResult} returns this
*/
proto.PbSendMessageResult.prototype.setChatmessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.PbSendMessageResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PbSendMessageResult} returns this
 */
proto.PbSendMessageResult.prototype.clearChatmessage = function() {
  return this.setChatmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PbSendMessageResult.prototype.hasChatmessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto);
