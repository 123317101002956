import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {map} from 'rxjs/operators';
import {AvatarDataService} from '../avatar/avatar-data.service';
import {GroupDbService} from '../group-db.service';
import {UserDbService} from '../user-db.service';
import {GrpcDataService} from '../login/grpc-data.service';
import {DomSanitizer} from '@angular/platform-browser';
import {FullnameService} from './fullname.service';

@Component({
  selector: 'app-fullname',
  templateUrl: './fullname.component.html',
  styleUrls: ['./fullname.component.scss']
})
export class FullnameComponent implements OnInit, OnChanges {
  @Input() userId?: string;
  @Input() groupId?: string;
  @Input() rainbow = false;
  @Input() plaintext = false;
  @Input() withYou = false;
  @Input() highlightText?: string;
  @Input() nameOnly = false;

  private label = '';

  constructor(private userDbService: UserDbService,
              private grpcDataService: GrpcDataService,
              private avatarDataService: AvatarDataService,
              private groupDbService: GroupDbService,
              private sanitizer: DomSanitizer,
              private fullnameService: FullnameService,
  ) {
  }

  ngOnInit() {
  }

  get freshLabel(): string {
    if (this.userId) {
      this.label = this.fullnameService.computeLabelForUser(this.userId, this.getOptions()); // recompute because caches are used and data could be stale
    }
    return this.label;
  }

  private getOptions() {
    return {plaintext: this.plaintext, withYou: this.withYou, highlightText: this.highlightText, nameOnly: this.nameOnly};
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.userId) {
      this.label = this.fullnameService.computeLabelForUser(this.userId, this.getOptions());
    } else {
      if (!this.groupId) {
        this.label = 'no group';
      } else {
        this.groupDbService.getGroup$(this.groupId).pipe(
          map(group => group ? group.getDescription() : 'unknown group')
        ).subscribe(v => {
          this.label = v;
        });
      }
    }
  }


  get color() {
    // 25 of 255 - transparency for background

    if (this.userId) {
      const contact = this.userDbService.findContact(this.userId);
      if (contact) {
        return this.colorFromName(contact.getFullname());
      }
    }

    let fullName = '';
    if (this.userId) {
      fullName = this.userDbService.findUser(this.userId)?.getFullname() ?? '';
    }
    return this.colorFromName(fullName);
  }

  colorFromName(fullName: string) {
    const colors = this.avatarDataService.getColorPair(fullName);
    return colors[0];
  }

}
