<jw-modal id="teaminfo-modal">
  <div class="tm-modal-header">
    <div class="tm-back-to-options" *ngIf="(archivedListVisible || teammatesListVisible) && !teammatesListNoBack"
         (click)="showArchivedList(false); teammatesListVisible = false;"></div>

    <h6 *ngIf="!archivedListVisible && !teammatesListVisible">Team info</h6>
    <h6 *ngIf="archivedListVisible">Archived topics</h6>
    <h6 *ngIf="teammatesListVisible">Teammates</h6>

    <div class="tm-close-options" (click)="closeAndResetPopup()"></div>
  </div>
  <div class="tm-modal-body">
    <div class="tm-modal-wrapper team-info-modal">

      <div class="team-options-holder" *ngIf="!archivedListVisible && !teammatesListVisible">
        <div class="team-options-info">
          <app-groupavatar [group]="selectedGroup" class="tm-huge-ava" (click)="fileInput.click()"></app-groupavatar>
          <input type="file" style="display: none" accept="image/*" #fileInput (change)="setAvatar(fileInput.files)" />

          <div class="name-and-info-wrapper">
            <div class="team-name-holder" *ngIf="!isEditingName">
              <div class="tm-big-title tm-string-shortener" [innerHTML]="selectedGroup?.getDescription() | emojiToSpan: 20 : 64"></div>
              <div class="edit-name" (click)="editNameStart()"></div>
            </div>

            <div class="tm-chat-subtitle" *ngIf="!isEditingName">{{groupMembers.length}} teammates, {{selectedGroup?.getChatsCount()}} topics</div>

            <div class="tm-name-input-holder" *ngIf="isEditingName">
              <div class="tm-input-item-new text-center">
                <input autocomplete="new-password" spellcheck="false" placeholder="Enter team name" name="editedName" required="required" maxlength="100" #inputToFocus
                       [(ngModel)]="editedName"
                       (keyup.enter)="saveNewName()"
                       (keyup.escape)="isEditingName = false" />
                <button class="btn-ok" (click)="saveNewName()"></button>
              </div>
            </div>

            <div class="team-invite-holder">
              <div class="members-invite" (click)="inviteToTeam()" title="Invite to team"></div>
              <div class="members-mini-avas" (click)="teammatesListVisible = true">
                <app-avatar *ngFor="let member of fiveTeammates" [userId]="member.getUser().getId()" class="members-xs" [title]="member.getUser().getFullname()"></app-avatar>
              </div>
              <div class="members-show" (click)="showTeammatesList(false)" title="Temmates list"></div>
            </div>
          </div>
        </div>

        <div class="tm-chat-subtitle">Settings</div>
        <div class="tm-options-pin tm-options-item">
          Pinned team <input class="tm-checkbox" name="enablePin" type="checkbox" [(ngModel)]="groupPin" (click)="toggleGroupPin()"/><div class="tm-switcher"></div>
        </div>

        <div class="tm-options-notify tm-options-item">
          Notifications <input class="tm-checkbox" name="enableNotify" type="checkbox" [(ngModel)]="groupNotifications" (click)="toggleGroupMute()"/><div class="tm-switcher"></div>
        </div>

        <div class="tm-options-show-archived tm-options-item tm-has-sublevel" (click)="showArchivedList(true)">Archived topics</div>

        <div class="tm-options-leave-holder" (click)="showLeaveConfirm()">
          <div class="tm-options-leave tm-options-item">Leave this team</div>
        </div>

      </div>

      <div class="tm-teammates-list styled-scrollbar" *ngIf="teammatesListVisible">
        <div class="tm-chat-subtitle">{{groupMembers.length}} Teammates</div>

        <div class="tm-chat-member tm-new-member">
          <div class="member-name" style="cursor: pointer" (click)="inviteToTeam()">Invite teammates</div>
        </div>

        <div class="tm-chat-member" *ngFor="let member of groupMembers" [class.is-online]="isOnline(findUser(member.getUser().getId()))"
             (click)="openPrivateChatWith(member.getUser().getId()); closeAndResetPopup();" style="cursor: pointer">
          <app-avatar [userId]="member.getUser().getId()"></app-avatar>
          <app-fullname [userId]="member.getUser().getId()"></app-fullname>
          <div class="member-status" >{{member.getAdmin() ? 'Team admin, ' : ''}} {{getLastSeenStr(findUser(member.getUser().getId()))}}</div>
        </div>
      </div>

      <div class="tm-options-holder tm-options-archived styled-scrollbar" *ngIf="archivedListVisible">
        <div class="tm-no-info" *ngIf="archivedChats.length===0">You have no any archived topics</div>
        <div class="tm-options-unarchive tm-chat-member" *ngFor="let archivedChat of archivedChats" (click)="unarchive(archivedChat)" title="Unarchive topic">
          <app-avatar [groupChat]="archivedChat"></app-avatar>
          <span class="ico-lock" *ngIf="archivedChat.getMembersOnly()"></span>
          <span class="tm-user-fullname tm-string-shortener" [innerHTML]="archivedChat.getDescription() | emojiToSpan"></span>
        </div>
      </div>

    </div>
  </div>
</jw-modal>

<jw-modal id="confirm-leave">
  <div class="tm-modal-header">
    <h6>Leave the team</h6>
  </div>
  <div class="tm-modal-body confirm-popup">
    <div class="tm-modal-wrapper" style="width: 376px;">
      <p>Are you sure you want to leave this team?</p>

      <div class="clear-fix tm-modal-buttons-holder">
        <button class="tm-btn tm-btn-red float-right" (click)="leaveTeam(true)">Leave</button>
        <button class="tm-btn tm-btn-transp float-right" (click)="leaveTeam(false)">Cancel</button>
      </div>
    </div>
  </div>
</jw-modal>
