export class SizeFmtUtil {
  // omit units if same - will be specified with the total value
  static formatPartialBytesNoUnits(ratio: number, total: number | undefined, fractionDigits?: number) {
    if (total === undefined) {
      return '';
    }
    // return this.formatBytes(ratio / 100 * total);
    const part = ratio / 100 * total;
    const omitUnits = SizeFmtUtil.sizeOrder(total) === SizeFmtUtil.sizeOrder(part);
    return SizeFmtUtil.formatBytes(part, fractionDigits, omitUnits);
  }

  static formatBytes(a: number, fractionDigits?: number, omitUnits?: boolean) {
    if (0 === a) {
      return '0 Bytes';
    }
    const c = 1024;
    const d = fractionDigits || 2;
    const e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + (omitUnits ? '' : ' ' + e[f]);
  }

  static sizeOrder(a: number) {
    const c = 1024;
    const f = Math.floor(Math.log(a) / Math.log(c));
    return f;
  }

}
