import {Injectable} from '@angular/core';
import {NewMessage} from '../new-message';
import {CommonUtils} from '../util/common.utils';
import {MessageDbService} from '../message-db.service';
import {EncryptionService} from '../encryption.service';
import {CloudStorageService} from '../cloud-storage.service';
import {ChatDisplay} from './chat.display';

@Injectable({
  providedIn: 'root'
})
export class SendMessageService {


  constructor(private messageDbService: MessageDbService,
              private encryptionService: EncryptionService,
              private cloudStorageService: CloudStorageService,
  ) {
  }

  async processNewMessage(newMessage: NewMessage, chatDisplay: ChatDisplay) {
    if (newMessage.file) {
      const file = newMessage.file;
      if (file.type.startsWith('video/')) {
        const video = document.createElement('video');
        // <video style="border: 1px solid black"  id="myVideo" width="50" height="50" autoplay="autoplay" ></video>
        // const video = document.getElementById('myVideo') as HTMLVideoElement;
        // video.style.border = '5px solid black';
        // video.onloadeddata = (e: Event) => {
        //   console.log('Making preview of video1', video.videoWidth, video.videoHeight);
        //   // Making preview of video 320 240
        //   // console.log('Making preview of video', video.width, video.height);
        //   try {
        //     // const canvas: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
        //     // const width = 320;
        //     // const height = 240;
        //     // canvas.width = width;
        //     // canvas.height = height;
        //     // console.log('Making preview of video2', video.videoWidth, video.videoHeight);
        //     // const ctx = canvas.getContext('2d');
        //     // if (ctx) {
        //     //   video.play();
        //     //   ctx?.drawImage(video, 0, 0, width, height);
        //     //   // video.pause();
        //     //   ctx.fillStyle = 'rgb(200, 0, 0)';
        //     //   ctx.fillRect(10, 10, 50, 50);
        //     //   // ctx?.fillStyle();
        //     //   // ctx?.fillRect(10, 20, 30, 40);
        //     // }
        //     // console.log('Making preview of video3', video.videoWidth, video.videoHeight);
        //     // // const dataUrl = canvas.toDataURL(/*'image/jpeg'*/file.type);
        //     // const dataUrl = canvas.toDataURL('image/jpeg'); // must not exceed 4 kbyte push limit in base64
        //     // console.log('Making preview of video4', video.videoWidth, video.videoHeight);
        //     // console.log('dataUrl', dataUrl);
        //     // (document.getElementById('myImg') as HTMLImageElement).src = dataUrl;
        //   } catch (err) {
        //     console.log('video err ', err);
        //   }
        //   console.log('video onloadeddata end');
        // };
        // video.onload = (e: Event) => {
        //   console.log('Making preview of video (onload)', video.videoWidth, video.videoHeight);
        //   // Making preview of video 320 240
        //   // console.log('Making preview of video', video.width, video.height);
        // };
        // console.log(`Making video preview ${file.type} canPlay=${video.canPlayType(file.type)}`);
        video.src = window.URL.createObjectURL(file);
        let started = false;
        try {
          await video.play();
          started = true;
        } catch (e) {
          console.log('error loading video for making video preview', file, e);
        }
        // video.play().then(() => {
        if (started) {
          console.log('video preview play started');
          console.log('Making preview of video using play', video.videoWidth, video.videoHeight);
          this.makeAndSendPreview(video.videoWidth, video.videoHeight, video, newMessage, chatDisplay);
          video.pause(); // pause as soon as drawImage is called, don't wait till Promise (which sends the new message to server) resolves
        } else {
          await this.sendFileWithPreview(newMessage, chatDisplay); // bare file, no preview
        }

        // // Making preview of video using play 320 240
        // const canvas: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
        // const width = 64;
        // const height = 64;
        // canvas.width = width;
        // canvas.height = height;
        // console.log('Making preview of video2', video.videoWidth, video.videoHeight);
        // const ctx = canvas.getContext('2d');
        // if (ctx) {
        //   // video.play();
        //   ctx?.drawImage(video, 0, 0, width, height);
        //   video.pause();
        //   // video.pause();
        //   // ctx.fillStyle = 'rgb(200, 0, 0)';
        //   // ctx.fillRect(10, 10, 50, 50);
        //   // ctx?.fillStyle();
        //   // ctx?.fillRect(10, 20, 30, 40);
        // }
        // console.log('Making preview of video3', video.videoWidth, video.videoHeight);
        // // const dataUrl = canvas.toDataURL(/*'image/jpeg'*/file.type);
        // const dataUrl = canvas.toDataURL('image/jpeg'); // must not exceed 4 kbyte push limit in base64
        // console.log('Making preview of video4', video.videoWidth, video.videoHeight);
        // console.log('dataUrl', dataUrl);
        // (document.getElementById('myImg') as HTMLImageElement).src = dataUrl;
        // }).catch();
        // const image = new Image();
        // image.onload = (imageEvent) => {
        //   // Resize the image
        //   console.log('Making preview of video', image.width, image.height);
        // };
        // image.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
        //   console.log('Error preview of video', image.width, image.height, event, source, lineno, colno, error);
        // };
        // image.src = window.URL.createObjectURL(file);
      } else if (file.type.startsWith('image/')) {
        // let reader = new FileReader();
        // reader.onload = (readerEvent) => {

        const image = new Image();
        image.onload = (imageEvent) => {
          this.makeAndSendPreview(image.width, image.height, image, newMessage, chatDisplay);
        };
        image.src = window.URL.createObjectURL(file);
        // };
        // reader.readAsDataURL(file);
      } else {
        await this.sendFileWithPreview(newMessage, chatDisplay);
      }
    } else {
      await this.postMessageToChat(newMessage);
    }

  }

  private makeAndSendPreview(srcWidth: number, srvHeight: number, imageOrVideo: CanvasImageSource, newMessage: NewMessage, chatDisplay: ChatDisplay) {
    // Resize the image
    console.log('Making preview of image', srcWidth, srvHeight);
    const max = 64; // chat with Demid, he uses size 64 on Android
    // let scale = 1;
    // while (image.width / scale > max || image.height / scale > max) {
    //   scale *= 2;
    // }
    // const width = Math.floor(image.width / scale); //64;
    // const height = Math.floor(image.height / scale); //64;
    let width;
    let height;
    if (srcWidth < srvHeight) {
      width = Math.round(max * srcWidth / srvHeight);
      height = max;
    } else {
      width = max;
      height = Math.round(max * srvHeight / srcWidth);
    }

    const canvas: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d')?.drawImage(imageOrVideo, 0, 0, width, height);
    // const dataUrl = canvas.toDataURL(/*'image/jpeg'*/file.type);
    const dataUrl = canvas.toDataURL('image/jpeg'); // must not exceed 4 kbyte push limit in base64
    console.log(`image preview dataURL ${width} x ${height}`, dataUrl.length, dataUrl);
    // this.sendFileWithPreview(file, dataUrl);
    newMessage.msg.getFile()?.setPreview(dataUrl);
    newMessage.msg.getFile()?.setWidth(srcWidth);
    newMessage.msg.getFile()?.setHeight(srvHeight);
    newMessage.copyMsg?.setFile(newMessage.msg.getFile()); // we modified the file object, sync with copyMsg which will be actually posted to chat history
    this.sendFileWithPreview(newMessage, chatDisplay);
  }

  private async sendFileWithPreview(newMessage: NewMessage, chatDisplay: ChatDisplay) {
    if (!newMessage.file) {
      return;
    }
    const folderName = newMessage.msg.getChatid().replace('@', '_');
    const folderId = await this.cloudStorageService.getFolder(folderName);
    try {
      // const {id: newId, name: newName} = await this.cloudStorageService.uploadFile(newMessage.msg.getId(), folderId, newMessage.file);
      const {id: newId, name: newName} = await this.cloudStorageService.uploadFileOver150M(newMessage.msg.getId(), folderId, newMessage.file);
      if (!newId || !newName) {
        console.log('sendFileWithPreview upload aborted', newMessage.msg.getId());
        chatDisplay.messages = chatDisplay.messages.filter(msg => msg.getId() !== newMessage.msg.getId()); // filter out aborted message
        // this.chatDisplay.messages = this.chatDisplay.messages.filter(msg => msg.getId() !== newMessage.msg.getId()); // filter out aborted message
        return;
      }
      const chatFile = newMessage.msg.getFile();
      if (chatFile) {
        // chatFile.setName(file.name);
        chatFile.setName(newName); // make unique to work around a bug in Android client which shows all 'image.png' files as the same (that name is produced by clipboard API on web)
        chatFile.setData(newId);
        newMessage.copyMsg.setFile(chatFile); // we modified the file object, sync with copyMsg which will be actually posted to chat history
        console.log('upload ok: ', chatFile.toObject(), newMessage.msg.getFile()?.getData(), newMessage.copyMsg.getFile()?.getData());
      }
    } catch (e) {
      console.log('error uploading file ' + e.stack);
      this.messageDbService.setSendError(newMessage);
      return;
      // Access to fetch at 'https://upload.4sync.com/v1_2/upload?folderId=iib0P5ao&size=3&name=11.html' from origin 'https://localhost.teamy.chat' has been blocked by CORS policy: Response to preflight request doesn't pass access control check: No 'Access-Control-Allow-Origin' header is present on the requested resource.
    }
    this.postMessageToChat(newMessage);
  }

  async postMessageToChat(newMessage: NewMessage) {
    if (!navigator.onLine) {
      console.log('postMessageToChat cancelled: no internet connection, msgId=' + newMessage.copyMsg?.getId());
      this.messageDbService.setSendError(newMessage);
      await CommonUtils.delay(3000);
      return;
    }
    try {
      console.log('sendMessageBody encrypting');
      await this.encryptionService.encryptMessage(newMessage.copyMsg, newMessage.body);
      console.log('sendMessageBody encrypted');
      await this.messageDbService.sendMessage(newMessage.isChatMulti, newMessage.copyMsg, newMessage.update);
      this.messageDbService.clearSendError(newMessage);
    } catch (e) {
      console.error('postMessageToChat error', e);
      this.messageDbService.setSendError(newMessage);
    }
  }

}
