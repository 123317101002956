import {Injectable} from '@angular/core';
// import {grpc} from 'grpc-web-client';
import {ServiceError} from '../api/security_pb_service';
import {grpc} from '@improbable-eng/grpc-web';
import {TeamyError} from '../types';

@Injectable({
  providedIn: 'root'
})
export class GrpcDataService {
  grpcURLs: any = {
    'preprod.teamy.chat': 'https://preprod.teamy.chat:8443',
    // 'web.teamy.chat': 'https://preprod.teamy.chat:8443',
    'web.teamy.chat': 'https://web.teamy.chat', // web.teamy.chat/.*Service  goes to gRPC, this way no problem with CORS
    // "localhost": "https://preprod.teamy.chat:8443",
    // "localhost": "http://localhost:8080",
    '': 'https://test.teamy.chat', // nginx will redirect to port :8444 all POST requests
  };
  public grpcURL = this.grpcURLs[window.location.hostname] || this.grpcURLs[''];
  private grpcMetadata: grpc.Metadata = new grpc.Metadata();
  public userId = '';

  constructor() {
    console.log(window.location.hostname + ' grpcURL=' + this.grpcURL);
  }

  setMetadata(metadata: grpc.Metadata) {
    this.grpcMetadata = metadata;
  }


  public call<Request, Response>(
    thisArg: any,
    func: (requestMessage: Request, metadata: grpc.Metadata, callback: (error: ServiceError | null, responseMessage: Response | null) => void) => void,
    request: Request): Promise<Response> {
    if (this.grpcMetadata) {
      const headers = this.grpcMetadata;
      return new Promise<Response>((resolve, reject) => {
        func.call(thisArg, request, headers, (error: ServiceError | null, responseMessage: any) => {
          if (error) {
            reject(this.translateError(this.funcName(thisArg, func), error));
          } else {
            resolve(responseMessage);
          }
        });
      });
    } else {
      throw Error('GrpcDataService.grpcMetadata uninitialized');
    }
  }

  funcName(thisArg: any, func: any) {
    return thisArg.constructor.name + '.' + func.name;
    // console.log('funcName', thisArg, [...Object.getOwnPropertyNames(Object.getPrototypeOf(thisArg))]);
    // for (const funcName of Object.getOwnPropertyNames(Object.getPrototypeOf(thisArg))) {
    //   if (thisArg[funcName] === func) {
    //     return thisArg.name + '.' + funcName;
    //   }
    // }
    // return thisArg.name + '.???';
  }

  translateError(call: string, reason: ServiceError) {
    // https://developers.google.com/maps-booking/reference/grpc-api/status_codes
    const CODE_UNKNOWN = 2;
    const CODE_INTERNAL = 13;

    if (reason.code === CODE_INTERNAL) {
      const startStr = 'java.lang.RuntimeException: io.grpc.StatusRuntimeException: UNKNOWN:';
      const start = reason.message.indexOf(startStr);
      if (start > 0) {
        const obj = JSON.parse(reason.message.substr(start + startStr.length));
        console.log('translateError ' + call, reason.code, obj);
        // {type: "NAME_TOO_LONG", description: "name length 104 is over 100"}
        return new TeamyError(call, obj);
      }
    }
    if (reason.code === CODE_UNKNOWN) {
      const obj = JSON.parse(reason.message);
      console.log('translateError ' + call, reason.code, obj);
      // {
      //   "type": "AUTH_TOKEN_INVALID",
      //   "description": "Auth token invalid: wzwsj7cuw7fer848w8lczpae5bvo6w0c"
      // }
      return new TeamyError(call, obj);
    }
    console.log('translateError-unchanged ' + call, reason.code, reason.message, reason.metadata, /*reason*/);
    return reason; // unchanged generic error
  }

}
