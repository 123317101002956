<div class="tm-chat-options noselect">
  <div class="tm-options-header" *ngIf="!archivedListVisible">
    <h6 class="tm-chat-title" *ngIf="infoType === 'GROUP_CHAT'">Topic info</h6>
    <h6 class="tm-chat-title" *ngIf="infoType === 'CHAT'">User info</h6>

    <div class="tm-close-options" (click)="closeInfo()"></div>
  </div>
  <div class="tm-options-holder styled-scrollbar" *ngIf="!archivedListVisible">
    <div class="tm-options-info">
      <app-avatar [userId]="selectedChat.getId()" class="tm-big-ava" *ngIf="selectedChat && infoType === 'CHAT'"></app-avatar>
      <app-avatar [groupChat]="selectedChat" class="tm-big-ava" *ngIf="infoType === 'GROUP_CHAT'" ></app-avatar>

      <input type="file" style="display: none" accept="image/*" #fileInput (change)="setAvatar(fileInput.files)" />

      <div *ngIf="!isEditingName">
        <div *ngIf="infoType === 'GROUP_CHAT'">
          <div class="tm-big-title tm-string-shortener" [innerHTML]="selectedChat?.getDescription() | emojiToSpan: 20 : 64"></div>
          <div class="tm-chat-subtitle">in <span [innerHTML]="selectedGroup?.getDescription() | emojiToSpan" (click)="goChatIfHome()"></span></div>
        </div>

        <div *ngIf="selectedChat && infoType === 'CHAT'">
          <div class="tm-big-title tm-string-shortener"><app-fullname [userId]="selectedChat.getId()"></app-fullname></div>
          <div class="tm-chat-subtitle">{{getLastSeenStr(findUser(selectedChat.getId()))}}</div>
        </div>

        <div class="edit-name" (click)="editNameStart()" *ngIf="infoType==='GROUP_CHAT'"></div>
      </div>

      <div class="tm-name-input-holder" *ngIf="isEditingName">
        <div class="tm-upload-ava" (click)="fileInput.click()"></div>
        <div class="tm-input-item">
          <div class="tm-input-ext">
            <input autocomplete="new-password" spellcheck="false" name="editedName" [(ngModel)]="editedName" #inputToFocus/>
            <div class="tm-input-placeholder" *ngIf="infoType==='GROUP_CHAT'">Topic name</div>
          </div>
        </div>

        <div class="clear-fix change-name-buttons-holder">
          <button class="tm-btn float-right" (click)="saveNewName()">Save</button>
          <button class="tm-btn tm-btn-transp float-right" (click)="isEditingName = false">Cancel</button>
        </div>
      </div>
    </div>

    <div *ngIf="selectedChat && infoType === 'CHAT' && isUserByEmail()">
      <div class="tm-chat-subtitle">Email</div>
      <div class="tm-options-profile tm-options-item">
        <div class="tm-profile-contact tm-string-shortener">{{findUser(selectedChat.getId())?.getEmail()}}</div>
        <div class="tm-open-chat" (click)="openPrivateChatWith(selectedChat.getId())"></div>
      </div>
    </div>

    <div *ngIf="selectedChat && infoType === 'CHAT' && isUserByPhone()">
      <div class="tm-chat-subtitle">Phone</div>
      <div class="tm-options-profile-phone tm-options-item">
        <div class="tm-profile-contact tm-string-shortener">{{selectedChat.getId()}}</div>
        <div class="tm-open-chat" (click)="openPrivateChatWith(selectedChat.getId())"></div>
      </div>
    </div>

    <div class="tm-chat-subtitle">Settings</div>
    <div class="tm-options-pin tm-options-item" *ngIf="isChatPinVisible()">
      Pinned item <input class="tm-checkbox" name="enablePin" type="checkbox" [(ngModel)]="selectedChatPin" (click)="toggleChatPin()"/>
      <div class="tm-switcher"></div>
    </div>


    <div class="tm-options-notify tm-options-item" *ngIf="infoType === 'GROUP_CHAT' || infoType === 'CHAT'">
      Notifications <input class="tm-checkbox" name="enableNotify" type="checkbox" [(ngModel)]="selectedChatNotifications" (click)="toggleChatMute()"/>
      <div class="tm-switcher"></div>
    </div>

    <div class="tm-options-archive tm-options-item" (click)="archiveTopic(selectedChat)" *ngIf="infoType === 'GROUP_CHAT'">Archive topic</div>

<!--    <div class="tm-options-block tm-options-item" *ngIf="infoType == 'CHAT'">Block user</div>-->
    <div class="tm-options-leave tm-options-item" (click)="leaveChat()"  *ngIf="infoType === 'GROUP_CHAT' && selectedChat?.getMembersOnly()">Leave topic</div>

    <div *ngIf="infoType === 'GROUP_CHAT' && selectedChat?.getMembersOnly()">
      <div class="tm-chat-subtitle">
        {{ selectedChat?.getUsersList()?.length | i18nPlural : {'=1': '1 Teammate', 'other': '# Teammates'} }}
      </div>

      <div class="tm-chat-member tm-new-member">
        <div class="member-name" style="cursor: pointer" (click)="inviteToChat()">Invite people</div>
      </div>

      <div class="tm-chat-member" *ngFor="let userId of selectedChat?.getUsersList()" [class.is-online]="isOnline(findUser(userId))"
           (click)="openPrivateChatWith(userId)" style="cursor: pointer">
        <app-avatar [userId]="userId"></app-avatar>
        <app-fullname [userId]="userId"></app-fullname>
        <div class="member-status" >{{getLastSeenStr(findUser(userId))}}</div>
      </div>
    </div>
  </div>

  <div class="tm-options-header tm-header-archived" *ngIf="archivedListVisible">
    <h6 class="tm-chat-title">Archived topics</h6>
    <div class="tm-close-options" (click)="showArchivedList(false)"></div>
  </div>
  <div class="tm-options-holder tm-options-archived styled-scrollbar" *ngIf="archivedListVisible">
    <div class="tm-no-info" *ngIf="archivedChats.length===0">You have no any archived topics</div>
    <div class="tm-options-unarchive tm-chat-member" *ngFor="let archivedChat of archivedChats" (click)="unarchive(archivedChat)" title="Unarchive topic">
      <app-avatar [groupChat]="archivedChat"></app-avatar>
      <span class="tm-user-fullname tm-string-shortener" [innerHTML]="archivedChat.getDescription() | emojiToSpan"></span>
    </div>
  </div>
</div>
