import {PbChatMessage} from './api/groupchats_pb';

export class NewMessage {
  update = false;
  msg = new PbChatMessage(); // message added to local chat history (unencrypted)
  copyMsg: PbChatMessage = new PbChatMessage(); // encrypted message posted to chat

  constructor(public isChatMulti: boolean, public body: string, public file?: File) {
  }
}

