import {EventEmitter, Injectable} from '@angular/core';

import {PbChatMessage, PbChatUpdate, PbGroupChat} from './api/groupchats_pb';
import {PbGroup, PbUser} from './api/groups_pb';
import {ChatDbService} from './chat-db.service';
import {GroupDbService} from './group-db.service';
import {GroupUtils} from './util/group.utils';
import {ChatDisplayService} from './chats/chat-display.service';
import {CloudStorageService} from './cloud-storage.service';
import {CommonUtils} from './util/common.utils';
import {AvatarDataService} from './avatar/avatar-data.service';
import {GrpcDataService} from './login/grpc-data.service';
import {MessageUtils} from './util/message.utils';
import {BoolHolder} from './login/bool-holder';
import UpdateType = PbChatUpdate.UpdateType;

interface NotificationTexts {
  name1: string;
  name2: string;
  msg: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public navigateGroupAndChat = new EventEmitter<{ group: PbGroup; chat: PbGroupChat }>();

  constructor(private chatDbService: ChatDbService,
              private groupDbService: GroupDbService,
              private chatDisplayService: ChatDisplayService,
              private cloudStorageService: CloudStorageService,
              private avatarDataService: AvatarDataService,
              private grpcDataService: GrpcDataService,
  ) {
  }

  // async showGroupInviteNotification(fromUser: PbUser, group: PbGroup, notificationSoundPlayed: BoolHolder) {
  //   const maker = () => {
  //     this.produceNotification(name1, name2, msg, false, group, undefined, this.getAvatarUrl(fromUser), fromUser);
  //     return true; // displayed
  //   };
  //   this.displayNotification(maker, notificationSoundPlayed);
  // }

  async showNotification(fromUser: PbUser, message: PbChatMessage, notificationSoundPlayed: BoolHolder) {
    console.log(`notification.service showNotification() fromUser=${fromUser} `);
    const privateChat = (message.getType() === PbChatMessage.Type.CHAT);
    const chat = await this.chatDbService.findChat(!privateChat, message.getChatid());
    if (!chat) {
      console.log(`chat from message not found`, message.toObject());
      return;
    }
    const isAlert = MessageUtils.isAlertMessage(message, this.grpcDataService.userId);
    if (chat.getMute() && !isAlert) {
      console.log('message from muted chat: skipped notification');
      return;
    }
    if (CommonUtils.isActive() && !this.chatDisplayService.isNotificationNeeded(message.getChatid())) {
      if (!notificationSoundPlayed.value) {
        notificationSoundPlayed.value = true;
        try {
          this.playAudio('income_message.mp3');
        } catch (e) {
          // console.error(`income_message.mp3 error: group ${group?.getId()} chat ${chat?.getId()} msg ${message.getFrom()} ${message.getId()}`, e);
          console.error(`income_message.mp3 error`, e);
        }
      }
      return; // don't notify when this chat is among visible chats
    }

    if (privateChat) {
      const maker = () => this.messageNotification(message, fromUser, GroupUtils.PEOPLE_GROUP, chat);
      this.displayNotification(maker, notificationSoundPlayed);
    } else {
      const group = await this.groupDbService.getOrLoadGroup(chat.getGroupId());
      if (!group) {
        console.warn(`showNotification group not found`, chat.getGroupId());
      } else if (group.getMute() && !isAlert) {
        console.log('message from muted group: skipped notification');
      } else {
        const maker = () => this.messageNotification(message, fromUser, group, chat);
        this.displayNotification(maker, notificationSoundPlayed);
      }
      // this.groupDbService.getGroup$(chat.getGroupid()).pipe(take(1)).subscribe(group => {
      //   if (!group) {
      //     console.warn(`showNotification group not found`, chat.getGroupid());
      //   } else if (group.getMute() && !isAlert) {
      //     console.log('message from muted group: skipped notification');
      //   } else {
      //     const maker = () => {
      //       return this.messageNotification(message, fromUser, group, chat);
      //     };
      //     this.displayNotification(maker, notificationSoundPlayed);
      //   }
      // });
    }
  }

  private displayNotification(maker: () => boolean, notificationSoundPlayed: BoolHolder) {
    console.log('Notification.permission=' + Notification.permission);
    if (Notification.permission === 'denied') {
      return;
    }
    // @todo we don't need permission if run inside Electron
    Notification.requestPermission().then(result => { // @todo should we ask this repeatedly or only once and mark absense of response in cookies?
      if (result === 'denied') {
        console.log('Notification: Permission wasn\'t granted. Allow a retry.');
        return;
      }
      if (result === 'default') {
        console.log('Notification: The permission request was dismissed.');
        return;
      }
      console.log('Notification: The permission request was granted: ' + result);
      // Do something with the granted permission.
      const shown = maker();
      if (shown && !notificationSoundPlayed.value) {
        notificationSoundPlayed.value = true;
        try {
          // console.log(`income_push.mp3: group ${group?.getId()} chat ${chat?.getId()} msg ${message.getFrom()} ${message.getId()}`);
          console.log(`income_push.mp3`);
          this.playAudio('income_push.mp3');
        } catch (e) {
          // console.error(`income_push.mp3 error: group ${group?.getId()} chat ${chat?.getId()} msg ${message.getFrom()} ${message.getId()}`, e);
          console.error(`income_push.mp3 error`, e);
        }
      }
    });

    // https://electronjs.org/docs/tutorial/notifications

    /*
    for notifications to work, seems like we have to enable them with Notification.requestPermission()

  Notification.permission
  "default"
  Notification.requestPermission()
  Promise {<pending>}
  Notification.permission
  "granted"
     */
  }

  private getAvatarUrl(fromUser: PbUser): string | undefined {
    if (fromUser.getId() === GroupUtils.TEAMY_BOT) {
      return GroupUtils.TEAMY_BOT_AVATAR_URL;
    } else if (fromUser.getAvatarurl()) {
      const url = this.cloudStorageService.getAvatarFinalUrl(fromUser.getAvatarurl());
      console.log('will display notification with user avatar: ', url);
      return url;
    } else {
      return undefined;
    }
  }

  private messageNotification(message: PbChatMessage, fromUser: PbUser, group: PbGroup, chat: PbGroupChat) {
    const privateChat = (message.getType() === PbChatMessage.Type.CHAT);
    let texts: NotificationTexts | undefined;
    let avatarUrl: string | undefined;
    if (privateChat) {
      avatarUrl = this.getAvatarUrl(fromUser);
      texts = this.getNotificationTexts(fromUser, message, group);
    } else {
      if (group.getAvatarUrl()) {
        const url = this.cloudStorageService.getAvatarFinalUrl(group.getAvatarUrl());
        console.log('will display notification with group avatar: ', url);
        avatarUrl = url;
        texts = this.getNotificationTexts(fromUser, message, group);
      } else {
        // default group avatar image with blue background
        avatarUrl = '/assets/img/default-notification-group-ava.png';
        texts = this.getNotificationTexts(fromUser, message, group);
      }
    }
    if (texts) {
      const isAlert = MessageUtils.isAlertMessage(message, this.grpcDataService.userId);
      this.produceNotification(texts, isAlert, group, chat, avatarUrl, fromUser);
      return true;
    }

    return false; // skipped
  }

  private systemMessageNotification(message: PbChatMessage, group: PbGroup): { name1: string; msg: string } | undefined {
    const update = message.getChatupdate();
    switch (update?.getType()) {
      // case UpdateType.UNDEFINED:
      //   return 'UNDEFINED';
      // case UpdateType.RENAME:
      //   return actor + 'renamed the topic to \'' + update.getDescription() + '\'';
      // case UpdateType.AVATAR:
      //   return actor + 'changed AVATAR';
      // case UpdateType.ARCHIVED:
      //   return actor + 'archived this topic';
      // case UpdateType.UNARCHIVED:
      //   return actor + 'unarchived this topic';
      //
      // case UpdateType.USER_ADD_CHAT:
      //   return actor + 'added new chat members: ' + usersNamed;
      case UpdateType.USER_ADD_TEAM:
        if (update.getUsersList().includes(this.grpcDataService.userId)) {
          return {name1: 'Welcome to ' + group.getDescription(), msg: 'added you to team'};
        }
        break;
      // return usersNamed + " joined this team";
      // case UpdateType.USER_REMOVE_CHAT:
      //   //return actor + 'removed chat members: ' + usersNamed;
      //   return usersNamed + " left this topic";
      // case UpdateType.USER_REMOVE_TEAM:
      //   return usersNamed + " left this team";
      //
      // case UpdateType.APPROVE_DEVICE:
      //   return msg.getBody();
      case UpdateType.CHAT_CREATE:
        // if (msg.getFrom() === GroupUtils.TEAMY_BOT) return "We created general topic for your team";
        // return actor + 'created the topic \'' + update.getDescription() + '\' with ' + update.getUsersList().length + ' teammates';
        return {name1: update.getDescription(), msg: 'created a new topic'};
    }
    return undefined;
  }

  private playAudio(file: string) {
    console.log('Playing audio ' + file);
    const audio = new Audio();
    // audio.src = "assets/out000.mp3";
    audio.src = 'assets/' + file;
    audio.load();
    audio.play();
  }

  parseDecorations(message: PbChatMessage) {
    let parsedMessage = message.getBody().replace(/@[\w_.+]+\s\(([^)]+)\)/g, '@$1'); // mentions
    parsedMessage = parsedMessage.split('[quote]').join('').split('[.quote]').join(''); // quotes
    parsedMessage = parsedMessage.replace(/<span[^>]*>([^<]*)<\/span>/g, '$1'); // emoji

    return parsedMessage;
  }

  private getNotificationTexts(fromUser: PbUser, message: PbChatMessage, group: PbGroup): NotificationTexts | undefined {
    let name1 = fromUser.getFullname();
    let name2 = '';
    let msg = this.parseDecorations(message);

    if (group && (group.getId() !== GroupUtils.PEOPLE_GROUP_ID)) {
      name1 = group.getDescription();
      name2 = fromUser.getFullname() + ':\n';
    }

    const file = message.getFile();
    if (file) {
      const type = file.getMimetype().split('/')[0];
      msg = 'Sent a file';
      if (type === 'image') {
        msg = 'Sent a photo';
      }
      if (type === 'video') {
        msg = 'Sent a video';
      }
      // msg = 'Sent a ' + (type === 'image' ? 'photo' : type);
    }
    const location = message.getLocation();
    if (location) {
      msg = 'Sent location';
      if (location.getLiveuntiltime() > 0) {
        msg = 'Shared location';
      }
    }

    // message.getChatupdate().getType() === UpdateType.APPROVE_DEVICE
    // APPROVE_DEVICE is special: contains both chatUpdate and a regular message
    if (message.hasChatupdate() && !msg) {
      const sys = this.systemMessageNotification(message, group);
      if (!sys) {
        console.log(`System message without description, suppressing notification`, message);
        return undefined; // skipped
      }
      ({name1, msg} = sys);
      if (group && (group.getId() !== GroupUtils.PEOPLE_GROUP_ID)) {
        // no colon and newline for system messages:
        name2 = fromUser.getFullname() + ' '; // + ':\n';
      }
    }
    return {name1, name2, msg}; // displayed
  }

  private produceNotification(texts: NotificationTexts, isAlert: boolean, group: PbGroup, chat: PbGroupChat, avatarUrl: string | undefined, fromUser: PbUser) {
    const {name1, name2, msg} = texts;
    const image = new Image();
    const max = 80;
    let ava = '';
    console.log(`Notification contents [${name1}] [${name2}] [${msg}]`);

    const makeNotification = () => {
      const myNotification = new Notification((isAlert ? '@ ' : '') + name1, {
        silent: true, /* we have our own sound */
        body: name2 + msg,
        icon: ava
      });

      // myNotification.onclick = () => {
      //   // console.log(`Notification clicked for ${message.getBody()} from ${message.getFrom()}`);
      //   console.log(`Notification clicked groupId=${group?.getId()}`);
      //   parent.focus();
      //   window.focus();
      //   if (group) {
      //     // this.currentDisplayService.setSelectedGroupAndChat(group.getId(), chat);
      //     this.navigateGroupAndChat.emit({group, chat});
      //   }
      //   myNotification.close();
      //   // alert(`notification from ${message.getFrom()} chat=${message.getChatid()} `);
      // };
      myNotification.addEventListener('click', (e: Event) => {
        e.preventDefault();
        console.log(`Notification clicked groupId=${group?.getId()}`);
        parent.focus();
        window.focus();
        if (group) {
          // this.currentDisplayService.setSelectedGroupAndChat(group.getId(), chat);
          this.navigateGroupAndChat.emit({group, chat});
        }
        (e.target as Notification).close();
        // myNotification.close();
      }, false);
      myNotification.onshow = () => {
        console.log('notification shown');
        setTimeout(() => myNotification.close(), 7000); // forcibly close notification after 7 seconds
      };
    };

    if (!avatarUrl) {
      const gradientColors = this.avatarDataService.getColorPair(fromUser.getFullname());
      const userLabel = this.avatarDataService.getCharacter(fromUser.getFullname(), 2);

      const canvas: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
      canvas.width = 80;
      canvas.height = 80;
      const ctx = canvas.getContext('2d');
      if (ctx) {

        // Create gradient
        const grd = ctx.createLinearGradient(40.000, 0.000, 40.000, 80.000);

        // Add colors
        grd.addColorStop(0.000, gradientColors[0]);
        grd.addColorStop(1.000, gradientColors[1]);

        ctx.font = '20px Roboto';
        // Fill with gradient
        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, 80.000, 80.000);

        ctx.fillStyle = 'rgba(0,0,0,0.35)';
        ctx.textAlign = 'center';
        ctx.fillText(userLabel, 40, 50);

        ctx.globalCompositeOperation = 'destination-in';
        ctx.beginPath();
        ctx.arc(40, 40, 40, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
      }

      ava = canvas.toDataURL('image/png');
      console.log(ava);

      makeNotification();
    } else {
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = avatarUrl;
      image.onload = (imageEvent) => {
        let width;
        let height;
        let offX;
        let offY;

        if (image.width < image.height) {
          height = Math.round(max * image.height / image.width);
          width = max;
          offX = 0;
          offY = -(height - max) / 2;
        } else {
          height = max;
          width = Math.round(max * image.width / image.height);
          offY = 0;
          offX = -(width - max) / 2;
        }

        const canvas: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
        canvas.width = 80;
        canvas.height = 80;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(image, offX, offY, width, height);
          ctx.globalCompositeOperation = 'destination-in';
          ctx.beginPath();
          ctx.arc(40, 40, 40, 0, Math.PI * 2);
          ctx.closePath();
          ctx.fill();
        }
        ava = canvas.toDataURL('image/png');
        makeNotification();
      };
    }
  }
}
