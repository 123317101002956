<div class="tm-chat-area" #chatWrapper [ngClass]="{
    'tm-chat-options-showed': infoType && infoType.length,
    'show-dnd': showDND,
    'is-bot-chat': isBotChat(),
    'reached-top': isNearTop,
    'reached-bottom': isNearBottom,
    'too-low-for-scroll': !isHome && scrollIsDisabled,
    'is-direct-chat': selectedChat && !isChatMulti()
  }">

  <div class="tm-empty-chat-placeholder" *ngIf="!selectedChat">
    <div class="tm-empty-chat-inner">
      <h6 class="tm-chat-title">Start chatting with your friends</h6>
      <div class="tm-chat-subtitle" *ngIf="!isChatMulti()">To create a chat with your friend just simply click on one of your contacts from sidebar on the left.</div>
    </div>
  </div>

  <div class="tm-chat-header" *ngIf="selectedChat && isChatMulti()">
    <h6 class="tm-chat-title tm-string-shortener" (click)="goChatIfHome()">
      <span class="ico-lock" *ngIf="selectedChat.getMembersOnly()"></span>
      <span [innerHTML]="selectedChat.getDescription() | emojiToSpan : 20 : 64"></span>
    </h6>
    <div class="tm-chat-subtitle tm-string-shortener" *ngIf="isHome">
      {{getLastMessageAgoStr()}} in <app-fullname [groupId]="selectedChat.getGroupId()" class="tm-user-fullname" (click)="goChatIfHome()"></app-fullname>
    </div>
    <div class="tm-chat-subtitle tm-string-shortener" *ngIf="!isHome" (click)="teammatesCountClick()"
         [style.cursor]="selectedChat.getMembersOnly() ? '' : 'pointer'" >
      {{ teammatesCount | i18nPlural : {'=1': '1 teammate', 'other': '# teammates'} }}, {{onlineTeammatesCount}} online
    </div>
    <div class="tm-chat-search"></div>
    <app-avatar [groupChat]="selectedChat" (click)="showTopicInfo($event)" [class.is-home]="isHome"></app-avatar>
    <div class="tm-go-message" *ngIf="isHome" (click)="goChatIfHome()">Show more messages</div>
  </div>

  <div class="tm-chat-header" *ngIf="selectedChat && !isChatMulti()" [class.empty-subtitle]="getLastSeenStr(findUser(selectedChat.getId())) === ''">
    <app-fullname [userId]="selectedChat.getId()" [class.is-home]="isHome" (click)="goChatIfHome()" class="tm-single-chat-title"></app-fullname>
    <div class="tm-chat-subtitle tm-string-shortener" *ngIf="!isHome" [class.is-online]="isOnline(findUser(selectedChat.getId()))">{{getLastSeenStr(findUser(selectedChat.getId()))}}</div>
    <div class="tm-chat-subtitle tm-string-shortener" *ngIf="isHome">{{getLastMessageAgoStr()}}</div>
    <div class="tm-chat-search"></div>
    <app-avatar [userId]="selectedChat.getId()" (click)="showChatInfo($event)" [class.is-home]="isHome"></app-avatar>
    <div class="tm-go-message" *ngIf="isHome" (click)="goChatIfHome()">Show more messages</div>
  </div>

  <div class="tm-chat-body styled-scrollbar" *ngIf="selectedChat" #chatScroller [class.historyLoading]="historyLoadingProgress"
       (scroll)="scrolled($event)"
       (wheel)="userScrolled($event)"
       (dragenter)="dragEnterCallback($event)">

    <div class="tm-scroller-wrapper" #chatScrollerInner>
      <div class="tm-chat-message" *ngFor="let message of messages; index as idx; let last = last" #messageItem
           [attr.data-idx]="idx"
           [attr.data-id]="message.getId()"
           [class.is-deleted]="isDeleted(message)">

        <div class="chat-date" *ngIf="isMessageOnNewDay(idx)"><span>{{getMessageDate(message)}}</span></div>
        <div class="chat-info-message" *ngIf="isSystem(message)" [innerHTML]="describeSystemMessage(message) | emojiToSpan"></div>
        <div class="chat-info-divider" *ngIf="!message.getIncoming() && message.getFrom()===''"><span>{{message.getBody()}}</span></div>

        <div class="chat-message-line clear-fix" *ngIf="isDecryptable(message) && !isSystem(message) || message.getCallevent()">
          <div class="messages-group-separator" *ngIf="!isMessageFromSamePersonAsBefore(idx)"></div>

          <div class="tm-message-author" *ngIf="isChatMulti() && message.getIncoming() && (!isMessageFromSamePersonAsBefore(idx) || isMessageOnNewDay(idx))">
            <app-avatar [userId]="message.getFrom()" (click)="showUserInfoInGroupChat(message.getFrom())"></app-avatar>
            <app-fullname [rainbow]="userDbService.rainbowChatBubbles" [userId]="message.getFrom()"></app-fullname>
          </div>

          <div class="tm-message-bubble" *ngIf="message.getFrom()!==''"
               (contextmenu)="onContextMenu($event, message)"
               [style.background]="getBubbleColor(message.getFrom(), message.getCallevent())"
               [ngClass]="{
               'tm-own-message': !message.getIncoming() && !message.getPromotion(),
               'tm-local-file': isLocalFile(message),
               'is-edited': isOwnMessage(message),
               'placeholder' : message.getPromotion(),
               'is-media-message' : message.getFile() && message.getFile()?.getPreview(),
               'load-error': isDeliveryError(message),
               'has-cite': message.hasQuote() || message.getAuthor(),
               'is-file': message.getFile() && !message.getFile()?.getPreview(),
               'is-loading': hasUploadProgress(message.getId()) || getDownloadProgress(message.getId()),
               'has-link-preview': hasLinks(message),
               'solo-symbol': checkIfSoloEmoji(message),
               'rainbow-bubbles': userDbService.rainbowChatBubbles,
               'chat-info-message': isDeleted(message),
               'is-video-message': message.getFile() && message.getFile()?.getPreview() && message.getFile()?.getMimetype()?.startsWith('video/'),
               'is-call-event': message.getCallevent(),
               'is-active-call': message.getCallevent() && !message.getCallevent()?.getCallended()
               }">

            <!--forwarded-->
            <div class="tm-message-fwd tm-message-quote" *ngIf="message.getAuthor()">
              <div class="fwd-title">Forwarded message</div>
              <div class="fwd-subtitle">From: <app-fullname [userId]="message.getAuthor()"></app-fullname></div>
            </div>

            <!--quote-->
            <div class="tm-message-quote" *ngIf="message.hasQuote() && !isDeleted(message)"
                 [class.has-preview]="message.getQuote()?.getFile() && message.getQuote()?.getFile()?.getPreview()"
                 (click)="scrollToSearchedMessage(message.getQuote())">
              <img class="preview" [src]="preview(message.getQuote())" *ngIf="message.getQuote()?.getFile() && message.getQuote()?.getFile()?.getPreview()" />
              <app-fullname [userId]="message.getQuote()?.getFrom()"></app-fullname>
              <span class="message-body message-body-wrapper" *ngIf="message.getQuote()?.getBody() !== ''" [innerHTML]="autolinkBody(message.getQuote(), true)"></span>
              <span class="message-body mime" *ngIf="message.getQuote()?.getFile()" [innerHTML]="fileTypeOrName(message.getQuote())"></span>
            </div>

            <!--regular message-->
            <div class="message-body-wrapper" *ngIf="message.getBody() !== ''">
              <span class="message-body" [innerHTML]="autolinkBody(message) | emojiToSpan: 20 : 64" (click)="messageClickHandler($event)"></span>
            </div>

            <!--call message-->
            <div class="message-body-wrapper" *ngIf="message.getCallevent()">
              <div class="message-body">
                <span class="call-icon"></span>
                <span class="call-title title-started">Call started</span>
                <span class="call-title title-ended">Call ended</span>
                <span class="small-title">Google Meet</span>
                <a class="call-link" href="{{message.getCallevent()?.getUrl()}}" *ngIf="!message.getCallevent()?.getCallended()" target="_blank"
                   [attr.data-hover-color]="getBubbleColor(message.getFrom(), message.getCallevent())">Join</a>
                <div class="call-link call-stop" *ngIf="!message.getCallevent()?.getCallended() && isOwnMessage(message)" (click)="endCall(message)">Stop</div>
              </div>
            </div>


            <!--link preview-->         <!--[class.isImageLink]="getParsedLink(message).getIsimage()"-->
            <div class="tm-message-link-preview tm-message-quote" *ngIf="hasLinks(message)">
              <div class="site-name">{{getLinkSiteName(message)}}</div>
              <div class="site-title" [innerHTML]="truncateLong(getParsedLink(message)?.getTitle(), 100)"></div>
              <span class="message-body" [innerHTML]="truncateLong(getParsedLink(message)?.getDescription(), 150)"></span>
              <img [src]="forceHttps(getParsedLink(message)?.getImage())" (load)="scrollToBottom()" (error)="clearLinkPreviewError($event)">
            </div>

            <!--new chat placeholder-->
            <div class="placeholder-text" *ngIf="message.getPromotion()">
              <span [innerHtml]="message.getPromotion()?.getHtmltext()"></span>
              <div class="create-first-chat-button" (click)="promotionClick(message)">{{message.getPromotion()?.getLinktext()}}</div>
            </div>

            <!--deleted message-->
            <span class="message-body" *ngIf="isDeleted(message)">
              <app-fullname [userId]="message.getFrom()" [plaintext]="true"></app-fullname> deleted a message
            </span>

            <!--media preview thumbnail-->
            <div class="media-preview-wrapper" *ngIf="message.getFile() && message.getFile()?.getPreview() && !message.getFile()?.getMimetype()?.startsWith('video/')">
              <img class="big-preview-loaded" [src]="previewBig(message)" (click)="openImageView(message)" (load)="previewBigLoaded($event)" />

              <div class="img-loader" [class.is-uploading]="hasUploadProgress(message.getId())"></div>
              <div class="underlayer"></div>
            </div>

            <!--video message-->
            <div class="media-preview-wrapper video-preview-wrapper"
                 *ngIf="message.getFile() && message.getFile()?.getPreview() && message.getFile()?.getMimetype()?.startsWith('video/')">
              <video [poster]="previewBig(message)" (click)="onClickVideo($event, message)" disablepictureinpicture controlslist="nodownload"></video>
              <div class="video-big-play-button" (click)="hideVideoOverlay($event)"></div>
            </div>

            <!--message time-->
            <div class="tm-message-creation-date noselect" *ngIf="!isDeleted(message)" [ngClass]="deliveryStatus(message)">{{formatDateLastMessage(message)}}</div>

            <!--file message-->
            <div class="clear-fix message-file" *ngIf="message.getFile() && !message.getFile()?.getPreview()">
              <div class="file-action-button" (click)="downloadFile(message)"></div>
              <div class="file-name">{{message.getFile()?.getName()}}</div>
              <div class="file-meta">
                <span *ngIf="getDownloadProgress(message.getId())">{{formatBytes(getDownloadProgress(message.getId())?.loaded)}}/</span>
                <span *ngIf="hasUploadProgress(message.getId())">{{formatPartialBytesNoUnits(getUploadProgress(message.getId()), message.getFile()?.getSize())}} /</span>
                {{formatFileSizeType(message)}}
              </div>

              <div class="upload-progress download-progress" *ngIf="getDownloadProgress(message.getId())">
                <mat-spinner (click)="cancelDownload(message.getId())" diameter="30" strokeWidth="2" mode="determinate"
                             [value]="getDownloadPercent(message.getId(), idx)" [ngClass]="{'mat-accent': !message.getIncoming()}"></mat-spinner>
              </div>
            </div>

            <!--message actions menu-->
            <div class="tm-message-options" *ngIf="showMessageOptionsButton(message)">
              <div class="tm-message-reply" title="Reply" (click)="replyMessage(message)"></div>
              <button mat-icon-button class="tm-message-menu" (click)="onContextMenu($event, message)"></button>
            </div>

            <div style="visibility: hidden; position: fixed"
                 [style.left]="contextMenuPosition.x"
                 [style.top]="contextMenuPosition.y"
                 [matMenuTriggerFor]="contextMenu">
            </div>

            <mat-menu #contextMenu="matMenu" class="tm-dropdown" hasBackdrop="true" backdropClass="backdrop-chat-context">
              <ng-template matMenuContent let-item="item">
                <button mat-menu-item class="dropdown-item ico-edit" (click)="editChatMessage(item)" *ngIf="isMyMessage(item) && isEditableMessage(item)"
                        (mouseover)="fixBG($event)" (mouseout)="fixBG($event)" data-hover="false"><span>Edit</span></button>
                <button mat-menu-item class="dropdown-item ico-forward" (click)="forwardMessage(item)" *ngIf="!item.getCallevent()"
                        (mouseover)="fixBG($event)" (mouseout)="fixBG($event)" data-hover="false"><span>Forward</span></button>
                <button mat-menu-item class="dropdown-item ico-copy" (click)="copyMessageText(item)" *ngIf="isTextMessage(item)"><span>Copy text</span></button>
                <button mat-menu-item class="dropdown-item ico-copy-link" (click)="copyMessageLink(item)" *ngIf="showCopyLink(item)"><span>Copy link</span></button>
                <button mat-menu-item class="dropdown-item ico-download" (click)="downloadFile(item)" *ngIf="item.getFile() && item.getFile()?.getPreview()"><span>Download</span></button>
                <button mat-menu-item class="dropdown-item ico-delete" (click)="showDeleteConfirm(item)" *ngIf="isMyMessage(item) && !isDeleted(item)"><span>Delete</span></button>
              </ng-template>
            </mat-menu>

            <div class="restart-loading" (click)="retrySendMessage(message.getId())" title="Retry"></div>
            <div class="remove-unsent" (click)="removeUnsentMessage(message.getId())" title="Cancel"></div>
            <div class="upload-progress" *ngIf="hasUploadProgress(message.getId())">
              <mat-spinner (click)="cancelUpload(message.getId())" diameter="30" strokeWidth="2" mode="determinate"
                           [value]="getUploadProgress(message.getId())" [ngClass]="{'mat-accent': !message.getIncoming()}"></mat-spinner>
            </div>
          </div>
        </div>

        <div *ngIf="isMessageLastSeen(message)" class="chat-info-divider"><span> New messages </span></div>
      </div>

    </div>
  </div>

  <div #chatIsVisibleMarker style="height: 1px"></div>

  <div *ngIf="typingUsers().length" class="tm-user-typing-ind">
    <span>
      <span *ngFor="let userId of typingUsers(); index as i" #typingUserItem>
        <span *ngIf=" i!==0 ">, </span>
        <app-fullname [userId]="userId" [rainbow]="false" [nameOnly]="true"></app-fullname>
      </span>
      {{typingUsers().length | i18nPlural : {'=1': 'is typing...', 'other': 'are typing...'} }}
    </span>
  </div>

  <div class="tm-chat-add-new-message" *ngIf="selectedChat && !isBotChat()"
       [ngClass]="{
       'has-quote': quotedMessage,
       'is-editing': editMessageMode,
       'is-single-chat': !isChatMulti(),
       'mentions-active': mentionsAreActive,
       'attach-active': uploadWindowIsActive
       }">
    <div class="tm-new-message-input-holder" [class.showEmoji]="showEmoji" [class.is-focused]="isFocused">

      <div class="tm-add-mention" (click)="addMentionsChar()" *ngIf="isChatMulti()"></div>

      <div class="tm-add-emoji" (click)="addEmojiChar()" [class.active]="showEmoji" data-showEmoji></div>
      <div class="emoji-block" #emojiBlock *ngIf="showEmoji">
        <emoji-mart (emojiClick)="addEmoji($event)" [showPreview]="false" [class.topOnHome]=""></emoji-mart>
      </div>

      <div class="mention-suggestions styled-scrollbar" *ngIf="mentionsAreActive">
        <div class="mention-item" *ngFor="let chatMember of mentionMembersList; index as idx"
             [attr.tabindex]="idx" [id]="'mention-' + idx"
             [attr.data-userId]="getUserId(chatMember)"
             (click)="pasteMention(getUserId(chatMember))"
             (mouseenter)="changeMentionSelectionByMouse($event)">
          <app-avatar [userId]="getUserId(chatMember)" class="members-xs"></app-avatar>
          <app-fullname [userId]="getUserId(chatMember)"></app-fullname>
        </div>
      </div>

      <div class="new-message-quote" *ngIf="quotedMessage" [class.image-quote]="quotedMessage.getFile() && quotedMessage.getFile()?.getPreview()"
           (click)="scrollToSearchedMessage(quotedMessage)">
        <div class="quote-img" *ngIf="quotedMessage.getFile() && quotedMessage.getFile()?.getPreview()"><img [src]="preview(quotedMessage)" /></div>
        <div class="quote-author"><app-fullname [userId]="quoteAuthor"></app-fullname></div>
        <div class="quote-body" *ngIf="!quotedMessage.getFile()" [innerHTML]="quoteBody | emojiToSpan"></div>
        <div class="quote-body mime" *ngIf="quotedMessage.getFile()" [innerHTML]="fileTypeOrName(quotedMessage)"></div>
        <div class="quote-cancel" (click)="resetQuote(editMessageMode)"></div>
      </div>

      <div class="new-message-text-holder styled-scrollbar">
        <div class="tm-new-message-input" contenteditable="true" placeholder="Message" #messageInput [class.home-message-input-field]="isHome"
             (paste)="pastePicture($event)"
             (mouseup)="changeInput($event)"
             (keyup)="keyUpListener($event)"
             (input)="changeInput($event)"
             (keydown)="keyDownListener($event)"
             (focusin)="setFocus(true)"
             (focusout)="setFocus(false)"
             (keydown.control.enter)="addNewLine($event);"
             (keydown.shift.enter)="addNewLine($event);"
             (keydown.arrowUp)="editLastMessage()"
             (keydown.enter)="sendMessage($event)"></div>
      </div>

      <input type="file" multiple style="display: none" #fileInput (change)="sendFiles(fileInput.files); fileInput.value='';" />
      <div class="tm-add-file" (click)="startAttach()"></div>

      <div class="send-message-button" (click)="messageInput.focus();sendMessage($event)" *ngIf="newMessageBody"></div>
      <div class="send-message-button button-placeholder" *ngIf="!newMessageBody"></div>
      <div class="scroll-to-bottom" (click)="scrollToBottom(true)" *ngIf="!isNearBottom" [ngClass]="{
         'turned-up': quotedMessage,
         'show-unread-counter': selectedChat.getUnreadMessagesCount()>0,
         'show-mention-badge': selectedChat.getUnreadAlertMessagesCount()>0
         }">
        <div class="badges-holder">
          <div class="unread-counter">{{selectedChat.getUnreadMessagesCount()}}</div>
          <div class="mention-notify" *ngIf="selectedChat.getUnreadAlertMessagesCount()>0">@</div>
        </div>
      </div>
    </div>
  </div>

  <div class="dnd-layer" appDragDrop (fileDropped)="showDND = false; sendFiles($event);" (dragleave)="showDND = false"></div>
  <input hidden type="file" #fileInput (change)="sendFilesEvent($event); fileInput.value='';">

</div>

<jw-modal id="confirm-delete">
  <div class="tm-modal-header">
    <h6>Delete message</h6>
  </div>
  <div class="tm-modal-body confirm-popup">
    <div class="tm-modal-wrapper" style="width: 376px;">
      <p>Are you sure you want to delete this message? This can not be undone.</p>


      <div class="clear-fix tm-modal-buttons-holder">
        <button class="tm-btn tm-btn-red float-right" (click)="deleteMessage()">Delete</button>
        <button class="tm-btn tm-btn-transp float-right" (click)="closeDeleteConfirm()">Cancel</button>
      </div>
    </div>
  </div>
</jw-modal>

<!--<div class="uploadfilecontainer" appDragDrop (onFileDropped)="sendFiles($event);">   &lt;!&ndash;sendFiles(fileInput.files); fileInput.value='';&ndash;&gt;-->
  <!---->
<!--</div>-->
