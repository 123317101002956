import {Injectable} from '@angular/core';
import {PbGroupChat} from '../api/groupchats_pb';

@Injectable({
  providedIn: 'root'
})
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
export class ChatDisplayService {

  // tslint:disable-next-line:variable-name
  private _home = false;
  // tslint:disable-next-line:variable-name
  private _visibleChats?: PbGroupChat[];
  // tslint:disable-next-line:variable-name
  private _homeVisibleChats = new Set<string>();

  constructor() {
  }

  setVisibleChats(chats: PbGroupChat[]) {
    this._visibleChats = chats;
  }

  private isVisibleChat(chatId: string) {
    return this._visibleChats && this._visibleChats.some(chat => chat.getId() === chatId);
  }

  isNotificationNeeded(chatId: string) {
    if (this._home) {
      return !this._homeVisibleChats.has(chatId);
    } else {
      return !this.isVisibleChat(chatId);
    }
  }

  // isChatCounterNeeded(chatId: string) {
  //   if (this._home) {
  //     return true;
  //   } else {
  //     return !this.isVisibleChat(chatId);
  //   }
  // }

  isChatCounterAutoResetNeeded(chatId: string) {
    if (this._home) {
      return this._homeVisibleChats.has(chatId);
    } else {
      return false;
    }
  }

  setHome(home: boolean) {
    this._home = home;
    if (!home) {
      this._homeVisibleChats.clear();
    }
  }

  setHomeVisibleChat(chatId: string, visible: boolean) {
    if (visible) {
      this._homeVisibleChats.add(chatId);
    } else {
      this._homeVisibleChats.delete(chatId);
    }
    console.log('_homeVisibleChats', this._homeVisibleChats);
  }

}
