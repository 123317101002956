import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

console.log('window.process=' + (window as any).process + ' version=2021-08-09'); // will we ever use Electron?

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
