import {Injectable} from '@angular/core';
import {PbGroupChat} from '../api/groupchats_pb';
import {GroupUtils} from '../util/group.utils';
import {ChatDbService} from '../chat-db.service';
import {GroupDbService} from '../group-db.service';

@Injectable({
  providedIn: 'root'
})
export class CountersService {

  constructor(
    private chatDbService: ChatDbService,
    private groupDbService: GroupDbService,
  ) {
  }

  onChatCountersUpdate(chat: PbGroupChat, groupChat: boolean) {
    const id = chat.getId();
    const unreadCount = chat.getUnreadMessagesCount();
    const unreadAlertCount = chat.getUnreadAlertMessagesCount();
    const lastViewed = chat.getLastViewed();
    this.chatDbService.onChatCountersUpdate(id, unreadCount, unreadAlertCount, lastViewed);
    const groupId: string = chat.getGroupId();
    this.recomputeGroupCounts(groupChat, groupId);
  }

  recomputeGroupCounts(groupChat: boolean, groupId: string) {
    if (groupChat) {
      this.groupDbService.setGroupUnreadMessages(groupId, this.chatDbService.getGroupUnreadMessagesCount(groupId), this.chatDbService.getGroupUnreadAlertMessagesCount(groupId));
    } else {
      GroupUtils.PEOPLE_GROUP.setUnreadMessagesCount(this.chatDbService.getGroupUnreadMessagesCount(''));
      GroupUtils.PEOPLE_GROUP.setUnreadAlertMessagesCount(this.chatDbService.getGroupUnreadAlertMessagesCount(''));
      this.groupDbService.recomputeHomeUnreadCounts();
    }
  }

}
