export class DelayQueue {
  private inProgress = 0;

  constructor(public delaySeconds: number, public maxQueue: number) {

  }

  enqueue<T>(operation: () => Promise<T>) {
    if (this.inProgress >= this.maxQueue) {
      console.log(`Queue SKIP TASK inProgress=${this.inProgress} max=${this.maxQueue}`);
      return;
    }
    this.inProgress++;
    const seconds = this.calcDelaySeconds();
    console.log(`Queue delay inProgress=${this.inProgress} delaySeconds=${seconds}`);
    setTimeout(() => {
      console.log(`Queue executing operation after delaySeconds=${seconds}`);
      this.exec(operation);
    }, seconds * 1000);
  }

  private calcDelaySeconds(): number {
    return Math.round(this.delaySeconds * this.inProgress * this.inProgress);
  }

  private async exec<T>(operation: () => Promise<T>) {
    try {
      await operation();
      this.inProgress--;
    } catch (e) {
      this.inProgress--;
    }
  }

}
