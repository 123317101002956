export type InfoType = 'TEAM' | 'CHAT' | 'GROUP_CHAT' | '';

export type NotifyBlock = { type: 'success' | 'error'; message: string };

// {type: "NAME_TOO_LONG", description: "name length 104 is over 100"}
interface TeamyErrorObj {
  type: 'UNDEFINED'
    | 'INCORRECT_PASSWORD'
    | 'USER_NOT_FOUND'
    | 'EMAIL_NOT_VERIFIED'
    | 'AUTH_TOKEN_INVALID'
    | 'CHAT_ARCHIVED'
    | 'ID_MISSING'
    | 'SESSION_ID_INVALID'
    | 'RATE_LIMIT'
    | 'NAME_TOO_LONG';
  description: string;
}

export class TeamyError {
  constructor(private call: string, private obj: TeamyErrorObj) {
  }

  isNameTooLong() {
    return this.obj.type === 'NAME_TOO_LONG';
  }
  isAuthTokenInvalid() {
    return this.obj.type === 'AUTH_TOKEN_INVALID';
  }

  public toString = (): string => this.call + ' ' + JSON.stringify(this.obj);
}
