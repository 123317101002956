import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  private timeDelta = 0;

  constructor() {
  }

  setServerTime(millis: number) {
    this.timeDelta = millis - Date.now();
  }

  getCorrectedTime() {
    return Date.now() + this.timeDelta;
  }

}
