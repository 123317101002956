<div class="tm-avatar noselect" *ngIf="groupAvatarUrlFresh">
  <div class="tm-avatar-inner" [ngStyle]="{'background-image': 'url('+groupAvatarUrl+')' }"></div>
</div>

<div class="tm-avatar group-avatar {{staticGroupName}}" *ngIf="!groupAvatarUrl">
  <div class="tm-avatar-inner one-section" *ngIf="groupSize===1">
    <div [style.background-image]="gradients[0]">
      <span>{{labels[0]}}</span>
    </div>
  </div>

  <div class="tm-avatar-inner two-sections" *ngIf="groupSize===2">
    <div [style.background-image]="gradients[0]" *ngIf="!avaUrl(0)"><span>{{labels[0]}}</span></div>
    <div [ngStyle]="{'background-image': 'url('+avaUrl(0)+')' }" *ngIf="avaUrl(0)"></div>

    <div [style.background-image]="gradients[1]" *ngIf="!avaUrl(1)"><span>{{labels[1]}}</span></div>
    <div [ngStyle]="{'background-image': 'url('+avaUrl(1)+')' }" *ngIf="avaUrl(1)"></div>
  </div>

  <div class="tm-avatar-inner four-sections" *ngIf="groupSize > 2">
    <div [style.background-image]="gradients[0]" *ngIf="!avaUrl(0)">
      <span>{{labels[0]}}</span>
    </div>
    <div [ngStyle]="{'background-image': 'url('+avaUrl(0)+')' }" *ngIf="avaUrl(0)"></div>

    <div [style.background-image]="gradients[1]" *ngIf="!avaUrl(1)">
      <span>{{labels[1]}}</span>
    </div>
    <div [ngStyle]="{'background-image': 'url('+avaUrl(1)+')' }" *ngIf="avaUrl(1)"></div>

    <div [style.background-image]="gradients[2]" *ngIf="!avaUrl(2)">
      <span>{{labels[2]}}</span>
    </div>
    <div [ngStyle]="{'background-image': 'url('+avaUrl(2)+')' }" *ngIf="avaUrl(2)"></div>

    <div [style.background-image]="gradients[3]" *ngIf="!avaUrl(3)">
      <span>{{labels[3]}}</span>
    </div>
    <div [ngStyle]="{'background-image': 'url('+avaUrl(3)+')' }" *ngIf="avaUrl(3)"></div>
  </div>
</div>
