<jw-modal id="newtopic-modal" (displayed)="topicNameInput.value=''; topicNameInput.focus();">
  <div class="tm-modal-header">
    <h6>Create new topic</h6>
  </div>
  <div class="tm-modal-body">
    <div class="tm-modal-wrapper" style="width: 330px;">
      <div class="tm-name-input-holder">
        <div class="tm-upload-ava" (click)="fileInput.click()">
          <div class="tm-new-topic-ava" [ngStyle]="{'background-image': 'url('+avatarLink+')' }" ></div>
          <input type="file" style="display: none" accept="image/*" #fileInput (change)="setAvatar(fileInput.files)" />
        </div>
        <div class="tm-input-item">
          <div class="tm-input-item-new">
            <input #topicNameInput autofocus autocomplete="on" spellcheck="false" placeholder="Topic name" maxlength="100"
                   [(ngModel)]="topicName"
                   (keydown.enter)="createTopic()"
                   (keydown.escape)="closeModal('newtopic-modal')"
            />
          </div>
        </div>
      </div>

      <div class="tm-regular-14 tm-private-chat">
        <div class="private-chat-toggle">Only invited teammates
          <span *ngIf="onlyInvitedTeammates">Turned on</span>
          <span *ngIf="!onlyInvitedTeammates">Turned off</span>
        </div>
        <input class="tm-checkbox" type="checkbox" [(ngModel)]="onlyInvitedTeammates" (change)="invitePeopleToPrivate()"/>
        <div class="tm-switcher"></div>
      </div>

      <div class="invited-avatars-holder" *ngIf="invitedTeammates && onlyInvitedTeammates" (click)="invitePeopleToPrivate(true)">
        <div class="members-mini-avas">
          <app-avatar class="members-xs" *ngFor="let member of firstSix" [userId]="member"></app-avatar>
        </div>
        <div class="members-show counter" *ngIf="invitedTeammates.size > 6" [innerHTML]="invitedTeammates.size - 6"></div>
        <div class="members-show" title="Show more"></div>
      </div>

      <div class="clear-fix tm-modal-buttons-holder">
        <button class="tm-btn float-right" (click)="createTopic()" [disabled]="isEmptyChatName()">Create</button>
        <button class="tm-btn tm-btn-transp float-right" (click)="closeAndClearModal()">Cancel</button>
      </div>
    </div>
  </div>
</jw-modal>
