import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {GroupDataService} from '../groups/group-data.service';
import {PbGroup, PbUser} from '../api/groups_pb';
import {PbGroupChat} from '../api/groupchats_pb';
import {GroupChatDataService} from '../groupchats/groupchat-data.service';
import {ChatDataService} from '../chats/chat-data.service';
import {GroupDbService} from '../group-db.service';
import {ChatDbService} from '../chat-db.service';
import {CloudStorageService} from '../cloud-storage.service';
import {TimeUtils} from '../util/time.utils';
import {UserDbService} from '../user-db.service';
import {GrpcDataService} from '../login/grpc-data.service';
import {GroupUtils} from '../util/group.utils';
import {MessageDbService} from '../message-db.service';
import {InfoType, NotifyBlock, TeamyError} from '../types';
import {PbGrpMember} from '../groups/pb-grp-member';


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnChanges {
  @Input() selectedGroup?: PbGroup;
  @Input() selectedChat?: PbGroupChat;
  @Input() infoType: 'TEAM' | 'CHAT' | 'GROUP_CHAT' | '' = '';

  @Output() notifyShowInviteToTeam: EventEmitter<void> = new EventEmitter<void>();
  @Output() notifyShowInviteToChat: EventEmitter<void> = new EventEmitter<void>();
  @Output() infoTypeChanged = new EventEmitter<InfoType>();
  @Output() openPrivateChat = new EventEmitter<PbGroupChat>();
  @Output() navigateToTeam = new EventEmitter<void>();
  @Output() showNotifyBlock = new EventEmitter<NotifyBlock>();
  // @Output() selectedChatHasBeenArchived = new EventEmitter<PbGroup>();

  @ViewChild('inputToFocus', {static: false}) newNameInput?: ElementRef;

  groupPin = false;
  groupNotifications = false;
  groupMembers: PbGrpMember[] = [];
  fiveTeammates: PbGrpMember[] = [];
  selectedChatPin = false;
  selectedChatNotifications = false;
  archivedListVisible = false;
  archivedChats: PbGroupChat[] = [];

  isEditingName = false;
  editedName?: string;

  constructor(protected groupChatDataService: GroupChatDataService,
              protected chatDataService: ChatDataService,
              protected cloudStorageService: CloudStorageService,
              protected chatDbService: ChatDbService,
              protected messageDbService: MessageDbService,
              protected userDbService: UserDbService,
              protected grpcDataService: GrpcDataService,
              protected groupDbService: GroupDbService,
              protected groupDataService: GroupDataService) {
  }

  ngOnInit() {
  }

  isChatPinVisible() {
    return this.infoType === 'GROUP_CHAT' ||
      (this.infoType === 'CHAT' && this.messageDbService.hasCachedMessages(this.selectedChat)); // hide for contacts without actual chat
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isEditingName = false; // auto-cancel editing upon going to another chat
    this.editedName = undefined;

    if (this.infoType !== 'TEAM') {
      this.archivedListVisible = false;
    } else {
      this.showArchivedList(this.archivedListVisible); // if new group is selected and archived chats are visible, reload them
    }
    if (this.selectedGroup) {
      this.groupPin = this.selectedGroup && this.selectedGroup.getPin();
      this.groupNotifications = this.selectedGroup && !this.selectedGroup.getMute();
      this.groupMembers = this.groupDbService.getGroupMembers(this.selectedGroup.getId());
      this.resortGroupMembers();
      this.selectedChatPin = this.selectedChat !== undefined && this.selectedChat.getPin();
      this.selectedChatNotifications = this.selectedChat !== undefined && !this.selectedChat.getMute();
      this.fiveTeammates = this.groupMembers.slice(0, 5);
    }
  }

  resortGroupMembers() {
    this.groupMembers.sort((g1, g2) => {
      const key1 = this.getMemberSortKey(g1);
      const key2 = this.getMemberSortKey(g2);
      if (key1 < key2) {
        return -1;
      }
      if (key1 > key2) {
        return 1;
      }
      return 0;
    });
  }

  getMemberSortKey(m: PbGrpMember) {
    const muser = m.getUser();
    if (!muser || muser.getId() === this.grpcDataService.userId) {
      return ''; // 'you', to the top
    }
    const user = this.findUser(muser.getId());
    if (!user) {
      return '';
    }
    const online = this.isOnline(user);
    if (m.getAdmin() && online) {
      return 'a1 ' + user.getFullname();
    }
    if (online) {
      return 'u1 ' + user.getFullname();
    }

    // offline
    if (m.getAdmin()) {
      return 'a2 ' + (Number.MAX_SAFE_INTEGER - user.getLastactivitytime()); // descending
    }
    return 'u2 ' + (Number.MAX_SAFE_INTEGER - user.getLastactivitytime()); // descending
  }

  sameGroup(a: PbGroup, b: PbGroup) {
    if (!a && !b) {
      return true;
    }
    if (!a || !b) {
      return false;
    }
    return a.getId() === b.getId();
  }

  leaveChat() {
    if (this.selectedChat && this.grpcDataService.userId) {
      this.groupChatDataService.removeMembers(this.selectedChat.getId(), [this.grpcDataService.userId]);
    }
    this.closeInfo();
  }

  closeInfo() {
    this.infoType = '';
    this.infoTypeChanged.emit(this.infoType);
  }

  archiveTopic(topic?: PbGroupChat) {
    if (!topic) {
      return;
    }
    topic.setArchived(true);
    this.showNotifyBlock.emit({
      type: 'success',
      message: `<b>Done!</b> You have archived the topic "${topic.getDescription()}"`
    });
    this.groupChatDataService.update(topic).subscribe((createdChat) => {
    });
    this.closeInfo();
    // this.selectedChatHasBeenArchived.emit(GroupUtils.PEOPLE_GROUP);
    // this.selectedChatHasBeenArchived.emit(this.selectedGroup);
  }

  toggleGroupPin() {
    if (!this.selectedGroup) {
      return;
    }
    this.selectedGroup.setPin(!this.groupPin);
    this.groupDataService.update(this.selectedGroup).subscribe(updatedGroup => {
      console.log('updated group pin');
    });
  }

  toggleGroupMute() {
    if (!this.selectedGroup) {
      return;
    }
    this.selectedGroup.setMute(!!this.groupNotifications);
    this.groupDataService.update(this.selectedGroup).subscribe(updatedGroup => {
      console.log('updated group mute');
    });
  }

  toggleChatPin() {
    if (!this.selectedChat) {
      return;
    }
    this.selectedChat.setPin(!this.selectedChat.getPin());
    this.updateSelectedChat()?.subscribe(updatedChat => {
      // this.notifyResortChats.emit();
    });
  }

  toggleChatMute() {
    if (!this.selectedChat) {
      return;
    }
    this.selectedChat.setMute(!this.selectedChat.getMute());
    this.updateSelectedChat()?.subscribe(updatedChat =>
      console.log('mute flag updated: ' + updatedChat.getMute())
    );
  }

  protected updateSelectedChat() {
    if (!this.selectedChat) {
      return undefined;
    }
    if (this.selectedChat.getGroupId()) {
      return this.groupChatDataService.update(this.selectedChat);
    } else {
      return this.chatDataService.update(this.selectedChat);
    }
  }

  protected updateChat(chat: PbGroupChat) {
    if (chat.getGroupId()) {
      return this.groupChatDataService.updateChat(chat);
    } else {
      return this.chatDataService.updateChat(chat);
    }
  }

  async showArchivedList(show: boolean) {
    if (!this.selectedGroup) {
      return;
    }
    if (show) {
      this.archivedChats = await this.chatDbService.getArchivedChats(this.selectedGroup.getId()) ?? [];
      this.archivedListVisible = true;
    } else {
      this.archivedListVisible = false;
    }
  }

  focusInput() {
    setTimeout(() => this.newNameInput?.nativeElement.focus());
  }

  async saveNewName() {
    if (this.infoType === 'GROUP_CHAT' && this.editedName && this.selectedChat) {
      const clone = this.selectedChat.clone(); // cloneMessage() is a deep clone?
      clone.setDescription(this.editedName);
      try {
        await this.updateChat(clone);
        console.log('saveNewName ' + this.infoType);
        this.isEditingName = false;
      } catch (e) {
        console.log('error saveNewName ' + this.infoType, e);
        this.showNotifyBlock.emit({
          type: 'error',
          message: `<b>Failed!</b> ` + (e instanceof TeamyError && e.isNameTooLong() ? 'Name too long' : 'Unknown reason')
        });
      }
    }
  }

  editNameStart() {
    if (this.infoType === 'GROUP_CHAT') {
      this.editedName = this.selectedChat?.getDescription();
    }
    this.isEditingName = true;
    this.focusInput();
  }

  inviteToTeam() {
    this.notifyShowInviteToTeam.emit();
  }

  inviteToChat() {
    this.notifyShowInviteToChat.emit();
  }

  goChatIfHome() {
    this.navigateToTeam.emit();
  }

  unarchive(archivedChat: PbGroupChat) {
    archivedChat.setArchived(false);
    this.groupChatDataService.update(archivedChat)
      .subscribe((chat) => console.log('unarchived chat', chat.getDescription()));
  }

  async openPrivateChatWith(userId: string) {
    const chat: PbGroupChat = await this.chatDbService.findOrCreatePrivateChat(userId);
    this.openPrivateChat.emit(chat);
  }

  async setAvatar(files: FileList | null) {
    const file = files?.item(0);
    if (file) {
      console.log(`setting avatar file ${file.name} size=${file.size} type=${file.type}`);

      if (file.type.startsWith('image/')) {
        const {id: newId, name: newName} = await this.cloudStorageService.uploadFile('avatar', '', file);
        const prefix = 'sourceId:';
        if (this.infoType === 'GROUP_CHAT') {
          this.selectedChat?.setAvatarUrl(prefix + newId);
          this.updateSelectedChat()?.subscribe(updatedChat =>
            console.log('saveNewAvatar ' + this.infoType)
          );
        }
      }
    }
  }

  getLastSeenStr(user?: PbUser) {
    if (!user || user.getId() === GroupUtils.TEAMY_BOT) {
      return '';
    }
    return TimeUtils.getLastSeenStrOrOnline(user);
  }

  isOnline(user?: PbUser) {
    return TimeUtils.isOnline(user);
    // return user.getLastactivitytime() > new Date().getTime() - 60_000; // one minute. Change to 40 seconds?
  }

  findUser(userId: string) {
    return this.userDbService.findUser(userId);
  }

  isUserByPhone() {
    return !isNaN(Number(this.selectedChat?.getId()));
  }

  isUserByEmail() {
    return !this.isUserByPhone() && this.selectedChat?.getId() !== GroupUtils.TEAMY_BOT;
  }

}
