import {PbGroup, PbRegister} from '../api/groups_pb';
import {SimpleChange, SimpleChanges} from '@angular/core';
import {PbEventSubscribe} from '../api/events_pb';
import {DomSanitizer} from '@angular/platform-browser';
import * as jspb from 'google-protobuf';

export class CommonUtils {
  static CLIENT_NAME = 'Teamy Web 0.8';

  static sameGroup(a: PbGroup, b: PbGroup) {
    if (!a && !b) {
      return true;
    }
    if (!a || !b) {
      return false;
    }
    return a.getId() === b.getId();
  }

  static changedGroup(changes: SimpleChanges) {
    if (changes.group) {
      const change: SimpleChange = changes.group;
      if (!CommonUtils.sameGroup(change.currentValue, change.previousValue)) {
        return true;
      }
    }
    return false;
  }

  static setDeviceName(reg: PbRegister) {
    reg.setClient('web');
    reg.setClientname(this.CLIENT_NAME);
    reg.setDevicename(navigator.appVersion);
  }

  static setDeviceNameUpdate(subscribe: PbEventSubscribe) {
    subscribe.setClientname(this.CLIENT_NAME);
    subscribe.setDevicename(navigator.appVersion);
  }

  static highlightHtml(sanitizer: DomSanitizer, name: string, highlightText: string) {
    // let nameNoTags = sanitizer.sanitize(SecurityContext.HTML, name);
    // console.log('Sanitized: ', name, nameNoTags);
    // nameNoTags = nameNoTags.replace(/[<>]/g, " "); // seems like SecurityContext.HTML does not do enough

    const nameNoTags = name.replace(/[<>]/g, ' '); // seems like SecurityContext.HTML does not do enough
    // let result = nameNoTags.replace(new RegExp(highlightText, "gi"), "<b>" + highlightText + "</b>");


    const result = nameNoTags.replace(new RegExp(this.escapeRegExp(highlightText), 'gi'), '<b style="font-weight: 900">$&</b>');
    // $&	Inserts the matched substring.
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#Specifying_a_string_as_a_parameter


    // console.log('highlightHtml', name, highlightText, result);
    return result;
    //  return this.sanitizer.bypassSecurityTrustHtml(nameNoTags.replace(new RegExp(this.highlightText, "gi"), "<b>" + this.highlightText + "</b>"));
  }

  // https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
  static escapeRegExp(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  static isActive() {
    return document.visibilityState === 'visible' && document.hasFocus();
  }

  static arrayRemove<T>(arr: Array<T>, value: T) {
    return arr.filter((ele) => ele !== value);
  }

  static isUrlFromCache(url?: string) {
    if (url?.startsWith('blob:')) { // produced by URL.createObjectURL(), cached locally
      return true;
    }
    return false;
  }

  static delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  static deleteOneFromArray<T>(arr: Array<T>, cond: (elem: T) => boolean): boolean {
    for (let i = 0; i < arr.length; i++) {
      if (cond(arr[i])) {
        arr.splice(i, 1);
        return true;
      }
    }
    return false;
  }

  static withoutUndefined(myObj: any): any {
    const ret: any = {};
    for (const i in myObj) {
      if (typeof myObj[i] !== 'undefined') {
        ret[i] = myObj[i];
      }
    }
    return ret;
  }

  static protobufToObj(struct: jspb.Message) {
    return CommonUtils.withoutUndefined(struct.toObject());
  }

}
