import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalService} from '../dialog/modal.service';
import {LoginDataService} from '../login/login-data.service';
import {PbGroup, PbGroupMember, PbUser} from '../api/groups_pb';
import {GroupDataService} from '../groups/group-data.service';
import {GroupDbService} from '../group-db.service';
import {UserDbService} from '../user-db.service';
import {PbChatMessage, PbGroupChat} from '../api/groupchats_pb';
import {GroupChatDataService} from '../groupchats/groupchat-data.service';
import {MessageDbService} from '../message-db.service';
import {GroupUtils} from '../util/group.utils';
import {EncryptionService} from '../encryption.service';
import {ChatDbService} from '../chat-db.service';
import {PbGrpMember} from '../groups/pb-grp-member';
import {NotifyBlock} from '../types';
import {ChatUtils} from '../util/chat.utils';

class AlphabetLetter {
  constructor(public letter: string, public users: PbUser[]) {
  }
}

/*@todo rename
 Actually this is not a 'new team' dialog, but umbrella dialog for inviting, creating a team or forwarding a message -
 all the cases where selecting contacts or chats is performed
*/


@Component({
  selector: 'app-chooseusers',
  templateUrl: './choose-users.component.html',
  styleUrls: ['./choose-users.component.scss']
  // encapsulation: ViewEncapsulation.ShadowDom
})
export class ChooseUsersComponent implements OnInit, AfterViewInit {

  constructor(private modalService: ModalService,
              private loginDataService: LoginDataService,
              private userDbService: UserDbService,
              private chatDbService: ChatDbService,
              private messageDbService: MessageDbService,
              private encryptionService: EncryptionService,
              private groupDataService: GroupDataService,
              private groupChatDataService: GroupChatDataService,
              private groupDbService: GroupDbService) {
  }

  get search(): string {
    return this.varSearch;
  }

  set search(value: string) {
    clearTimeout(this.searchInterval);
    this.varSearch = value;

    // debounce time
    this.searchInterval = setTimeout(() => {
      this.initKnownUsers();
      this.knownUsers = this.knownUsers?.filter(user => this.searchFilter(user));
      this.alphabet = this.constructAlphabet();
      if (this.allGroupChats) {
        this.searchFilteredGroupChats = this.allGroupChats.filter(chat => this.searchFilter(chat.getDescription()));
      } else {
        this.searchFilteredGroupChats = undefined;
      }

      if (this.contactListScroll) {
        this.contactListScroll.nativeElement.scrollTop = 0;
      }
    }, 300);
  }

  static DEFAULT_TEAM_NAME = 'New team';
  @Input() selectedGroup?: PbGroup;
  @Input() selectedChat?: PbGroupChat;

  @Output() selectedGroupChanged = new EventEmitter<PbGroup>();
  @Output() notifyContactsCollected = new EventEmitter<Set<string>>();
  @ViewChild('userListWrapper', {static: false}) scrollWrapper?: ElementRef;
  @ViewChild('peopleList', {static: false}) contactListScroll?: ElementRef;
  @ViewChild('bubblesList', {static: false}) bubblesList?: ElementRef;

  inviteToGroup?: PbGroup;
  inviteToChat?: PbGroupChat;
  forwardMessage?: PbChatMessage;

  teamName: string = ChooseUsersComponent.DEFAULT_TEAM_NAME;
  phoneNumberError = false;
  emailError = false;
  private varSearch = '';
  contactsPage = false;
  selectedContacts: Set<string> = new Set<string>(); // userIds or chatIds (in case of message forwarding)
  knownUsers: string[] = [];
  alphabet: AlphabetLetter[] = [];
  groupMembers?: PbGrpMember[];

  allGroupChats?: PbGroupChat[]; // for forwarding a message
  private searchFilteredGroupChats?: PbGroupChat[]; // for forwarding a message

  isNearBottom = true;
  isNearTop = true;
  addNewIconClass = '';
  bubblesOverflowed = false;
  collectUsers = false;

  @Output() showNotifyBlock = new EventEmitter<NotifyBlock>();

  searchInterval?: number;

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  closeModal(id: string) {
    this.modalService.close(id);
    this.clearErrors();

    this.inviteToGroup = undefined;
    this.inviteToChat = undefined;
    this.forwardMessage = undefined;
    this.collectUsers = false;
  }

  selectedPrivateChats(): string[] {
    if (!this.selectedContacts) {
      return [];
    }
    return [...this.selectedContacts].filter(chatId => !this.isGroupChat(chatId)).reverse();
  }

  selectedGroupChats(): PbGroupChat[] {
    if (!this.selectedContacts || !this.allGroupChats) {
      return [];
    }
    return this.allGroupChats.filter(chat => this.selectedContacts?.has(chat.getId())).reverse();
  }

  users(): string[] {
    return this.knownUsers ?? [];
  }

  recentUsers(): string[] {
    return this.knownUsers ? this.knownUsers.slice(0, this.forwardMessage ? 5 : 10) : [];
  }

  // trackAlphabet(index: number, letter: string) {
  //   return letter;
  // }

  trackUserId(index: number, user: PbUser) {
    return user.getId();
  }

  forEachAvailableUser(callbackFn: (user: PbUser) => void) {
    if (this.groupMembers) {
      if (!this.inviteToChat) {
        return;
      }
      if (this.collectUsers) {
        this.groupMembers.map(member => member.getUser()).forEach(callbackFn);
        return;
      }
      this.groupMembers.map(member => member.getUser())
        .filter(user => !this.inviteToChat?.getUsersList().includes(user.getId()))
        .forEach(callbackFn);
    } else {
      const ids = new Set<string>(this.userDbService.usersSeen.keys());
      this.userDbService.usersSeen.forEach(callbackFn);
      this.userDbService.contactsMap?.forEach(contact => {
        if (!ids.has(contact.getId())) {
          const tmpUser = new PbUser();
          tmpUser.setId(contact.getId());
          tmpUser.setFullname(contact.getFullname());
          callbackFn(tmpUser);
        }
      });
    }
  }

  private initKnownUsers() {
    if (this.groupMembers) {
      if (!this.inviteToChat) {
        this.knownUsers = [];
      } else if (this.collectUsers) {
        this.knownUsers = this.groupMembers
          .map(groupMember => groupMember.getUser()?.getId() ?? '');
      } else {
        this.knownUsers = this.groupMembers
          .map(groupMember => groupMember.getUser()?.getId() ?? '')
          .filter(user => !this.inviteToChat?.getUsersList().includes(user));
      }
    } else {
      this.knownUsers = [...new Set([...this.userDbService.usersCache.keys(), ...this.userDbService.contactsMap?.keys() ?? []])]
        .filter(userId => userId !== GroupUtils.TEAMY_BOT);
      this.resortKnownUsers();
      console.log('choose-users: knownUsers', this.knownUsers);
    }
  }

  private constructAlphabet(): AlphabetLetter[] {
    // let t0 = new Date().getTime();
    // let rnd = new Date();
    const map = new Map<string, AlphabetLetter>();
    this.forEachAvailableUser(user => {
      // console.log('alphabet', rnd, user.getId(), user.getFullname());
      if (this.searchFilter(user.getFullname())) {
        const letter = String.fromCodePoint(user.getFullname().trim().codePointAt(0) ?? 0).toUpperCase();
        const alph = map.get(letter);
        if (alph) {
          alph.users.push(user);
        } else {
          map.set(letter, new AlphabetLetter(letter, [user]));
        }
      }
    });
    const res = [...map.values()];
    res.sort((a, b) => a.letter < b.letter ? -1 : (a.letter > b.letter ? 1 : 0));
    for (const alph of res) {
      alph.users.sort((a, b) => a.getFullname().toUpperCase() < b.getFullname().toUpperCase() ? -1 :
        (a.getFullname().toUpperCase() > b.getFullname().toUpperCase() ? 1 : 0));
    }
    // console.log('alphabet() took ms', new Date().getTime() - t0);
    return res;
  }

  // usersByFirstLetter(alpha: string) {
  //   const set: PbUser[] = [];
  //   this.forEachAvailableUser(user => {
  //     if (this.searchFilter(user.getFullname())) {
  //       if (alpha === String.fromCodePoint(user.getFullname().trim().codePointAt(0)).toUpperCase()) {
  //         set.push(user);
  //       }
  //     }
  //   });
  //   set.sort((a, b) => a.getFullname().toUpperCase() < b.getFullname().toUpperCase() ? -1 :
  //     (a.getFullname().toUpperCase() > b.getFullname().toUpperCase() ? 1 : 0));
  //   return set;
  // }

  filteredAllGroupChats(): PbGroupChat[] | undefined {
    if (!this.allGroupChats) {
      return undefined;
    }
    if (!this.varSearch) {
      return this.allGroupChats.slice(0, 4);
    }
    return this.searchFilteredGroupChats;
  }

  toggleSelection(userOrChatId: string) {
    if (this.selectedContacts?.has(userOrChatId)) {
      this.selectedContacts.delete(userOrChatId);
    } else {
      this.selectedContacts?.add(userOrChatId);
    }
  }

  onShowInviteToTeam() {
    this.onShowContactsPopup();
    this.inviteToGroup = this.selectedGroup; // passed from app.component
  }

  async onShowForwardMessage(message: PbChatMessage) {
    this.onShowContactsPopup();
    this.forwardMessage = message;
    const chats = await this.chatDbService.getAll();
    this.allGroupChats = chats
      .filter(chat => GroupUtils.isGroupChat(chat.getId()))
      .sort((a, b) => ChatUtils.compare(false, a, b));
  }

  private resortKnownUsers() {
    const chats = this.chatDbService.getAllCached();
    const lastMessageTimes = new Map<string, number>();
    for (const chat of chats) {
      lastMessageTimes.set(chat.getId(), ChatUtils.lastMessageTime(chat));
    }
    // console.log('onShowForwardMessage users-pre-sort', this.knownUsers);
    this.knownUsers.sort((a: string, b: string) => {
      const timeA = lastMessageTimes.get(a) ?? -1;
      const timeB = lastMessageTimes.get(b) ?? -1;
      if (timeA > timeB) {
        return -1;
      }
      if (timeA < timeB) {
        return 1;
      }
      return 0;
    });
    // console.log('onShowForwardMessage users-post-sort', this.knownUsers);
  }

  isGroupChat(chatId: string) {
    return GroupUtils.isGroupChat(chatId);
  }

  // getBody(msg: PbChatMessage) {
  //   return MessageUtils.getBody(msg);
  // }

  onShowInviteToChat(collectedTemmates?: Set<string>) {
    this.onShowContactsPopup();
    this.knownUsers = [];
    this.inviteToChat = this.selectedChat;
    if (this.inviteToChat) {
      const members = this.groupDbService.getGroupMembers(this.inviteToChat.getGroupId());
      this.groupMembers = members;
    }
    this.initKnownUsers();
    if (collectedTemmates) {
      this.selectedContacts = collectedTemmates;
    }
  }

  onShowInviteToPrivateChat(invitedTeammates: Set<string>) {
    this.collectUsers = true;
    this.onShowInviteToChat(invitedTeammates);
  }

  onShowContactsPopup() {
    this.inviteToGroup = undefined;
    this.inviteToChat = undefined;
    this.groupMembers = undefined;
    this.teamName = ChooseUsersComponent.DEFAULT_TEAM_NAME;
    this.contactsPage = true;
    // this._search = '';
    this.search = ''; // calls setter and constructs alphabet
    this.selectedContacts = new Set<string>();
    // this.initKnownUsers();
    this.focusSearchInput();
  }

  focusSearchInput() {
    setTimeout(() => {
      document.getElementById('usersSearchField')?.focus();
    }, 10);
  }

  // onSearchBackspace() {
  //   const cont = [...this.selectedContacts];
  //   if (cont.length > 0 && !this.varSearch) {
  //     this.selectedContacts.delete(cont[cont.length - 1]);
  //   }
  // }

  newUserFromSearch() {
    if (this.inviteToChat) {
      return undefined; // only from the same group, no new users
    }
    if (this.alphabet && this.alphabet.length) {
      return undefined;
    }
    if (this.varSearch && this.knownUsers.length < 1) {
      const posAt = this.varSearch.indexOf('@');
      const posPlus = this.varSearch.indexOf('+');

      if (posPlus < 0 && !isNaN(Number(this.varSearch))) {
        this.addNewIconClass = 'add-by-phone';
        return '+' + this.varSearch;
      }
      if (posPlus === 0 && !isNaN(Number(this.varSearch))) {
        this.addNewIconClass = 'add-by-phone';
        return this.varSearch;
      }

      if (posAt < 0) {
        this.addNewIconClass = 'add-by-email';
        return this.varSearch + '@gmail.com';
      } else {
        const domain = this.varSearch.split('@').pop();
        if (domain && 'gmail.com'.startsWith(domain)) {
          this.addNewIconClass = 'add-by-email';
          return this.varSearch.split('@').shift() + '@gmail.com';
        }
      }

      return this.varSearch;
    }

    return undefined;
  }

  newUserFromSearchID() {
    return this.newUserFromSearch()?.replace('@', '_') ?? ''; // make userId from email
  }

  addNewUserFromSearch() {
    const userOrChatId = this.newUserFromSearchID();
    if (userOrChatId) {
      this.toggleSelection(userOrChatId);
    }
    console.log('addNewUserFromSearch', userOrChatId, this.selectedContacts);
  }

  validateNewContact() {
    console.log('validateNewContact', this.varSearch);
    const newUser = this.newUserFromSearch();
    if (!newUser) {
      return false;
    }

    if (newUser.startsWith('+') && (this.varSearch.length < 8 || this.varSearch.length > 16)) {
      this.phoneNumberError = true;
      return false;
    }

    if (!newUser.startsWith('+') && !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(newUser))) {
      this.emailError = true;
      return false;
    }

    this.addNewUserFromSearch();
    return undefined;
  }

  clearErrors() {
    this.phoneNumberError = false;
    this.emailError = false;
  }

  searchFilter(s: string): boolean {
    return !!s && s.toUpperCase().includes(this.varSearch.toUpperCase());
  }

  async forward() {
    if (!this.selectedContacts || !this.forwardMessage) {
      return;
    }
    for (const chatId of this.selectedContacts) {
      const copyMsg: PbChatMessage = this.forwardMessage.cloneMessage() as PbChatMessage;
      copyMsg.setId('id-web-' + new Date().getTime());
      copyMsg.setChatid(chatId);
      copyMsg.setAuthor(copyMsg.getFrom()); // forwarded message
      copyMsg.setQuote(undefined); // clear intentionally, TEAMYWEB-312 Forward messages. Sent only messages without "Reply" structure
      await this.encryptionService.encryptMessage(copyMsg, copyMsg.getBody());
      copyMsg.setBody('');
      await this.messageDbService.sendMessage(GroupUtils.isGroupChat(chatId), copyMsg, false);
    }
    this.closeModal('newteam-modal');
  }

  async invite() {
    if (this.inviteToGroup) {
      await this.executeInviteToGroup();
    } else if (this.inviteToChat) {
      await this.executeInviteToChat();
    }
  }

  returnSelectedContacts() {
    this.notifyContactsCollected.emit(this.selectedContacts);
    this.closeModal('newteam-modal');
    this.collectUsers = false;
  }

  private async executeInviteToChat() {
    if (this.inviteToChat && this.selectedContacts) {
      await this.groupChatDataService.addMembers(this.inviteToChat.getId(), [...this.selectedContacts]);
      this.closeModal('newteam-modal');
      this.inviteToChat = undefined;
      this.showNotifyBlock.emit({
        type: 'success',
        message: '<b>Well done!</b> ' + this.selectedContacts.size + ' users added to the chat'
      });
    }
  }

  private async executeInviteToGroup() {
    const toGroup = this.inviteToGroup;
    if (toGroup && this.selectedContacts) {
      await this.groupDataService.addMembers(toGroup.getId(), [...this.selectedContacts]);
      this.closeModal('newteam-modal');
      await this.groupDbService.loadGroups();
      this.selectedGroupChanged.emit(toGroup);
      this.inviteToGroup = undefined; // component is not destroyed, only hidden and group might disappear if user later leaves the group
      this.showNotifyBlock.emit({
        type: 'success',
        message: '<b>Well done!</b> ' + this.selectedContacts.size + ' users added to the team'
      });
    }
  }

  createGroup() {
    if (this.selectedContacts && this.loginDataService.loginUser) {
      this.groupDataService.create(this.teamName, [this.loginDataService.loginUser, ...this.selectedContacts]).subscribe((createdGroup) => {
        this.groupCreated(createdGroup);
      });
    }
  }

  private async groupCreated(createdGroup: PbGroup) {
    this.closeModal('newteam-modal');
    await this.groupDbService.loadGroups();
    this.selectedGroupChanged.emit(createdGroup);
    this.showNotifyBlock.emit({
      type: 'success',
      message: '<b>Well done!</b> You created a team with ' + this.selectedContacts?.size + ' people.'
    });
  }

  closeDialog() {
    this.inviteToGroup = undefined; // component is not destroyed, only hidden and group might disappear if user later leaves the group
    this.closeModal('newteam-modal');
  }

  private isUserNearBottom(): boolean {
    const threshold = 80;
    const position = this.contactListScroll?.nativeElement.scrollTop + this.contactListScroll?.nativeElement.offsetHeight;
    const height = this.contactListScroll?.nativeElement.scrollHeight;
    return position > height - threshold;
  }

  private isUserNearTop(): boolean {
    return this.contactListScroll?.nativeElement.scrollTop < 30;
  }

  scrolled(event: any): void {
    this.isNearBottom = this.isUserNearBottom();
    this.isNearTop = this.isUserNearTop();
  }

  scrollBubbles() {
    this.bubblesOverflowed = this.bubblesList?.nativeElement.scrollTop > 2;
  }

  clearSearch() {
    this.search = '';
    this.focusSearchInput();
  }
}
