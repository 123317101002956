import {Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {LoginDataService} from './login-data.service';
import {PbAuthResult, PbRegister} from '../api/groups_pb';
import {TeamyAccount} from './teamy-account';
import {EncryptionService} from '../encryption.service';
// import {computeStyle} from '@angular/animations/browser/src/util';
import {DomSanitizer} from '@angular/platform-browser';
import {AnimationOptions} from 'ngx-lottie';
import ClientConfig = gapi.auth2.ClientConfig;

declare const gapi: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  // providers: [LoginDataService] - commonted out to make the service singleton
})
export class LoginComponent implements OnInit {
  @Input() loggingIn = false;
  @Input() linkEmail?: string;
  @Input() showVerificationBlock?: string;

  @ViewChild('userFullName', {static: true}) userFullName?: ElementRef;

  loginUser?: string;
  signUpUsername?: string;
  loggedIn = false;
  pin1 = '';
  pin2 = '';
  pin3 = '';
  pin4 = '';
  pin5 = '';
  pin6 = '';
  showBlock?: string;
  // avatarFileUrl: any;
  isGoogleSignIn?: string;
  verificationMessage?: string;
  verificationMessageType = '';
  waitingTimerInProgress = false;

  options: AnimationOptions = {
    path: 'assets/anim/loading-round.json',
    //renderer: 'canvas',
    autoplay: true,
    loop: true
  };
  private anim: any;

  // tslint:disable:new-parens
  constructor(private loginDataService: LoginDataService,
              private encryptionService: EncryptionService,
              private sanitizer: DomSanitizer,
              private zone: NgZone) {
    console.log('gapi=', gapi);
    gapi.load('auth2', () => {
      // eslint-disable-next-line new-parens
      gapi.auth2.init(new class implements ClientConfig {
      });
    });
  }

  async googleLogin() {
    const googleAuth: gapi.auth2.GoogleAuth = await gapi.auth2.getAuthInstance();
    const googleUser = await googleAuth.signIn({scope: 'profile email', prompt: 'select_account'});
    this.loginUser = googleUser.getBasicProfile().getEmail();
    const idToken: string = googleUser.getAuthResponse(true).id_token;
    console.log('User verified by google: ', idToken, googleUser.getBasicProfile());
    const account = await this.loginDataService.register(idToken, '');

    if (!account) {
      this.goToEnterSigninCode();
      this.isGoogleSignIn = idToken;

      // const code = prompt('Please enter the verification code you should have now received in your already connected Teamy client', '');
      // account = await this.loginDataService.register(idToken, code);
    } else {
      // Note: without using 'zone', app-component wouldn't refresh, obstinately staying in the non-logged-in state
      this.zone.run(() => {
        this.loginDataService.signIn(account);
        this.loggedIn = this.loginDataService.loggedIn;
      });
    }
  }

  ngOnInit() {
    if (!navigator.onLine) {
      this.showBlock = 'select-signin-type';
      alert('Offline mode. Please connect to the Internet to continue.');
      // obviously this check is not enough
      // something needs to be done to the Angular service worker
      // https://stackoverflow.com/questions/66638934/angular-pwa-with-firebase-page-does-not-work-offline
      return;
    }
    if (!this.loginDataService.everLoggedIn) {
      setTimeout(() => {
        this.loginDataService.localStorageSignIn().then(result => {
          if (result) {
            console.log('successful localStorageSignIn()');
            this.loggedIn = this.loginDataService.loggedIn;
          } else {
            document.querySelector('#loading-teamy')?.remove();
          }
        });
      }, 0);
    }

    if (this.loggingIn) {
      this.showValidationBlock('validation-loading');
    } else {
      this.showBlock = 'select-signin-type';
    }
  }

  isPinDisabled(pin: number) {
    if (!this.pin1.length) {
      return true;
    }
    if (pin === 2) {
      return false;
    }
    if (!this.pin2.length) {
      return true;
    }
    if (pin === 3) {
      return false;
    }
    if (!this.pin3.length) {
      return true;
    }
    if (pin === 4) {
      return false;
    }
    if (!this.pin4.length) {
      return true;
    }
    if (pin === 5) {
      return false;
    }
    if (!this.pin5.length) {
      return true;
    }
    if (pin === 6) {
      return false;
    }
    return undefined;
  }

  postSignInWithCode() {
    setTimeout(() => this.signInWithCode(), 0);
  }

  async signInWithCode() {
    const email = this.loginUser;
    const code = this.pin1 + this.pin2 + this.pin3 + this.pin4 + this.pin5 + this.pin6;
    console.log(`signInWithCode code=[${code}]`);
    if (code.length !== 6 || !email) {
      return;
    }

    this.showValidationBlock('validation-loading');
    this.clearCode();

    let account: TeamyAccount | undefined;
    if (this.isGoogleSignIn) {
      account = await this.loginDataService.register(this.isGoogleSignIn, code);
    } else {
      account = await this.loginDataService.approveDevice(email, code);
    }


    if (!account) {
      setTimeout(() => {
        this.goBlock('code-validation-error');
      }, 300);
      return;
    } else {
      setTimeout(() => {
        this.showValidationBlock('validation-ok');
      }, 300);
    }

    // Note: without using 'zone', app-component wouldn't refresh, obstinately staying in the non-logged-in state
    const constAccount = account;
    this.zone.run(() => {
      this.loginDataService.signIn(constAccount);
      this.loggedIn = this.loginDataService.loggedIn;
    });
  }

  private clearCode() {
    this.pin1 = '';
    this.pin2 = '';
    this.pin3 = '';
    this.pin4 = '';
    this.pin5 = '';
    this.pin6 = '';
  }

  async signIn() {
    const email = this.loginUser;
    console.log('Email=' + email);
    if (!email) {
      return;
    }
    this.clearCode();
    let loginResult: PbAuthResult = await this.loginDataService.login(email);
    if (loginResult.getNeedapprovedevice()) {
      if (this.showBlock !== 'enter-signin-code') {
        this.goToEnterSigninCode();
        return;
      }
    } else if (loginResult.getNeedverifyemail()) {
      // will process this case in a loop below
    } else {
      if (this.showBlock !== 'avatar-and-username' || !this.signUpUsername) {
        this.goBlock('avatar-and-username');
        this.setFocusToInput('signUpUsername');
        return;
      }

      loginResult = await this.loginDataService.registerEmail(email, this.signUpUsername);
      if (!loginResult.getNeedverifyemail()) {
        alert('Oops... something went wrong. Account already exists.');
        return;
      }
    }

    if (loginResult.getNeedverifyemail()) {
      this.goBlock('email-verification');
      return; // use must click a link in email at this point, no direct code entering possible by UI design
    }

    // @todo this place in code is unreachable??

    // let account: TeamyAccount;
    // this.loginDataService.signIn(account);
    this.loggedIn = this.loginDataService.loggedIn;
  }

  async resendMagicLink() {
    if (this.waitingTimerInProgress) {
      return;
    }
    const res = await this.sendMagicLink();
    if (res) {
      this.verificationMessage = 'Magic link has been sent again';
      this.verificationMessageType = 'success';
      setTimeout(() => this.verificationMessage = '', 4000);
    }
  }

  async resendInstructions() {
    if (this.waitingTimerInProgress) {
      return;
    }

    this.verificationMessage = '';
    let email = this.loginUser;
    if (!email) {
      email = this.linkEmail;
    }
    console.log('Email=' + email);
    if (!email || !this.signUpUsername) {
      return;
    }
    const loginResult = await this.loginDataService.registerEmail(email, this.signUpUsername);

    setTimeout(() => this.verificationMessage = '', 4000);

    if (!loginResult.getNeedverifyemail()) {
      this.verificationMessage = 'Oops... something went wrong. Account already exists?';
      this.verificationMessageType = 'error';
      return;
    }
    if (loginResult.getResenddelayseconds()) {
      this.resendTimer(loginResult.getResenddelayseconds());
      // this.verificationMessage = `Please wait ${loginResult.getResenddelayseconds()} seconds before resending`;
      // this.verificationMessageType = 'info';
      return;
    }
    this.verificationMessage = 'Verification link has been sent again';
    this.verificationMessageType = 'success';
  }

  goToEnterSigninCode() {
    this.goBlock('enter-signin-code');
    this.setFocusToInput('pin1');
  }

  goBlockSigninViaEmail() {
    this.isGoogleSignIn = undefined;
    this.goBlock('signin-via-email');
    this.setFocusToInput('loginUser');
  }

  goBlock(blockName: string) {
    this.clearCode(); // should never preserve, changes with every attempt
    this.showBlock = blockName;
    this.showVerificationBlock = '';
  }

  showValidationBlock(blockName: string) {
    this.showBlock = '';
    this.showVerificationBlock = blockName;
  }

  // focusNextField(e) {
  //   if (/^[0-9]$/.test(e.key)) {
  //     //if (isNaN(1 * e.key)) return;
  //     //console.log(`e.key=[${e.key}]`);
  //     setTimeout(() => e.target.nextSibling.focus(), 0);
  //   }
  // }

  codeBackspace(e: Event) {
    const input = e.target as HTMLInputElement;
    input.value = '';
    input.dispatchEvent(new Event('input'));
    if (input.previousSibling) {
      const previousSibling = input.previousSibling as HTMLInputElement;
      previousSibling.value = '';
      previousSibling.dispatchEvent(new Event('input'));
      previousSibling.focus();
    }
  }

  // get diagnostic() { return JSON.stringify(this); }

  resendTimer(timeLeft: number) {
    const resendLinkTimer = setInterval(() => {
      if (timeLeft <= 0) {
        clearInterval(resendLinkTimer);
        this.verificationMessage = '';
        this.waitingTimerInProgress = false;
      } else {
        this.verificationMessage = `Please wait ${timeLeft} seconds before resending`;
        this.verificationMessageType = 'info';
        timeLeft -= 1;
        this.waitingTimerInProgress = true;
      }
    }, 1000);
  }

  async sendMagicLink() {
    let email = this.loginUser;
    if (!email) {
      email = this.linkEmail;
    }
    if (!email) {
      return false;
    }
    console.log('sendMagicLink', email);
    const res = await this.loginDataService.sendMagicLink(email);

    if (res.getResenddelayseconds()) {
      this.resendTimer(res.getResenddelayseconds());
      return false;
    }

    const request = new PbRegister();
    await this.encryptionService.generateKeysAtRegistration(request);
    request.setEmail(email);

    setTimeout(() => {
      this.goBlock('magic-sent');
    }, 0);

    console.log('login.component sendMagicLink.result=', res.toObject());
    return res;
  }

  // async setAvatarFile(files: FileList) {
  //   const file: File = files.item(0);
  //   if (file) {
  //     console.log(`setAvatarFile ${file.name} size=${file.size} type=${file.type}`);
  //
  //     if (file.type.startsWith('image/')) {
  //       this.avatarFileUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
  //     }
  //   }
  // }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  setFocusToInput(nodeId: string) {
    setTimeout(() => {
      document.getElementById(nodeId)?.focus();
    }, 10);
  }
}
