import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PbContact, PbUser} from '../api/groups_pb';
import {AvatarDataService} from './avatar-data.service';
import {PbGroupChat} from '../api/groupchats_pb';
import {CloudStorageService} from '../cloud-storage.service';
import {UserDbService} from '../user-db.service';
import {GroupUtils} from '../util/group.utils';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() userId?: string;
  @Input() groupChat?: PbGroupChat;
  @Input() contact?: PbContact;

  imageUrl = '';
  gradient = '';
  debugMap = new Map<string, string>();

  constructor(private userDbService: UserDbService,
              private avatarDataService: AvatarDataService,
              private cloudStorageService: CloudStorageService) {
  }

  ngOnInit() {
  }

  debugString() {
    return [...this.debugMap].toString();
  }

  isPhone(userId: string) {
    return userId.match(/^[+][0-9][0-9]+/);
  }

  phoneLabel(userIdPhone: string) {
    return userIdPhone.substr(1, 2);
  }

  get label(): string {
    if (this.contact) {
      this.debugMap.set('co', this.contact.getFullname());
      return this.avatarDataService.getCharacter(this.contact.getFullname(), 2);
    }
    if (this.groupChat) {
      this.debugMap.set('gr', this.groupChat.getDescription());
      return this.imageUrl ? '' : this.avatarDataService.getCharacter(this.groupChat.getDescription(), 1);
    }

    if (!this.userId) {
      this.debugMap.set('n', '');
      return '';
    }
    if (this.isPhone(this.userId)) {
      this.debugMap.set('p', this.phoneLabel(this.userId));
      return this.phoneLabel(this.userId);
    }
    const cachedContact = this.userDbService.findContact(this.userId);
    if (cachedContact) {
      this.debugMap.set('ca', cachedContact.getFullname());
      return this.avatarDataService.getCharacter(cachedContact.getFullname(), 2);
    }

    const user = this.userDbService.findUser(this.userId);
    if (!user) {
      this.debugMap.set('n2', '');
      return '';
    }
    return user ? (this.imageUrl ? '' : this.avatarDataService.getCharacter(this.avatarDataService.userName(user), 2)) : '';

    // return Observable.create(observer => {
    //   observer.next("#" + this.userId);
    // })
  }

  get freshGradient(): string {
    if (!this.contact) {
      this.findImageUrl();
    }
    this.computeGradient();
    return this.gradient;
  }

  computeGradient() {
    if (this.contact) {
      this.gradient = this.avatarDataService.getLinearGradientByName(this.contact.getFullname());
      return;
    }
    if (this.groupChat) {
      this.gradient = (this.imageUrl ? `url("${this.imageUrl}")` : this.avatarDataService.getLinearGradientByName(this.groupChat.getDescription()));
      return;
    }

    if (!this.userId) {
      this.gradient = '';
      return;
    }
    if (this.userId === GroupUtils.TEAMY_BOT) {
      this.gradient = (`url("${GroupUtils.TEAMY_BOT_AVATAR_URL}")`);
      return;
    }
    const cachedContact = this.userDbService.findContact(this.userId);
    if (cachedContact) {
      this.gradient = this.avatarDataService.getLinearGradientByName(cachedContact.getFullname());
      return;
    }

    const user = this.userDbService.findUser(this.userId);
    if (!user) {
      this.gradient = '';
      return;
    }

    if (user.getAvatarurl()) {
      const url = this.cloudStorageService.getAvatarFinalUrl(user.getAvatarurl());
      if (url) {
        this.gradient = `url("${url}")`;
      }
      return;
    } else {
      this.gradient = this.avatarDataService.getLinearGradient(user);
      return;
    }

    // return pbUserSubject.pipe(
    //   map((user: PbUser) => user ? (this.imageUrl ? `url("${this.imageUrl}")` : this.avatarDataService.getLinearGradient(user)) : '')
    // );

    // return "linear-gradient(blue, yellow)";
  }

  private findImageUrl() {
    if (this.groupChat) {
      // console.log(`chat ${this.groupChat.getId()} ${this.groupChat.getDescription()} avatarUrl=${this.groupChat.getAvatarurl()}`);
      this.imageUrl = this.cloudStorageService.getAvatarFinalUrl(this.groupChat.getAvatarUrl());
      return;
    }
    if (!this.userId) {
      return;
    }
    // console.log('findImageUrl()', this.userId, GroupUtils.TEAMY_BOT);
    if (this.userId === GroupUtils.TEAMY_BOT) {
      // console.log('findImageUrl() BOT', this.userId);
      this.imageUrl = GroupUtils.TEAMY_BOT_AVATAR_URL;
      // console.log(this.imageUrl);
      return;
    }
    const user = this.userDbService.findUser(this.userId);
    if (!user) {
      return;
    }
    const avatarId = user.getAvatarurl();
    if (avatarId) {
      this.imageUrl = this.cloudStorageService.getAvatarFinalUrl(avatarId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
//    console.log("avatar ngOnChanges", this.groupChat, this.userId);
    if (!this.contact) {
      this.findImageUrl();
    }
    this.computeGradient();
  }

  // ngOnDestroy() {
  //   // prevent memory leak when component destroyed
  //   this.subscription.unsubscribe();
  // }
}
