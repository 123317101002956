import {Injectable} from '@angular/core';
import {Empty, PbContact, PbContactList, PbUser, PbUserFind, PbUserList} from './api/groups_pb';
import {UserServiceClient} from './api/groups_pb_service';
import {GrpcDataService} from './login/grpc-data.service';
import {MessageFormatService} from './message-format.service';
import {GroupUtils} from './util/group.utils';

@Injectable({
  providedIn: 'root'
})
export class UserDbService /*implements OnInit - https://github.com/angular/angular/issues/23235*/ {

  userService: UserServiceClient = new UserServiceClient(this.grpcDataService.grpcURL, undefined);

  contactsMap?: Map<string, PbContact>;
  contactsList?: PbContact[];
  usersCache: Map<string, PbUser> = new Map();
  usersSeen: Map<string, PbUser> = new Map();
  isCardViewEnabled = localStorage.getItem('cardView') === 'true';

  rainbowChatBubbles = true;

  constructor(
    private grpcDataService: GrpcDataService,
    private messageFormatService: MessageFormatService,
  ) {
    console.log('UserDbService constructor');
    // setTimeout(() => this.testContacts(), 5000);
  }

  // ngOnInit(): void {
  //   console.log('UserDbService init');
  //   this.testContacts();
  // }

  // private async testContacts() {
  //   let contacts = new PbContactList();
  //   contacts.addContact(new PbContact());
  //   contacts.addContact(new PbContact());
  //   contacts.getContactList()[0].setId("+38096111111");
  //   contacts.getContactList()[0].setFullname("big name");
  //   contacts.getContactList()[1].setId("+38096111112");
  //   contacts.getContactList()[1].setFullname("big name2");
  //   await this.grpcDataService.call(this.userService, this.userService.updateContacts, contacts);
  //
  //   let res = await this.grpcDataService.call<Empty, PbContactList>(this.userService, this.userService.getContacts, new Empty());
  //   console.log('testContacts', res.toObject());
  //
  // }

  async getContacts() {
    if (!this.contactsMap) {
      const res = await this.grpcDataService.call<Empty, PbContactList>(this.userService, this.userService.getContacts, new Empty());
      const contactsCache = res.getContactList();
      this.contactsMap = new Map();
      for (const c of contactsCache) {
        this.contactsMap.set(c.getId(), c);
      }
    }
    this.makeContactsList();
    return this.contactsList;
  }

  private makeContactsList() {
    if (this.contactsMap) {
      this.contactsList = [...this.contactsMap.values()].sort((a, b) => this.compareContacts(a, b));
      // for (let i = 0; i < 5; i++) { // artificial size multiplication for performance testing
      //   this.contactsList = [...this.contactsList, ...this.contactsList];
      // }
      console.log('contactsList size', this.contactsList.length);
    }
  }

  private compareContacts(a: PbContact, b: PbContact) {
    if (a.getUserid() && !b.getUserid()) {
      return -1;
    } // contacts with userId (already registered in Teamy) first
    if (!a.getUserid() && b.getUserid()) {
      return 1;
    }

    if (a.getFullname().toLowerCase() < b.getFullname().toLowerCase()) {
      return -1;
    } // alphabetically
    if (a.getFullname().toLowerCase() > b.getFullname().toLowerCase()) {
      return 1;
    }
    return 0;
  }

  getCachedContacts() {
    return this.contactsList;
  }

  updateContacts(upd: PbContact[]) {
    if (this.contactsMap) {
      for (const c of upd) {
        this.contactsMap.set(c.getId(), c);
      }
    }
    this.makeContactsList();
  }

  deleteContacts(del: PbContact[]) {
    if (this.contactsMap) {
      for (const c of del) {
        this.contactsMap.delete(c.getId());
      }
    }
    this.makeContactsList();
  }

  findContact(id: string) {
    if (this.contactsMap) {
      return this.contactsMap.get(id);
    }
    return undefined;
  }

  updateUser(user: PbUser) {
    // if (this.usersCache.has(user.getId())) {
    this.usersCache.set(user.getId(), user);
    console.log('updated user ' + user.getId() + '  fullname=' + user.getFullname());
    // }
  }

  async findServerUser(id: string) {
    const userFind = new PbUserFind();
    userFind.addUser(id);
    const res: PbUserList = await this.grpcDataService.call<PbUserFind, PbUserList>(this.userService, this.userService.find, userFind);
    if (res.getUserList().length > 0) {
      const user = res.getUserList()[0];
      this.usersCache.set(id, user);
      return user;
    }
    return undefined;
  }

  precacheInitialUsers(users: Array<PbUser>) {
    for (const user of users) {
      this.cacheUser(user.getId(), user);
    }
  }

  async precacheUsers(userIds: Set<string>) {
    const newUserIds = [...userIds]
      .filter(userId => !!userId)
      .filter(userId => !userId.includes('@'))
      .filter(userId => !this.usersCache.get(userId));
    const userFind = new PbUserFind();
    for (const userId of newUserIds) {
      const userInit = new PbUser();
      userInit.setId(userId);
      this.usersCache.set(userId, userInit);
      const phoneUser = this.findPhone(userId);
      if (phoneUser) {
        this.usersCache.set(userId, phoneUser);
      } else {
        userFind.addUser(userId);
      }
    }
    if (!userFind.getUserList().length) {
      return;
    }
    console.log('precacheUsers new set', userFind.getUserList());
    const result = await this.findUsersList(userFind);
    for (const userId of userFind.getUserList()) {
      this.cacheUser(userId, result.get(userId));
    }
  }

  // async findUserFresh(userId: string) {
  //   const userFind = new PbUserFind();
  //   userFind.addUser(userId);
  //   const res = await this.findUsersList(userFind);
  //   return res.get(userId);
  // }

  private async findUsersList(userFind: PbUserFind) {
    const res: PbUserList = await this.grpcDataService.call<PbUserFind, PbUserList>(this.userService, this.userService.find, userFind);
    const result = new Map<string, PbUser>();
    for (const user of res.getUserList()) {
      result.set(user.getId(), user);
    }
    return result;
  }

  private findPhone(userId: string) {
    if (!userId.includes('_') && userId.startsWith('+')) {
      console.log('findUser skipped (a phone number)', userId); // probably a phone number, like +380677485947 - never present on server because we currently register only with an email
      const user = new PbUser(); // dummy
      user.setId(userId);
      user.setFullname(this.contactsMap?.get(userId)?.getFullname() ?? userId);
      return user;
    }
    return null;
  }

  async findOrLoadUser(userId: string) {
    await this.precacheUsers(new Set<string>([userId]));
    return this.findUser(userId);
  }

  findUser(userId: string): PbUser | undefined {
    if (userId.includes('@')) {
      return undefined;
    }
    if (!this.usersCache.get(userId)) {
      // to avoid hitting server with a lot of repeated requests, only ever request user info once
      const userInit = new PbUser();
      userInit.setId(userId);
      this.usersCache.set(userId, userInit);
      const phoneUser = this.findPhone(userId);
      if (phoneUser) {
        this.usersCache.set(userId, phoneUser);
      } else {
        // enqueue the actual gRPC query
        const userFind = new PbUserFind();
        userFind.addUser(userId);
        this.grpcDataService.call<PbUserFind, PbUserList>(this.userService, this.userService.find, userFind).then(userList => {
          this.cacheUser(userId, userList?.getUserList().length === 0 ? undefined : userList?.getUserList()[0]);
        });
      }

    }
    return this.usersCache.get(userId);
  }

  isNotRegisteredCached(userId: string) {
    const user = this.usersCache.get(userId);
    if (user) {
      return !user.getRegistered();
    }
    return false;
  }

  private cacheUser(userId: string, serverUser?: PbUser) {
    if (serverUser && userId === serverUser.getFullname()) {
      const email = userId.replace('_', '@'); // make email from userId
      serverUser.setFullname(email);
    }
    let user: PbUser;
    if (!serverUser) {
      user = new PbUser(); // dummy
      user.setId(userId);
    } else {
      user = serverUser;
      // console.log(this.myId + ' findUser gRPC ' + userId + " fullName=" + user.getFullname());
    }
    if (!user.getFullname()) {
      const pbContact = this.contactsMap?.get(userId);
      if (pbContact) {
        user.setFullname(pbContact.getFullname());
      }
    }
    // if (/*user.getFullname().includes("John") ||*/ userId === '4shared.mob_gmail.com') {
    //   console.log("user full name " + userId + " " + user.getFullname());
    // }

    // obs.next(user);
    this.messageFormatService.cacheUserFullName(user.getId(), user.getFullname(), user.getGivenname());
    if (user.getId() !== GroupUtils.TEAMY_BOT) {
      this.usersSeen.set(user.getId(), user);
    }
    // if(!user.getFullname()){
    //   debugger;
    // }
    this.usersCache.set(userId, user);
  }

}
