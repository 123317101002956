import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PbGroup, PbGroupMember, PbUser} from '../api/groups_pb';
import {AvatarDataService} from '../avatar/avatar-data.service';
import {CloudStorageService} from '../cloud-storage.service';
import {CommonUtils} from '../util/common.utils';
import {GroupDbService} from '../group-db.service';
import {GroupUtils} from '../util/group.utils';
import {PbGrpMember} from '../groups/pb-grp-member';

@Component({
  selector: 'app-groupavatar',
  templateUrl: './groupavatar.component.html',
  styleUrls: ['../avatar/avatar.component.scss']
})
export class GroupavatarComponent implements OnInit, OnChanges {
  @Input() group?: PbGroup;

  groupSizeVal = 0;
  staticGroupName?: string;

  groupAvatarUrl?: string;
  gradients: string[] = ['', '', '', ''];
  labels: string[] = ['', '', '', ''];
  previousGroupMembers?: PbGrpMember[];
  groupMembersSorted: PbGrpMember[] = [];

  // dbg: string = '';

  constructor(private groupDbService: GroupDbService,
              private avatarDataService: AvatarDataService,
              private cloudStorageService: CloudStorageService) {
  }

  ngOnInit() {
  }

  avaUrl(memberNumber: number) {
    if (memberNumber >= this.groupMembersSorted.length) {
      return '';
    }
    const user = this.groupMembersSorted[memberNumber].getUser();
    if (user.getAvatarurl()) {
      return this.cloudStorageService.getAvatarFinalUrl(user.getAvatarurl());
    }
    return '';
  }

  get groupAvatarUrlFresh(): string {
    this.refreshGroupMembers();
    this.refreshGroup();
    return this.groupAvatarUrl ?? '';
  }

  get groupSize() {
    if (!this.staticGroupName) {
      this.refreshGroupMembers();
    }
    return this.groupSizeVal;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!CommonUtils.changedGroup(changes)) {
      return; // @todo too strong of a condition? avoids blinking but will show stale avatar sometimes?
    }

    if (this.group?.getId() === GroupUtils.HOME_GROUP_ID) {
      this.staticGroupName = 'home-section';
      return;
    }
    if (this.group?.getId() === GroupUtils.PEOPLE_GROUP_ID) {
      this.staticGroupName = 'people-section';
      return;
    }
    this.gradients = ['', '', '', ''];
    this.labels = ['', '', '', ''];
    this.groupMembersSorted = [];
    this.refreshGroupMembers();
    this.refreshGroup();
  }

  private refreshGroup() {
    const avatar = this.group?.getAvatarUrl();
    if (avatar) {
      // console.log('group avatar ID: ' + avatar);
      this.groupAvatarUrl = this.cloudStorageService.getAvatarFinalUrl(avatar);
    } else {
      this.groupAvatarUrl = undefined;
    }
  }

  // tslint:disable:no-bitwise
  /* eslint-disable no-bitwise */
  private sortPos(user: PbUser) {
    // avatars first, exact order unspecified, using hash to have some fixed visual picture
    if (user.getAvatarurl()) {
      return 0x10000 + (this.avatarDataService.hashCode(user.getAvatarurl()) & 0xffff);
    }
    return 0x20000 + (this.avatarDataService.hashCode(user.getEmail()) & 0xffff);
  }

  private refreshGroupMembers() {
    if (!this.group) {
      return;
    }
    let members = this.groupDbService.getGroupMembers(this.group.getId());
    if (this.previousGroupMembers === members) {
      return;
    }
    this.previousGroupMembers = members;
    this.groupSizeVal = members.length;
    // console.log(` group ${this.group.getId()} size=${this.groupSize}`);

    members = [...members]; // make a copy for sorting
    members = members.sort((a, b) => this.sortPos(a.getUser()) - this.sortPos(b.getUser()));
    this.groupMembersSorted = members;
    // this.dbg = members.map(m => this.sortPos(m.getUser()).toString(16)).join('__');

    // @todo sort members to have people with defined photo avatars at the top, and get those avatars from 4sync
    for (let i = 0; i < 4; i++) {
      if (members.length > i) {
        const user = members[i].getUser();
        this.gradients[i] = this.avatarDataService.getLinearGradient(user);
        this.labels[i] = this.avatarDataService.getCharacter(user.getFullname(), 2);
      }
    }
  }
}
