<div class="media-preview-header" *ngIf="message">
    <div class="media-header-holder">
      <div class="media-meta">
        <app-avatar class="media-ava" [userId]="messageAuthor"></app-avatar>
        <div class="media-name">{{imageName}}</div>
        <div class="media-owner"><app-fullname [userId]="messageAuthor"></app-fullname> <span>{{formatDateLastMessage(message)}}</span></div>
      </div>
      <div class="media-actions">
        <div class="media-action-button media-forward" (click)="data.showForwardMessage(message)">Forward</div>
        <div class="media-action-button media-download" (click)="downloadMedia()">Download</div>
        <div class="media-action-button media-rotate" (click)="rotateMedia()">Rotate</div>
        <div class="media-action-button media-more" (click)="showMoreMenu = !showMoreMenu">More
          <div class="media-more-menu" [class.showMediaMore]="showMoreMenu">
            <div class="media-go-message" (click)="goToCurrentMediaMessage()">Go to message</div>
            <div class="media-delete" (click)="data.showDeletePopup(message)" *ngIf="isOwnMessage">Delete</div>
          </div>
        </div>
        <div class="media-action-button media-close" [mat-dialog-close]></div>
      </div>
    </div>
  </div>
  <div class="media-modal-holder" [class.image-loaded]="imgHasLoaded">
    <div class="media-preview" [ngClass]="rotateAngleClass">
      <div class="media-modal-backdrop" [mat-dialog-close]></div>
      <div class="spinner"></div>
      <img [src]="currentImageUrl" class="media" (error)="mediaPreviewError($event)" (load)="mediaPreviewLoaded($event)" />
      <div class="change-media go-prev" (click)="changeMediaInView(false)" [class.disabled]="currentMediaIndex === 0"></div>
      <div class="change-media go-next" (click)="changeMediaInView(true)" [class.disabled]="currentMediaIndex === maxMediaIndex"></div>
    </div>
  </div>
